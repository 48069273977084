//-------------------------------------------------------
//---------------------HEADER
//-------------------------------------------------------

export const SET_CONDENSED_HEADER = 'SET_CONDENSED_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';
export const HIDE_FOOTER = 'HIDE_FOOTER';
export const USE_FULL_WIDTH_BODY = 'USE_FULL_WIDTH_BODY';
export const DISABLE_SCROLL = 'DISABLE_SCROLL';
export const DISABLE_MOBILE_SCROLL = 'DISABLE_MOBILE_SCROLL';
export const SHOW_AUTHENTICATION = 'SHOW_AUTHENTICATION';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SHOW_NOTICE = 'SHOW_NOTICE';
export const SET_BREAK_POINT = 'SET_BREAK_POINT';


export const GET_REGISTRATION_PREVIEW = "GET_REGISTRATION_PREVIEW";
export const B_EXPRESS_INTEREST = "B_EXPRESS_INTEREST";
export const B_REGISTER = "B_REGISTER";
export const B_ONBOARD = "B_ONBOARD";
export const GET_ONBOARDING_PREVIEW = "GET_ONBOARDING_PREVIEW"


export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";


//-------------------------------------------------------
//------------------CAMPAIGNS
//-------------------------------------------------------


export const GET_NEW_SUBSCRIPTION_COUNT = "GET_NEW_SUBSCRIPTION_COUNT";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGN_PURCHASES_ACTIVE = "GET_CAMPAIGN_PURCHASES_ACTIVE";
export const GET_CAMPAIGN_PURCHASES_HISTORY = "GET_CAMPAIGN_PURCHASES_HISTORY";
export const GET_PARTNER_DETAILS = "GET_PARTNER_DETAILS"

export const GET_HASHTAG_COUNT = "GET_HASHTAG_COUNT"
export const GET_HASHTAG_IMAGES = "GET_HASHTAG_IMAGES"

//-------------------------------------------------------
//------------------LANDING
//-------------------------------------------------------
export const GETTING_STALLS = 'GETTING_STALLS';
export const GET_STALLS_SUCCESS = 'GET_STALLS_SUCCESS';
export const GET_ENTRIES_FEATURED = 'GET_ENTRIES_FEATURED';
export const GET_ENTRY = 'GET_ENTRY'

//-------------------------------------------------------
//-----------------STALL STATS
//-------------------------------------------------------
export const GET_SUSPENDED_MEALS = "GET_SUSPENDED_MEALS"


export const GETTING_STALL_STATS = 'GETTING_STALL_STATS';
export const GET_STALL_STATS_SUCCESS = 'GET_STALL_STATS_SUCCESS';

export const GETTING_SUSPENDEDMEALS_ACTIVE = 'GETTING_SUSPENDEDMEALS_ACTIVE';
export const GET_SUSPENDEDMEALS_ACTIVE_SUCCESS = 'GET_SUSPENDEDMEALS_ACTIVE_SUCCESS';
export const GET_SUSPENDEDMEALS_ACTIVE_ERROR = 'GET_SUSPENDEDMEALS_ACTIVE_ERROR';

export const GETTING_SUSPENDEDMEALS_HISTORY = 'GETTING_SUSPENDEDMEALS_HISTORY';
export const GET_SUSPENDEDMEALS_HISTORY_SUCCESS = 'GET_SUSPENDEDMEALS_HISTORY_SUCCESS';
export const GET_SUSPENDEDMEALS_HISTORY_ERROR = 'GET_SUSPENDEDMEALS_HISTORY_ERROR';

export const GET_PARTICIPATING_STALLS = 'GET_PARTICIPATING_STALLS';

//-------------------------------------------------------
//-----------------PURCHASE PREVIEW
//-------------------------------------------------------


export const GUEST_PURCHASE_MEALS = "GUEST_PURCHASE_MEALS"

export const GETTING_PURCHASE_PREVIEW = 'GETTING_PURCHASE_PREVIEW';
export const GET_PURCHASE_PREVIEW_SUCCESS = 'GET_PURCHASE_PREVIEW_SUCCESS';

export const CLEAR_PURCHASE_PROPS = 'CLEAR_PURCHASE_PROPS'
export const EXCHANGING_FOR_STRIPE_TOKEN = 'EXCHANGING_FOR_STRIPE_TOKEN';
export const FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN = "FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN";
export const FAILED_TO_PURCHASE_MEALS = 'FAILED_TO_PURCHASE_MEALS';
export const FAILED_TO_CHARGE = "FAILED_TO_CHARGE"
export const PURCHASE_MEALS_SUCCESS = 'PURCHASE_MEALS_SUCCESS'

export const PURCHASE_MEALS_DEFERRED_PAYMENT = 'PURCHASE_MEALS_DEFERRED_PAYMENT'

export const GETTING_CLAIMS_FROM_PURCHASE = 'GETTING_CLAIMS_FROM_PURCHASE';
export const GET_CLAIMS_FROM_PURCHASE_SUCCESS = 'GET_CLAIMS_FROM_PURCHASE_SUCCESS';
export const GET_CLAIMS_FROM_PURCHASE_ERROR = 'GET_CLAIMS_FROM_PURCHASE_ERROR';

export const EXCHANGE_FOR_STRIPE_TOKEN = "EXCHANGE_FOR_STRIPE_TOKEN";
export const MARK_MEALS_AS_PAID = "MARK_MEALS_AS_PAID";

// export const SAVE_PURCHASE_DETAILS = "SAVE_PURCHASE_DETAILS"
export const SAVE_PURCHASE_COUNT_AND_TYPE = "SAVE_PURCHASE_COUNT_AND_TYPE"
export const SAVE_PURCHASE_USER_INFORMATION = "SAVE_PURCHASE_USER_INFORMATION"
export const CLEAR_PURCHASE_INFORMATION = "CLEAR_PURCHASE_INFORMATION"


//-------------------------------------------------------
//-----------------UNSUBSCRIBE
//-------------------------------------------------------
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const GET_RECURRING_PURCHASES = 'GET_RECURRING_PURCHASES';

//-------------------------------------------------------
//-----------------INITIATIVES
//-------------------------------------------------------

export const GET_INITIATIVES = 'GET_INITIATIVES';
export const GET_INITIATIVES_STALL_STATS = 'GET_INITIATIVES_STALL_STATS';

//-------------------------------------------------------
//-----------------BEFICIARY PROFILE
//-------------------------------------------------------
export const GET_BENEFICIARY_PROFILE = 'GET_BENEFICIARY_PROFILE';
export const BENEFICIARY_RECOMMEND = "BENEFICIARY_RECOMMEND"


//-------------------------------------------------------
//-----------------PROFILE
//-------------------------------------------------------
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_CARD = 'ADD_CARD';
export const AUTHENTICATE_ACCOUNT = 'AUTHENTICATE_ACCOUNT';
export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_CARD = 'DELETE_CARD';
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_CARDS = 'GET_CARDS';
export const CLEAR_AUTHENTICATION_STATE = 'CLEAR_AUTHENTICATION_STATE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_VERIFICATION_CODE = 'VERIFY_VERIFICATION_CODE';
export const GET_USER_QUICK_PROFILE = "GET_USER_QUICK_PROFILE";
export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD_WITH_VERIFICATION_CODE = "RESET_PASSWORD_WITH_VERIFICATION_CODE";

export const USER_PURCHASE_MEALS = "USER_PURCHASE_MEALS"
export const GET_PURCHASE_PREVIEW = "GET_PURCHASE_PREVIEW"
export const GET_USER_PURCHASE_COUNT_HISTORY = "GET_USER_PURCHASE_COUNT_HISTORY"
export const GET_USER_PURCHASES = "GET_USER_PURCHASES"
export const EXTENDED_GET_USER_PURCHASES = "EXTENDED_GET_USER_PURCHASES"


export const GET_STRIPE_CLIENT_SECRET = "GET_STRIPE_CLIENT_SECRET"
export const CAPTURE_CLIENT_INTENT = "CAPTURE_CLIENT_INTENT"
//-------------------------------------------------------
//---------------APPLICATION LEVEL TYPES
//-------------------------------------------------------
export const RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND';
export const UNAUTHORISED = "UNAUTHORISED"
export const FORBIDDEN = "FORBIDDEN"


//-------------------------------------------------------
//---------------UI TYPES
//-------------------------------------------------------
export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM"
export const DISPLAY_HEADER = "DISPLAY_HEADER"
export const DISPLAY_SIDE_MENU = "DISPLAY_SIDE_MENU"
export const DISPLAY_MOBILE_MENU = "DISPLAY_MOBILE_MENU"
export const TOGGLE_MODAL = "TOGGLE_MODAL"


//-------------------------------------------------------
//---------------ANALYTICS TYPES
//-------------------------------------------------------
export const INITIATED_ACTION = "INITIATED_ACTION"
export const INITIALISE_NEW_SESSION = "INITIALISE_NEW_SESSION"
export const INITIALISE_NEW_CLIENT = "INITIALISE_NEW_CLIENT"
export const PING = "PING"



