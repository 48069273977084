import React, {useEffect} from "react";
import "./ParticipatingStalls.modules.scss"
import {isMobile} from "react-device-detect";
import facebookIcon from "../../../01_application/assets/icons/facebook.svg";
import instagramIcon from "../../../01_application/assets/icons/instagram.svg";
import stalls from "../../../01_application/assets/ui/stalls.svg";
import {GET_PARTICIPATING_STALLS} from "../../../02_redux/redux_config/types";
import StallList from "./components/StallList";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import Impression from "../../components_higherOrder/Ping/Impression";

const ParticipatingStalls = () => {


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          0: GET_PARTICIPATING_STALLS_LOADING,
          1: GET_PARTICIPATING_STALLS_SUCCESS,
          2: GET_PARTICIPATING_STALLS_ERROR,
          3: GET_PARTICIPATING_STALLS_EVENT_CODE,
          4: GET_PARTICIPATING_STALLS_MESSAGE,
          5: GET_PARTICIPATING_STALLS_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_PARTICIPATING_STALLS);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
          getParticipatingStalls
        } = useActions()

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    getParticipatingStalls();
  }, [])


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const handleNavigateToFacebook = () => {
    // console.log("ismobile????", isMobile)
    if (isMobile) {
      window.location.href = 'fb://page?id=126429478034462'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href = 'https://www.facebook.com/secondmeal.sg/'
    }
  }

  const handleNavigateToInstagram = () => {
    // console.log("ismobile????", isMobile)
    if (isMobile) {
      window.location.href = 'instagram://user?username=secondmeal.io'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href = 'https://www.instagram.com/secondmeal.sg/'
    }
  }


  const renderStallList = () => {
    if (GET_PARTICIPATING_STALLS_SUCCESS) {
      return <StallList stalls={GET_PARTICIPATING_STALLS_PAYLOAD}/>
    }
  }


  return (
    <Impression pageload page={"participating_stalls"}>
      <div className={"pageContainer"}>

        <div className={"aboutUsBlock"}>
          <div className={"aboutUsText"}>
            PARTICIPATING STALLS
          </div>
        </div>

        <div className={"contentBlock"}>
          <div className={"contentBlock_image"} style={{background: "#02BAA3"}}>
            <img src={stalls} alt="stalls" className="image"/>
          </div>
        </div>

        <div className={"link-block"}>
          <img src={facebookIcon} alt="info" className="link-icon" onClick={() => handleNavigateToFacebook()}/>
          &nbsp;
          <img src={instagramIcon} alt="info" className="link-icon" onClick={() => handleNavigateToInstagram()}/>
        </div>

        <div className={"descriptionBlock"}>
          <div className={"descriptionText"}>
            There is a basic meal and decent meal at each stall you can claim by scanning your QR code. If you would
            like any additional rice, noodles or ingredients, you will have to pay for the excess.
            Please check with each stall what the meal is.
            <br/>
            <br/>
            Please check this page regularly to be updated on the participating stalls.
          </div>

          {renderStallList()}

        </div>
      </div>
    </Impression>
  )
}


export default ParticipatingStalls;

