
import React, {Component} from "react";
// import PropTypes from "prop-types";
import moment from "moment";

import "./ClaimsFromPurchaseTable.modules.scss";
import stall from "../../../../01_application/assets/icons/food-stall.svg";
import user from "../../../../01_application/assets/icons/user2.svg";

class ClaimsFromPurchaseTable extends Component {

  renderRows = () => {
    return this.props.data.map((claim, i) => {
      // console.log("purpurpurpurpurpurpurpurpur", claim);
      return (
        <div className={"table-row-container"} key={claim._id}>

          <div className={"date-time-section"}>
            <div>
              {moment(claim.date, 'YYYYMMDD').format("Do MMMM YYYY")}

            </div>
            <div>
              {claim.time}
            </div>
          </div>

          <div className={"rowDetails"}>

            <img src={stall} alt="info" className="stallIcon" onClick={() => {
            }}/>


            <div className={"stallDetails"}>
              <div className={"stallName"}>
                {claim._stallID.stallname}
              </div>


              <div className={"stallAddress"}>
                {claim._stallID.address}
              </div>
            </div>

          </div>

          <div className={"rowDetails"}>

            <img src={user} alt="info" className="userIcon" onClick={() => {
            }}/>

            <div className={"beneficiary"}>
              {claim._beneficiary}
            </div>
          </div>

          {/*<div className={"spacer"}/>*/}

        </div>
      );
    });
  };

  render = () => {
    // console.log("yoyoyoyoyoyoy", this.props.data);
    return <div className={"table-container"}>{this.renderRows()}</div>;
  };
}


export default ClaimsFromPurchaseTable;
