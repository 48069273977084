//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import {
  DISPLAY_HEADER,
  DISPLAY_MOBILE_MENU,
  DISPLAY_SIDE_MENU,
  SET_ACTIVE_MENU_ITEM,
  TOGGLE_MODAL
} from "../redux_config/types";

const INITIAL_STATE = {
  DISPLAY_HEADER     : true,
  DISPLAY_SIDE_MENU  : null,
  DISPLAY_MOBILE_MENU: false,
  ACTIVE_MENU_ITEM   : "home",
  OPEN_MODAL         : false
}

export function ui(state = INITIAL_STATE, action) {

  switch (action.type) {
    
    case DISPLAY_MOBILE_MENU:
      return {...state, DISPLAY_MOBILE_MENU: action.toggle};
    
    case DISPLAY_SIDE_MENU:
      return {...state, DISPLAY_SIDE_MENU: action.toggle};
    
    case DISPLAY_HEADER:
      return {...state, DISPLAY_HEADER: action.toggle};
    
    case SET_ACTIVE_MENU_ITEM:
      return {...state, ACTIVE_MENU_ITEM: action.item};
    
    case TOGGLE_MODAL:
      return {...state, ACTIVE_MENU_ITEM: action.toggle};
  
      
    default:
      return state;
  }
}
