import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_CAMPAIGNS} from "../redux_config/types";
import {USER_V2_GET_CAMPAIGNS} from "../redux_config/endpoints";

export const getCampaigns = (payload) => {
  return async dispatch => {

    const config = {}
    const params = {}

    try {
      await apiFlight(GET_CAMPAIGNS, dispatch).post(
        USER_V2_GET_CAMPAIGNS,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
