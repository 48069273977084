import axios from "axios";
import { suspendedActiveURL} from "../redux_config/endpoints"
import {
    GETTING_SUSPENDEDMEALS_ACTIVE,
    GET_SUSPENDEDMEALS_ACTIVE_SUCCESS,
    GET_SUSPENDEDMEALS_ACTIVE_ERROR
} from "../redux_config/types";


/*
      Actions on this page will be handled in suspendedMeals_reducer.js
 */

export const getSuspendedMealsActive = (stall_id) => {
    return async dispatch => {
        dispatch ({ type: GETTING_SUSPENDEDMEALS_ACTIVE})

        const config = {
            // headers: {
            //     Authorization: "Bearer " + JWT
            // }
        }

        const result = await axios.get(`${suspendedActiveURL}/${stall_id}`, config);

        // console.log("SUSPENDED_MEALS_ACTIVE", result)

        if(result.data.status_code === 200 ) {
            return dispatch({
                type: GET_SUSPENDEDMEALS_ACTIVE_SUCCESS,
                payload: result.data.payload
            })
        } else {
            return dispatch({
                type: GET_SUSPENDEDMEALS_ACTIVE_ERROR
            })
        }
    }
}
