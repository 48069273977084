import React from "react";
import styles from "./UserContactInfoForm.module.scss";

const UserContactInfoForm = (props) => {
  
  const handleUpdateFormikValue = (field, value) => {
    props.handleUpdateFormikValue(field, value)
  }
  
  return (
    <div className={styles.formBlock}>
      <div className={styles.heading2Text}>
        What is your contact information?
      </div>
      
      <div className={styles.subheadingText}>
        If needed, the delivery partner can update you on your delivery.
      </div>
      
      <input
        type="text"
        name={"contact_number"}
        className={`${styles.formField}`}
        placeholder={"Mobile Phone Number"}
        onBlur={(e) => handleUpdateFormikValue("contact_number", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
    </div>
  )
  
  
}

export default UserContactInfoForm