/**
 * get errorCode from endpoints
 * fallback to statusCode if errorCode is not available
 * @param  {object}     err     xhr response object
 * @return {string}             error code
 */
export function getEventCode(err) {
  // console.warn('Boilerplate: Check api error response to ensure error code is captured properly.');
  // console.log("in error code function", err)

  if (!err) return "0";

  let code = null;
  if (err) {
    if (err.event_code) {
      code = err.event_code;
    }
  }
  if (!code && err) {
    code = 0//err.StatusCode;
  }

  return code || "0";
}
