import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {B_REGISTER} from "../redux_config/types";
import { USER_V2_BENEFICIARY_REGISTER} from "../redux_config/endpoints";


export const beneficiaryRegister = (payload) => {
  return async dispatch => {

    const config = {}
    const params = {
      name: payload.name ,
      email: payload.email,
      contact_number: payload.contact_number,
      profile_picture_url: payload.profile_picture_url,
      supporting_documents: payload.supporting_documents,
      initiative_id: payload.initiative_id
    }

    try {
      await apiFlight(B_REGISTER, dispatch).post(
        USER_V2_BENEFICIARY_REGISTER,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
