import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_ADDRESSES} from "../redux_config/types";
import { USER_V2_GET_ADDRESSES} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";

export const USER_V2_GET_ADDRESSES_ACTION = (payload) => {
  
  isLoading(GET_ADDRESSES)
  
  return async dispatch => {
  
    if (isLoading(GET_ADDRESSES) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }
  
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(GET_ADDRESSES, dispatch).post(
        USER_V2_GET_ADDRESSES,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
