import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {AUTHENTICATE_ACCOUNT} from "../redux_config/types";
import {USER_V2_AUTHENTICATE_ACCOUNT} from "../redux_config/endpoints";
import {isLoading} from "../redux_utilities/loadingStatus";
import {Dispatch} from "redux";


interface USER_V2_AUTHENTICATE_ACCOUNT_REQUEST_PAYLOAD {
  email: string
  password: string
}

export const USER_V2_AUTHENTICATE_ACCOUNT_ACTION = (payload: USER_V2_AUTHENTICATE_ACCOUNT_REQUEST_PAYLOAD) => {

  return async (dispatch: Dispatch<any>) => {

    if (isLoading(AUTHENTICATE_ACCOUNT) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }

    const config = {}
    // const params = {}


    try {
      await apiFlight(AUTHENTICATE_ACCOUNT, dispatch).post(
        USER_V2_AUTHENTICATE_ACCOUNT,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }

}


export interface USER_V2_AUTHENTICATE_ACCOUNT_SUCCESS_PAYLOAD {
  jwt: string
}