//keys.js - figure out what set of credentials to return
if (process.env.REACT_APP_DEPLOYMENT === 'PRODUCTION') {
  module.exports = require('./prod');
} else if (process.env.REACT_APP_DEPLOYMENT === 'STAGING') {
  module.exports = require('./staging');
} else if (process.env.REACT_APP_DEPLOYMENT === 'LOCAL_STAGING') {
  module.exports = require('./local_staging');
} else if (process.env.REACT_APP_DEPLOYMENT === 'DEV') {
  module.exports = require('./dev');
}
