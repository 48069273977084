import apiFlight from "../redux_utilities/apiFlightInterceptor";
import { GET_CARDS} from "../redux_config/types";
import { USER_V2_GET_CARDS} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";

export const USER_V2_GET_CARDS_ACTION = (payload) => {
  
  return async dispatch => {
  
    if (isLoading(GET_CARDS) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }
  
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(GET_CARDS, dispatch).post(
        USER_V2_GET_CARDS,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
