import React from "react";
import "./ProgresBar.scss"
import {handleCalculatePercentage} from "../../../04_utilities/common/calculatePercentage";
import {MealHealthStatus} from "../MealHealthStatus/mealHealthStatus";

const handleRenderHealthStatus = (percentage) => {

  const status = MealHealthStatus(percentage)

  switch (status) {
    case "Severely Low" :
      return (
        <div className={"low"}>
          Severely Low
        </div>
      )

    case "Low" :
      return (
        <div className={"low"}>
          Low
        </div>
      )

    case "Average" :
      return (
        <div className={"average"}>
          Average
        </div>
      )

    case "Healthy" :
      return (
        <div className={"healthy"}>
          Healthy
        </div>
      )

  }
}



const ProgressBar = (props) => {

  const {count, expected, meal_subscription_count} = props

  // console.log(count)
  // console.log(expected)

  const percentage = handleCalculatePercentage(count, expected)

  return (
    <div className={"progress-bar-block"}>
      <div className={"stats"}>
        <div>Status:</div>
        <div>{handleRenderHealthStatus(percentage)}</div>
      </div>

      <div className={"progress-bar"}>
        <div
          style={{
            background: "#74B8AE",
            height: 5,
            width: `${percentage}%`
          }}
        />
      </div>


      <div className={"stats"}>
        <div className={"fontSize"}>meal subscriptions:</div>
        <div className={"fontSize"}>{meal_subscription_count ? meal_subscription_count : 0}</div>
      </div>

      <div className={"stats"}>
        <div className={"fontSize"}>meals available for claim:</div>
        <div className={"fontSize"}>{count}</div>
      </div>
      <div className={"stats"}>
        <div className={"fontSize"}>expected meals claimed a month:</div>
        <div className={"fontSize"}>{expected}</div>
      </div>

    </div>
  );

};

// Button.propTypes = propTypes;

export default ProgressBar
