import axios from "axios";
import { stallsURL} from "../redux_config/endpoints"
import {GETTING_STALLS, GET_STALLS_SUCCESS} from "../redux_config/types";


/*
      Actions on this page will be handled in stall_reducer.js
 */



export const getStalls = () => {
 return async dispatch => {

     dispatch ({ type: GETTING_STALLS})

     const config = {
         // headers: {
         //     Authorization: "Bearer " + JWT
         // }
     }

     // console.log("dfdfdfdfdfdfdfdfdfdfdfdfdfdf", stallsURL)
     // console.log(process.env.REACT_APP_DEPLOYMENT)

     const result = await axios.get(`${stallsURL}`, config);

     // console.log(result)

     if(result.data.status_code === 200 ) {
         return dispatch({
             type: GET_STALLS_SUCCESS,
             payload: result.data.payload
         })
     }

     // if(result.data.status_code === SUCCESS && result.data.payload.length === 0) {
     //   return dispatch({
     //     type: "EMPTY",
     //   })
     // }

     // if ( result.status !== 200) {
     //     return dispatch({
     //         type: "ERROR_GETTING_PACKS",
     //     })
     // }

 }
}
