import {useTypedSelector} from "./useTypedSelector";
import reducers from "../reducers";

type apiFlightStatusType<Type> = [loading: boolean, success: boolean, error: boolean, eventCode: string, message:string, payload:Type ]

export function GetApiFlightStatus<Type>(type: string): apiFlightStatusType<Type> {
    //reading the key api from the API reducer

    return useTypedSelector(state => state.api)[type as keyof typeof reducers] || [false, false, false, 0, "", null];
}
