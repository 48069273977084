import styles from "./PurchaseStatusTag.module.scss"

export interface PurchaseStatusTagInterface {
  refunded: boolean,
  processing_payment: number,
  status: number,
}

const PurchaseStatusTag = (props: PurchaseStatusTagInterface) => {

  const {
    refunded,
    processing_payment,
    status,
  } = props

  const renderRefunded = () => {
    return (
            <div className={styles.refunded}>
              <div className={styles.tagText}>
              Refunded
              </div>
            </div>
    )
  }

  const renderFailed = () => {
    return (
            <div className={styles.failed}>
              <div className={styles.tagText}>
              Failed
              </div>
            </div>
    )
  }

  const renderContent = () => {
    if (refunded) {
      return renderRefunded()
    }

    if (status === 204) {
      return renderFailed()
    }


    if (processing_payment === 2) {
      return renderFailed()
    }


  }
  return (
          <div>
            {renderContent()}
          </div>
  )
}

export default PurchaseStatusTag