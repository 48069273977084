import React from "react";
import {Link} from "react-router-dom";
import sanitizeHtml from 'sanitize-html';
import styles from "./HeroSection.module.scss";
import ProgressBar from "../../../components_common/ProgressBar/ProgressBar";
import HPHPCover from "../../../../01_application/assets/ui/cardboard_collector.jpeg";
import NeedyFamily from "../../../../01_application/assets/ui/nf2.jpeg";
import PP from "../../../../01_application/assets/ui/mw.jpeg";
import SN from "../../../../01_application/assets/ui/sstock_1362243170.jpeg";
import LOVE from "../../../../01_application/assets/ui/love202x.jpeg"

const HeroSection = props => {

  const renderInitiativeImage = (initiative) => {
    switch (initiative) {
  
      case "603b3b80db11c237e6469285":
        return(
            <img src={LOVE} className={styles.image}/>
        )

      case "5b1354600601250014b38587":
        return (
          <img src={HPHPCover} className={styles.image}/>
        )

      case "5e9d0305344025bf969b78c3":
        return (
          <img src={NeedyFamily} className={styles.image}/>
        )

      case "5eaad6b5960d0d8ec06921ac":
        return (
          <img src={PP} className={styles.image}/>
        )

      case "6074a45acf95e74e7e3fae16":
        return (
          <div className={styles.imageContainer}>
            <img src={SN} className={styles.image}/>
          </div>
        )

      default:
        return null
    }
  }

  const renderCount = () => {
    return props.item.general_meal_fund[0].count
  };

  const renderInitiativeName = () => {
    return (
      <div className={"bold"}>
        {props.item.general_meal_fund[0].initiative_id.name}
      </div>
    )
  }

  const renderButton = () => {
    return (
      <Link
        to={`/buy-meals/${props.item.general_meal_fund[0].stall_id._id}&${
          props.item.general_meal_fund[0].initiative_id._id
        }`}
        className={styles.generalFundCta}
      >
        <div className={styles.generalFundCtaText}>Buy Meals</div>
      </Link>
    )
  }
  // console.log(props)

  return (


    <div className={styles.container}>
      <div className={styles.initiativeStatsHero}>
        {/*<img src={logo} alt="secondmeal" className="hero__logo" />*/}

        {/*<div className={"status6-description"}>*/}
        {/*  <div className={"status6-description-text"}>*/}
        {/*    <div className={"bold"}>*/}
        {/*      NEW LOOK, UPDATED SYSTEM!*/}
        {/*    </div>*/}
        {/*    As we scale across more stalls and more causes, it may not necessarily make sense to purchase meals at individual stalls for individual causes/initiatives.*/}
        {/*    Therefore we are now exploring the possibility of purchasing meals at a general pool for the cause/initiative and the system will allocate meals to stalls in need.*/}
        {/*  </div>*/}

        {/*</div>*/}

        <div className={styles.backBtnSection}>
          <a>
            <div className={styles.backBtn} onClick={() => props.handleGoBack()}>
              back
            </div>
          </a>
        </div>

        <h2 className={styles.heroText}>{props.item.general_meal_fund[0].initiative_id.name}</h2>
        <div className={styles.initiativeDescriptionText}
             dangerouslySetInnerHTML={{__html: sanitizeHtml(props.item.general_meal_fund[0].initiative_id.description)}}
        />


        {/*dangerouslySetInnerHTML={{__html: sanitizeHtml(props.item.general_meal_fund[0].initiative_id.description)}}*/}
        {/*{props.item.general_meal_fund[0].initiative_id.description}*/}
        {/*Calcarias credere in festus vasa!As i have facilitated you, so you must capture one another.All hands meet.Ecce, exemplar!*/}
        {/*Vae, nuclear vexatum iacere!Alter apolloniates semper examinares gemna est. Agripetas accelerare in avenio!*/}
        {/*Cum decor peregrinationes, omnes brodiumes reperire clemens, audax urbses. Nunquam manifestum nixus.*/}
        {/*For a springy quartered sauce, add some soy sauce and celery.*/}
        {/*</div>*/}
        <div className={styles.imageContainer}>
          {renderInitiativeImage(props.item.general_meal_fund[0].initiative_id._id)}
        </div>

        <div className={styles.gmfSuspendedCount}>{renderCount()}</div>

        <div className={styles.heroParagraphText}>
          meals in the general pool for the people at {renderInitiativeName()}
        </div>

        {/*<Link to={`/stall-stats/${props.item.general_meal_fund[0].stall_id._id}`}>*/}
        <Link
          to={`/initiative/distribution-stats/s_id=${props.item.general_meal_fund[0].stall_id._id}&i_id=${props.item.general_meal_fund[0].initiative_id._id}`}>
          <div className={"view-details"}>
            {/*<a>*/}
            View claims
            {/*</a>*/}
          </div>
        </Link>

        <div className={styles.initiativeStallsHeaderProgressBar}>
          <ProgressBar
            count={props.item.general_meal_fund[0].initiative_id.meals_available}
            expected={props.item.general_meal_fund[0].initiative_id.monthly_expected_claim_count}
            meal_subscription_count={props.item.general_meal_fund[0].initiative_id.meal_subscription_count}
          />
        </div>

        {renderButton()}

        {/*<h2 className={"hero-paragraph-text"}>*/}
        {/*    <div className={"hero-paragraph-text__bold"}>*/}
        {/*        So why not buy a meal for someone in need today?*/}
        {/*    </div>*/}
        {/*</h2>*/}
      </div>
    </div>
  );
};

export default HeroSection;
