import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useEffect, useRef, useState} from "react";
import short from "short-uuid";
import {shallowEqual, useSelector} from "react-redux";
import {AnalyticsReducerInterface} from "../../../02_redux/reducers/analytics_reducer";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";


const REFRESH_MINUTE_MS = 90_000;
const SESSION_TIME_MINUTE_MS = 120_000;
const Analytics = () => {

  const analytics = useTypedSelector<AnalyticsReducerInterface>(state => state.analytics, shallowEqual)

  const {
    initialiseNewSessionAction,
    initialiseNewClientAction
  } = useActions()


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    runAnalyticsCheck()
  }, [])


  // use custom useInterval hook
  // if we use normal setInterval, the function will make reference to initial state values on first render
  useInterval(() => {
    runAnalyticsCheck()
  }, REFRESH_MINUTE_MS)


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const runAnalyticsCheck = () => {
    checkClientIDExist()

    const sessionIDExist = checkSessionIDExist()

    if (sessionIDExist === true) {
      checkLastAction()
    }
  }


  const checkClientIDExist = () => {
    if (analytics.c_id === "") {

      const cid = short.generate()

      initialiseNewClientAction({
        c_id: cid
      })

      return false
    }

    return true
  }

  const checkSessionIDExist = () => {
    if (analytics.s_id === "") {
      initialiseNewSessionAction({
        s_id: short.generate()
      })

      return false
    }
    return true
  }

  const checkLastAction = () => {

    if (analytics.last_action_at === null) {
      return null
    }

    const now = new Date().getTime()
    const lastAction = new Date(analytics.last_action_at)
    const sessionExpiry = lastAction.setMilliseconds(lastAction.getMilliseconds() + SESSION_TIME_MINUTE_MS)


    if (now > sessionExpiry) {
      // console.log("expired, starting new session")
      initialiseNewSessionAction({
        s_id: short.generate().toString()
      })
    }
  }

  return null
}

export default Analytics


function useInterval(callback: any, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      //@ts-ignore
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
