import React, { Component} from "react";
// import PropTypes from "prop-types";
import moment from "moment";
// import ReactTable from "react-table";

import "./SuspendedMealsTableActive.scss";
import styles from "./SuspendedMealsTableActive.module.scss";

class SuspendedMealsTableHistory extends Component {

  renderPaymentProcessing = (purchase) => {
    if (purchase?.processing_payment === 1) {
      return (
        <i className={"processing-payment"}>Processing Payment</i>
      )
    }
  }

  renderTag = (purchase) => {
    if (purchase?.auto_repeat_purchase === true) {
      return (
        <i className={styles.autoRepeatPurchase}>Automated Repeat Purchase</i>
      )
    }

    if (purchase?.subscription === 1) {
      return (
        <i className={styles.monthlySubscription}>Monthly Subscription</i>
      )
    }
  }



  renderRows = () => {
    return this.props.data.map((purchase, i) => {
      return (
        <div className={styles.tableRow} key={purchase._id}>

          <div className={styles.mainInfoContainer}>
            <div className={styles.leftInnerRowContainer}>
              <div className={styles.rowDate}>
                {moment(purchase.created_at).format("Do MMMM YYYY")}
              </div>

              <div className={styles.displayName}>
                {purchase.display_name}
              </div>

              {this.renderTag(purchase)}
              {this.renderPaymentProcessing(purchase)}

            </div>


            <div className={styles.rightInnerRowContainer}>
              <div className={styles.countContainer}>
                {purchase.suspended_count}/{purchase.purchased}
              </div>
            </div>
          </div>

          <div className={styles.userPurchaseStats}>
            <div className={""}>
              Meals available for claim: &nbsp;
            </div>

            <div className={""}>
              {purchase.suspended_count}
            </div>
          </div>

          <div className={styles.userPurchaseStats}>
            <div className={""}>
              Total meals purchased:
            </div>

            <div className={""}>
              {purchase.purchased}
            </div>
          </div>

        </div>
      );
    });
  };

  render = () => {
    //console.log("yoyoyoyoyoyoy", this.props.data);
    return <div className={styles.tableContainer}>{this.renderRows()}</div>;
  };
}

export default SuspendedMealsTableHistory;
