import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./HeaderNavItem.scss";

const propTypes = {
    /** @type {string}        name to display */
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
    ]).isRequired,
    /** @type {string}        where this nav item links to */
    to: PropTypes.string.isRequired,
    /** @type {boolean}        active state */
    active: PropTypes.bool,
    /** @type {node}        children elements */
    children: PropTypes.node,
    /** @type {function}    nav item click callback */
    onClick: PropTypes.func,
    /** @type {string}        react className prop */
    className: PropTypes.string,
    /** @type {boolean}    show notification badge as red dot */
    badge: PropTypes.bool
};

const defaultProps = {
    active: false,
    children: null,
    onClick: () => {},
    className: null,
    badge: false
};

/**
 * top level nav item
 */
export default function HeaderNavItem({
    name,
    to,
    active,
    children,
    onClick,
    className,
    badge
}) {
    let classes = "HeaderNavItem list-inline-item " + className + " ";
    if (children) classes += " HeaderNavItem--has-children ";
    if (active) classes += " HeaderNavItem--active ";

    return (
        <li className={classes} onClick={() => onClick()}>
            <NavLink
                to={to}
                // className="HeaderNavItem__link animated d-flex"
                className={({ isActive }) => isActive ? 'HeaderNavItem__link--active' : 'HeaderNavItem__link animated d-flex'}
                    // activeClassName="HeaderNavItem__link--active"
            >
                {name}
                {/*{badge ? <div className="HeaderNavItem__badge circle" /> : null}*/}
            </NavLink>
            {/*<div*/}
            {/*    className={*/}
            {/*        "HeaderNavItem__sub " +*/}
            {/*        (active && " HeaderNavItem__sub--active ")*/}
            {/*    }*/}
            {/*>*/}
            {/*    {children}*/}
            {/*</div>*/}
        </li>
    );
}
HeaderNavItem.propTypes = propTypes;
HeaderNavItem.defaultProps = defaultProps;
