import React from "react";
import { useSelector} from "react-redux";
import {disableMobileScroll, setActiveSection, DISPLAY_MOBILE_MENU} from "../../../../02_redux/actions";
import HeaderNavItem from "../HeaderNavItem/HeaderNavItem.jsx";
import "./HeaderNavItems.scss";
import {useActions} from "../../../../02_redux/redux_utilities/useActions";



/**
 * main navigation
 */
const HeaderNavItems = () => {




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const {
          disableMobileScroll,
          DISPLAY_MOBILE_MENU,
          setActiveSection
        } = useActions()


  const ui = useSelector(store => store.ui)


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  const hideMobileNavigation = () => {
    DISPLAY_MOBILE_MENU(false);
    disableMobileScroll(false);
    setActiveSection("");
  }


    return (
      <nav
        className={
          "headerNavMain " +
          (ui.condenseHeader &&
            " headerNavMain--condensed ")
        }
      >
        <ul className="headerNavMain__list">

          <HeaderNavItem
            name="Home"
            to="/home"
            onClick={() => hideMobileNavigation()}
          />

          <HeaderNavItem
            name="About us"
            to="/about-us"
            onClick={() => hideMobileNavigation()}
          />


          {/*<HeaderNavItem*/}
          {/*  name="Campaigns"*/}
          {/*  to="/campaigns"*/}
          {/*  onClick={() => this.hideMobileNavigation()}*/}
          {/*/>*/}

          <HeaderNavItem
            name="Participating stalls"
            to="/participating-stalls"
            onClick={() => hideMobileNavigation()}
          />

          <HeaderNavItem
            name="How it works"
            to="/how-it-works"
            onClick={() => hideMobileNavigation()}
          />

          <HeaderNavItem
            name="Why meals?"
            to="/why-meals"
            onClick={() => hideMobileNavigation()}
          />

          {/*<HeaderNavItem*/}
          {/*    name="FAQs"*/}
          {/*    to="/faq"*/}
          {/*    onClick={() => this.hideMobileNavigation()}*/}
          {/*/>*/}

        </ul>
      </nav>
    );

}



export default HeaderNavItems

