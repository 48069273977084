import React, {Component, useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import styles from "./InitiativeDistributionStats.module.scss";
import StallStatusCard from "./components/stallStatusCard";
import Masonry from "react-masonry-css";
import {GET_INITIATIVES_STALL_STATS, GET_STALL_STATS_SUCCESS} from "../../../02_redux/redux_config/types";
import {Dots} from "react-activity";
import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import HeroSection from "./components/HeroSection";
import {getInitiativeStallStats, getStallStats, resetState} from "../../../02_redux/actions";
import Plate from "../../../01_application/assets/icons/lunch.svg";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import useWindowDimensions from "../../../04_utilities/hooks/getWindowDimensions";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {SuspendedMealsReducer} from "../../../02_redux/reducers/suspendedMeals_reducer";
import {StallReducer} from "../../../02_redux/reducers/stall_reducer";
import Impression from "../../components_higherOrder/Ping/Impression";

// import styles from "../InitiativeDonationStats/InitiativeDonationStats.module.scss";

const InitiativeDistributionStats = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // state = {
  //   height: null,
  //   width: null,
  //   stalls: [],
  // };
  //

  const {
          0: GET_INITIATIVES_STALL_STATS_LOADING,
          1: GET_INITIATIVES_STALL_STATS_SUCCESS,
          2: GET_INITIATIVES_STALL_STATS_ERROR,
          3: GET_INITIATIVES_STALL_STATS_EVENT_CODE,
          4: GET_INITIATIVES_STALL_STATS_MESSAGE,
          5: GET_INITIATIVES_STALL_STATS_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_INITIATIVES_STALL_STATS);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          resetState,
          getStallStats,
          getInitiativeStallStats
        } = useActions()

  const stalls = useTypedSelector<StallReducer>(state => state.stalls)
  const navigate = useNavigate();
  const params = useParams()


  //Doing this weird split because react router v6 no longer supports multiple params and
  // I want to keep the link the same
  let {id} = params
  if (!id) {
    id = ""
  }
  const split = id.split("&")
  const stall_id = split[0].split("=")[1]
  const initiative_id = split[1].split("=")[1]


  const {height, width} = useWindowDimensions();

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    getInitiativeStallStats(initiative_id);

    getStallStats({
      stall_id: stall_id,
      initiative_id: initiative_id ? initiative_id : null
    });

  }, [])


  const handleGoBack = () => {
    navigate(-1)
  }

  const renderMasonrySection = () => {
    const breakpointColumnsObj = {
      default: 3,
      1200: 2,
      700: 1
    };

    const masonryOptions = {
      transitionDuration: 0.2,
      //itemSelector: ".grid-image",
      //columnWidth: 120,
      isFitWidth: true
    };

    if (GET_INITIATIVES_STALL_STATS_PAYLOAD.stalls.length > 0) {
      return (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={styles.myMasonryGrid}
          columnClassName={styles.myMasonryGridColumn}
          // @ts-ignore
          options={masonryOptions}
        >
          {GET_INITIATIVES_STALL_STATS_PAYLOAD.stalls.map((item: any) => {
            return (
              <div key={item._id}>
                <StallStatusCard
                  item={item}
                  initiative={GET_INITIATIVES_STALL_STATS_PAYLOAD.general_meal_fund[0].initiative_id}/>
              </div>)
          })}

        </Masonry>
      );
    }

  };

  const renderInitiativeName = () => {
    return (
      <div className={styles.boldInitiativeName}>
        {GET_INITIATIVES_STALL_STATS_PAYLOAD.general_meal_fund[0].initiative_id.name}
      </div>
    )
  }


  const renderEmptyState = () => {
    return (
      <div className={styles.emptyStateBlock}>

        <img src={Plate} alt="info" className={styles.plateIcon}/>

        No meals served yet this month for people at
        &nbsp;
        {renderInitiativeName()}
      </div>
    )
  }


  const renderStalls = () => {
    if (GET_INITIATIVES_STALL_STATS_LOADING) {
      return (
        <div className={styles.initiativeStallStatsLoading}>
          <Dots/>
        </div>
      )

    }

    if (GET_INITIATIVES_STALL_STATS_SUCCESS) {
      if (GET_INITIATIVES_STALL_STATS_PAYLOAD.stalls.length === 0) {
        return (
          renderEmptyState()
        )
      } else {
        return (
          renderMasonrySection()
        )
      }
    }
  }


  const renderStallName = () => {
    return (
      <div className={styles.boldStallName}>
        {stalls.payload.stall.stallname}
      </div>
    )
  }


  const renderHeroSection = () => {
    return (
      <HeroSection
        stall={stalls}
        data={stalls.payload.monthlyArr}
        height={height}
        width={width}
      />
    )
  }


  const renderStats = () => {
    if (stalls.case === GET_STALL_STATS_SUCCESS) {
      if (stalls.payload.monthlyArr.length > 0) {
        return renderHeroSection()
      }
    }
  };


  return (
    <Impression pageload page={"initiative_distribution_stats"}>
      <div className={styles.landingContainer}>
        {/*{renderHeroSection()}*/}

        {renderStats()}

        <div className={styles.stallCardsCollection}>
          {renderStalls()}
        </div>
      </div>
    </Impression>
  );

};


export default InitiativeDistributionStats;

