import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {UPLOAD_DOCUMENT} from "../redux_config/types";
import { USER_V2_UPLOAD_DOCUMENT} from "../redux_config/endpoints";

export const uploadDocument = (payload) => {

  const { upload_id, type, file  } = payload

  return async dispatch => {

    let data = new FormData();
    data.set("upload_id", upload_id)
    data.set("type", type)
    data.append('file', file);

    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
      }
    }

    try {

      await apiFlight(UPLOAD_DOCUMENT, dispatch).post(
        USER_V2_UPLOAD_DOCUMENT,
        data,
        config
      )
    } catch (err) {
      console.log(err)
    }

  }
}

