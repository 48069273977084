import React, {Component} from "react";
// import FamilyDining from "../../../assets/ui/family-dining.svg"
// import Landing from "../../../assets/ui/landing.png"
// import Purchase from "../../../assets/ui/purchase.png"
// import TransparentPricing from "../../../assets/ui/transparentPricing.svg"
// import "./AboutUs.modules.scss"
// import {
//   isMobile
// } from "react-device-detect";
import PrivacyPolicyMD from "./PrivacyPolicy.md"
import ReactMarkdown from 'react-markdown';


class PrivacyPolicy extends Component {

  state = { markdown: '' };

  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PrivacyPolicyMD).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }


  render() {
    const { markdown } = this.state;
    return (
      <div className={"pageContainer"}>

        <div className={"aboutUsBlock"}>
          <div className={"aboutUsText"}>
            PRIVACY POLICY AND DATA PROTECTION NOTICE
          </div>
        </div>

        <ReactMarkdown source={markdown} />

          <div className={"footer"}/>
      </div>

    )
  }


}

export default PrivacyPolicy;
