import React from "react";
import styles from "./UserAddressForm.module.scss";

const UserAddressForm = (props) => {
  
  const handleUpdateFormikValue = (field, value) => {
    props.handleUpdateFormikValue(field, value)
  }
  
  return (
    <div className={styles.formBlock}>
      
      <div className={styles.heading2Text}>
        Enter your name and address:
      </div>
      
      <input
        type="text"
        name={"name"}
        className={`${styles.formField}`}
        placeholder={"Name"}
        onBlur={(e) => handleUpdateFormikValue("name", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
      
      <input
        type="text"
        name={"address_street"}
        className={`${styles.formField}`}
        placeholder={"Building Name and Street"}
        onBlur={(e) => handleUpdateFormikValue("address_street", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
      
      <input
        type="text"
        name={"address_unit_no"}
        className={`${styles.formField}`}
        placeholder={"Apartment or Suite"}
        onBlur={(e) => handleUpdateFormikValue("address_unit_no", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
      
      <input
        type="text"
        name={"address_postal_code"}
        className={`${styles.formField}`}
        placeholder={"Postal Code"}
        onBlur={(e) => handleUpdateFormikValue("address_postal_code", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
      
      <input
        type="text"
        name={"address_country"}
        className={`${styles.formField}`}
        placeholder={"Country"}
        disabled={true}
        defaultValue={"Singapore"}
        onBlur={(e) => handleUpdateFormikValue("address_country", e.target.value)}
        // onBlur={() => handleGenerateCombinations(true, "UPDATE_NAME")}
      />
    
    </div>
  )
}

export default UserAddressForm
