import {ReactComponent as EmailVerifiedIcon} from "../../../../01_application/assets/icons/email-verified.svg";
import React from "react";
import styles from "./EmailVerified.module.scss"

const EmailVerified = props => {
  
  return (
    <div className={styles.container}>
      
      <div className={styles.iconBlock}>
        <EmailVerifiedIcon className={styles.icon}/>
      </div>
      
      <div className={styles.headerText}>
        Email verified
      </div>
      
      <div className={styles.subHeadingText}>
        You have successfully verified your email!
      </div>
      
      <div className={styles.subHeadingText}>
        You may now close this page
      </div>
    
    </div>
  )
}

export default EmailVerified