import React, {Component} from "react";
import FamilyDining from "../../../01_application/assets/ui/family-dining.svg"
import Landing from "../../../01_application/assets/ui/landing.png"
import Purchase from "../../../01_application/assets/ui/purchase.png"
import TransparentPricing from "../../../01_application/assets/ui/transparentPricing.svg"
import "./AboutUs.modules.scss"
import {
  isMobile
} from "react-device-detect";
import facebookIcon from "../../../01_application/assets/icons/facebook.svg";
import instagramIcon from "../../../01_application/assets/icons/instagram.svg";
import Impression from "../../components_higherOrder/Ping/Impression";

const AboutUs = () => {

  const handleNavigateToFacebook = () => {
    console.log("ismobile????", isMobile)
    if (isMobile) {
      window.location.href = 'fb://page?id=126429478034462'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href = 'https://www.facebook.com/secondmeal.io/'
    }
  }

  const handleNavigateToInstagram = () => {
    console.log("ismobile????", isMobile)
    if (isMobile) {
      window.location.href = 'instagram://user?username=secondmeal.io'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href = 'https://www.instagram.com/secondmeal.io/'
    }
  }


  return (
    <Impression pageload page={"about_us"}>
      <div className={"pageContainer"}>

        <div className={"aboutUsBlock"}>
          <div className={"aboutUsText"}>
            ABOUT US
          </div>
        </div>

        <div className={"coverImageBlock"}>
          <img src={FamilyDining} alt="dining" className="coverImage"/>
        </div>

        <div className={"link-block"}>
          <img src={facebookIcon} alt="info" className="link-icon" onClick={() => handleNavigateToFacebook()}/>
          &nbsp;
          <img src={instagramIcon} alt="info" className="link-icon" onClick={() => handleNavigateToInstagram()}/>
        </div>

        <div className={"descriptionBlock"}>
          <div className={"descriptionText"}>
            The moment of inspiration birthed from a post chanced upon on social media. It tells of people of another
            country, paying for a second cup of coffee and, passing it forward to another. The thought came that the
            same could work for meals and so came about the name of “Secondmeal”. We then registered ourselves with ACRA
            as a Non-Profit Organisation, and thus the birth of Secondmeal Ltd (UEN: 201933559N)
          </div>

          <div className={"descriptionText"}>
            Along with you, Secondmeal exists to be a vehicle to give your gift of a second meal to those in need.
          </div>

          <div className={"descriptionText"}>
            Before Secondmeal, donors often have to take a measure of faith to trust fundraisers to steward donations
            well. Secondmeal addresses key accountability and reporting constraints:
          </div>
        </div>


        <div className={"concernBlock"}>

          <div className={"concernTitleBlock"}>
            <div className={"concernTitleText"}>
              Concern #1: An accurate report of the need from the ground
            </div>
          </div>

          <div className={"concernContentBlock"}>
            <div className={"phoneBlock"}>
              <img src={Landing} alt="dining" className="phoneImage"/>
            </div>

            <div className={"concernDescriptionBlock"}>
              <div className={"concernDescriptionText"}>
                We provide real time data on Secondmeal reserves and community needs.
              </div>
            </div>
          </div>
        </div>


        <div className={"concernBlock"}>


          <div className={"concernTitleBlock"}>
            <div className={"concernTitleText"}>
              Concern #2: The appropriate and efficient use of donors’ funds
            </div>
          </div>

          <div className={"concernContentBlock"}>
            <div className={"phoneBlock"}>
              <img src={Purchase} alt="dining" className="phoneImage"/>
            </div>

            <div className={"concernDescriptionBlock"}>
              <div className={"concernDescriptionText"}>
                Your contribution is channelled to the intended beneficiaries in a transparent and accountable manner.
              </div>
            </div>
          </div>

          <div className={"transparentPricingBlock"}>
            <div className={"transparentPricingText"}>
              WRITE TO US
            </div>
            <div className={"link-block"}>
              <img src={facebookIcon} alt="info" className="link-icon" onClick={() => handleNavigateToFacebook()}/>
            </div>

            <div className={"descriptionBlock"}>
              <div className={"descriptionText"}>
                Write to us on facebook! Facebook has actually very decent customer ticketing system, and chats are
                way cooler than emails.
              </div>
            </div>

          </div>

          <div className={"transparentPricingBlock"}>
            <div className={"transparentPricingText"}>
              TRANSPARENT PRICING
            </div>
          </div>


          {/*list out meaing of general meal funds, excess will be used to offset other cost*/}
          {/*List out more cost*/}

          <div className={"descriptionBlock"}>
            <div className={"descriptionText"}>
              We are commited to keep the whole process as transparent as possible, that is why we have the pricing
              breakdown for each meal you purchase.
              A significant bulk of the money goes directly to the stall owners that which provides the food to the
              beneficiaries. As far as possible
              we do try out best on board smaller hawkers and stall owners.
            </div>

            <div className={"descriptionText"}>
              When Secondmeal first launched, users are able to buy meals for the specific initiative at the individual
              stall.
              However, as we scale across more stalls and initiatives, it may not necessarily be efficient to purchase
              meals at the
              individual stalls for the specific initiative you will like to support. If there are 3 causes and 20
              stalls, there
              will be 60 possible combinations. Meals will be stuck a combination where the beneficiaries of the
              initiative do not
              frequent often. For example, if donors were to buy meals at a stall at Woodlands for a particular
              initiative whose
              beneficiaries primarily stays at Ang Mo Kio, the meals at the mentioned combination will hardly be served.
              This is an inefficient way of allocating meals.
            </div>

            <div className={"descriptionText"}>
              Therefore we are implementing a General Meal Pool where users will buy meals for a cause, and the system
              will
              allocate to the stalls accordingly. This system is considerably more efficient. The price of the meals
              will
              now be aggregated, so you may possibly see a slighly higher price under a newer system. Secondmeal
              will be taking a slightly higher margin at certain stalls and this will
              be used to offset the losses for other meals.
            </div>

            <div className={"descriptionText"}>
              Secondmeal is established as Non-Profit Organisation and is fully run by volunteers. But by establishing
              an organisation for transparency, there are further cost involved as compared to running it as a hobby
              project.
              Cost such as ACRA, accounting and auditing, a more robust API server as well as Database server, Apple and
              Google developer
              licenses, development tools and subscriptions, transport fees as we shuttle between coffee shops. The
              extra margin that Secondmeal accrued
              will be used to offset these expenses. Do let us know if you would like to make a contribution to these
              other costs.
            </div>

          </div>

          <div className={"pricingImageBlock"}>
            <img src={TransparentPricing} alt="TransparentPricing" className="pricingImage"/>
          </div>


          <div className={"footer"}/>

        </div>
      </div>
    </Impression>

  )


}

export default AboutUs;
