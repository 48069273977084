import {AUTHENTICATE_ACCOUNT, CREATE_USER_ACCOUNT, REQUEST_PASSWORD_RESET} from "../../../02_redux/redux_config/types";
import {
  resetState,
  setActiveMenuItem,
  USER_V2_AUTHENTICATE_ACCOUNT_ACTION,
  USER_V2_CREATE_USER_ACCOUNT_ACTION,
  USER_V2_REQUEST_PASSWORD_RESET_ACTION,
} from "../../../02_redux/actions";
import {CLEAR_AUTHENTICATION_STATE_ACTION} from "../../../02_redux/actions/profile_actions";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styles from "./SignIn.module.scss";
import toast from "react-hot-toast";
import NotSignedIn from "../../components_common/SignIn/SignIn";
import CreateAccount from "../../components_common/CreateAccount/CreateAccount";
import ForgotPasswordForm from "../../components_common/ForgotPasswordForm/ForgotPasswordForm";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {ProfileReducer} from "../../../02_redux/reducers/profile_reducer";
import {useForm} from "react-hook-form";
import Impression from "../../components_higherOrder/Ping/Impression";


export interface SignInFormValues {
  name: string
  email: string
  password: string
}


export enum SignInViews {
  NULL            = "NULL",
  SIGN_IN         = "SIGN_IN",
  CREATE_ACCOUNT  = "CREATE_ACCOUNT",
  PROFILE         = "PROFILE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}


const SignIn = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const [view, setView] = useState<SignInViews>(SignInViews.NULL)

  const {
          0: AUTHENTICATE_ACCOUNT_LOADING,
          1: AUTHENTICATE_ACCOUNT_SUCCESS,
          2: AUTHENTICATE_ACCOUNT_ERROR,
          3: AUTHENTICATE_ACCOUNT_EVENT_CODE,
          4: AUTHENTICATE_ACCOUNT_MESSAGE,
          5: AUTHENTICATE_ACCOUNT_PAYLOAD,
        } = GetApiFlightStatus<any>(AUTHENTICATE_ACCOUNT);

  const {
          0: CREATE_USER_ACCOUNT_LOADING,
          1: CREATE_USER_ACCOUNT_SUCCESS,
          2: CREATE_USER_ACCOUNT_ERROR,
          3: CREATE_USER_ACCOUNT_EVENT_CODE,
          4: CREATE_USER_ACCOUNT_MESSAGE,
          5: CREATE_USER_ACCOUNT_PAYLOAD,
        } = GetApiFlightStatus<any>(CREATE_USER_ACCOUNT);

  const {
          0: REQUEST_PASSWORD_RESET_LOADING,
          1: REQUEST_PASSWORD_RESET_SUCCESS,
          2: REQUEST_PASSWORD_RESET_ERROR,
          3: REQUEST_PASSWORD_RESET_EVENT_CODE,
          4: REQUEST_PASSWORD_RESET_MESSAGE,
          5: REQUEST_PASSWORD_RESET_PAYLOAD,
        } = GetApiFlightStatus<any>(REQUEST_PASSWORD_RESET);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const {
          setActiveMenuItem,
          resetState,
          USER_V2_REQUEST_PASSWORD_RESET_ACTION,
          CLEAR_AUTHENTICATION_STATE_ACTION,
          USER_V2_AUTHENTICATE_ACCOUNT_ACTION,
          USER_V2_CREATE_USER_ACCOUNT_ACTION,
        } = useActions()

  const navigate = useNavigate();
  const profile = useTypedSelector<ProfileReducer>(state => state.profile)

  const {
          register,
          handleSubmit,
          setValue,
          getValues,
          formState: {errors}
        } = useForm<SignInFormValues>()

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (profile.jwt === null) {
      setView(SignInViews.SIGN_IN)
    } else {
      navigate("/user")
    }
  }, [])


  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_ERROR) {
      toast.error(AUTHENTICATE_ACCOUNT_MESSAGE)
      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [AUTHENTICATE_ACCOUNT_ERROR])


  useEffect(() => {
    if (CREATE_USER_ACCOUNT_ERROR) {
      toast.error(CREATE_USER_ACCOUNT_MESSAGE)
      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [CREATE_USER_ACCOUNT_ERROR])


  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_SUCCESS) {
      resetState(AUTHENTICATE_ACCOUNT)
      navigate("/user")
    }
  }, [AUTHENTICATE_ACCOUNT_SUCCESS])


  useEffect(() => {
    if (CREATE_USER_ACCOUNT_SUCCESS) {

      toast.success(`Account successfully created!`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      navigate("/user")
    }
  }, [CREATE_USER_ACCOUNT_SUCCESS])


  useEffect(() => {
    if (REQUEST_PASSWORD_RESET_SUCCESS) {
      toast.success(`Reset link sent! `, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })
      handleUpdateViewToSignIn()
    }
  }, [REQUEST_PASSWORD_RESET_SUCCESS])


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleRequestPasswordReset = () => {

    // console.log("reset password for : ",  formik.values.email)

    USER_V2_REQUEST_PASSWORD_RESET_ACTION({
      email: getValues().email,
    })
  }

  const handleAuthenticateAccount = () => {
    const body = {
      email: getValues().email,
      password: getValues().password
    }

    USER_V2_AUTHENTICATE_ACCOUNT_ACTION(body)
  }

  const handleCreateAccount = () => {
    const body = {
      name: getValues().name,
      email: getValues().email,
      password: getValues().password
    }

    USER_V2_CREATE_USER_ACCOUNT_ACTION(body)
  }

  const handleUpdateViewToResetPassword = () => {
    setView(SignInViews.FORGOT_PASSWORD)
  }

  const handleUpdateViewToCreateAccount = () => {
    setView(SignInViews.CREATE_ACCOUNT)
  }

  const handleUpdateViewToSignIn = () => {
    setView(SignInViews.SIGN_IN)
  }

  const renderContent = () => {
    if (view === SignInViews.SIGN_IN) {
      return (
        <NotSignedIn
          headingTextSize={""}
          headingText={"Please sign in."}
          subHeadingText={""}
          register={register as any}
          handleUpdateViewToCreateAccount={handleUpdateViewToCreateAccount}
          handleUpdateViewToResetPassword={handleUpdateViewToResetPassword}
          handleAuthenticateAccount={handleAuthenticateAccount}
          AUTHENTICATE_ACCOUNT_LOADING={AUTHENTICATE_ACCOUNT_LOADING}
        />
      )
    }

    if (view === SignInViews.CREATE_ACCOUNT) {
      return (
        <CreateAccount
          register={register as any}
          handleUpdateViewToSignIn={handleUpdateViewToSignIn}
          handleCreateAccount={handleCreateAccount}
          CREATE_USER_ACCOUNT_LOADING={CREATE_USER_ACCOUNT_LOADING}
        />
      )
    }


    if (view === SignInViews.FORGOT_PASSWORD) {
      return (
        <ForgotPasswordForm
          headingTextSize={""}
          register={register as any}
          handleUpdateViewToSignIn={handleUpdateViewToSignIn}
          // handleCreateAccount={handleCreateAccount}
          handleRequestPasswordReset={handleRequestPasswordReset}
          REQUEST_PASSWORD_RESET_LOADING={REQUEST_PASSWORD_RESET_LOADING}
        />
      )
    }

  }


  return (
    <Impression pageload page={"sign_in"}>
      <div className={styles.pageContainer}>
        {renderContent()}
        <div className={styles.bottomSpacer}/>
      </div>
    </Impression>
  )


}


export default SignIn


