import React from "react";
import {Link} from "react-router-dom";
import infoIcon from "../../../../01_application/assets/icons/info.svg";
import sanitizeHtml from 'sanitize-html';
import styles from "./initiativeStatusCard.module.scss";
import {MealHealthStatus} from "../../../components_common/MealHealthStatus/mealHealthStatus";
import NeedyFamily from "../../../../01_application/assets/ui/nf2.jpeg";
import HPHPCover from "../../../../01_application/assets/ui/cardboard_collector.jpeg";
import PP from "../../../../01_application/assets/ui/mw.jpeg";
import SN from "../../../../01_application/assets/ui/sstock_1362243170.jpeg";
import LOVE from "../../../../01_application/assets/ui/love202x.jpeg"
import Interaction from "../../../components_higherOrder/Ping/Interaction";
// <img src={HopScotch} alt="secondmeal" className={styles.item}/>

const InitiativeStatusCard = props => {
  // console.log(props);

  const renderInitiativeImage = (initiative) => {
    switch (initiative) {
      
      case "603b3b80db11c237e6469285":
        return(
          <div className={styles.imageContainer}>
            <img src={LOVE} className={styles.image}/>
          </div>
        )

      case "5b1354600601250014b38587":
        return (
          <div className={styles.imageContainer}>
            <img src={HPHPCover} className={styles.image}/>
          </div>
        )

      case "5e9d0305344025bf969b78c3":
        return (
          <div className={styles.imageContainer}>
            <img src={NeedyFamily} className={styles.image}/>
          </div>
        )

      case "5eaad6b5960d0d8ec06921ac":
        return (
          <div className={styles.imageContainer}>
            <img src={PP} className={styles.image}/>
          </div>
        )

      case "6074a45acf95e74e7e3fae16":
        return (
          <div className={styles.imageContainer}>
            <img src={SN} className={styles.image}/>
          </div>
        )

      default:
        return null
    }
  }

  const handleCalculatePercentage = () => {
    const percentage = (props.item.meals_available / props.item.monthly_expected_claim_count) * 100;

    // console.log(percentage)

    if (percentage >= 100) {
      return 100;
    }

    return percentage;
  };

  const renderButton = () => {
    return (
      <Interaction
        elementProps={{
          element_name: 'donate_meals',
          element_type:"button"
        }}
        interactionProps={{onClick: {action: 3}}}
      >
      <Link
        to={`/buy-meals/${props.item.general_meal_fund}&${props.item._id}`}
        className={styles.generalFundCta}
      >
        <div className={styles.generalFundCtaText}>Buy Meals</div>
      </Link>
      </Interaction>
    )
  }

  const renderCount = () => {

    const status = MealHealthStatus(handleCalculatePercentage())

    // console.log(status)

    switch (status) {
      // display number of meals lacking instaed of how many meals
      // case "Low" :
      //   return (
      //     <div>
      //       <div
      //         className={`${styles.suspendedCount} ${styles.lowColour}`}>{props.item.monthly_expected_claim_count - props.item.meals_available}</div>
      //       <div className={`${styles.pending} ${styles.lowColour}`}>meals short from healthy</div>
      //     </div>
      //   )

      //display number of meals
      case "Severely Low" :
      case "Low" :
        return (
          <div>
            <div
              className={`${styles.suspendedCount} ${styles.lowColour}`}>{props.item.meals_available}</div>
            <div className={`${styles.pending} ${styles.lowColour}`}>meals left in reserve only</div>
          </div>
        )

      default:
        return (
          <div>
            <div className={styles.suspendedCount}>{props.item.meals_available}</div>
            <div className={styles.pending}>meals in collective pool</div>
          </div>
        )


    }
  };

  const renderStatus = () => {
    if (props.item.status === 1) {
      return (
        <div className={styles.infoSection}>
          <img src={infoIcon} alt="info" className={styles.infoIcon}/>

          <div className={styles.infoText}>
            This stall is currently not raising meals on this platform. The
            initiative is raising funds on their own and paying the stall
            directly.
          </div>
        </div>
      );
    } else if (props.item.status === 2) {
      return (
        <div className={styles.infoSection}>
          <img src={infoIcon} alt="info" className={styles.infoIcon}/>

          <div className={styles.infoText}>
            We are currently raising funds for this stall, beneficiaries are not
            claiming from this pool yet.
          </div>
        </div>
      );
    } else if (props.item.status === 3) {
      // return (
      //     <div className={"info-section"}>
      //         <img src={infoIcon} alt="info" className="info-icon" />
      //
      //         <div className={"info-text"}>
      //             .
      //         </div>
      //     </div>
      // );
    }
  };

  // const renderCTA = () => {
  //   if (props.item.status === 1) {
  //     return <div className={"card__bottom-button__buy-text"} />;
  //   } else {
  //     return (
  //       <Link
  //         to={`/buy-meals/${props.item.stall_id._id}&${
  //           props.item.initiative_id._id
  //         }`}
  //         className={
  //           "card__bottom-button " +
  //           (props.item.status === 1 ? "disable-button" : " ")
  //         }
  //       >
  //         <div className={"card__bottom-button__buy-text"}>Buy Meals</div>
  //       </Link>
  //     );
  //   }
  // };


  const handleRenderHealthStatus = (percentage) => {


    const status = MealHealthStatus(handleCalculatePercentage())

    // console.log(status)

    switch (status) {
      case "Severely Low" :
        return (
          <div className={"low"}>
            Severely Low
          </div>
        )

      case "Low" :
        return (
          <div className={styles.low}>
            Low
          </div>
        )

      case "Average" :
        return (
          <div className={styles.average}>
            Average
          </div>
        )

      case "Healthy" :
        return (
          <div className={styles.healthy}>
            Healthy
          </div>
        )

    }
  }

  const handleRenderPricing = (price_per_meal) => {
    if (price_per_meal) {
      return (
        <div>
          at <b>${(price_per_meal / 100).toFixed(2)}</b> per meal
        </div>
      )
    }
  }


  const renderProgressBar = () => {

    // console.log(props.item)

    const percentage = handleCalculatePercentage()


    return (
      <div className={styles.progressBarBlock}>
        <div className={styles.stats}>
          <div>Status:</div>
          <div>{handleRenderHealthStatus(percentage)}</div>
        </div>

        <div className={styles.progressBar}>
          <div
            style={{
              background: "#74B8AE",
              height: 5,
              width: `${percentage}%`
            }}
          />
        </div>

        <div className={styles.stats}>
          <div className={styles.fontSize}>meal subscriptions:</div>
          <div className={styles.fontSize}>{props.item.meal_subscription_count? props.item.meal_subscription_count : 0}</div>
        </div>


        <div className={styles.stats}>
          <div className={styles.fontSize}>meals available for claim:</div>
          <div className={styles.fontSize}>{props.item.meals_available}</div>
        </div>


        <div className={styles.stats}>
          <div className={styles.fontSize}>expected meals claimed a month:</div>
          <div className={styles.fontSize}>{props.item.monthly_expected_claim_count}</div>
        </div>

      </div>
    );

  };


  return (
    <div className={styles.card}>
      <div className={styles.cardInfoSection}>


        <div className={styles.landingInitiativeName}>{props.item.name}</div>

        <div className={styles.description}
             dangerouslySetInnerHTML={{__html: sanitizeHtml(props.item.brief_description)}}
        />


        {renderInitiativeImage(props.item._id)}

        {renderCount()}

        {handleRenderPricing(props.item.price_per_meal)}

        {renderProgressBar()}

        {renderButton()}
        
        <Interaction
          elementProps={{
            element_name: 'view_donations',
            element_type:"button"
          }}
          interactionProps={{onClick: {action: 3}}}
        >
        <Link to={`/initiative/donation-stats/s_id=${props.item.general_meal_fund}&i_id=${props.item._id}`}>
          <div className={styles.viewDonationDetails}>
            {/*<a>*/}
            View donations
            {/*</a>*/}
          </div>
        </Link>
        </Interaction>
        
        <Interaction
          elementProps={{
            element_name: 'view_claims',
            element_type:"button"
          }}
          interactionProps={{onClick: {action: 3}}}
        >
        <Link to={`/initiative/distribution-stats/s_id=${props.item.general_meal_fund}&i_id=${props.item._id}`}>
          <div className={styles.viewClaimDetails}>
            {/*<a>*/}
            View claims
            {/*</a>*/}
          </div>
        </Link>
        </Interaction>


        <div>{renderStatus()}</div>
      </div>

      {/*{renderCTA()}*/}
    </div>
  );
};

export default InitiativeStatusCard;
