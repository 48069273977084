import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {PING} from "../redux_config/types";
import {KLANGA_V1_PING} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";
import {Dispatch} from "redux";
import {KLANGA_API_KEY} from "../../00_config/keys/keys";
import {PingTypeEnum} from "../../03_views/components_higherOrder/Ping/Ping";


export interface KLANGA_PING_REQUEST_PAYLOAD {
  action?: PingTypeEnum
  page?: string
  section?: string
  component?: string
  element_type?: string
  element_name?: string
  element_index?: string
}


export const KLANGA_PING_ACTION = (payload: KLANGA_PING_REQUEST_PAYLOAD) => {

  return async (dispatch: Dispatch<any>) => {

    if (isLoading(PING) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }


    const config = {
      headers: {
        APIKey: KLANGA_API_KEY
      }
    }
    // const params = {}

    try {
      await apiFlight(PING, dispatch).post(
        KLANGA_V1_PING,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
