import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_HASHTAG_IMAGES} from "../redux_config/types";
import {USER_V2_GET_HASHTAG_IMAGES} from "../redux_config/endpoints";

export const getHashtagImages = (payload) => {
  return async dispatch => {

    const config = {}
    // const params = {}

    try {
      await apiFlight(GET_HASHTAG_IMAGES, dispatch).post(
        USER_V2_GET_HASHTAG_IMAGES,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
