import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {ADD_CARD} from "../redux_config/types";
import {USER_V2_ADD_CARD} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";

export const USER_V2_ADD_CARD_ACTION = (payload) => {
  
  return async dispatch => {
  
    if (isLoading(ADD_CARD) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }
  
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(ADD_CARD, dispatch).post(
        USER_V2_ADD_CARD,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
