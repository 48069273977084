import React from "react";
import ProgressBar from "./ProgressBar";
import styles from "./MealCountSelection.module.scss";
import Interaction from "../../../components_higherOrder/Ping/Interaction";


const MealCountSelection = props => {

// console.log(props)

  const renderProgressBar = () => {
    return (
      <ProgressBar
        view={props.view}
        views={props.views}
      />
    )
  }

  const renderAutoRepeatOption = () => {
    if (props.mealCount >= 20) {
      return (
        <div className={styles.radioOption}>
          <div className={styles.radioBtn}>
            <Interaction
              elementProps={{
                element_name: 'auto_repeat',
                element_type: "radio_button"
              }}
              interactionProps={{onClick: {action: 3}}}
            >
            <input
              type="radio"
              //value={result.SITE_NAME}
              checked={props.auto_repeat_purchase === true}
              onChange={() => {
                props.handleSubscription(0)
                props.handleUpdateRepeat(true)
              }}
            />
            </Interaction>
          </div>
          <div className={styles.radioOptionText}>

            Automatically purchase another {props.mealCount} meals when this current purchase is fully claimed by
            the beneficiaries.

          </div>
        </div>
      )
    }
  }

  const renderAdditionalPurchaseInformation = () => {
    if(props.subscription === 1) {
      return(
        <div className={styles.addtionalInformationBlock}>
          The payment gateway will make a charge someday on the 1st - 5th every month. <br/> <br/>
          We will send you a receipt!  <br/> <br/>
          Also remember you can opt out anytime!
        </div>
      )
    }

    if(props.auto_repeat_purchase === true) {
      return(
        <div className={styles.addtionalInformationBlock}>
          We will send you a receipt everytime a purchase has been made  <br/> <br/>
          Also remember you can opt out anytime!
        </div>
      )
    }

  }


  const renderRepeatPurchaseOption = () => {
    return (
      <div className={styles.repeatPurchaseBlock}>
        <div className={styles.labelText}>Automated purchase</div>
        <div className={styles.subLabelText}>
          <b>🤔 Consider buying lesser meals now but commit for a longer period of time</b> <br/><br/>

          As Secondmeal grows larger and feeding more people, we have reached a phase where it is a constant juggle
          between out reach to the beneficiaries and being sure that we have enough meals to feed the beneficiaries.
          Automated purchase helps us forecast how many meals
          will be coming our way in future. This gives us accurate data and confidence to onboard new beneficiaries to
          benefit from this programme.<br/><br/>


          You may choose opt out from this automated purchase anytime via <b>any</b> of the following methods: <br/>

        </div>
        <div className={styles.subLabelText}>
          &nbsp; 1. Via "Manage purchase" on the email receipt, or <br/>
          &nbsp; 2. Via "Manage purchase" on the meal tracking link, or <br/>
          &nbsp; 3. Write to us!
        </div>

        <div className={styles.radioOptionsContainer}>


          <div className={styles.radioOption}>
            <div className={styles.radioBtn}>
              <Interaction
                elementProps={{
                  element_name: 'subscription',
                  element_type: "radio_button"
                }}
                interactionProps={{onClick: {action: 3}}}
              >
              <input
                type="radio"
                //value={result.SITE_NAME}
                checked={props.subscription === 1}
                onChange={() => {
                  props.handleSubscription(1)
                  props.handleUpdateRepeat(false)
                }}
              />
              </Interaction>
            </div>
            <div className={styles.radioOptionText}>
              Subscription: Automatically purchase {props.mealCount} meals every month
            </div>
          </div>

          {renderAutoRepeatOption()}


          <div className={styles.radioOption}>
            <div className={styles.radioBtn}>
              <Interaction
                elementProps={{
                  element_name: 'no_recurring',
                  element_type: "radio_button"
                }}
                interactionProps={{onClick: {action: 3}}}
              >
              <input
                type="radio"
                checked={props.auto_repeat_purchase === false && props.subscription === 0}
                onChange={() => {
                  props.handleSubscription(0)
                  props.handleUpdateRepeat(false)
                }}
                // value={this.state[name]}
                // name={name}
                // key={opt.id}
                // onChange={this.selectionChanged(name)}
              />
              </Interaction>
            </div>

            <div className={styles.radioOptionText}>No thanks! 😀</div>

          </div>
        </div>
      </div>
    )
  }


  return (
    <div>

      <div>
        <div className={styles.initiativeName}>
          {
            props.purchasePreview.purchase
              .initiative_id.name
          }
        </div>

        {renderProgressBar()}

        <div className={styles.pricePerMeal}>
          $ {((props.purchasePreview.matrix.price_per_meal) / 100).toFixed(2)}{" "}
          per meal
        </div>

        <div className={styles.stallName}>
          {
            props.purchasePreview.purchase.stall_id
              .stallname
          }
        </div>

        {/*<div className={styles.stallAddress}>*/}
        {/*  {*/}
        {/*    props.purchasePreview.payload.purchase.stall_id*/}
        {/*      .address*/}
        {/*  }*/}
        {/*</div>*/}

        <div className={styles.countSection}>
          <Interaction
            elementProps={{
              element_name: 'minus_button',
              element_type: "button"
            }}
            interactionProps={{onClick: {action: 3}}}
          >
          <a>
            <div
              className={styles.counterBtn}
              onClick={props.handleCountDec}
            >
              <div className={styles.counterIndicator}>-</div>
            </div>
          </a>
          </Interaction>

          <div
            className={`${styles.countContainer}  ${props.invalidMealCount ? styles.countContainerError : ""}}`}
          >
            <Interaction
              elementProps={{
                element_name: 'count_input',
                element_type: "field_input"
              }}
              interactionProps={{onClick: {action: 3}}}
            >
            <input
              className={styles.countInput}
              type="number"
              autoFocus
              //placeholder={"YYYY"}
              value={props.mealCount}
              onChange={props.handleMealCountInput}
            />
            </Interaction>

            <div className={styles.mealText}>meals</div>
          </div>
          
          <Interaction
            elementProps={{
              element_name: 'plus_button',
              element_type: "button"
            }}
            interactionProps={{onClick: {action: 3}}}
          >
          <a>
            <div
              className={styles.counterBtn}
              onClick={props.handleCountInc}
            >
              <div className={styles.counterIndicator}>+</div>
            </div>
          </a>
          </Interaction>
        </div>

        <div className={styles.pricePerMeal}>
          Total: $ {props.handleCalculateTotal()}
        </div>

        {renderRepeatPurchaseOption()}

        {renderAdditionalPurchaseInformation()}

        {/*TOTAL:  $ {this.props.purchasePreview.payload.matrix.price_per_meal}{" "}*/}
        {/*per meal X {this.state.mealCount} meals = $ {this.state.mealCount * this.props.purchasePreview.payload.matrix.price_per_meal}*/}

        <div className={styles.buttonSection}>
          <a>
            <div
              className={styles.nextStepBtn}
              onClick={props.handleProceedToPurchaseInfo}
            >
              Proceed
            </div>
          </a>
        </div>

      </div>
    </div>
  )
}

export default MealCountSelection;
