import React from "react";
import styles from "./CardSelection.module.scss";
import {Dots} from "react-activity";
import {ReactComponent as Plus} from "../../../../01_application/assets/icons/plus.svg";
import {ReactComponent as Cancel} from "../../../../01_application/assets/icons/red-cancel.svg";
import Card from "../../../components_common/Card/Card";
import CreditCardForm from "../../../components_common/CreditCardForm/CreditCardForm";
import ProgressBar from "./ProgressBar";
import Cards from "../../../components_common/Cards/Cards";
import StripeCreditCardForm from "../../../components_common/StripeCreditCardForm/StripeCreditCardForm";
import {PaymentMethodsEnum} from "../PurchasePreview";
import {GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD} from "../../../../02_redux/actions";


interface CardSelectionInterface {
  view: string
  views: any
  initiative_id: string
  stall_id: string
  add_card: boolean
  edit_cards: boolean
  selected_card: string
  saved_cards: Array<any>
  paymentMethod: PaymentMethodsEnum
  handleToggleEditCards: () => void
  handleDeleteCardPrompt: (card_id: string) => void
  handleSelectCard: (card_id: string) => void
  handleSelectAddCard: (card_id: string) => void
  handleSelectPaymentMethod: (paymentMethod: PaymentMethodsEnum) => void
  handleUserPurchaseMeals: () => void
  handleUpdateFormikValue: (field: string, value: string) => void
  handleCancelAddCard: () => void
  GET_CARDS_LOADING: boolean
  GET_STRIPE_CLIENT_SECRET_LOADING: boolean
  GET_STRIPE_CLIENT_SECRET_PAYLOAD: GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD
  EXCHANGE_FOR_STRIPE_TOKEN_LOADING: boolean
  ADD_CARD_LOADING: boolean
  USER_PURCHASE_MEALS_LOADING: boolean

}

const CardSelection = (props: CardSelectionInterface) => {

  const {
          view,
          views,
          initiative_id,
          stall_id,
          add_card,
          edit_cards,
          paymentMethod,
          selected_card,
          saved_cards,
          handleToggleEditCards,
          handleDeleteCardPrompt,
          handleSelectCard,
          handleSelectAddCard,
          handleSelectPaymentMethod,
          handleUserPurchaseMeals,
          handleUpdateFormikValue,
          handleCancelAddCard,
          GET_CARDS_LOADING,
          GET_STRIPE_CLIENT_SECRET_LOADING,
          GET_STRIPE_CLIENT_SECRET_PAYLOAD,
          EXCHANGE_FOR_STRIPE_TOKEN_LOADING,
          ADD_CARD_LOADING,
          USER_PURCHASE_MEALS_LOADING
        } = props

  const handleDeleteCardPromptForwarder = (card_id: string) => {
    handleDeleteCardPrompt(card_id)
  }

  const handleCancelAddCardForwarder = () => {
    handleCancelAddCard()
  }

  const handleToggleEditCardsForwarder = () => {
    handleToggleEditCards()
  }

  const handleSelectCardForwarder = (card_id: string) => {
    handleSelectCard(card_id)
  }

  const handleSelectAddCardForwarder = (card_id: string) => {
    handleSelectAddCard(card_id)
  }

  const handleSelectPaymentMethodForwarder = (method: PaymentMethodsEnum) => {
    handleSelectPaymentMethod(method)
  }

  const handleUserPurchaseMealsForwarder = () => {
    handleUserPurchaseMeals()
  }

  const handleUpdateFormikValueForwarder = (field: string, value: any) => {
    handleUpdateFormikValue(field, value)
  }


  const handleAddCardForwarder = () => {
    // const {cc_no, cc_exp, cc_cvv} = checkOutFormik.values
    // handleAddCard({
    //   cc_no,
    //   cc_cvv,
    //   cc_month: parseInt(cc_exp.split("/")[0]),
    //   cc_year : parseInt(`20${cc_exp.split("/")[1]}`)
    // })

  }

  const renderProgressBar = () => {
    return (
      <ProgressBar
        view={view}
        views={views}
      />
    )
  }


  const renderAddCardButtonContent = () => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_LOADING === true || ADD_CARD_LOADING === true) {
      return <Dots/>
    }

    return "Add card"
  }

  const renderPurchaseMealsButtonContent = () => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_LOADING === true || USER_PURCHASE_MEALS_LOADING === true) {
      return <Dots/>
    }

    return "Purchase meals"
  }


  const renderButton = () => {

    if (add_card === true) {
      return (
        null
      )
    } else if (paymentMethod === PaymentMethodsEnum.NULL || selected_card === "") {
      return (
        <button className={`${styles.priBtnContainer} ${styles.buttonDisabled}`}>
          {renderPurchaseMealsButtonContent()}
        </button>
      )
    } else {
      return (
        <button className={`${styles.priBtnContainer}`} onClick={() => handleUserPurchaseMealsForwarder()}>
          {renderPurchaseMealsButtonContent()}
        </button>


      )
    }
  }

  const renderCardPaymentDetails = () => {
    if (paymentMethod === PaymentMethodsEnum.CARD) {
      return (
        <StripeCreditCardForm
          GET_STRIPE_CLIENT_SECRET_LOADING={GET_STRIPE_CLIENT_SECRET_LOADING}
          GET_STRIPE_CLIENT_SECRET_PAYLOAD={GET_STRIPE_CLIENT_SECRET_PAYLOAD?.client_secret}
          return_path={`/buy-meals/${stall_id}&${initiative_id}?v=pi`}
          handleCloseModal={() => {
            handleCancelAddCardForwarder()
          }}
        />
      )
    }
  }

  const renderPaymentMethods = () => {
    return (
      <div
        className={`${styles.paymentMethodBtn} ${paymentMethod === PaymentMethodsEnum.CARD ? styles.highlight : ""} `}
        onClick={() => handleSelectPaymentMethodForwarder(PaymentMethodsEnum.CARD)}
      >
        Credit or Debit Card
      </div>
    )
  }

  const renderCardForm = () => {
    // console.log(add_card)
    if (add_card === true) {
      return renderCardPaymentDetails()
    }
  }

  const handleEditCardText = () => {

    if (saved_cards.length === 0) {
      return ""
    }

    if (edit_cards === true) {
      return "done!"
    } else {
      return "edit cards"
    }
  }

  const renderCardList = () => {
    if (paymentMethod === PaymentMethodsEnum.CARD) {
      return (
        <div className={styles.cardsBlock}>
          <Cards
            GET_CARDS_LOADING={GET_CARDS_LOADING}
            saved_cards={saved_cards}
            edit_cards={edit_cards}
            handleDeleteCardPrompt={handleDeleteCardPromptForwarder}
            handleSelectCard={handleSelectCardForwarder}
            handleSelectAddCard={handleSelectAddCardForwarder}
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>

        {renderProgressBar()}

        <div className={styles.headingText}>
          Select your card for payment?
        </div>

        {/*{renderPaymentMethods()}*/}
        <br/>
        <br/>
        <br/>
        <a onClick={handleToggleEditCardsForwarder}>
          {handleEditCardText()}
        </a>
        {renderCardList()}


        <div className={styles.spacer}/>
        {renderCardForm()}
        {renderButton()}
      </div>

    </div>
  );
};

export default CardSelection;
