import React from 'react';
import forEach from 'lodash/forEach';
// import Log, { LogContext } from './log';
import Impression, {LogContext} from "./Impression";
import {PingTypeEnum} from "./Ping";

// import { sendLog } from './utils';

const getEventHandlers = (child: any, consumedProps: any, interactionProps: any) => {
  const eventHandlers = {};

  forEach(interactionProps, (specificHandlerProps, eventHandlerName) => {
    // @ts-ignore
    eventHandlers[eventHandlerName] = (...eventHandlerArgs: any) => {
      child.props[eventHandlerName]?.apply(child, eventHandlerArgs);
      // console.log("pinging interaction: ", {...consumedProps, ...specificHandlerProps})
      // sendLog({ ...consumedProps, ...specificHandlerProps });
    };
  });

  return eventHandlers;
}


interface InteractionProps {
  interactionProps: {
    onClick: {
      action: PingTypeEnum
    }
  }
  elementProps: {
    element_name: string
    element_type?: string
    element_index?: number
  }
  children: any
}

const Interaction = (props: InteractionProps) => {
  const {interactionProps, elementProps, children} = props;

  if (!elementProps || !interactionProps) {
    return children;
  }

  return (
    <Impression
      element_type={elementProps.element_type}
      // impression={false}
      {...elementProps}
    >
      <LogContext.Consumer>
        {(consumedProps) =>
          React.Children.map(children, (child) =>
            React.cloneElement(
              child,
              getEventHandlers(child, consumedProps, interactionProps)
            )
          )
        }
      </LogContext.Consumer>
    </Impression>
  );
}

export default Interaction
