import React, {PureComponent} from "react";

import {ErrorMessage, Field, Form, Formik} from 'formik';
import "./FormSection.modules.scss"
import Dropzone from "./Dropzone";
import FormikPersist from "../../../components_common/FormikPersist";
import {Dots} from "react-activity";
// import 'react-activity/lib/Dots/Dots.css';
import {ReactComponent as SmartPhone} from "../../../../01_application/assets/icons/smartphone.svg"
import {ReactComponent as IDCard} from "../../../../01_application/assets/icons/id-card.svg"
import ProfileImage from "./ProfileImage";


// let initialValues = {
//   name: '',
//   gender: 2,
//   dd: "",
//   mm: "",
//   yyyy: "",
//   email: '',
//   contactNumber: '',
//   password: '',
//   scanQRCode: false,
//   mealEntitlement: '',
//   weeklyMealEntitlement: '',
//   validityPeriod: '',
//   notes: ''
// }

const FormSection = props => {
  
  // const {touched, errors, values, setFieldValue, handleSubmit, handleChange} = props.beneficiaryFormik
  
  const handleReviewBeneficiaryInformation = (values) => {
    props.handleReviewBeneficiaryInformation(values)
  }
  
  const handleTab = (event, limit, setFieldValue) => {
    if (event.currentTarget.value.length >= limit) {
      event.target.nextElementSibling !== null ? event.target.nextElementSibling.focus() : console.log()
    }
    
    setFieldValue(event.currentTarget.name, event.currentTarget.value, true)
  }
  
  const handleUpdateGender = (setFieldValue, value) => {
    setFieldValue("gender", value)
  }
  
  const handleUpdateScanQR = (setFieldValue, value) => {
    setFieldValue("scanQRCode", value)
  }
  
  const handleValidation = (values) => {
    
    const errors = {};
    
    // if (!values.email) {
    //   errors.email = 'Required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    // ) {
    //   errors.email = 'Invalid email address';
    // }
    
    
    if (!values.name) {
      errors.name = 'Required';
    }
    
    if (!values.contactNumber) {
      errors.contactNumber = 'Required';
    }
    
    if (!values.dd) {
      errors.yyyy = 'Required';
    }
    
    if (!values.mm) {
      errors.yyyy = 'Required';
    }
    
    if (!values.yyyy) {
      errors.yyyy = 'Required';
    }
    
    if (parseInt(values.dd) > 31) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.mm) > 12) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.yyyy) > 2022) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.yyyy) < 1900) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    // const firstDigit = values.contactNumber.toString().charAt(0)
    //
    // if (firstDigit !== "8") {
    //   if (firstDigit !== "9") {
    //     if (firstDigit !== "6") {
    //       errors.contactNumber = 'invalid phone number';
    //     }
    //   }
    // }
    //
    // if (values.contactNumber.toString().length !== 8) {
    //   errors.contactNumber = 'invalid phone number, enter a local phone number without "+65"';
    // }
    
    if (!values.mealEntitlement) {
      errors.mealEntitlement = 'Meal entitlement required'
    }
    
    if (!values.weeklyMealEntitlement) {
      errors.weeklyMealEntitlement = 'Meal entitlement required'
    }
    
    if (!values.validityPeriod) {
      errors.validityPeriod = 'Validity required'
    }
    
    return errors;
  }
  
  const renderUploadError = () => {
    if (props.profilePictureMissing) {
      return <div className={"error-block"}>missing profile picture</div>
    }
  }
  
  const renderButtonContent = () => {
    if (props.B_ONBOARD_LOADING) {
      return <Dots/>
    }
    
    return (
      <div className={"buttonContentSubBlock"}>
        <SmartPhone fill={"#FFFFFF"} className={"icon"}/>
        <div className={"buttonContentSubBlock"}>
          Digital QR Code
        </div>
      </div>
    )
    
    
  }
  
  const renderFormSection = () => {
    // if (props.crop === true) {
    //   return null
    // }
    
    return (
      <div className={"form"}>
        <Formik
          enableReinitialize
          initialValues={props.beneficiaryInitialValues}
          validate={values => handleValidation(values)}
          onSubmit={(values) => {
            handleReviewBeneficiaryInformation(values);
          }}
        >
          {({setFieldValue, values}) => (
            <Form>
              <FormikPersist name="FormName"/>
              
              <div className={"form-label"}>
                Name as in NRIC
              </div>
              <Field type="name" name="name" className={"form-field"}/>
              <ErrorMessage name="name">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <div className={"form-label"}>
                Gender
              </div>
              
              <div className={"radioHorizontalOptionsBlock"}>
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.gender === 1}
                    onChange={() => {
                      handleUpdateGender(setFieldValue, 1)
                    }}
                  />
                  Male
                </div>
                
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.gender === 2}
                    onChange={() => {
                      handleUpdateGender(setFieldValue, 2)
                    }}
                  />
                  Female
                </div>
              </div>
              
              <div className={"form-label"}>
                Date Of Birth
              </div>
              
              <div className={"DOBBlock"} aria-label="Date of Birth">
                <Field type="number" name="dd" placeholder="DD" className={"form-field DD"}
                       onChange={e => handleTab(e, 2, setFieldValue)}/>
                <Field type="number" name="mm" placeholder="MM" className={"form-field MM"}
                       onChange={e => handleTab(e, 2, setFieldValue)}/>
                <Field type="number" name="yyyy" placeholder="YYYY" className={"form-field YYYY"}
                       onChange={e => handleTab(e, 4, setFieldValue)}/>
              </div>
              
              <ErrorMessage name="yyyy">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              {/*<div className={"form-label"}>*/}
              {/*  Email*/}
              {/*</div>*/}
              {/*<div className={"form-subLabel"}>*/}
              {/*  We will email you your peronalised link where you can access your QR code*/}
              {/*</div>*/}
              {/*<Field type="email" name="email" className={"form-field"} />*/}
              {/*<ErrorMessage name="email" >{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*/}
              
              <div className={"form-label"}>
                Mobile Number
              </div>
              <div className={"form-subLabel"}>
                The last four digits of your phone number is your pin number to access the digital QR code
              </div>
              <Field name="contactNumber" className={"form-field"}/>
              <ErrorMessage name="contactNumber">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              
              
              <div className={"form-label"}>
                Period Of Validity
              </div>
              
              <Field
                name="validityPeriod"
                as="select"
                className={"form-field"}
              >
                <option key={"1"} value="">Select period of validity</option>
                <option key={"2"} value="1">1 month</option>
                <option key={"3"} value="3">3 months</option>
                <option key={"4"} value="6">6 months</option>
                <option key={"5"} value="12">12 months</option>
                <option key={"6"} value="nil">No expiry</option>
              </Field>
              <ErrorMessage name="validityPeriod">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <div className={"form-label"}>
                Daily Meal Entitlement
              </div>
              <div className={"form-subLabel"}>
                The maximum the beneficiary can claim daily. <br/><br/> There will need to be a buffer of 2 hours
                between each meal claim.
              </div>
              <Field
                name="mealEntitlement"
                as="select"
                className={"form-field"}
              >
                <option value="">Select daily meal entitlement</option>
                <option value="1">1 meal</option>
                <option value="2">2 meals</option>
                <option value="3">3 meals</option>
                <option value="4">4 meals</option>
              </Field>
              <ErrorMessage name="mealEntitlement">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              
              <div className={"form-label"}>
                Weekly Meal Entitlement
              </div>
              <div className={"form-subLabel"}>
                The maximum the beneficiary can claim weekly. This limit takes precedence over the Daily Meal
                Entitlement. <br/><br/>
                
                If the Weekly Meal Entitlement is set to 4 and the Daily Meal Entitlement is set to 2, it means that the
                beneficiary can claim a maximum of 2 meals a day with a maximum of 4 meals a week. <br/><br/>
                
                If you will like the beneficiary to claim 1 meal a day with no restrictions, set Daily Meal Entitlement
                is set to 1 and Weekly Meal Entitlement is set to 7
              
              </div>
              <Field
                name="weeklyMealEntitlement"
                as="select"
                className={"form-field"}
              >
                <option value="">Select weekly meal entitlement</option>
                <option value="1">1 meal</option>
                <option value="2">2 meals</option>
                <option value="3">3 meals</option>
                <option value="4">4 meals</option>
                <option value="5">5 meal</option>
                <option value="6">6 meals</option>
                <option value="7">7 meals</option>
                <option value="8">8 meals</option>
                <option value="9">9 meals</option>
                <option value="10">10 meals</option>
                <option value="11">11 meals</option>
                <option value="12">12 meals</option>
                <option value="13">13 meals</option>
                <option value="14">14 meals</option>
              </Field>
              <ErrorMessage name="weeklyMealEntitlement">{msg => <div
                className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <div className={"form-label"}>
                Initiative Password
              </div>
              
              <Field type="password" name="password" className={"form-field"}/>
              <ErrorMessage name="password">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <div className={"form-label"}>
                Notes / Address
              </div>
              
              <Field type="notes" name="notes" className={"form-field"} component="textarea" rows={8}/>
              <ErrorMessage name="notes">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <Dropzone
                handleUploadDocument={props.handleUploadDocument}
                UPLOAD_DOCUMENT_LOADING={props.UPLOAD_DOCUMENT_LOADING}
                initiative_id={props.initiative_id}
              />
              
              <div className={"form-label"}>
                Digital QR code
              </div>
              
              <div className={"radioHorizontalOptionsBlock"}>
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.scanQRCode === 0}
                    onChange={() => {
                      handleUpdateScanQR(setFieldValue, 0)
                    }}
                  />
                  Yes
                </div>
                
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.scanQRCode === 1}
                    onChange={() => {
                      handleUpdateScanQR(setFieldValue, 1)
                    }}
                  />
                  No
                </div>
              </div>
              
              {renderUploadError()}
              
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              
              <div className={"buttons"}>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className={"submit-button"}*/}
                {/*  onClick={(e) => {*/}
                {/*    setFieldValue('scanQRCode', false)*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {renderButtonContent()}*/}
                {/*</button>*/}
                
                {/*<br/>*/}
                <button
                  // type="submit"
                  className={"submit-button"}
                  onClick={(e) => {
                    // setFieldValue('scanQRCode', true)
                  }}
                >
                  <IDCard fill={"#FFFFFF"} className={"icon"}/>
                  Proceed
                </button>
              
              </div>
            
            
            </Form>
          )}
        </Formik>
        
        
        
        {/*<div className={"form-label"}>*/}
        {/*  Name as in NRIC*/}
        {/*</div>*/}
        {/*<input type="name" name="name" className={"form-field"}/>*/}
        {/*{touched.name && errors.name && <div className={"error-block"}>{errors.name}</div>}*/}
        {/*/!*<ErrorMessage name="name">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Gender*/}
        {/*</div>*/}
        
        {/*<div className={"radioHorizontalOptionsBlock"}>*/}
        {/*  <div className={"radioHorizontalOptionBlock"}>*/}
        {/*    <input*/}
        {/*      type="radio"*/}
        {/*      //value={result.SITE_NAME}*/}
        {/*      checked={props.beneficiaryFormik.values.gender === 1}*/}
        {/*      onChange={() => {*/}
        {/*        handleUpdateGender(props.beneficiaryFormik.setFieldValue, 1)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    Male*/}
        {/*  </div>*/}
        {/*  */}
        {/*  <div className={"radioHorizontalOptionBlock"}>*/}
        {/*    <input*/}
        {/*      type="radio"*/}
        {/*      //value={result.SITE_NAME}*/}
        {/*      checked={props.beneficiaryFormik.values.gender === 2}*/}
        {/*      onChange={() => {*/}
        {/*        handleUpdateGender(props.beneficiaryFormik.setFieldValue, 2)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    Female*/}
        {/*  </div>*/}
        {/*</div>*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Date Of Birth*/}
        {/*</div>*/}
        
        {/*<div className={"DOBBlock"} aria-label="Date of Birth">*/}
        {/*  <input type="number" name="dd" placeholder="DD" className={"form-field DD"}*/}
        {/*         onChange={e => handleTab(e, 2, props.beneficiaryFormik.setFieldValue)}/>*/}
        {/*  <input type="number" name="mm" placeholder="MM" className={"form-field MM"}*/}
        {/*         onChange={e => handleTab(e, 2, props.beneficiaryFormik.setFieldValue)}/>*/}
        {/*  <input type="number" name="yyyy" placeholder="YYYY" className={"form-field YYYY"}*/}
        {/*         onChange={e => handleTab(e, 4, props.beneficiaryFormik.setFieldValue)}/>*/}
        {/*</div>*/}
        
        {/*{touched.yyyy && errors.yyyy && <div className={"error-block"}>{errors.yyyy}</div>}*/}
        {/*/!*<ErrorMessage name="yyyy">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*/!*<div className={"form-label"}>*!/*/}
        {/*/!*  Email*!/*/}
        {/*/!*</div>*!/*/}
        {/*/!*<div className={"form-subLabel"}>*!/*/}
        {/*/!*  We will email you your peronalised link where you can access your QR code*!/*/}
        {/*/!*</div>*!/*/}
        {/*/!*<Field type="email" name="email" className={"form-field"} />*!/*/}
        {/*/!*<ErrorMessage name="email" >{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Mobile Number*/}
        {/*</div>*/}
        {/*<div className={"form-subLabel"}>*/}
        {/*  The last four digits of your phone number is your pin number to access the digital QR code*/}
        {/*</div>*/}
        {/*<input name="contactNumber" className={"form-field"}/>*/}
        {/*{touched.contactNumber && errors.contactNumber && <div className={"error-block"}>{errors.contactNumber}</div>}*/}
        {/*/!*<ErrorMessage name="contactNumber">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        
        {/*<div className={"form-label"}>*/}
        {/*  Period Of Validity*/}
        {/*</div>*/}
        
        {/*<select*/}
        {/*  name="validityPeriod"*/}
        {/*  as="select"*/}
        {/*  className={"form-field"}*/}
        {/*>*/}
        {/*  <option key={"1"} value="">Select period of validity</option>*/}
        {/*  <option key={"2"} value="1">1 month</option>*/}
        {/*  <option key={"3"} value="3">3 months</option>*/}
        {/*  <option key={"4"} value="6">6 months</option>*/}
        {/*  <option key={"5"} value="12">12 months</option>*/}
        {/*  <option key={"6"} value="nil">No expiry</option>*/}
        {/*</select>*/}
        {/*{touched.validityPeriod && errors.validityPeriod &&*/}
        {/*  <div className={"error-block"}>{errors.validityPeriod}</div>}*/}
        {/*/!*<ErrorMessage name="validityPeriod">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Daily Meal Entitlement*/}
        {/*</div>*/}
        {/*<div className={"form-subLabel"}>*/}
        {/*  The maximum the beneficiary can claim daily. <br/><br/> There will need to be a buffer of 2 hours*/}
        {/*  between each meal claim.*/}
        {/*</div>*/}
        {/*<select*/}
        {/*  name="mealEntitlement"*/}
        {/*  as="select"*/}
        {/*  className={"form-field"}*/}
        {/*>*/}
        {/*  <option value="">Select daily meal entitlement</option>*/}
        {/*  <option value="1">1 meal</option>*/}
        {/*  <option value="2">2 meals</option>*/}
        {/*  <option value="3">3 meals</option>*/}
        {/*  <option value="4">4 meals</option>*/}
        {/*</select>*/}
        {/*{touched.mealEntitlement && errors.mealEntitlement &&*/}
        {/*  <div className={"error-block"}>{errors.mealEntitlement}</div>}*/}
        {/*/!*<ErrorMessage name="mealEntitlement">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        
        {/*<div className={"form-label"}>*/}
        {/*  Weekly Meal Entitlement*/}
        {/*</div>*/}
        {/*<div className={"form-subLabel"}>*/}
        {/*  The maximum the beneficiary can claim weekly. This limit takes precedence over the Daily Meal*/}
        {/*  Entitlement. <br/><br/>*/}
        {/*  */}
        {/*  If the Weekly Meal Entitlement is set to 4 and the Daily Meal Entitlement is set to 2, it means that the*/}
        {/*  beneficiary can claim a maximum of 2 meals a day with a maximum of 4 meals a week. <br/><br/>*/}
        {/*  */}
        {/*  If you will like the beneficiary to claim 1 meal a day with no restrictions, set Daily Meal Entitlement*/}
        {/*  is set to 1 and Weekly Meal Entitlement is set to 7*/}
        
        {/*</div>*/}
        {/*<select*/}
        {/*  name="weeklyMealEntitlement"*/}
        {/*  as="select"*/}
        {/*  className={"form-field"}*/}
        {/*>*/}
        {/*  <option value="">Select weekly meal entitlement</option>*/}
        {/*  <option value="1">1 meal</option>*/}
        {/*  <option value="2">2 meals</option>*/}
        {/*  <option value="3">3 meals</option>*/}
        {/*  <option value="4">4 meals</option>*/}
        {/*  <option value="5">5 meal</option>*/}
        {/*  <option value="6">6 meals</option>*/}
        {/*  <option value="7">7 meals</option>*/}
        {/*  <option value="8">8 meals</option>*/}
        {/*  <option value="9">9 meals</option>*/}
        {/*  <option value="10">10 meals</option>*/}
        {/*  <option value="11">11 meals</option>*/}
        {/*  <option value="12">12 meals</option>*/}
        {/*  <option value="13">13 meals</option>*/}
        {/*  <option value="14">14 meals</option>*/}
        {/*</select>*/}
        {/*{touched.weeklyMealEntitlement && errors.weeklyMealEntitlement &&*/}
        {/*  <div className={"error-block"}>{errors.weeklyMealEntitlement}</div>}*/}
        {/*/!*<ErrorMessage name="weeklyMealEntitlement">{msg => <div*!/*/}
        {/*/!*  className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Initiative Password*/}
        {/*</div>*/}
        
        {/*<input name="password" className={"form-field"}/>*/}
        {/*{touched.password && errors.password && <div className={"error-block"}>{errors.password}</div>}*/}
        {/*/!*<ErrorMessage name="password">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<div className={"form-label"}>*/}
        {/*  Notes / Address*/}
        {/*</div>*/}
        
        {/*<input type="notes" name="notes" className={"form-field"} component="textarea" rows={8}/>*/}
        {/*{touched.notes && errors.notes && <div className={"error-block"}>{errors.notes}</div>}*/}
        {/*/!*<ErrorMessage name="notes">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*!/*/}
        
        {/*<Dropzone*/}
        {/*  handleUploadDocument={props.handleUploadDocument}*/}
        {/*  UPLOAD_DOCUMENT_LOADING={props.UPLOAD_DOCUMENT_LOADING}*/}
        {/*  initiative_id={props.initiative_id}*/}
        {/*/>*/}
        
        {/*{renderUploadError()}*/}
        
        {/*<div className={"buttons"}>*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className={"submit-button"}*/}
        {/*    onClick={(e) => {*/}
        {/*      */}
        {/*      props.beneficiaryFormik.setFieldValue('scanQRCode', false, true)*/}
        {/*      props.handleReviewBeneficiaryInformation()*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {renderButtonContent()}*/}
        {/*  </button>*/}
        {/*  */}
        {/*  <br/>*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className={"submit-button"}*/}
        {/*    onClick={(e) => {*/}
        {/*      props.beneficiaryFormik.setFieldValue('scanQRCode', true, true)*/}
        {/*      props.handleReviewBeneficiaryInformation()*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <IDCard fill={"#FFFFFF"} className={"icon"}/>*/}
        {/*    Physical QR code*/}
        {/*  </button>*/}
        
        {/*</div>*/}
      </div>
    )
  }
  
  
  return (
    <div className={"form-block"}>
      
      <ProfileImage
        handleUploadProfileImage={props.handleUploadProfileImage}
        handleToggleCrop={props.handleToggleCrop}
        handleCropTrue={props.handleCropTrue}
        handleCropFalse={props.handleCropFalse}
      />
      
      {renderFormSection()}
      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      
      {/*</Form>*/}
      {/*</Formik>*/}
    </div>
  )
  
}

export default FormSection
