import {ReactComponent as EmailError} from "../../../../01_application/assets/icons/email-error.svg";
import React from "react";
import styles from "./EmailVerified.module.scss"

const EmailVerificationError = props => {
  
  return (
    <div className={styles.container}>
      
      <div className={styles.iconBlock}>
        <EmailError className={styles.icon}/>
      </div>
      
      <div className={styles.headerText}>
        Error verifying email
      </div>
      
      <div className={styles.subHeadingText}>
        {props.message}
      </div>
      
    
    </div>
  )
}

export default EmailVerificationError