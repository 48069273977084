import React, {useState} from "react";
import "./ProfileImage.modules.scss";
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../../04_utilities/core/image/cropImage'
import Resizer from 'react-image-file-resizer';
import profilePicturePlaceHolder from "../../../../01_application/assets/icons/profile_picture_place_holder.svg";
import {Dots} from "react-activity";
// import 'react-activity/lib/Dots/Dots.css';

// const ORIENTATION_TO_ANGLE = {
//   '3': 180,
//   '6': 90,
//   '8': -90,
// }

const ProfileImage = props => {
  
  const [displayCrop, setDisplayCrop] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  
  const onCropChange = crop => {
    setCrop(crop)
  }
  
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setcroppedAreaPixels(croppedAreaPixels)
  }
  
  const onZoomChange = zoom => {
    setZoom(zoom)
  }
  
  const handleCropImage = async () => {
    // console.log("uploading cropped image from profileImage.js")
    // console.log(croppedImage)
    
    try {
      setIsCropping(true)
      
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      )
      // console.log('done', croppedImage)
      props.handleCropFalse()
    
      props.handleUploadProfileImage(croppedImage)
      
      setCroppedImage(croppedImage)
      setIsCropping(false)
      setDisplayCrop(false)
      
    } catch (e) {
      console.error(e)
      setIsCropping(false)
      setDisplayCrop(false)

    }
  }
  
  
  
  const onFileChange = async e => {
    // console.log(e)
    
    props.handleCropTrue()
    
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      
      const resize = await Resizer.imageFileResizer(
        file,
        1080,
        1080,
        'JPEG',
        70,
        0,
        async (resized) => {
          // let imageDataUrl = await readFile(resized)
          setImageSrc(resized)
          setIsCropping(false)
          setDisplayCrop(true)
          setCrop({x: 0, y: 0})
          setZoom(1)
          // this.setState({
          //   imageSrc   : resized,
          //   isCropping : false,
          //   displayCrop: true,
          //   crop       : {x: 0, y: 0},
          //   zoom       : 1,
          // })
        },
        'base64'
      )
      // console.log(resize)
      
      // // apply rotation if needed
      // const orientation = await getOrientation(file)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }
      
      
    }
  }
  
  const renderNoProfilePictureError = () => {
  
  }
  
  const renderPlaceHolder = () => {
    return <img src={profilePicturePlaceHolder} alt="info" className="profile-picture-icon" onClick={() => {}}/>
  }
  
  const renderImageSrc = () => {
    return <img src={croppedImage} alt="info" className="profile-picture-icon" onClick={() => {
    }}/>
  }
  
  const renderProfileImage = () => {
    // console.log("rendering profle Img")
    // console.log(imageSrc)
    // console.log(croppedImage)
    
    if (!imageSrc) {
      return renderPlaceHolder()
    }
    
    if (croppedImage) {
      return renderImageSrc()
    }
  }
  
  const renderButton = () => {
    // console.log(this.state.isCropping)
    
    
    if (isCropping) {
      return (
        <button className={"save-button"}>
          <Dots/>
        </button>
      )
    }
    return (
      <button className={"save-button"} onClick={handleCropImage}>
        Save
      </button>
    )
    
  }
  
  const renderCrop = () => {
    // console.log("renderingcrop")
    // console.log(imageSrc)
    // console.log(displayCrop)
    
    if (imageSrc && displayCrop) {
      return (
        <div className={"crop-container"}>
          
          <div className={"crop-block"}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
            />
          </div>
          <br/>
          <br/>
          <div className={"crop-controls"}>
            {renderButton()}
          </div>
        
        </div>
      )
    }
  }
  
  
  const renderInputBtn = () => {
    if (!croppedImage) {
      return (
        <div className={"profile-pic-explain"}>
          <input type="file" accept="image/*" onChange={onFileChange}/>
          <br/>
          You will need to upload a front facing photo of yourself. This is so that stall can verify
          that you are indeed the bearer of the QR Code.
          
          <br/>
          <br/>
          Applications will be rejected if there is no visible face.
        </div>
      )
    }
  }
  
  
  return (
    <div className={"profile-image-section"}>
      <div className={"profile-image-block"}>
        {renderProfileImage()}
      </div>
      
      {renderCrop()}
      {renderInputBtn()}
    </div>
  )
  
}


function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}


export default ProfileImage


// const mapStateToProps = state => {
//   //console.log(state);
//   return {};
// };
//
//
// const mapDispatchToProps = dispatch => {
//   return bindActionCreators(
//     {
//       uploadDocument
//     },
//     dispatch
//   );
// };
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ProfileImage);
//
