import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_REGISTRATION_PREVIEW} from "../redux_config/types";
import { USER_V2_GET_REGISTRATION_PREVIEW} from "../redux_config/endpoints";


export const getRegistrationPreview = (initiative_id) => {
  return async dispatch => {

    const config = {}
    const params = {
      initiative_id: initiative_id
    }

    try {
      await apiFlight(GET_REGISTRATION_PREVIEW, dispatch).post(
        USER_V2_GET_REGISTRATION_PREVIEW,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
