import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {BENEFICIARY_RECOMMEND} from "../redux_config/types";
import { USER_V2_BENEFICIARY_RECOMMEND } from "../redux_config/endpoints";


export const BENEFICIARY_RECOMMEND_action = (payload) => {
  
  return async dispatch => {
    
    const config = {}
    // const params = {
    //   partner_id
    // }
    
    try {
      await apiFlight(BENEFICIARY_RECOMMEND, dispatch).post(
        USER_V2_BENEFICIARY_RECOMMEND,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
