import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {B_ONBOARD} from "../redux_config/types";
import {ADMIN_V2_BENEFICIARY_ONBOARD} from "../redux_config/endpoints";


export const beneficiaryOnboard = (payload) => {
  return async dispatch => {

    const config = {}
    // const params = {
    //   name: payload.name ,
    //   contact_number: payload.contact_number,
    //   profile_picture_url: payload.profile_picture_url,
    //   initiative_id: payload.initiative_id,
    //   password: payload.password
    // }

    try {
      await apiFlight(B_ONBOARD, dispatch).post(
        ADMIN_V2_BENEFICIARY_ONBOARD,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
