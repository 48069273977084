import React, {Component} from "react";
import "./BeneficiaryOnboard.modules.scss"

import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {B_ONBOARD, GET_ONBOARDING_PREVIEW, UPLOAD_DOCUMENT} from "../../../02_redux/redux_config/types";
import {bindActionCreators} from "redux";
import {beneficiaryOnboard, getOnboardingPreview, resetState, uploadDocument} from "../../../02_redux/actions";
import {connect} from "react-redux";
import FormSection from "./components/FormSection";
import ReviewProfile from "./components/ReviewProfile"
import closed from "../../../01_application/assets/icons/close.svg"
import complete from "../../../01_application/assets/icons/mission-accomplished.svg";
import {v4 as uuid} from "uuid";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QrReader from 'react-qr-reader'
import {useFormik} from "formik";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import Impression from "../../components_higherOrder/Ping/Impression";

// const ORIENTATION_TO_ANGLE = {
//   '3': 180,
//   '6': 90,
//   '8': -90,
// }

const views = {
  PROFILE_ENTRY : "PROFILE_ENTRY",
  PROFILE_REVIEW: "PROFILE_REVIEW"

}

let beneficiaryInitialValues = {
  name                 : '',
  gender               : 2,
  dd                   : "",
  mm                   : "",
  yyyy                 : "",
  email                : '',
  contactNumber        : '',
  password             : '',
  scanQRCode           : false,
  mealEntitlement      : '',
  weeklyMealEntitlement: '',
  validityPeriod       : '',
  notes                : ''
}

const BeneficiaryRegistration = props => {

  const navigate = useNavigate()
  const beneficiaryFormik = useFormik({
    initialValues: beneficiaryInitialValues,
    onSubmit     : values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const params = useParams()
  let {i_id} = params
  if (!i_id) {
    i_id = ""
  }


  return <BeneficiaryRegistrationClass navigate={navigate} beneficiaryFormik={beneficiaryFormik}
                                       i_id={i_id.split("=")[1]} {...props} />
}

class BeneficiaryRegistrationClass extends Component {

  state = {
    crop                      : false,
    profile_picture_url       : null,
    supporting_documents      : [],
    profilePictureMissing     : false,
    supportingDoucmentsMissing: false,
    openCamera                : false,
    holdFormValues            : {},
    view                      : views.PROFILE_ENTRY,
    scannedCode               : null
  };

  componentDidMount() {
    const initiative_id = this.props.i_id;

    this.props.getOnboardingPreview(initiative_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {


    if (this.props.UPLOAD_DOCUMENT_SUCCESS) {
      if (prevProps.UPLOAD_DOCUMENT_SUCCESS !== this.props.UPLOAD_DOCUMENT_SUCCESS) {
        if (this.props.UPLOAD_DOCUMENT_PAYLOAD.type === "PROFILE_PICTURE") {
          this.setState({
            profile_picture_url  : this.props.UPLOAD_DOCUMENT_PAYLOAD.tmp_loc,
            profilePictureMissing: false,
          })

        }

        if (this.props.UPLOAD_DOCUMENT_PAYLOAD.type === "SUPPORTING_DOCUMENT") {
          this.setState(prevState => ({
            supporting_documents: [...prevState.supporting_documents, this.props.UPLOAD_DOCUMENT_PAYLOAD.tmp_loc]
          }), () => {
            // console.log(this.state)
          })
        }
      }
    }


    if (this.props.B_ONBOARD_SUCCESS) {
      if (prevProps.B_ONBOARD_SUCCESS !== this.props.B_ONBOARD_SUCCESS) {
        const initiative_id = this.props.i_id;
        const split = this.props.B_ONBOARD_PAYLOAD.b_url.split("=")
        this.props.navigate(`/onboard-success/i_id=${initiative_id}&b_id=${split[1]}`)
      }
    }
  }

  handleRefresh = () => {
    window.location.reload(false);
    window.location.reload(false);
  }

  handleEditInformation = () => {
    this.setState({
      view: views.PROFILE_ENTRY
    })
  }

  handleDisplayReview = () => {
    this.setState({
      view: views.PROFILE_REVIEW
    })
  }

  handleScan = data => {
    if (data) {
      // console.log(data)
      this.setState({
        openCamera : false,
        scannedCode: data
      })

      this.handleDisplayReview()

      // const values = this.state.holdFormValues
      //
      // const payload = {
      //   name: values.name,
      //   dob: parseInt(values.yyyy + values.mm + values.dd),
      //   gender: values.gender,
      //   contact_number: values.contactNumber,
      //   profile_picture_url: this.state.profile_picture_url,
      //   initiative_id: this.props.match.params.i_id,
      //   supporting_documents: this.state.supporting_documents,
      //   password: values.password,
      //   inputCode: data,
      //   meal_entitlement: values.mealEntitlement,
      //   weekly_meal_entitlement: values.weeklyMealEntitlement,
      //   validity_period: values.validityPeriod,
      //   notes: values.notes
      //
      // }

      // this.props.beneficiaryOnboard(payload)
    }
  }

  handleSubmitBeneficiaryOnboard = () => {

    const values = this.state.holdFormValues

    const payload = {
      name                   : values.name,
      dob                    : parseInt(values.yyyy + values.mm + values.dd),
      gender                 : values.gender,
      contact_number         : values.contactNumber,
      profile_picture_url    : this.state.profile_picture_url,
      initiative_id          : this.props.i_id,
      supporting_documents   : this.state.supporting_documents,
      password               : values.password,
      meal_entitlement       : values.mealEntitlement,
      weekly_meal_entitlement: values.weeklyMealEntitlement,
      validity_period        : values.validityPeriod,
      notes                  : values.notes,
    }

    if (this.state.scannedCode !== null) {
      payload.inputCode = this.state.scannedCode
    }

    // console.log(payload)


    this.props.beneficiaryOnboard(payload)
  }

  handleReviewBeneficiaryInformation = (passedValues) => {

    if (!this.state.profile_picture_url) {
      // console.log("no profile url")
      return this.setState({profilePictureMissing: true})
    }

    if (passedValues.scanQRCode === 1) {
      this.setState({
        holdFormValues: passedValues,
        openCamera    : true
      })
    } else {
      this.setState({
        holdFormValues: passedValues,
        view          : views.PROFILE_REVIEW
      })
    }
  }


  handleUploadProfileImage = async (blob_url) => {
    let blob = await fetch(blob_url).then(r => r.blob());

    this.props.uploadDocument({
      upload_id: uuid(),
      type     : "PROFILE_PICTURE",
      file     : blob
    })
  }

  handleUploadDocument = (file) => {
    this.props.uploadDocument({
      upload_id: uuid(),
      type     : "SUPPORTING_DOCUMENT",
      file     : file
    })

    // this.props.uploadDocument("SUPPORTING_DOCUMENT", file)
  }

  handleToggleCrop = () => {
    if (this.state.crop) {
      return this.setState({crop: false})
    }

    this.setState({crop: true})
  }

  handleCropTrue = () => {
    this.setState({crop: true})
  }

  handleCropFalse = () => {
    this.setState({crop: false})
  }

  handleOpenCamera = () => {
    this.setState({camera: true})
  }


  handleError = err => {
    console.error(err)
  }

  renderScanner = () => {

    const previewStyle = {
      height: 320,
      width : 320,
    }

    return (
      <div className={"cameraContainer"}>
        <QrReader
          className={"camera"}
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={previewStyle}
          facingMode={"environment"}
          showViewFinder={true}
          resolution={600}
        />
        <p>{this.state.result}</p>
      </div>
    )
  }


  renderReviewBeneficiaryInformation = () => {
    const values = this.state.holdFormValues

    return (
      <div className={"content-block"}>
        {/*<ProfileImage*/}
        {/*  handleUploadProfileImage={this.handleUploadProfileImage}*/}
        {/*  handleToggleCrop={this.handleToggleCrop}*/}
        {/*  handleCropTrue={this.handleCropTrue}*/}
        {/*  handleCropFalse={this.handleCropFalse}*/}
        {/*/>*/}

        <ReviewProfile
          profileInformation={values}
          handleEditInformation={this.handleEditInformation}
          handleSubmitBeneficiaryOnboard={this.handleSubmitBeneficiaryOnboard}
          B_ONBOARD_LOADING={this.props.B_ONBOARD_LOADING}
        />
      </div>
    )

  }


  renderRegistrationSuccess = () => {
    return (
      <div className={"registration-success-block"}>

        <img src={complete} alt="secondmeal" className="complete-logo"/>

        Beneficiary has been onboarded! <br/>
        please send the above link to the beneficiary
        <br/><br/>

        <br/>
        <div className={"beneficiary-url"}>{this.props.B_ONBOARD_PAYLOAD.b_url}</div>


        <button className={"home-btn"} onClick={this.handleRefresh}>
          Create Another Beneficiary
        </button>


      </div>
    )
  }


  renderClosedForRegistration = () => {
    if (this.props.GET_ONBOARDING_PREVIEW_EVENT_CODE === "5202QTPU") {
      return (
        <div className={"closed-block"}>
          <img src={closed} alt="secondmeal" className="closed-icon"/>
          <br/>
          Registration is currently closed
        </div>
      )
    }
  }

  renderClearProfileBtn = () => {
    return (
      <div onClick={() => {
        window.location.reload(false)
      }} className={"clearFieldsBlock"}>
        <a className={"clearFieldsBtn"}>
          clear fields
        </a>
      </div>
    )
  }

  renderFormBlock = () => {

    if (this.state.openCamera === true) {
      return this.renderScanner()
    }
    // if (!this.state.crop) {
    return (
      <div className={"form-section"}>
        <FormSection
          crop={this.state.crop}
          beneficiaryFormik={this.props.beneficiaryFormik}
          beneficiaryInitialValues={beneficiaryInitialValues}
          handleUploadProfileImage={this.handleUploadProfileImage}
          handleToggleCrop={this.handleToggleCrop}
          handleCropTrue={this.handleCropTrue}
          handleCropFalse={this.handleCropFalse}
          initiative_id={this.props.GET_ONBOARDING_PREVIEW_PAYLOAD._id}
          handleUploadDocument={this.handleUploadDocument}
          handleReviewBeneficiaryInformation={this.handleReviewBeneficiaryInformation}
          profilePictureMissing={this.state.profilePictureMissing}
          supportingDocumentsMissing={this.state.supportingDoucmentsMissing}
          B_ONBOARD_LOADING={this.props.B_ONBOARD_LOADING}
          B_ONBOARD_SUCCESS={this.props.B_ONBOARD_SUCCESS}
          UPLOAD_DOCUMENT_LOADING={this.props.UPLOAD_DOCUMENT_LOADING}
        />
      </div>
    )
    // }
  }

  renderContentBlock = () => {
    return (
      <div className={"content-block"}>

        {this.renderClearProfileBtn()}
        {this.renderFormBlock()}

      </div>
    )
  }

  renderOnboardError = () => {
    if (this.props.B_ONBOARD_ERROR) {

      toast.warn(`${this.props.B_ONBOARD_MESSAGE}`, {
        onClose: () => this.props.resetState(B_ONBOARD)
      });

      return (
        <div>
          <ToastContainer/>
        </div>
      )
    }
  }

  renderHeader = () => {
    if (this.props.GET_ONBOARDING_PREVIEW_SUCCESS) {
      return this.props.GET_ONBOARDING_PREVIEW_PAYLOAD.name
    }
  }

  renderContent = () => {

    // console.log(this.props.B_ONBOARD_SUCCESS)

    // if (this.props.B_ONBOARD_SUCCESS) {
    //   return this.renderRegistrationSuccess()
    // } else

    if (this.props.GET_ONBOARDING_PREVIEW_EVENT_CODE === "5202QTPU") {
      return this.renderClosedForRegistration()
    } else if (this.props.GET_ONBOARDING_PREVIEW_EVENT_CODE === "B2V6L4W5") {

      if (this.state.view === views.PROFILE_ENTRY) {
        return this.renderContentBlock()
      }
      if (this.state.view === views.PROFILE_REVIEW) {
        return this.renderReviewBeneficiaryInformation()
      }

    }


  }

  render() {
    return (
      <Impression pageload page={"beneficiary_onboard"}>
        <div className={"pageContainer"}>

          {this.renderOnboardError()}
          <div className={"aboutUsBlock"}>
            <div className={"aboutUsText"}>
              {this.renderHeader()}
            </div>
          </div>
          {this.renderContent()}
        </div>
      </Impression>

    )
  }
}


const mapStateToProps = state => {

  const {
          0: UPLOAD_DOCUMENT_LOADING,
          1: UPLOAD_DOCUMENT_SUCCESS,
          2: UPLOAD_DOCUMENT_ERROR,
          3: UPLOAD_DOCUMENT_EVENT_CODE,
          4: UPLOAD_DOCUMENT_MESSAGE,
          5: UPLOAD_DOCUMENT_PAYLOAD,
        } = apiFlightStatus(UPLOAD_DOCUMENT);
  const {
          0: B_ONBOARD_LOADING,
          1: B_ONBOARD_SUCCESS,
          2: B_ONBOARD_ERROR,
          3: B_ONBOARD_EVENT_CODE,
          4: B_ONBOARD_MESSAGE,
          5: B_ONBOARD_PAYLOAD,
        } = apiFlightStatus(B_ONBOARD);
  const {
          0: GET_ONBOARDING_PREVIEW_LOADING,
          1: GET_ONBOARDING_PREVIEW_SUCCESS,
          2: GET_ONBOARDING_PREVIEW_ERROR,
          3: GET_ONBOARDING_PREVIEW_EVENT_CODE,
          4: GET_ONBOARDING_PREVIEW_MESSAGE,
          5: GET_ONBOARDING_PREVIEW_PAYLOAD,
        } = apiFlightStatus(GET_ONBOARDING_PREVIEW);

  // console.log(state)
  // console.log(
  //   'state',
  //   apiFlightStatus(UPLOAD_DOCUMENT)
  // );

  return {
    UPLOAD_DOCUMENT_LOADING,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_ERROR,
    UPLOAD_DOCUMENT_EVENT_CODE,
    UPLOAD_DOCUMENT_MESSAGE,
    UPLOAD_DOCUMENT_PAYLOAD,

    B_ONBOARD_LOADING,
    B_ONBOARD_SUCCESS,
    B_ONBOARD_ERROR,
    B_ONBOARD_EVENT_CODE,
    B_ONBOARD_MESSAGE,
    B_ONBOARD_PAYLOAD,

    GET_ONBOARDING_PREVIEW_LOADING,
    GET_ONBOARDING_PREVIEW_SUCCESS,
    GET_ONBOARDING_PREVIEW_ERROR,
    GET_ONBOARDING_PREVIEW_EVENT_CODE,
    GET_ONBOARDING_PREVIEW_MESSAGE,
    GET_ONBOARDING_PREVIEW_PAYLOAD,
  };
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetState,
      beneficiaryOnboard,
      getOnboardingPreview,
      uploadDocument
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeneficiaryRegistration);

