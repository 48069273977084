import React, {useRef} from "react";
import styles from "./ModalContent.module.scss";
import {ReactComponent as Close} from "../../../01_application/assets/icons/close.svg";
import useOnClickOutside from "./onClickOutside";


type ModalContentProps = {
  children: React.ReactNode;
  handleCloseModal?: () => void;
  noAnimation?: boolean
  style?: any
}

const ModalContent: React.FC<ModalContentProps> = (props: ModalContentProps) => {
  const {
          children,
          noAnimation,
          handleCloseModal,
          style
          // height
        } = props
  const ref = useRef(null)


  const renderHeaderButtons = () => {

    if (!handleCloseModal) {
      return null
    }

    return (
      <div className={styles.headerButtonBlock} onClick={handleCloseModal}>
        <Close fill={"#212121"} className={styles.icon}/>
      </div>
    )
  }

  const handleClickOutside = () => {
    // Your custom logic here
    // console.log('clicked outside')

    if (!handleCloseModal) {
      return null
    }

    handleCloseModal()
  }
  useOnClickOutside(ref, handleClickOutside)

  return (
    <div
      className={`${styles.modalContainer} ${noAnimation === true ? styles.noAnimation : {}}`}
    >
      <div style={style} className={styles.modalCard} ref={ref}>
        {renderHeaderButtons()}
        {children}
      </div>
    </div>
  )
}

export default ModalContent
