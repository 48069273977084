import {useEffect} from "react";


interface userPersistFormInterface {
    localStorageKey: string
    values: any
}

export const usePersistForm = (props: userPersistFormInterface) => {
    const {
        values,
        localStorageKey
    } = props

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(values));
    }, [values, localStorageKey]);

    return;
};


export const getSavedFormValues = (key: string) => {
    let data = localStorage.getItem(key);
    if (data) {
        // Parse it to a javaScript object
        try {
            data = JSON.parse(data);
        } catch (err) {
            console.log(err);
        }
        return data;
    }
}
