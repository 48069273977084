import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_PARTNER_DETAILS} from "../redux_config/types";
import { USER_V2_GET_PARTNER_DETAILS} from "../redux_config/endpoints";


export const getPartnerDetails = ({partner_id}) => {

  return async dispatch => {

    const config = {}
    const params = {
      partner_id
    }

    try {
      await apiFlight(GET_PARTNER_DETAILS, dispatch).post(
        USER_V2_GET_PARTNER_DETAILS,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}



