import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {B_EXPRESS_INTEREST} from "../redux_config/types";
import { ADMIN_V2_CREATE_BENE_DIGITAL_QR_CODE} from "../redux_config/endpoints";


export const beneficiary_express_interest = (payload) => {

  return async dispatch => {

    const config = {}
    const params = {
      name: payload.name ,
      contact_number: payload.contact_number,
      profile_picture_url: payload.profile_picture_url,
    }

    try {
      await apiFlight(B_EXPRESS_INTEREST, dispatch).post(
        ADMIN_V2_CREATE_BENE_DIGITAL_QR_CODE,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
