import {store} from "../../01_application/store"

export function isLoading(type) {
  const apiStore = store.getState().api
  const typeStatus = apiStore[type]
  
  if (typeStatus === undefined) {
    return false
  }
  
  if (typeStatus[0] === true) {
    return true
  }

  return false
}
