import React, {Component, useEffect, useState} from "react";
import styles from "./UserPurchases.module.scss"
import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {bindActionCreators} from "redux";
import {
  PeriodInterface,
  USER_V2_GET_USER_PURCHASES_ACTION,
  USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED,
  USER_V2_GET_USER_PURCHASES_SUCCESS_PAYLOAD, UserPurchasePayload
} from "../../../02_redux/actions";
import {connect} from "react-redux";
import {EXTENDED_GET_USER_PURCHASES, GET_USER_PURCHASES} from "../../../02_redux/redux_config/types";
import UserPurchaseList from "./components/UserPurchaseList";
import {produce} from "immer";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import Impression from "../../components_higherOrder/Ping/Impression";

const DEFAULT_FETCH_SIZE = 20


const UserPurchases = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const [periods, setPeriods] = useState<Array<PeriodInterface>>([])
  const [hasMore, setHasMore] = useState<boolean>(false)


  const {
          0: GET_USER_PURCHASES_LOADING,
          1: GET_USER_PURCHASES_SUCCESS,
          2: GET_USER_PURCHASES_ERROR,
          3: GET_USER_PURCHASES_EVENT_CODE,
          4: GET_USER_PURCHASES_MESSAGE,
          5: GET_USER_PURCHASES_PAYLOAD
        } = GetApiFlightStatus<USER_V2_GET_USER_PURCHASES_SUCCESS_PAYLOAD>(GET_USER_PURCHASES);

  const {
          0: EXTENDED_GET_USER_PURCHASES_LOADING,
          1: EXTENDED_GET_USER_PURCHASES_SUCCESS,
          2: EXTENDED_GET_USER_PURCHASES_ERROR,
          3: EXTENDED_GET_USER_PURCHASES_EVENT_CODE,
          4: EXTENDED_GET_USER_PURCHASES_MESSAGE,
          5: EXTENDED_GET_USER_PURCHASES_PAYLOAD
        } = GetApiFlightStatus<USER_V2_GET_USER_PURCHASES_SUCCESS_PAYLOAD>(EXTENDED_GET_USER_PURCHASES);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          USER_V2_GET_USER_PURCHASES_ACTION,
          USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED
        } = useActions()


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // will run on mount
  useEffect(() => {
    USER_V2_GET_USER_PURCHASES_ACTION({
      limit: DEFAULT_FETCH_SIZE
    })
  }, [])


  //will run if GET_USER_PURCHASES_SUCCESS changes
  useEffect(() => {
    if (GET_USER_PURCHASES_SUCCESS) {
      setPeriods(GET_USER_PURCHASES_PAYLOAD.periods)
      setHasMore(GET_USER_PURCHASES_PAYLOAD.has_more)
    }
  }, [GET_USER_PURCHASES_SUCCESS])


  useEffect(() => {
    if (EXTENDED_GET_USER_PURCHASES_SUCCESS) {

      const nextState = produce(periods, (draft: any[]) => {


        //append payload from first order of extended response to payload of last order of previous response
        const periodCount = periods.length
        // const newArrayForLastPeriod = Array.isArray(periods[periodCount-1]) ?  periods[periodCount - 1].concat(EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[0]) : [];

        // const newArrayForLastPeriod: Array<PeriodInterface> = periods[periodCount - 1].concat(EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[0])
        const newArrayForLastPeriod: Array<PeriodInterface> = periods.concat(EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[0])

        draft[periodCount - 1] = newArrayForLastPeriod

        // since we have added the results from the first element of the array, .shift to remove the first element
        // @ts-ignore
        let shifted = produce(EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods, draft => {
          draft.shift()
        })

        //append the rest of the order
        // @ts-ignore
        draft.concat([...shifted])
        // draft.has_more = EXTENDED_GET_USER_PURCHASES_SUCCESS.has_more
      })

      setPeriods(nextState)
      setHasMore(EXTENDED_GET_USER_PURCHASES_PAYLOAD.has_more)
    }
  }, [EXTENDED_GET_USER_PURCHASES_SUCCESS])


  const handleGetMorePurchases = () => {
    // const periodCount = EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods.length
    // const purchaseCountInLastPeriod = EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[periodCount - 1].length
    //
    // USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED({
    //     cursor: EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[periodCount - 1][purchaseCountInLastPeriod - 1]._id,
    //     last_date: EXTENDED_GET_USER_PURCHASES_PAYLOAD.periods[periodCount - 1][purchaseCountInLastPeriod - 1].created_at,
    //     limit: DEFAULT_FETCH_SIZE
    // })
  }

  const renderUserPurchaseList = () => {

    return (
      <UserPurchaseList
        GET_USER_PURCHASES_LOADING={GET_USER_PURCHASES_LOADING}
        EXTENDED_GET_USER_PURCHASES_LOADING={EXTENDED_GET_USER_PURCHASES_LOADING}
        periods={periods}
        has_more={hasMore}
        GET_USER_PURCHASES_ERROR={GET_USER_PURCHASES_ERROR}
        handleGetMorePurchases={handleGetMorePurchases}
      />
    )
  }


  const renderContent = () => {
    return renderUserPurchaseList()
  }


  return (
    <Impression pageload page={"user_purchases"}>
      <div className={styles.pageContainer}>
        {renderContent()}
      </div>
    </Impression>
  )

}


export default UserPurchases
