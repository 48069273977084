import React, {Component} from 'react';
import initiatives from "../../../01_application/assets/ui/initiatives.svg"
import beneficiaries from "../../../01_application/assets/ui/beneficiaries.svg"
import stalls from "../../../01_application/assets/ui/stalls.svg"
import you from "../../../01_application/assets/ui/you.svg"

import "./HowItWorks.modules.scss"
import Impression from "../../components_higherOrder/Ping/Impression";

const HowItWorks = () => {

  return (
    <Impression pageload page={"how-it-works"}>
      <div>

        <div className={"headerBlock"}>
          <div className={"headerBlock-text"}>
            HOW IT WORKS
          </div>
        </div>

        <div className={"block"}>
          <div className={"titleBlock"}>
            <div className={"titleBlock-text"}>
              Initiatives
            </div>
          </div>

          <div className={"contentBlock"}>

            <div className={"contentBlock_image"} style={{background: "#D8D8D8"}}>
              <div className={"circle"}/>
              <img src={initiatives} alt="intiatives" className="image"/>
            </div>

            <div className={"contentBlock_descriptionBlock"}>
              <div className={"contentBlock_descriptionBlock_text"}>
                Initiatives are the people Secondmeal work with directly. They can be non-profit organisations,
                charities or informal ground up initiatives. We mainly depend on them to find the beneficiaries in need
                and work about how we can help help the beneficiaries.
              </div>
              <div className={"contentBlock_descriptionBlock_text"}>
                Secondmeal equips the initiatives the authority to issue QR Codes and the beneficiaries can use the
                QR codes to claim their meal at the participating stalls
              </div>
              <div className={"contentBlock_descriptionBlock_text"}>
                Initiatives do not have to be a formal organisation for us to work with. So start an initiative today!
              </div>
            </div>
          </div>
        </div>


        <div className={"block"}>
          <div className={"titleBlock"}>
            <div className={"titleBlock-text"}>
              Beneficiaries
            </div>
          </div>

          <div className={"contentBlock"}>

            <div className={"contentBlock_image"} style={{background: "#CC335C"}}>
              <div className={"circle"}/>
              <img src={beneficiaries} alt="beneficiaries" className="image"/>
            </div>

            <div className={"contentBlock_descriptionBlock"}>
              <div className={"contentBlock_descriptionBlock_text"}>
                Beneficiaries are each issued a unique QR code. They will use this QR code to claim their meals at the
                stall.
              </div>
              <div className={"contentBlock_descriptionBlock_text"}>
                Beneficiaries are generally onboarded from initiatives.
              </div>
            </div>
          </div>
        </div>


        <div className={"block"}>
          <div className={"titleBlock"}>
            <div className={"titleBlock-text"}>
              Stalls
            </div>
          </div>

          <div className={"contentBlock"}>

            <div className={"contentBlock_image"} style={{background: "#029F8B"}}>
              <div className={"circle"}/>
              <img src={stalls} alt="stalls" className="image"/>
            </div>

            <div className={"contentBlock_descriptionBlock"}>
              <div className={"contentBlock_descriptionBlock_text"}>
                Stalls are where the beneficiaries bring their QR code to and claim their meal.
              </div>
              <div className={"contentBlock_descriptionBlock_text"}>
                The participating stalls are equipped with the ability to scan the beneficiaries’ QR code. With this
                technology, we know in real-time which beneficiary has claimed their meals and at what time. This
                technology gives
                a real-time and accurate statistics to how many meals donated meals are pending for claims and how many
                meals have been claimed.
              </div>
            </div>
          </div>
        </div>


        <div className={"block"}>
          <div className={"titleBlock"}>
            <div className={"titleBlock-text"}>
              You
            </div>
          </div>

          <div className={"contentBlock"}>

            <div className={"contentBlock_image"} style={{background: "#EDC9AA"}}>
              <div className={"circle"}/>
              <img src={you} alt="stalls" className="image"/>
            </div>

            <div className={"contentBlock_descriptionBlock"}>
              <div className={"contentBlock_descriptionBlock_text"}>
                When you buy a meal, you are given a uniquely generated URL where You can track the date and
                the time your meal is being claimed by a beneficiary so that you know know your contribution
                goes directly to the beneficiary and it is on a necessity. Your contribution of a meal
                goes a long way. It may only be a simple meal, but it does help the beneficiaries defray the cost
                and they are able spend it on other areas in their lives.
              </div>
              <div className={"contentBlock_descriptionBlock_text"}>
                Buy a second meal for the beneficiaries.
              </div>
            </div>
          </div>
        </div>

        <div className={"footer"}/>


      </div>
    </Impression>
  )

}

export default HowItWorks;
