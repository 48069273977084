import React from "react";
import styles from "./ProgressBar.module.scss"
import {ReactComponent as Tick} from "../../../../01_application/assets/icons/tick.svg"

const ProgressBar = props => {

  const {view, views} = props

  const futureCircle = () => {
    return (
      <div className={styles.futureCircleStyle}/>
    )
  }


  const inProgressCircle = () => {
    return (
      <div className={styles.inProgressCircle}/>
    )
  }

  const completedCircle = () => {
    return (
      <div className={styles.completedCircle}>
        <Tick fill={"#FFFFFF"} className={styles.checkIcon}/>
      </div>
    )
  }

  const acrossedBridge = () => {
    return (
      <div className={styles.acrossedBridgeStyle}/>
    )
  }

  const uncrossedBridge = () => {
    return (
      <div className={styles.unCrossedBridgeStyle}/>
    )
  }

  const renderMealCountCircle = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return inProgressCircle()

      default:
        return completedCircle()
    }
  }

  const renderFirstBridge = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return uncrossedBridge()

      default:
        return acrossedBridge()
    }
  }


  const renderPurchaseInfoCircle = () => {
    switch (view) {

      case views.MEAL_COUNT_SELECTION:
        return futureCircle()

      case views.PURCHASE_INFORMATION:
        return inProgressCircle()

      case views.PAYMENT_INFORMATION:
        return completedCircle()

    }
  }

  const renderSecondBridge = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return uncrossedBridge()

      case views.PURCHASE_INFORMATION:
        return uncrossedBridge()

      case views.PAYMENT_INFORMATION:
        return acrossedBridge()
    }
  }


  const renderPaymentInfoCircle = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return futureCircle()

      case views.PURCHASE_INFORMATION:
        return futureCircle()

      case views.PAYMENT_INFORMATION:
        return inProgressCircle()
    }
  }

  const renderMealCountText = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return (
          <div className={styles.lightedText}>
            Meal Count
          </div>
        )

      case views.PURCHASE_INFORMATION:
        return futureCircle()

      case views.PAYMENT_INFORMATION:
        return inProgressCircle()
    }
  }

  const renderPurchaseInfoText = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return (
          <div className={styles.futureText}>
            Purchase Info
          </div>
        )

      case views.PURCHASE_INFORMATION:
        return (
          <div className={styles.lightedText}>
            Purchase Info
          </div>
        )

      case views.PAYMENT_INFORMATION:
        return (
          <div className={styles.lightedText}>
            Purchase Info
          </div>
        )
    }
  }

  const renderPaymentInfoText = () => {
    switch (view) {
      case views.MEAL_COUNT_SELECTION:
        return (
          <div className={styles.futureText}>
            Payment Info
          </div>
        )

      case views.PURCHASE_INFORMATION:
        return (
          <div className={styles.futureText}>
            Payment Info
          </div>
        )

      case views.PAYMENT_INFORMATION:
        return (
          <div className={styles.lightedText}>
            Payment Info
          </div>
        )
    }
  }


  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.checkBoxBlock}>

        {renderMealCountCircle()}

        {renderFirstBridge()}

        {renderPurchaseInfoCircle()}

        {renderSecondBridge()}

        {renderPaymentInfoCircle()}
      </div>

      <div className={styles.textContainer}>
        <div className={styles.textBlock}>

          <div className={styles.lightedText}>
            Meal Count
          </div>

          <div>

            {renderPurchaseInfoText()}
          </div>
          <div>

            {renderPaymentInfoText()}
          </div>

        </div>
      </div>


    </div>
  )
}

export default ProgressBar;
