import React, {Component} from "react";
import "./BeneficiaryRecommend.modules.scss"
import FormSection from "./components/FormSection";
import complete from "../../../../01_application/assets/icons/mission-accomplished.svg";
import {v4 as uuid} from "uuid";
import 'react-toastify/dist/ReactToastify.css';
import {apiFlightStatus} from "../../../../02_redux/redux_utilities/apiFlightStatus";
import {B_ONBOARD, BENEFICIARY_RECOMMEND, UPLOAD_DOCUMENT} from "../../../../02_redux/redux_config/types";
import {bindActionCreators} from "redux";
import {BENEFICIARY_RECOMMEND_action, beneficiaryOnboard, resetState, uploadDocument} from "../../../../02_redux/actions";
import {connect} from "react-redux";

const views = {
  PROFILE_ENTRY : "PROFILE_ENTRY",
  PROFILE_REVIEW: "PROFILE_REVIEW"
}

class BeneficiaryRecommend extends Component {
  
  state = {
    supporting_documents: [],
    holdFormValues      : {},
    view                : views.PROFILE_ENTRY,
  };
  
  componentDidMount() {
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (this.props.UPLOAD_DOCUMENT_SUCCESS) {
      if (prevProps.UPLOAD_DOCUMENT_SUCCESS.event_id !== this.props.UPLOAD_DOCUMENT_SUCCESS.event_id) {
        if (this.props.UPLOAD_DOCUMENT_SUCCESS.payload.type === "SUPPORTING_DOCUMENT") {
          this.setState(prevState => ({
            supporting_documents: [...prevState.supporting_documents, this.props.UPLOAD_DOCUMENT_SUCCESS.payload.tmp_loc]
          }), () => {
            console.log(this.state)
          })
        }
      }
    }
  }
  
  handleRefresh = () => {
    window.location.reload(false);
  }
  
  handleUploadDocument = (file) => {
    this.props.uploadDocument({
      upload_id: uuid(),
      type     : "SUPPORTING_DOCUMENT",
      file     : file
    })
    
    // this.props.uploadDocument("SUPPORTING_DOCUMENT", file)
  }
  
  
  handleSubmitBeneficiaryRecommend = (values) => {
    // console.log(values)
    
    let updateNotes = "Profile: "
    
    switch (values.initiativeProfile) {
      case 1:
        updateNotes = updateNotes.concat("Person with disabilities or special needs \n\n");
        return;
      case 2:
        updateNotes = updateNotes.concat("Elder in need with no dependents \n\n");
        return;
      case 3:
        updateNotes = updateNotes.concat("No options available \n\n");
        return;
    }
  
    updateNotes = updateNotes.concat(values.notes)
    
    // console.log(updateNotes)
    
    const payload = {
      name                : values.name,
      dob                 : parseInt(values.yyyy + values.mm + values.dd),
      gender              : values.gender,
      contact_number      : values.contactNumber,
      notes               : updateNotes,
      supporting_documents: this.state.supporting_documents
    }
    
    this.props.BENEFICIARY_RECOMMEND_action(payload)
  }
  
  
  renderRegistrationSuccess = () => {
    return (
      <div className={"registration-success-block"}>
        
        <img src={complete} alt="secondmeal" className="complete-logo"/>
        
        You have been taken note of! <br/>
        <br/><br/>


        
        
        <button className={"home-btn"} onClick={this.handleRefresh}>
          Submit another profile
        </button>
      
      </div>
    )
  }
  
  
  renderContentBlock = () => {
    return (
      <div className={"form-section"}>
        <FormSection
          handleSubmitBeneficiaryRecommend={this.handleSubmitBeneficiaryRecommend}
          handleUploadDocument={this.handleUploadDocument}
          B_ONBOARD_LOADING={this.props.B_ONBOARD_LOADING}
          B_ONBOARD_SUCCESS={this.props.B_ONBOARD_SUCCESS}
          UPLOAD_DOCUMENT_LOADING={this.props.UPLOAD_DOCUMENT_LOADING}
        />
      </div>
    )
  }
  
  renderHeader = () => {
    return (
      <div>
        Please join us if you would like some meals at our stall network
      </div>
    )
  }
  
  renderContent = () => {
    if (this.props.BENEFICIARY_RECOMMEND_SUCCESS) {
      return this.renderRegistrationSuccess()
    } else {
      
      if (this.state.view === views.PROFILE_ENTRY) {
        return this.renderContentBlock()
      }
      if (this.state.view === views.PROFILE_REVIEW) {
        return this.renderReviewBeneficiaryInformation()
      }
    }
  }
  
  render() {
    return (
      <div className={"pageContainer"}>
        <div className={"aboutUsBlock"}>
          <div className={"aboutUsText"}>
            {this.renderHeader()}
          </div>
        </div>
        {this.renderContent()}
      </div>
    
    )
  }
}


const mapStateToProps = store => {
  
  const {
          0: UPLOAD_DOCUMENT_LOADING,
          1: UPLOAD_DOCUMENT_SUCCESS,
          2: UPLOAD_DOCUMENT_ERROR,
          3: UPLOAD_DOCUMENT_EVENT_CODE
        } = apiFlightStatus(UPLOAD_DOCUMENT);
  
  const {
          0: B_ONBOARD_LOADING,
          1: B_ONBOARD_SUCCESS,
          2: B_ONBOARD_ERROR,
          3: B_ONBOARD_EVENT_CODE
        } = apiFlightStatus(B_ONBOARD);
  
  const {
          0: BENEFICIARY_RECOMMEND_LOADING,
          1: BENEFICIARY_RECOMMEND_SUCCESS,
          2: BENEFICIARY_RECOMMEND_ERROR,
          3: BENEFICIARY_RECOMMEND_EVENT_CODE
        } = apiFlightStatus(BENEFICIARY_RECOMMEND);
  
  
  // console.log(state)
  // console.log(
  //   'state',
  //   apiFlightStatus(UPLOAD_DOCUMENT)
  // );
  
  return {
    UPLOAD_DOCUMENT_LOADING,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_ERROR,
    UPLOAD_DOCUMENT_EVENT_CODE,
    
    B_ONBOARD_LOADING,
    B_ONBOARD_SUCCESS,
    B_ONBOARD_ERROR,
    B_ONBOARD_EVENT_CODE,
    
    BENEFICIARY_RECOMMEND_LOADING,
    BENEFICIARY_RECOMMEND_SUCCESS,
    BENEFICIARY_RECOMMEND_ERROR,
    BENEFICIARY_RECOMMEND_EVENT_CODE,
    
    
  };
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetState,
      beneficiaryOnboard,
      uploadDocument,
      BENEFICIARY_RECOMMEND_action
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeneficiaryRecommend);

