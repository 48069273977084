import React, {Component} from "react";
import {
  isMobile
} from "react-device-detect";
import "./Facebook.modules.scss"
import Impression from "../../components_higherOrder/Ping/Impression";

class Facebook extends Component {

  componentDidMount() {
    this.handleNavigateToFacebook()
  }

  handleNavigateToFacebook = () => {
    // console.log("ismobile????", isMobile)
    if (isMobile) {
      window.location.href='fb://page?id=126429478034462'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href='https://www.facebook.com/secondmeal.sg/'
    }
  }

  handleNavigateToInstagram = () => {
    if (isMobile) {
      window.location.href='instagram://user?username=secondmeal.sg'
    } else {
      // <a href={"https://www.facebook.com/secondmeal.io/"}>Facebook</a>
      window.location.href='https://www.instagram.com/secondmeal.sg/'
    }
  }


  render() {
    return (
      <Impression pageload page={"direct_to_facebook"}>
      <div className={"page-container"}>
        <div className={"title"}>
          Redirecting you to facebook...
        </div>

        <div className={"sub-title"}>
          We will open your Facebook application if you are on mobile, Facebook website if you are on a desktop.
        </div>

      </div>
      </Impression>
    )
  }
}

export default Facebook;
