

import axios from "axios";
import { monthlyStatsURL} from "../redux_config/endpoints"
import {GETTING_STALL_STATS, GET_STALL_STATS_SUCCESS} from "../redux_config/types";

/*
      Actions on this page will be handled in stalls_reducer.js
 */

export const getStallStats = (payload) => {
    return async dispatch => {
        dispatch ({ type: GETTING_STALL_STATS})

        const config = {
            // headers: {
            //     Authorization: "Bearer " + JWT
            // }
        }

        const params = {
          stall_id: payload.stall_id,
          initiative_id: payload.initiative_id
        }

        //console.log("getting stalls")

        const result = await axios.post(`${monthlyStatsURL}`,params, config);

        // console.log(result)

        if(result.data.status_code === 200 ) {
            return dispatch({
                type: GET_STALL_STATS_SUCCESS,
                payload: result.data.payload
            })
        }

        // if(result.data.status_code === SUCCESS && result.data.payload.length === 0) {
        //   return dispatch({
        //     type: "EMPTY",
        //   })
        // }

        // if ( result.status !== 200) {
        //     return dispatch({
        //         type: "ERROR_GETTING_PACKS",
        //     })
        // }

    }
}


//REDUCERS
// const INITIAL_STATE = {
//     case:null,
//     payload: []
// }
//
// export function stalls_redux (state = INITIAL_STATE, action) {
//     switch (action.type) {
//
//         case GETTING_STALL_STATS:
//             return {
//                 case: GETTING_STALL_STATS,
//                 payload: null
//             }
//
//         case GET_STALL_STATS_SUCCESS:
//             return {
//                 case: GET_STALL_STATS_SUCCESS,
//                 payload: action.payload
//             }
//
//         default:
//             return state;
//     }
// }
