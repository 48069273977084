import React from "react";
import ProgressBar from "./ProgressBar";
import styles from "./PurchaseInformation.module.scss";
import {UseFormReturn} from "react-hook-form";
import {InputText, InputType} from "../../../components_core/InputText/InputText";
import {InputTextArea} from "../../../components_core/InputTextArea/InputTextArea";
import {PurchasePreviewFormValuesErrors} from "../PurchasePreview";
import Interaction from "../../../components_higherOrder/Ping/Interaction";


const shoutOutPlaceHolder = [
  "Share with us why you donated",
  "Any one you would like to dedicate this donation to?",
  "Share with us the last time someone did something nice to you",
  "What was the last incident you witnessed that brought a smile to your face"
]

interface PurchaseInformationProps {
  view: string
  views: any
  register: UseFormReturn['register'];
  formErrors: PurchasePreviewFormValuesErrors
  handleNavigateBackToMealCountSelection: () => void
  handleValidateAndProceedToPaymentInfo: () => void
}

const PurchaseInformation = (props: PurchaseInformationProps) => {

  const {
          view,
          views,
          register,
          handleNavigateBackToMealCountSelection,
          handleValidateAndProceedToPaymentInfo,
          formErrors
        } = props

  const renderProgressBar = () => {


    return (
      <ProgressBar
        view={props.view}
        views={props.views}
      />
    )
  }

  return (
    <div>


      {renderProgressBar()}


      <InputText
        inputType={InputType.TEXT}
        fieldName={"display_name"}
        fieldLabel={"Display Name:"}
        fieldPlaceHolder={""}
        fieldError={false}
        fieldErrorMessage={formErrors.display_name?.message ? formErrors.display_name?.message : null}
        register={props.register}
      />


      <InputText
        inputType={InputType.EMAIL}
        fieldName={"email"}
        fieldLabel={"Email:"}
        fieldSubLabel={`We hate spams too! It is for the purpose of sending you a receipt, updates about your purchase and pleas when meals are running
        low. It is not publicly visible.`}
        fieldPlaceHolder={""}
        fieldError={false}
        fieldErrorMessage={formErrors.email?.message ? formErrors.email?.message : null}
        register={props.register}
      />

      <InputTextArea
        inputType={InputType.TEXTAREA}
        fieldName={"post_message"}
        fieldLabel={"Post Message:"}
        fieldSubLabel={`Any one you would like to dedicate this meal to?`}
        fieldPlaceHolder={""}
        fieldError={false}
        fieldErrorMessage={formErrors.post_message?.message ? formErrors.post_message?.message : null}
        register={props.register}
        rows={5}
      />


      {/*<div className={styles.labelText}>Display Name:</div>*/}
      {/*<div>*/}
      {/*  <input*/}
      {/*    className={`${styles.textInput} ${props.invalidDisplayName ? styles.error : ""} `}*/}
      {/*    onChange={props.handleDisplayNameInput}*/}
      {/*    value={props.display_name}*/}
      {/*    name={"name"}*/}
      {/*    autoComplete={'off'}*/}
      {/*    maximum-scale={1}*/}
      {/*  />*/}
      {/*</div>*/}


      {/*<div className={styles.labelText}>*/}
      {/*    Email Address:*/}
      {/*</div>*/}

      {/*<div className={styles.subLabelText}>*/}
      {/*    We hate spams too!*/}
      {/*</div>*/}
      {/*<div className={styles.subLabelText}>*/}
      {/*    It is for the purpose of sending you a receipt, updates about your purchase and pleas when meals are*/}
      {/*    running*/}
      {/*    low. It is not publicly visible.*/}
      {/*</div>*/}
      {/*<input*/}
      {/*    className={`${styles.textInput} ${props.invalidEmail ? styles.error : ""} `}*/}
      {/*    value={props.email}*/}
      {/*    onChange={props.handleEmailInput}*/}
      {/*    name={"email"}*/}
      {/*    maximum-scale={1}*/}
      {/*    disabled={props.profile.jwt !== null ? true : false}*/}
      {/*/>*/}


      {/*<div className={styles.labelText}>Shout Out:</div>*/}
      {/*<div className={styles.subLabelText}>*/}
      {/*    Optional, but any good vibes you would like to share with the world?*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*    /!*<textarea*!/*/}
      {/*    /!*  className={`${styles.textInput}`}*!/*/}
      {/*    /!*  onChange={props.handlePostMessageInput}*!/*/}
      {/*    /!*  value={props.post_message}*!/*/}
      {/*    /!*  name={"message"}*!/*/}
      {/*    /!*  autoComplete={'off'}*!/*/}
      {/*    /!*  maximum-scale={1}*!/*/}
      {/*    /!*  component="textarea"*!/*/}
      {/*    /!*  placeholder={`${shoutOutPlaceHolder[Math.floor(Math.random() * shoutOutPlaceHolder.length) + 0  ]}`}*!/*/}
      {/*    /!*  rows={8}*!/*/}


      <div className={styles.buttonSection}>

        <div className={styles.backBtnSection}>
          <Interaction
            elementProps={{
              element_name: 'back_to_meal_count',
              element_type: "button"
            }}
            interactionProps={{onClick: {action: 3}}}
          >
            <a>
              <div className={styles.backBtn} onClick={handleNavigateBackToMealCountSelection}>
                back
              </div>
            </a>
          </Interaction>
        </div>

        <Interaction
          elementProps={{
            element_name: 'proceed_to_payment_info',
            element_type: "button"
          }}
          interactionProps={{onClick: {action: 3}}}
        >
          <a>
            <div
              className={styles.nextStepBtn}
              onClick={handleValidateAndProceedToPaymentInfo}
            >
              Proceed
            </div>
          </a>
        </Interaction>
      </div>

    </div>
  )
}

export default PurchaseInformation;
