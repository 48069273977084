const keys = require("../../00_config/keys/keys");

//const rootURL = "http://www.localhost:5000";
//const rootURL = "https://secondmeal-staging.herokuapp.com";
//const rootURL = "https://www.api-production.secondmeal.io";
//const rootURL = "https://secondmeal-production.herokuapp.com";
//const rootURL = "https://www.secondmeal.io";
//
// let ROOT_URL
//
// if (process.env.REACT_APP_DEPLOYMENT === 'production') {
//   ROOT_URL = "https://api-production.secondmeal.io";
// } else {
//   ROOT_URL = "https://api-staging.secondmeal.io";
//   // ROOT_URL = "https://api-production.secondmeal.io";
//   // ROOT_URL="http://www.localhost:8080"
// }

let ROOT_URL = keys.API_ROOT_URL
let KLANGA_ROOT_URL = keys.KLANGA_ROOT_URL
let SUBSCRIBE_API_ROOT_URL = ""

module.exports = {
  stallsURL          : `${ROOT_URL}/v1/user/stalls`, //GET
  claimsURL          : `${ROOT_URL}/v1/user/claims`, //GET
  monthlyStatsURL    : `${ROOT_URL}/v1/user/monthly`, //POST
  suspendedActiveURL : `${ROOT_URL}/v1/user/suspended-active`, //GET
  suspendedHistoryURL: `${ROOT_URL}/v1/user/suspended-history`, //GET
  purchasePreviewURL : `${ROOT_URL}/v1/user/purchase-preview`, //POST
  purchasemealsURL   : `${ROOT_URL}/v1/user/guest-purchase-meals`,   //POST

  USER_V2_GET_BENEFICIARY_PROFILE              : `${ROOT_URL}/users/v2/get-beneficiary-profile`,        //POST
  USER_V2_GET_INITIATIVES                      : `${ROOT_URL}/users/v2/get-initiatives`,                        //POST
  USER_V2_GET_INITIATIVE_STALL_STATS           : `${ROOT_URL}/users/v2/get-initiative-stall-stats`,  //POST
  USER_V2_GET_PARTICIPATING_STALLS             : `${ROOT_URL}/users/v2/get-participating-stalls`,      //POST
  USER_V2_UPLOAD_DOCUMENT                      : `${ROOT_URL}/users/v2/upload-document`,                       //POST
  USER_V2_GET_REGISTRATION_PREVIEW             : `${ROOT_URL}/users/v2/get-registration-preview`,     //POST
  USER_V2_BENEFICIARY_REGISTER                 : `${ROOT_URL}/users/v2/b-register`,                       //POST
  USER_V2_PURCHASE_MEALS_DEFERRED_PAYMENT      : `${ROOT_URL}/users/v2/purchase-meals-deferred-payment`,  //POST
  USER_V2_GET_CAMPAIGN_PURCHASES_ACTIVE        : `${ROOT_URL}/users/v2/get-campaign-purchases-active`, //POST
  USER_V2_GET_CAMPAIGN_PURCHASES_HISTORY       : `${ROOT_URL}/users/v2/get-campaign-purchases-history`, //POST
  USER_V2_GET_PARTNER_DETAILS                  : `${ROOT_URL}/users/v2/get-partner-details`,
  USER_V2_GET_CAMPAIGNS                        : `${ROOT_URL}/users/v2/get-campaigns`,
  USER_V2_GET_RECURRING_PURCHASES              : `${ROOT_URL}/users/v2/get-recurring-purchase`,
  USER_V2_UNSUBSCRIBE                          : `${ROOT_URL}/users/v2/unsubscribe`,
  USER_V2_GET_HASHTAG_COUNT                    : `${ROOT_URL}/users/v2/get-hashtag-count`,
  USER_V2_GET_HASHTAG_IMAGES                   : `${ROOT_URL}/users/v2/get-hashtag-images`,
  USER_V2_GET_ENTRIES_FEATURED                 : `${ROOT_URL}/users/v2/get-featured-entries`,
  USER_V2_GET_ENTRY                            : `${ROOT_URL}/users/v2/get-entry`,
  USER_V2_GUEST_PURCHASE_MEALS                 : `${ROOT_URL}/users/v2/guest-purchase-meals`,
  USER_V2_GET_SUSPENDED_MEALS                  : `${ROOT_URL}/users/v2/get-suspended-meals`,
  USER_V2_GET_NEW_SUBSCRIPTION_COUNT           : `${ROOT_URL}/users/v2/get-new-subscription-count`,
  USER_V2_BENEFICIARY_RECOMMEND                : `${ROOT_URL}/users/v2/beneficiary-recommend`,
  USER_V2_CREATE_USER_ACCOUNT                  : `${ROOT_URL}/users/v2/create-user-account`,
  USER_V2_VERIFY_EMAIL                         : `${ROOT_URL}/users/v2/verify-email`,
  USER_V2_AUTHENTICATE_ACCOUNT                 : `${ROOT_URL}/users/v2/authenticate-account`,
  USER_V2_REQUEST_PASSWORD_RESET               : `${ROOT_URL}/users/v2/request-password-reset`,
  USER_V2_VERIFY_VERIFICATION_CODE             : `${ROOT_URL}/users/v2/verify-verification-code`,
  USER_V2_RESET_PASSWORD_WITH_VERIFICATION_CODE: `${ROOT_URL}/users/v2/reset-password-with-verification-code`,
  USER_V2_GET_PURCHASE_PREVIEW                 : `${ROOT_URL}/users/v2/get-purchase-preview`,
  USER_V2_MARK_MEALS_AS_PAID                   : `${ROOT_URL}/users/v2/mark-meals-as-paid`,


  //Authentication required
  USER_V2_GET_USER_QUICK_PROFILE         : `${ROOT_URL}/users/v2/get-user-quick-profile`,
  USER_V2_ADD_ADDRESS                    : `${ROOT_URL}/users/v2/add-address`,
  USER_V2_ADD_CARD                       : `${ROOT_URL}/users/v2/add-card`,
  USER_V2_GET_ADDRESSES                  : `${ROOT_URL}/users/v2/get-addresses`,
  USER_V2_GET_CARDS                      : `${ROOT_URL}/users/v2/get-cards`,
  USER_V2_DELETE_CARD                    : `${ROOT_URL}/users/v2/delete-card`,
  USER_V2_DELETE_ADDRESS                 : `${ROOT_URL}/users/v2/delete-address`,
  USER_V2_RESEND_VERIFICATION_EMAIL      : `${ROOT_URL}/users/v2/resend-verification-email`,
  USER_V2_USER_PURCHASE_MEALS            : `${ROOT_URL}/users/v2/user-purchase-meals`,
  USER_V2_GET_USER_PURCHASE_COUNT_HISTORY: `${ROOT_URL}/users/v2/get-user-purchase-count-history`,
  USER_V2_GET_USER_PURCHASES             : `${ROOT_URL}/users/v2/get-user-purchases`,
  USER_V2_GET_STRIPE_CLIENT_SECRET       : `${ROOT_URL}/users/v2/get-stripe-client-secret`,
  USER_V2_CAPTURE_CLIENT_INTENT          : `${ROOT_URL}/users/v2/capture-client-intent`,

  ADMIN_V2_CREATE_BENE_DIGITAL_QR_CODE: `${ROOT_URL}/admin/v2/create-beneficiary-digital-qr-code`,  //POST
  ADMIN_V2_GET_ONBOARDING_PREVIEW     : `${ROOT_URL}/admin/v2/get-onboarding-preview`,                   //POST
  ADMIN_V2_BENEFICIARY_ONBOARD        : `${ROOT_URL}/admin/v2/beneficiary-onboard`,                          //POST


  SUBSCRIBE_SHOP_V1_UPLOAD_DOCUMENT: `${SUBSCRIBE_API_ROOT_URL}/socialgood/shop/v1/upload-document`,
  KLANGA_V1_PING                   : `${KLANGA_ROOT_URL}/listener/v1/ping`,
};
