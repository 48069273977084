import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_INITIATIVES_STALL_STATS} from "../redux_config/types";
import { USER_V2_GET_INITIATIVE_STALL_STATS} from "../redux_config/endpoints";


export const getInitiativeStallStats = ( initiative_id) => {
  return async dispatch => {

    const config = {}
    const params = {
      initiative_id
    }

    try {
      await apiFlight(GET_INITIATIVES_STALL_STATS, dispatch).post(
        USER_V2_GET_INITIATIVE_STALL_STATS,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
