import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {AUTHENTICATE_ACCOUNT, GET_ENTRIES_FEATURED} from "../redux_config/types";
import {USER_V2_GET_ENTRIES_FEATURED} from "../redux_config/endpoints";
import {Dispatch} from "redux";
import {isLoading} from "../redux_utilities/loadingStatus";


interface USER_V2_GET_ENTRIES_FEATURED_REQUEST_PAYLOAD {

}
export const GET_ENTRIES_FEATURED_ACTION = (payload: USER_V2_GET_ENTRIES_FEATURED_REQUEST_PAYLOAD) => {
  return async (dispatch: Dispatch<any>) => {

    if (isLoading(GET_ENTRIES_FEATURED) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }

    const config = {}
    // const params = {}

    try {
      await apiFlight(GET_ENTRIES_FEATURED, dispatch).post(
        USER_V2_GET_ENTRIES_FEATURED,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}


export interface EntryInterface {
  is_featured: number
  og_description: number
  og_image: string
  publication_date: Date
  slug: string
  status: number
  title: string
  views: number
  _id: string
}

interface USER_V2_GET_ENTRIES_FEATURED_SUCCESS_PAYLOAD extends Array<EntryInterface> {

}