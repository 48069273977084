import styles from "./Addresses.module.scss";
import React from "react";
import {Dots} from "react-activity";
import Address from "../../../components_common/Address/Address";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import addressStyles from "../../../components_common/Address/Address.module.scss";
import {ReactComponent as Plus} from "../../../../01_application/assets/icons/plus.svg";
import {ReactComponent as Cancel} from "../../../../01_application/assets/icons/red-cancel.svg";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items     : 5
  },
  desktop          : {
    breakpoint: {max: 3000, min: 1024},
    items     : 3
  },
  tablet           : {
    breakpoint: {max: 1024, min: 600},
    items     : 2
  },
  mobile           : {
    breakpoint: {max: 600, min: 0},
    items     : 1
  }
};

const Addresses = props => {
  
  const handleAddAddress = () => {
    props.handleAddAddress()
  }
  
  const handleDeleteAddressPrompt = (card_id) => {
    props.handleDeleteAddressPrompt(card_id)
  }
  
  
  const renderAddress = (address) => {
    const {_id, name, address_street, address_unit_no, address_postal_code, address_country, email, contact_number, selected} = address
    
    if (_id === "ADD") {
      return renderAddAddress()
    }
    
    return <Address
      _id={_id}
      name={name}
      address_street={address_street}
      address_unit_no={address_unit_no}
      address_postal_code={address_postal_code}
      address_country={address_country}
      email={email}
      contact_number={contact_number}
      selected={selected}
      handleSelectAddress={() => {
      }}
    />
  }
  
  const renderAddAddress = () => {
    return (
      <div className={`${addressStyles.addressCard} ${props.add_address ? addressStyles.selected : ""}`}
           onClick={() => handleAddAddress()}>
        <div className={styles.addAddressBlock}>
          <Plus fill={"#565656"} className={styles.plusIcon}/>
          <div className={styles.addAddressText}>
            Add Address
          </div>
        </div>
      </div>
    )
  }
  
  const renderCancelIcon = (card_id) => {
    if (props.edit_addresses === true) {
      
      if (card_id === "ADD") {
        return null
      }
      
      return (
        <div className={`${styles.cancelBlock}`}>
          <Cancel className={`${styles.cancelIcon}`} onClick={() => handleDeleteAddressPrompt(card_id)}/>
        </div>
      )
    }
  }
  
  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Dots/>
      </div>
    )
  }
  
  const mapAddresses = () => {
    return (
      props.saved_addresses.map((address, index) => {
        return (
          <div key={address._id}>
            <div>
              {renderCancelIcon(address._id)}
            </div>
            {renderAddress(address)}
          </div>
        )
      })
    )
  }
  
  const renderSavedAddresses = () => {
    
    if (props.GET_ADDRESSES_LOADING === true) {
      return renderLoading()
    }
    // console.log(props.saved_addresses)
    if (props.saved_addresses?.length > 0) {
      return (
        <div>
          <div className={styles.carouselContainer}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              containerClass={styles.carouselContainer}
              itemClass={styles.carouselItem}
              infinite={props.saved_addresses?.length > 1 ? true : false}
              // autoPlay={this.props.deviceType !== "mobile" ? true : false}
              // autoPlaySpeed={1000}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={500}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              // dotListClass="custom-dot-list-style"
            >
              {mapAddresses()}
            </Carousel>
          </div>
          
          <div className={styles.addressList}>
            {mapAddresses()}
          </div>
        
        </div>
      )
    }
  }
  
  return (
    <div className={styles.container}>
      {renderSavedAddresses()}
    </div>
  );
  
  
}

export default Addresses