import React, {useEffect, useState} from "react";

import "./Initiatives.modules.scss";
import {
  EntryInterface,
  GET_NEW_SUBSCRIPTION_COUNT_action,
  InitiativeInterface,
  resetState
} from "../../../02_redux/actions/";
import InitiativeStatusCard from "./components/initiativeStatusCard";
import HeroSection from "./components/HeroSection"
import Masonry from "react-masonry-css";
import {GET_ENTRIES_FEATURED, GET_INITIATIVES} from "../../../02_redux/redux_config/types";
import {Dots} from "react-activity";
import FeaturedEntries from "./components/FeaturedEntries";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import Impression from "../../components_higherOrder/Ping/Impression";


const Initiatives = () => {


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [stalls, setStalls] = useState<any>([])

  const {
    0: GET_INITIATIVES_LOADING,
    1: GET_INITIATIVES_SUCCESS,
    2: GET_INITIATIVES_ERROR,
    3: GET_INITIATIVES_EVENT_CODE,
    4: GET_INITIATIVES_MESSAGE,
    5: GET_INITIATIVES_PAYLOAD
  } = GetApiFlightStatus<Array<InitiativeInterface>>(GET_INITIATIVES);

  const {
    0: GET_ENTRIES_FEATURED_LOADING,
    1: GET_ENTRIES_FEATURED_SUCCESS,
    2: GET_ENTRIES_FEATURED_ERROR,
    3: GET_ENTRIES_FEATURED_EVENT_CODE,
    4: GET_ENTRIES_FEATURED_MESSAGE,
    5: GET_ENTRIES_FEATURED_PAYLOAD
  } = GetApiFlightStatus<Array<EntryInterface>>(GET_ENTRIES_FEATURED);

  // const {
  //     0: GET_NEW_SUBSCRIPTION_COUNT_LOADING,
  //     1: GET_NEW_SUBSCRIPTION_COUNT_SUCCESS,
  //     2: GET_NEW_SUBSCRIPTION_COUNT_ERROR,
  //     3: GET_NEW_SUBSCRIPTION_COUNT_EVENT_CODE,
  //     4: GET_USER_PURCHASES_MESSAGE,
  //     5: GET_USER_PURCHASES_PAYLOAD
  // } = GetApiFlightStatus(GET_NEW_SUBSCRIPTION_COUNT);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
    resetState,
    USER_V2_GET_INITIATIVES_ACTION,
    GET_ENTRIES_FEATURED_ACTION,
    GET_NEW_SUBSCRIPTION_COUNT_action
  } = useActions()


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    USER_V2_GET_INITIATIVES_ACTION({});
    GET_ENTRIES_FEATURED_ACTION({});
    // this.props.GET_NEW_SUBSCRIPTION_COUNT_action({day: 28, month: 5, year: 2021})
  }, [])


  const renderHeroSection = () => {
    return (
            <HeroSection
                    // GET_NEW_SUBSCRIPTION_COUNT_SUCCESS={GET_NEW_SUBSCRIPTION_COUNT_PAYLOAD}
            />
    )
  }

  const renderMasonrySection = () => {
    const breakpointColumnsObj = {
      default: 3,
      1200: 2,
      700: 1
    };

    const masonryOptions = {
      transitionDuration: 0.2,
      //itemSelector: ".grid-image",
      //columnWidth: 120,
      isFitWidth: true
    };

    if (GET_INITIATIVES_PAYLOAD.length > 0) {
      return (
              <Impression impression section="initiatives">
              <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                      // options={masonryOptions}
              >
                {GET_INITIATIVES_PAYLOAD.map((item, i) => {
                  return <div key={item._id}><InitiativeStatusCard item={item}/></div>;
                })}

              </Masonry>
              </Impression>
      );
    }
  };

  const renderFeaturedEntries = () => {
    return (
            <Impression impression section="featured_entries">
            <div className={"featuredEntriesSection"}>
              <FeaturedEntries
                      GET_ENTRIES_FEATURED_LOADING={GET_ENTRIES_FEATURED_LOADING}
                      GET_ENTRIES_FEATURED_SUCCESS={GET_ENTRIES_FEATURED_PAYLOAD}
              />
            </div>
            </Impression>
    )
  }


  const renderStalls = () => {
    if (GET_INITIATIVES_LOADING) {
      return (
              <div className={"loading"}>
                <Dots/>
              </div>
      )

    }
    if (GET_INITIATIVES_SUCCESS) {
      return renderMasonrySection()
    }
  }

  const renderSeparator = () => {
    return (
            <div className={"landingPageAlign"}>
              <div className={"landingPageHorizontalSeparator"}/>
            </div>
    )
  }


  return (
          <Impression pageload page={"home"}>

            <div className={"landing-container"}>
              {renderHeroSection()}

              {renderFeaturedEntries()}
              {renderSeparator()}

              {/*<div className={"supportedBy"}>*/}
              {/*  <div className={"supportedByText"}>*/}
              {/*  Supported By:*/}
              {/*  </div>*/}
              {/*  <SupportedBy />*/}
              {/*</div>*/}

              <div className={"stall-cards-collection"}>
                {renderStalls()}
              </div>
            </div>
          </Impression>
  );
}


export default Initiatives

