import React from "react";
import styles from "./ManualCreditCardForm.module.scss";
import {InputText, InputType} from "../../components_core/InputText/InputText";
import {UseFormReturn} from "react-hook-form";


interface CreditCardFormProps {
  register: UseFormReturn['register']
  formErrors: any
}

const ManualCreditCardForm = (props: CreditCardFormProps) => {

  const {register, formErrors} = props

  // const {name_on_card, cc_no, cc_exp, cc_cvv} = props.values
  //
  // const handleUpdateFormikValue = (field, value) => {
  //   props.handleUpdateFormikValue(field, value)
  // }

  return (
    <div>

      <div className={styles.heading2Text}>
        <h2>
          Enter your card information:
        </h2>
      </div>

      <div className={styles.formBlock}>


        <InputText
          fieldName={"name_on_card"}
          // fieldLabel={"Name on card"}
          fieldLabel={""}
          fieldError={false}
          fieldErrorMessage={formErrors.name?.message ? formErrors.name?.message : null}
          inputType={InputType.TEXT}
          fieldPlaceHolder={"Name on card"}
          register={register as any}
        />

        <InputText
          fieldName={"cc_no"}
          // fieldLabel={"Credit/Debit Card Number"}
          fieldLabel={""}
          fieldPlaceHolder={"Credit/Debit Card Number"}
          fieldErrorMessage={formErrors.cc_no?.message ? formErrors.cc_no?.message : null}
          fieldError={false}
          inputType={InputType.TEXT}
          register={register as any}

        />


        {/*<input*/}
        {/*  type="text"*/}
        {/*  name={"name_on_card"}*/}
        {/*  className={`${styles.formField}`}*/}
        {/*  placeholder={"Name on card"}*/}
        {/*  // value={name_on_card}*/}
        {/*  // onChange={()=>{}}*/}
        {/*  defaultValue={name_on_card}*/}
        {/*  onBlur={(e) => handleUpdateFormikValue("name_on_card", e.target.value)}*/}
        {/*/>*/}


        {/*<input*/}
        {/*  type="text"*/}
        {/*  name={"cc_no"}*/}
        {/*  className={`${styles.formField}`}*/}
        {/*  placeholder={"Credit/Debit Card Number"}*/}
        {/*  // value={cc_no}*/}
        {/*  // onChange={()=>{}}*/}
        {/*  defaultValue={cc_no}*/}
        {/*  onBlur={(e) => handleUpdateFormikValue("cc_no", e.target.value)}*/}
        {/*/>*/}

        <div className={styles.cardSupplementaryInfoBlock}>
          <div className={styles.expirationBlock}>

            <InputText
              fieldName={"cc_exp"}
              fieldLabel={""}
              // fieldLabel={"Expiration MM/YY"}
              fieldPlaceHolder={"Expiration MM/YY"}
              fieldError={false}
              fieldErrorMessage={formErrors.cc_exp?.message ? formErrors.cc_exp?.message : null}
              inputType={InputType.TEXT}
              register={register as any}

            />

            {/*<input*/}
            {/*  type="text"*/}
            {/*  name={"cc_exp"}*/}
            {/*  className={`${styles.formField}`}*/}
            {/*  placeholder={"Expiration MM/YY"}*/}
            {/*  // value={cc_exp}*/}
            {/*  defaultValue={cc_exp}*/}
            {/*  onBlur={(e) => handleUpdateFormikValue("cc_exp", e.target.value)}*/}
            {/*/>*/}
          </div>

          <div className={styles.cvvBlock}>

            <InputText
              fieldName={"cc_cvv"}
              fieldPlaceHolder={"CVV"}
              fieldLabel={""}
              fieldErrorMessage={formErrors.cc_cvv?.message ? formErrors.cc_cvv?.message : null}
              // fieldLabel={"CVV"}
              fieldError={false}
              inputType={InputType.TEXT}
              register={register as any}

            />

            {/*<input*/}
            {/*  type="text"*/}
            {/*  name={"cc_cvv"}*/}
            {/*  className={`${styles.formField}`}*/}
            {/*  placeholder={"CVV"}*/}
            {/*  // value={cc_cvv}*/}
            {/*  // onChange={()=>{}}*/}
            {/*  defaultValue={cc_cvv}*/}
            {/*  onBlur={(e) => handleUpdateFormikValue("cc_cvv", e.target.value)}*/}
            {/*/>*/}
          </div>

        </div>


        <div className={styles.spacer}/>
      </div>
    </div>
  )
}

export default ManualCreditCardForm;
