import React from "react";
import styles from "./CreateAccount.module.scss";
import SM2 from "../../../01_application/assets/ui/Frame 1.png";
import {Dots} from "react-activity";
import {InputText, InputType} from "../../components_core/InputText/InputText";

const CreateAccount = props => {
  
  const handleUpdateViewToSignIn = () => {
    props.handleUpdateViewToSignIn()
  }
  
  const handleCreateAccount = () => {
    props.handleCreateAccount()
  }
  
  const renderCreateAccountContent = () => {
    if (props.CREATE_USER_ACCOUNT_LOADING) {
      return <Dots />
    }
    
    return "Create an account"
  }
  
  
  return (
    <div className={styles.container}>
      
      <div>
        {/*<img src={Logo} alt="socialgood" className={styles.logoImage}/>*/}
        <img src={SM2} alt="socialgood" className={styles.logoImage}/>
        {/*<img src={SM} alt="secondmeal" className={styles.logoImage}/>*/}
      </div>
      
      
      <div className={styles.headingText}>
        Create an account.
      </div>
      
      <div className={styles.subheadingText}>
        Create an account to check out and track your purchases with ease. <br/>
      </div>
      
      
      <div className={styles.formBlock}>
        
        
        <InputText
          inputType={InputType.TEXT}
          fieldName={"name"}
          fieldLabel={""}
          fieldPlaceHolder={"name"}
          fieldError={false}
          register={props.register}
        />
        
        <InputText
          inputType={InputType.EMAIL}
          fieldName={"email"}
          fieldLabel={""}
          fieldPlaceHolder={"email"}
          fieldError={false}
          register={props.register}
        />
        
        <InputText
          inputType={InputType.PASSWORD}
          fieldName={"password"}
          fieldLabel={""}
          fieldPlaceHolder={"password"}
          fieldError={false}
          register={props.register}
        />
        
        
        {/*<input*/}
        {/*  className={styles.formField}*/}
        {/*  name="name"*/}
        {/*  type="text"*/}
        {/*  placeholder={"name"}*/}
        {/*  onChange={props.formik.handleChange}*/}
        {/*  value={props.formik.values.name}*/}
        {/*/>*/}
        {/*<input*/}
        {/*  className={styles.formField}*/}
        {/*  name="email"*/}
        {/*  type="email"*/}
        {/*  placeholder={"email"}*/}
        {/*  onChange={props.formik.handleChange}*/}
        {/*  value={props.formik.values.email}*/}
        {/*/>*/}
        {/*<input*/}
        {/*  className={styles.formField}*/}
        {/*  name="password"*/}
        {/*  type="password"*/}
        {/*  placeholder={"password"}*/}
        {/*  onChange={props.formik.handleChange}*/}
        {/*  value={props.formik.values.password}*/}
        {/*/>
        */}
      
      </div>
      
      <div className={styles.signInBlock}>
        <div className={styles.priBtn} onClick={handleCreateAccount}>
          {renderCreateAccountContent()}
        </div>
      
      
      </div>
      
      <a onClick={handleUpdateViewToSignIn}>
        <div className={styles.createAccount}>
          sign in
        </div>
      </a>
     
    </div>
  );
};

export default CreateAccount;
