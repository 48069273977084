import {
    AUTHENTICATE_ACCOUNT,
    CLEAR_AUTHENTICATION_STATE,
    CLEAR_PROFILE,
    CREATE_USER_ACCOUNT,
    GET_USER_QUICK_PROFILE,
    UNAUTHORISED
} from '../redux_config/types';


export interface ProfileReducer {
    _id: string
    jwt: string | null
    email: string
    email_verified: boolean,
    name: string,
}

const INITIAL_STATE = {
    _id: "",
    jwt: null,
    email: "",
    email_verified: false,
    name: "",
}


export function profileReducer(state = INITIAL_STATE, action: any) {

    switch (action.type) {
        case CLEAR_AUTHENTICATION_STATE: {
            return INITIAL_STATE;
        }

        case `${AUTHENTICATE_ACCOUNT}_SUCCESS`:
        case `${CREATE_USER_ACCOUNT}_SUCCESS`: {
          return {
                ...state,
                jwt: action.payload.payload.jwt,
                email: action.payload.payload.email,
                name: action.payload.payload.name,
                email_verified: action.payload.payload.email_verified,
            };
        }

        case `${GET_USER_QUICK_PROFILE}_SUCCESS`: {
            return {
                ...state,
                email: action.payload.payload.email,
                name: action.payload.payload.name,
                email_verified: action.payload.payload.email_verified,
            };
        }

        case CLEAR_PROFILE: {
            return INITIAL_STATE
        }

        case UNAUTHORISED: {
            window.location.href = '/'

            return INITIAL_STATE
        }

        default:
            return state;
    }
}
