import React from "react";
import {ReactComponent as Visa} from "../../../01_application/assets/ui/card_brands/visa.svg";
import {ReactComponent as MC} from "../../../01_application/assets/ui/card_brands/mc_symbol.svg";
import {ReactComponent as Amex} from "../../../01_application/assets/ui/card_brands/Amex_logo_color.svg";
import styles from "./Card.module.scss";


export interface CreditCardInterface {
  _id: string
  selected: boolean
  name_on_card: string,
  last_4: string
  exp_month: string
  exp_year: string
  brand: string
  handleSelectCard: (card_id: string) => void
}

const Card = (props: CreditCardInterface) => {

  const {
          _id,
          selected,
          name_on_card,
          last_4,
          exp_month,
          exp_year,
          brand,
          handleSelectCard
        } = props

  const renderCardIcon = (brand: string) => {
    switch (brand.toLowerCase()) {
      case "visa": {
        return (
          <Visa className={styles.visaIcon}/>
        )
      }

      case "master card":
      case "mastercard": {
        return (
          <MC className={styles.masterCardIcon}/>
        )
      }

      case "amex":
      case "american express": {
        return (
          <Amex className={styles.amexIcon}/>
        )
      }

      default:
        return null
    }
  }


  return (
    <div className={`${styles.card} ${selected ? styles.selected : ""}`}
         onClick={() => handleSelectCard(_id)}>
      <div className={styles.cardDetailsBlock}>

        {renderCardIcon(brand)}
        <div>
          ****  &nbsp;  &nbsp; **** &nbsp;  &nbsp; **** &nbsp; &nbsp; {last_4}
        </div>

        <div>
          {exp_month} / {exp_year}
        </div>

      </div>
    </div>
  )
}


export default Card;