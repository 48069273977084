import React from "react";
import styles from "./Typography.module.scss"

type TextProps = {
    children?: React.ReactNode
};

export const Paragraph: React.FC<TextProps> = ({children}) => {
    return(
        <p>
            {children}
        </p>
    )
}

export const ParagraphLabel: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.ParagraphLabel}>
            {children}
        </p>
    )
}

export const ParagraphSemiBold: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.ParagraphSemiBold}>
            {children}
        </p>
    )
}
export const SmallText: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.SmallText}>
            {children}
        </p>
    )
}

export const SmallTextSemiBold: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.SmallTextSemiBold}>
            {children}
        </p>
    )
}


export const SmallTextLabel: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.SmallTextLabel}>
            {children}
        </p>
    )
}


export const TinyText: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.TinyText}>
            {children}
        </p>
    )
}

export const TinyTextSemiBold: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.TinyTextSemiBold}>
            {children}
        </p>
    )
}

export const TinyTextLabel: React.FC<TextProps> = ({children}) => {
    return(
        <p className={styles.TinyTextLabel}>
            {children}
        </p>
    )
}
