import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import styles from "./Dropzone.module.scss"
import plus from "../../../../01_application/assets/icons/plus.svg";
import {v4 as uuidv4} from 'uuid';
import {Dots} from "react-activity";

class UploadItem extends Component {

  state = {
    files: []
  };


  handleDrop = (files) => {
    // console.log(files)

    this.setState(prevState => ({
      files: [...prevState.files, ...files]
    }), () => {
      // console.log(this.state)
    })


    files.forEach(file => {
      file.id = uuidv4()
      this.props.handleUploadDocument(file)
    });
  };

  handleRemoveItems = (index) => {

  }

  renderLoading = (fileLength, index) => {
    if ((fileLength === index + 1) && this.props.UPLOAD_DOCUMENT_LOADING) {
      return (
        <div className={styles.loadingContainer}>
          <Dots/>
        </div>
      )
    }
  }

  renderFiles = () => {

    const fileLength = this.state.files.length

    return this.state.files.map((file, i) => {

      return (
        <div key={i} className={styles.fileName}>
          {file.name}
          {this.renderLoading(fileLength, i)}
        </div>
      )
    })
  }


  renderInstructions = () => {
    if (this.state.files.length === 0) {
      return (
        <p className={styles.dropzoneInstructionText}>
          Drag and drop some files here, or click to select files. Maximum file size of 5mb
        </p>
      )
    }
  }

  renderPlusIcon = () => {
    if (this.props.UPLOAD_DOCUMENT_LOADING) {
      return
    } else {
      return (
        <div className={styles.plusIconBlock}>
          <img src={plus} alt="plus" className={styles.plusIcon}/>
          {this.renderInstructions()}
        </div>
      )
    }
  }

  render() {

    return (
      <div className={styles.dropzoneBlock}>

        <div className={styles.formLabel}>
          Supporting Documents
        </div>

        <Dropzone
          onDrop={this.handleDrop}
          minSize={0}
          maxSize={5242880}
          accept="image/*, application/pdf"
        >
          {({getRootProps, getInputProps}) => (
            <section className={"container"}>
              <div {...getRootProps({className: styles.dropzone})}>
                <input {...getInputProps()} />

                <div className={styles.fileItemsBlock}>
                  {this.renderFiles()}
                </div>


                {this.renderPlusIcon()}


              </div>

            </section>
          )}
        </Dropzone>
      </div>
    );
  }


}


export default UploadItem
