import React, {useEffect} from "react";
import {getClaimsFromPurchase} from "../../../02_redux/actions";
import moment from "moment";
import "./ClaimsFromPurchase.modules.scss";
import ClaimsFromPurchaseTable from "./components/ClaimsFromPurchaseTable";
import {Dots} from "react-activity";
import {GET_CLAIMS_FROM_PURCHASE_SUCCESS, GETTING_CLAIMS_FROM_PURCHASE} from "../../../02_redux/redux_config/types";
import logo from "../../../01_application/assets/ui/logo-secondmeal-yellow-bowl.svg";
import Plate from "../../../01_application/assets/icons/plate-food.svg";
import {NavLink} from "react-router-dom";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {ClaimsFromPurchaseReducer} from "../../../02_redux/reducers/claimsFromPurchase_reducer";
import PurchaseStatusTag from "../../components_common/PurchaseStatusTag/PurchaseStatusTag";
import Impression from "../../components_higherOrder/Ping/Impression";

const ClaimsFromPurchase = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
          getClaimsFromPurchase
        } = useActions()

  const params = useParams()
  //@ts-ignore

  let {id} = params
  if (!id) {
    id = ""
  }

  const purchase_id = id.split("=")[1]
  const claimsFromPurchase = useTypedSelector<ClaimsFromPurchaseReducer>(state => state.claimsFromPurchase)


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    getClaimsFromPurchase(purchase_id);
  }, [])


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const renderEmptyState = () => {
    return (
      <div className={"empty-state-block"}>

        <img src={Plate} alt="info" className="plate-icon"/>

        <div className={"empty-state-text"}>
          No one has claimed your delicious meals yet! Check this link again soon to see who has claimed the meals
          you have purchased
        </div>
      </div>
    )
  }

  const renderPurchaseClaimsTable = () => {
    if (claimsFromPurchase.case === GET_CLAIMS_FROM_PURCHASE_SUCCESS) {
      if (claimsFromPurchase.payload.claims.length > 0) {
        return (
          <div className={"table-section"}>
            <ClaimsFromPurchaseTable
              data={claimsFromPurchase.payload.claims}
            />
          </div>
        );
      }

      return (
        renderEmptyState()
      )


    }
  };

  const renderReceipt = () => {
    if (claimsFromPurchase.case === GETTING_CLAIMS_FROM_PURCHASE) {
      return <Dots/>;
    }
    if (claimsFromPurchase.case === GET_CLAIMS_FROM_PURCHASE_SUCCESS) {
      return (
        <div className={"receipt"}>
          {renderReceiptHeader()}
          {renderDetails()}
        </div>
      );
    }
  };

  const renderManageRecurring = () => {

    const {parent_repeat_purchase = null, subscription, auto_repeat_purchase = false} = claimsFromPurchase.payload

    // console.log(claimsFromPurchase)
    // console.log(auto_repeat_purchase)
    // console.log(subscription)

    if (auto_repeat_purchase === true || subscription === 1) {
      return (
        <div className={"manageRecurringPurchaseContainer"}>
          <NavLink
            to={`/manage-recurring-purchase/${parent_repeat_purchase}`}
          >
            manage automated purchases
          </NavLink>
        </div>
      )
    }
  }


  const renderPurchaseStatus = () => {

    const {refunded, processing_payment, status} = claimsFromPurchase.payload


    return (
      <PurchaseStatusTag
        refunded={refunded}
        processing_payment={processing_payment}
        status={status}
      />

    )
  }


  const renderDetails = () => {
    return (
      <div className={"details-section"}>
        {/*{claimsFromPurchase.payload.time}*/}

        {renderManageRecurring()}

        <br/>

        {renderPurchaseStatus()}


        <div className={"display_name"}>
          Thank you{" "}
          {claimsFromPurchase.payload.display_name}!
        </div>

        <div className={"sub_text"}>
          Please save this link and check back often to see the difference you are making in someone's life. You
          will be
          able to see the meals claimed by them on this page.
        </div>


        <div className={"details-container"}>
          <div className={"details-sub-container"}>
            <div>Purchase ID:</div>
            <div className={"purchase-details"}>
              {claimsFromPurchase.payload._id}
            </div>
          </div>

          <div className={"details-sub-container"}>
            <div>Purchase Date:</div>

            <div className={"purchase-details"}>
              {moment(
                claimsFromPurchase.payload.created_at
              ).format("Do MMMM YYYY")}
            </div>
          </div>

          <div className={"details-sub-container"}>
            <div>Total meals purchased:</div>
            <div className={"purchase-details"}>
              {claimsFromPurchase.payload.purchased}{" "}
              Meals
            </div>
          </div>

          <div className={"details-sub-container"}>
            <div>Amount Paid:</div>
            <div className={"purchase-details"}>
              ${" "}
              {(
                claimsFromPurchase.payload.amount /
                100
              ).toFixed(2)}
            </div>
          </div>
        </div>

        {/*<div className={"count"}>*/}
        {/*    {claimsFromPurchase.payload.suspended_count}/*/}
        {/*    {claimsFromPurchase.payload.purchased} left*/}
        {/*</div>*/}

        <div className={"separator"}/>

        <div className={"claims-summary-container"}>
          <div className={"claims-sub-container"}>
            <div>TOTAL CLAIMS:</div>
            <div>
              {claimsFromPurchase.payload.purchased -
                claimsFromPurchase.payload
                  .suspended_count}{" "}
              Meals
            </div>
          </div>

          <div className={"claims-sub-container"}>
            <div>CLAIMS LEFT:</div>
            <div>
              {
                claimsFromPurchase.payload
                  .suspended_count
              }{" "}
              Meals
            </div>
          </div>
        </div>

        {renderPurchaseClaimsTable()}
      </div>
    );
  };

  const renderReceiptHeader = () => {
    return (
      <div className={"receipt-header"}>
        <img
          src={logo}
          alt="secondmeal"
          className="receipt-header-logo"
        />
        <div className={"header-text"}>SECONDMEAL</div>
      </div>
    );
  };


  return (
    <Impression pageload page={"claims_from_purchase"}>
      <div className="claims-from-purchase-page">
        {renderReceipt()}
      </div>
    </Impression>
  );

}

// const mapStateToProps = state => {
//   return {
//     claimsFromPurchase: state.claimsFromPurchase
//   };
// };


export default ClaimsFromPurchase;

