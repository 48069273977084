import React from 'react';
import RoutingDirectory from "./routes/routes";
import './styles/App.scss';
import Header from "../03_views/components_higherOrder/Header/Header";
import {ToastProvider} from 'react-toast-notifications';
import AnalyticsController from "./controllers/AnalyticsController"
import {Toaster} from 'react-hot-toast';
import "react-activity/dist/Dots.css";
import "react-multi-carousel/lib/styles.css";
import "./styles/index.scss";
import * as Sentry from "@sentry/react";
import Analytics from "../03_views/components_higherOrder/Analytics/Analytics";

// const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));
// let basename = "/";

const App = () => {
  //

  /*
     Prevents double click to zoom
  */
  // let preLastTouchStartAt = 0;
  // let lastTouchStartAt = 0;
  // const delay = 500;
  //
  // document.addEventListener('touchstart', () => {
  //   preLastTouchStartAt = lastTouchStartAt;
  //   lastTouchStartAt = +new Date();
  // });
  // document.addEventListener('touchend', (event) => {
  //   const touchEndAt = +new Date();
  //   if (touchEndAt - preLastTouchStartAt < delay) {
  //     event.preventDefault();
  //     event.target.click();
  //   }
  // });

  /*
  End of prevent double click to zoom
   */

  return (
          <ToastProvider>
            <div className={"app"}>
              <Header/>
              <Analytics/>
              <div className="app__main-inner">
                <RoutingDirectory/>
              </div>
              <Toaster/>
              <AnalyticsController/>
            </div>
          </ToastProvider>
  )
}

export default Sentry.withProfiler(App)
