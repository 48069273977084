import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {CAPTURE_CLIENT_INTENT} from "../redux_config/types";
import {USER_V2_CAPTURE_CLIENT_INTENT} from "../redux_config/endpoints";
import {getJWT, isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";

export const USER_V2_CAPTURE_CLIENT_INTENT_ACTION = (payload) => {
  
  return async dispatch => {
    
    if (isLoading(CAPTURE_CLIENT_INTENT) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(CAPTURE_CLIENT_INTENT, dispatch).post(
        USER_V2_CAPTURE_CLIENT_INTENT,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
