import React, {Component, useEffect} from "react";
import {Link} from "react-router-dom";
import styles from "./PurchaseSuccess.module.scss"
import {GUEST_PURCHASE_MEALS, MARK_MEALS_AS_PAID, USER_PURCHASE_MEALS} from "../../../02_redux/redux_config/types";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import Impression from "../../components_higherOrder/Ping/Impression";

const PurchaseSuccess = () => {


  const {
          resetState,
        } = useActions()
  const params = useParams()

  //Doing this weird split because react router v6 no longer supports multiple params and
  // I want to keep the link the same
  let {id} = params
  if (!id) {
    id = ""
  }
  const split = id.split("&")
  const purchase_id = split[0]
  const email = split[1]

  useEffect(() => {
    resetState(USER_PURCHASE_MEALS)
    resetState(GUEST_PURCHASE_MEALS)
    resetState(MARK_MEALS_AS_PAID)
    localStorage.removeItem("PURCHASE_PREVIEW_PERSIST_KEY");
  })


  const renderSuccess = () => {

    // @ts-ignore
    // const {email, purchase_id} = params;


    return (
      <div className={styles.successContainer}>
        <div className={styles.successMsg}>SUCCESS</div>

        <div className={styles.successSubMsg}>
          Thank you for purchasing.
        </div>

        <div className={styles.successSubMsg}>
          We have sent you an email receipt to <b>{email}</b> where you can track your claims.
        </div>

        <div className={styles.successSubMsg}>
          If you have not received it, or the email address is incorrect, please write in to us on facebook.
        </div>

        <Link
          to={`/claims/purchase_id=${purchase_id}`}
          className={styles.navToPurchaseClaimsButton}
        >
          <div className={styles.navToPurchaseClaimsText}>
            TRACK CLAIMS
          </div>
        </Link>
      </div>
    );
  };


  return (
    <Impression pageload page={"purchase_meals_success"}>
      <div className={styles.page}>
        {renderSuccess()}
      </div>
    </Impression>
  )
}

export default PurchaseSuccess
