import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_BENEFICIARY_PROFILE} from "../redux_config/types";
import { USER_V2_GET_BENEFICIARY_PROFILE} from "../redux_config/endpoints";
import { isLoading } from "../redux_utilities/loadingStatus";
import { Dispatch } from "react";
import { isAuthenticated } from "../redux_utilities/authenticationStatus";

interface USER_V2_GET_BENEFICIARY_PROFILE_REQUEST_PAYLOAD {
    id : string;
    last4: string;
}


export const USER_V2_GET_BENEFICIARY_PROFILE_ACTION = (payload:USER_V2_GET_BENEFICIARY_PROFILE_REQUEST_PAYLOAD ) => {
  return async (dispatch: Dispatch<any>) => {

    if (isLoading(GET_BENEFICIARY_PROFILE) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    const config = {
      headers: {
          Authorization: `Bearer ${isAuthenticated()}`
      }
    }

    try {
      await apiFlight(GET_BENEFICIARY_PROFILE, dispatch).post(
        USER_V2_GET_BENEFICIARY_PROFILE,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export interface USER_V2_GET_BENEFICIARY_PROFILE_SUCCESS_PAYLOAD {
    code: string;
    code_id_history: string[];
    dailymax: number;
    daily_max_meals: number;
    daily_max_drinks: number;
    claimcount_previous30days: number;
    supporting_documents: string[];
    _id: string;
    profile_picture_url: String;
    name: string;
    codeID: number;
    gender: number;
    contact_number: number;
    dob: number;
    weekly_max_meals: number;
    notes: string;
    _initiativeID: string;
    is_active: number;
    expiry: string|null;
    onboarding_authority: string;
    address: string|null;
    bio: string|null;
    weekly_max_drinks: number;
}


// sample payload for
// "code": "603bf5d4-bf60-4dcf-862f-d1f75eb2f7f7",
//   "code_id_history": [],
//   "dailymax": 1,
//   "daily_max_meals": 14,
//   "daily_max_drinks": 14,
//   "claimcount_previous30days": 0,
//   "supporting_documents": [],
//   "_id": "6108b465973bac32066bc163",
//   "profile_picture_url": "https://secondmeal-server-staging.s3.ap-southeast-1.amazonaws.com/b_profile_picture/ceda15d1-0642-460d-a8fa-e74deefa2623.jpeg",
//   "name": "TEST USERASD 12121212",
//   "codeID": 8445,
//   "gender": 1,
//   "contact_number": "12121212",
//   "dob": 19211231,
//   "weekly_max_meals": 14,
//   "notes": "asdfasdf\n\n\nasdfasdfasd\n\nasdfasdfa",
//   "_initiativeID": "5e9d0305344025bf969b78c3",
//   "is_active": 1,
//   "expiry": null,
//   "onboarding_authority": "5f745a69feb802e611f4721d",
//   "__v": 0,
//   "address": null,
//   "bio": null,
//   "weekly_max_drinks": 14
