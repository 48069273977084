import React from 'react';
import ReactDOM from 'react-dom';
 import {Provider} from "react-redux"
import {store, persistor} from "./01_application/store";
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from "./01_application/reportWebVitals";

import App from './01_application/App';

import {BrowserRouter} from "react-router-dom";
import {SentryInit} from "./01_application/sentry/Sentry";

// const store = createStore(reducers, {},compose(applyMiddleware(thunk)));
let basename = "/";
// SentryInit()

ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={basename}>
            <App />
        </BrowserRouter>
      </PersistGate>
     </Provider>,
    document.getElementById('root')
);

reportWebVitals();
