import React, {useEffect, useState} from "react";
import {getUnsubscribePreview, resetState, unsubscribe} from "../../../02_redux/actions";
import {Dots} from "react-activity";
import moment from "moment"
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {GET_RECURRING_PURCHASES, UNSUBSCRIBE} from "../../../02_redux/redux_config/types";
import logo from "../../../01_application/assets/ui/logo-secondmeal-yellow-bowl.svg";
import Plate from "../../../01_application/assets/icons/plate-food.svg";
// import FormikPersist from "../../components_common/FormikPersist";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import styles from "./ManageRecurring.module.scss"
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";
import Impression from "../../components_higherOrder/Ping/Impression";

const views = {
  PURCHASE_DETAILS: "PURCHASE_DETAILS",
  CONFIRMATION: "CONFIRMATION",
  ENTER_EMAIL: "ENTER_EMAIL",
  SUCCESS: "SUCCESS",
  ASSOCIATED_PURCHASE: "ASSOCIATED_PURCHASE",
}

let initialValues = {
  email: '',
}


const ManageRecurring = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [view, setView] = useState(views.PURCHASE_DETAILS)
  const [selectedPrentPurchaseIndex, setSelectedPrentPurchaseIndex] = useState<number | null>(null)


  const {
          0: UNSUBSCRIBE_LOADING,
          1: UNSUBSCRIBE_SUCCESS,
          2: UNSUBSCRIBE_ERROR,
          3: UNSUBSCRIBE_EVENT_CODE,
          4: UNSUBSCRIBE_MESSAGE,
          5: UNSUBSCRIBE_PAYLOAD,
        } = GetApiFlightStatus<any>(UNSUBSCRIBE);

  const {
          0: GET_RECURRING_PURCHASES_LOADING,
          1: GET_RECURRING_PURCHASES_SUCCESS,
          2: GET_RECURRING_PURCHASES_ERROR,
          3: GET_RECURRING_PURCHASES_EVENT_CODE,
          4: GET_RECURRING_PURCHASES_MESSAGE,
          5: GET_RECURRING_PURCHASES_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_RECURRING_PURCHASES);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          unsubscribe,
          getUnsubscribePreview,
          resetState
        } = useActions()

  const params = useParams()
  //@ts-ignore
  const {parent_repeat_purchase} = params

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getUnsubscribePreview({purchase_id: parent_repeat_purchase});
  }, [])


  useEffect(() => {
    if (UNSUBSCRIBE_SUCCESS) {
      if (UNSUBSCRIBE_SUCCESS === true) {
        setView(views.SUCCESS)
      }
    }
  }, [UNSUBSCRIBE_SUCCESS])


  // state = {
  //   view: views.PURCHASE_DETAILS,
  //   parent_purchase_index_selected: null
  //   // view: views.SUCCESS
  // };


  const handleNavigateToClaims = () => {

  }

  const handleClickStopAutoRecurringPurchases = () => {
    setView(views.CONFIRMATION)
  }

  const handleViewAssociatedPurchase = (purchase_id: number) => {
    setView(views.ASSOCIATED_PURCHASE)
    setSelectedPrentPurchaseIndex(purchase_id)
  }

  const handleProceedToStopFeedingBene = () => {
    setView(views.ENTER_EMAIL)
  }

  const handleBack = () => {
    setView(views.PURCHASE_DETAILS)
    setSelectedPrentPurchaseIndex(null)
  }

  const handleUnsubscribe = (values: any) => {
    unsubscribe({
      email: values.email,
      purchase_id: GET_RECURRING_PURCHASES_PAYLOAD[`${selectedPrentPurchaseIndex}`]._id
    })
  }


  const handleValidation = (values: any) => {

    const errors: any = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }


  const renderPurchaseDetails = () => {

    return (
      GET_RECURRING_PURCHASES_PAYLOAD.map((claim: any, i: number) => {

        // console.log(claim)

        const {
                initiative_name,
                name,
                amount,
                day,
                month,
                year,
                purchased,
                subscription,
                auto_recurring_purchase
              } = claim
        const date = moment(`${day} ${month} ${year}`, "DD MM YYYY").format("Do MMMM YYYY")
        const type = subscription === 1 ? "Subscription" : "Auto Recurring Purchase"
        const description = subscription === 1 ? `Automatically purchase ${purchased} meals every month` : `Automatically purchase ${purchased} meals when the beneficiaries have completed claiming your active purchase`

        return (
          <div key={claim._id} className={styles.row}>

            {/*<div className={styles.dateStyle}>*/}
            {/*  {date}*/}
            {/*</div>*/}

            <div className={styles.horizontalRowDetals}>
              <div>
                Initiative:
              </div>
              <div className={styles.typeBlock}>
                <b>{initiative_name} </b> <br/>
              </div>
            </div>


            <div className={styles.horizontalRowDetals}>
              <div>
                Meal Count:
              </div>
              <div>
                <b>{purchased}</b> meals <br/>
              </div>
            </div>


            <div className={styles.horizontalRowDetals}>
              <div>
                Amount:
              </div>
              <div>
                <b>${amount / 100}</b> <br/>
              </div>
            </div>

            <div className={styles.horizontalRowDetals}>
              <div>
                Type:
              </div>
              <div className={styles.typeBlock}>
                < b>{type}</b> <br/>
                <div className={styles.descriptionText}>
                  {description}

                </div>
              </div>
            </div>

            <a>
              <div className={styles.viewAssociatedPurchase}
                   onClick={() => handleViewAssociatedPurchase(i)}>
                View all purchases associated with this recurring purchase
              </div>
            </a>

            {/*</div>*/}


          </div>
        )
      })
    )
  }


  const renderAssociatedPurchase = () => {

    // @ts-ignore
    const {child_purchases = []} = GET_RECURRING_PURCHASES_PAYLOAD[`${selectedPrentPurchaseIndex}`]

    return (
      <div>

        <div className={styles.horizontalRow}>
          <div className={styles.leftBlock}>
            <div className={styles.cancelBtnContainer}>
              <a className={styles.cancelBtn} onClick={handleBack}>
                back
              </a>
            </div>
          </div>

          <div className={styles.rightBlock}>
            <div className={styles.stopAutoRecurringContainer}>
              <div className={styles.stopAutoRecurringBtn} onClick={handleClickStopAutoRecurringPurchases}>
                Stop Auto-recurring purchases
              </div>
            </div>
          </div>
        </div>

        {child_purchases.map((purchases: any, i: number) => {
          // console.log(purchases)

          const {_id, display_name, day, month, year, amount, purchased, initiative_id} = purchases
          const date = moment(`${day} ${month} ${year}`, "DD MM YYYY").format("Do MMMM YYYY")
          return (
            <div key={_id} className={styles.row}>

              <div className={styles.dateStyle}>
                {date}
              </div>


              <div className={styles.horizontalRowDetals}>
                <div className={styles.leftBlock}>
                  Re-purchased <b>{purchased} meals</b> for the beneficiaries at <b>{initiative_id.name}</b>
                </div>

                <div className={styles.rightBlock}>
                  <div className={styles.pricingLarge}>
                    <b>${amount / 100} </b>
                  </div>
                </div>
              </div>


              <Link
                to={`/claims/purchase_id=${_id}`}
              >
                <div className={styles.viewAssociatedPurchase}
                     onClick={() => handleViewAssociatedPurchase(i)}>
                  View claim details
                </div>
              </Link>

            </div>
          )
        })}

      </div>
    )
  }

  const renderStopConfirmation = () => {
    return (
      <div>
        <div className={styles.cancelBtnContainer}>
          <a className={styles.cancelBtn} onClick={handleBack}>
            back
          </a>
        </div>


        <div className={styles.confirmationHeader}>
          Are you sure you want to cancel the auto-recurring purchase?
        </div>

        <div className={styles.confirmationSubHeader}>
          Every meal counts and each purchase goes a long way, to helping those in need. We urge you to reconsider
          cancelling the auto-recurring purchase. <br/><br/>

          As Secondmeal grows larger and feeding more people, we have reached a phase where it is a constant
          juggle
          between out reach to the beneficiaries and being sure that we have enough meals to feed the
          beneficiaries.
          Auto-recurring purchase helps us forecast how many meals
          will be coming our way in future. This gives us accurate data and confidence to onboard new
          beneficiaries to
          benefit from this programme.
        </div>

        <div className={styles.buttonContainer}>

          <div>
            <a className={styles.backBtn} onClick={handleBack}>
              go back
            </a>
          </div>

          <div>
            <a className={styles.ProceedBtn} onClick={handleProceedToStopFeedingBene}>
              Stop feeding those in need
            </a>
          </div>


        </div>


      </div>
    )
  }


  const renderEmptyState = () => {
    return (
      <div className={styles.emptyStateBlock}>

        <img src={Plate} alt="info" className={styles.plateIcon}/>

        <div className={styles.emptyStateText}>
          There is no auto recurring purchase associated with this purchase!
        </div>
      </div>
    )
  }

  const renderSubmitButtonContent = () => {
    if (UNSUBSCRIBE_LOADING) {
      return <Dots/>
    }

    return "proceed to stop feeding those in need"
  }

  const renderAPIErrors = () => {
    if (UNSUBSCRIBE_ERROR) {
      return (
        <div className={styles.errorBlock}>
          {UNSUBSCRIBE_MESSAGE}
        </div>
      )
    }
  }

  const renderEnterEmail = () => {
    return (
      <div>
        <div className={styles.cancelBtnContainer}>
          <a className={styles.cancelBtn} onClick={handleBack}>
            back
          </a>
        </div>


        <div className={styles.confirmationHeader}>
          Please enter your email associated with the purchase to complete the process
        </div>


        <Formik
          initialValues={initialValues}
          validate={values => handleValidation(values)}
          onSubmit={(values) => {
            handleUnsubscribe(values);
          }}
        >
          {({setFieldValue}) => (
            <Form>
              {/*<FormikPersist name="UnsubForm"/>*/}

              <Field type="name" name="email" className={styles.formField}/>
              <ErrorMessage name="email">{msg => <div
                className={styles.errorBlock}>{msg}</div>}</ErrorMessage>
              {renderAPIErrors()}

              <div className={styles.buttonContainer}>


                <div>
                  <a className={styles.backBtn} onClick={handleBack}>
                    go back
                  </a>
                </div>

                <button
                  className={styles.ProceedBtn}
                  type="submit"
                >
                  {/*<a  onClick={handleProceedToStopFeedingBene}>*/}
                  {renderSubmitButtonContent()}
                  {/*</a>*/}
                </button>
              </div>

            </Form>
          )}

        </Formik>

      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <div className={styles.emptyStateBlock}>

        <img src={Plate} alt="info" className={styles.plateIcon}/>

        <div className={styles.confirmationHeader}>
          Stop recurring purchase successful
        </div>

        <div className={styles.emptyStateText}>
          We really hate to see you go :(
        </div>
      </div>
    )
  }


  const renderContent = () => {


    if (GET_RECURRING_PURCHASES_LOADING) {
      return (
        <>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <LoadingDots/>
        </>
      )
    }

    if (GET_RECURRING_PURCHASES_PAYLOAD === null) {
      return renderEmptyState()
    }

    // const {purchases} = GET_RECURRING_PURCHASES_PAYLOAD

    if (GET_RECURRING_PURCHASES_ERROR || GET_RECURRING_PURCHASES_PAYLOAD.length === 0) {
      return renderEmptyState()
    } else {

      if (view === views.PURCHASE_DETAILS) {
        return (
          <div className={styles.detailsSection}>

            <div className={styles.greetings}>
              Hello {GET_RECURRING_PURCHASES_PAYLOAD[0].display_name}!
            </div>

            <div className={styles.subGreetings}>
              you have <b>{GET_RECURRING_PURCHASES_PAYLOAD.length}</b> active recurring purchases:
            </div>


            <div className={styles.line}/>
            {renderPurchaseDetails()}
          </div>
        )


      } else if (view === views.ASSOCIATED_PURCHASE) {
        return (
          <div className={styles.detailsSection}>
            {renderAssociatedPurchase()}
          </div>
        )
      } else if (view === views.CONFIRMATION) {
        return (
          <div className={styles.detailsSection}>
            {renderStopConfirmation()}
          </div>
        )
      } else if (view === views.ENTER_EMAIL) {
        return (
          <div className={styles.detailsSection}>
            {renderEnterEmail()}
          </div>
        )
      } else if (view === views.SUCCESS) {
        return (
          <div className={styles.detailsSection}>
            {renderSuccess()}
          </div>
        )
      }
    }
  };


  const renderReceiptHeader = () => {
    return (
      <div className={styles.receiptHeader}>
        <img
          src={logo}
          alt="secondmeal"
          className={styles.headerLogo}
        />
        <div className={styles.headerText}>SECONDMEAL</div>
      </div>
    );
  };

  const renderReceipt = () => {
    if (GET_RECURRING_PURCHASES_LOADING) {
      return <Dots/>;
    } else if (GET_RECURRING_PURCHASES_SUCCESS) {
      return (
        <div className={styles.receipt}>
          {renderReceiptHeader()}
          {renderContent()}
        </div>
      );
    }

    return (
      <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <LoadingDots/>
      </>
    )

  };

  return (
    <Impression pageload page={"manage_recurring_purchase"}>
      <div className={styles.block}>
        {/*{renderUnsubscribeError()}*/}
        {renderReceipt()}
      </div>
    </Impression>
  );

}


export default ManageRecurring;
