import {
    GET_STALLS_SUCCESS,
    GET_STALL_STATS_SUCCESS,
    GETTING_STALLS,
    GETTING_STALL_STATS
} from "../redux_config/types";

const INITIAL_STATE = {
    case: null,
    payload: []
};

export interface StallReducer {
    case: string
    payload: {
        monthlyArr: Array<any>
        stall: any
    }
}

export function stalls_redux(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case GETTING_STALLS: {
            return {
                case: GETTING_STALLS,
                payload: []
            };
        }

        case GETTING_STALL_STATS: {
            return {
                case: GETTING_STALL_STATS,
                payload: null
            };
        }

        case GET_STALL_STATS_SUCCESS:
            return {
                case: GET_STALL_STATS_SUCCESS,
                payload: action.payload
            };

        case GET_STALLS_SUCCESS:
            return {
                case: GET_STALLS_SUCCESS,
                payload: action.payload
            };

        default:
            return state;
    }
}
