import apiFlight from "../redux_utilities/apiFlightInterceptor";
import { CREATE_USER_ACCOUNT} from "../redux_config/types";
import {USER_V2_CREATE_USER_ACCOUNT} from "../redux_config/endpoints";
import {isLoading} from "../redux_utilities/loadingStatus";


export const USER_V2_CREATE_USER_ACCOUNT_ACTION = (payload) => {
  
  return async dispatch => {
  
    if (isLoading(CREATE_USER_ACCOUNT) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }
    
    const config = {}
    // const params = {}
    
    try {
      await apiFlight(CREATE_USER_ACCOUNT, dispatch).post(
        USER_V2_CREATE_USER_ACCOUNT,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
