import React, {useEffect, useState} from "react";
import {GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD, resetState} from "../../../02_redux/actions";
import {useLocation, useNavigate} from "react-router-dom";
import SignIn from "../../components_common/SignIn/SignIn";
import styles from "./User.module.scss";
import CreateAccount from "../../components_common/CreateAccount/CreateAccount";
import {
  ADD_ADDRESS,
  ADD_CARD,
  AUTHENTICATE_ACCOUNT,
  CREATE_USER_ACCOUNT,
  DELETE_ADDRESS,
  DELETE_CARD,
  EXCHANGE_FOR_STRIPE_TOKEN,
  GET_ADDRESSES,
  GET_CARDS,
  GET_STRIPE_CLIENT_SECRET,
  GET_USER_PURCHASE_COUNT_HISTORY,
  RESEND_VERIFICATION_EMAIL
} from "../../../02_redux/redux_config/types";
import toast from "react-hot-toast";
import {CLEAR_AUTHENTICATION_STATE_ACTION} from "../../../02_redux/actions/profile_actions";
import Cards from "../../components_common/Cards/Cards";
import Addresses from "./components/Addresses";
import Account from "./components/Account";
import InfoCard from "./components/InfoCard";
import {Dots} from "react-activity";
import UserContactInfoForm from "../../components_common/FormContactInfo/UserContactInfoForm";
import UserAddressForm from "../../components_common/FormAddress/UserAddressForm";
import {ObjectId} from "bson";
import {isAuthenticated} from "../../../02_redux/redux_utilities/authenticationStatus";
import {
  USER_V2_GET_USER_PURCHASE_COUNT_HISTORY_ACTION
} from "../../../02_redux/actions/GET_USER_PURCHASE_COUNT_HISTORY_ACTION";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {ProfileReducer} from "../../../02_redux/reducers/profile_reducer";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useForm} from "react-hook-form";
import ModalContent from "../../components_core/ModalContent/ModalContent";
import StripeCreditCardForm from "../../components_common/StripeCreditCardForm/StripeCreditCardForm";
import PaymentIntentSection from "../../components_common/PaymentIntentStatus/PaymentIntentStatus";
import Impression from "../../components_higherOrder/Ping/Impression";


export interface UserFormValues {
  email: string,
  password: string,

  card_id: string,
  name_on_card: string,
  cc_no: string,
  cc_exp: string,
  cc_cvv: string,

  name: string,
  address_id: string,
  address_street: string,
  address_unit_no: string,
  address_postal_code: string,
  address_country: string,
  contact_number: string,
}


export enum UserViewEnum {
  NULL           = "NULL",
  SIGN_IN        = "SIGN_IN",
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  PROFILE        = "PROFILE"
}


const User = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [view, setView] = useState<UserViewEnum>(UserViewEnum.NULL)
  const [openModal, setOpenModal] = useState(false)
  const [savedAddress, setSavedAddress] = useState([])
  const [addAddress, setAddAddress] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [deleteAddress, setDeleteAddress] = useState(false)
  const [addressToDelete, setAddressToDelete] = useState("")
  const [savedCards, setSaveCards] = useState([])
  const [addCard, setAddCard] = useState(false)
  const [editCard, setEditCard] = useState(false)
  const [deleteCard, setDeleteCard] = useState(false)
  const [cardToDelete, setCardToDelete] = useState("")


  const {
          0: AUTHENTICATE_ACCOUNT_LOADING,
          1: AUTHENTICATE_ACCOUNT_SUCCESS,
          2: AUTHENTICATE_ACCOUNT_ERROR,
          3: AUTHENTICATE_ACCOUNT_EVENT_CODE,
          4: AUTHENTICATE_ACCOUNT_MESSAGE,
          5: AUTHENTICATE_ACCOUNT_PAYLOAD,
        } = GetApiFlightStatus<any>(AUTHENTICATE_ACCOUNT);

  const {
          0: CREATE_USER_ACCOUNT_LOADING,
          1: CREATE_USER_ACCOUNT_SUCCESS,
          2: CREATE_USER_ACCOUNT_ERROR,
          3: CREATE_USER_ACCOUNT_EVENT_CODE,
          4: CREATE_USER_ACCOUNT_MESSAGE,
          5: CREATE_USER_ACCOUNT_PAYLOAD,
        } = GetApiFlightStatus<any>(CREATE_USER_ACCOUNT);


  const {
          0: ADD_ADDRESS_LOADING,
          1: ADD_ADDRESS_SUCCESS,
          2: ADD_ADDRESS_ERROR,
          3: ADD_ADDRESS_EVENT_CODE,
          4: ADD_ADDRESS_MESSAGE,
          5: ADD_ADDRESS_PAYLOAD,
        } = GetApiFlightStatus<any>(ADD_ADDRESS);

  const {
          0: ADD_CARD_LOADING,
          1: ADD_CARD_SUCCESS,
          2: ADD_CARD_ERROR,
          3: ADD_CARD_EVENT_CODE,
          4: ADD_CARD_MESSAGE,
          5: ADD_CARD_PAYLOAD,
        } = GetApiFlightStatus<any>(ADD_CARD);

  const {
          0: GET_ADDRESSES_LOADING,
          1: GET_ADDRESSES_SUCCESS,
          2: GET_ADDRESSES_ERROR,
          3: GET_ADDRESSES_EVENT_CODE,
          4: GET_ADDRESSES_MESSAGE,
          5: GET_ADDRESSES_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_ADDRESSES);

  const {
          0: GET_CARDS_LOADING,
          1: GET_CARDS_SUCCESS,
          2: GET_CARDS_ERROR,
          3: GET_CARDS_EVENT_CODE,
          4: GET_CARDS_MESSAGE,
          5: GET_CARDS_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_CARDS);

  const {
          0: DELETE_CARD_LOADING,
          1: DELETE_CARD_SUCCESS,
          2: DELETE_CARD_ERROR,
          3: DELETE_CARD_EVENT_CODE,
          4: DELETE_CARD_MESSAGE,
          5: DELETE_CARD_PAYLOAD,
        } = GetApiFlightStatus<any>(DELETE_CARD);

  const {
          0: DELETE_ADDRESS_LOADING,
          1: DELETE_ADDRESS_SUCCESS,
          2: DELETE_ADDRESS_ERROR,
          3: DELETE_ADDRESS_EVENT_CODE,
          4: DELETE_ADDRESS_MESSAGE,
          5: DELETE_ADDRESS_PAYLOAD,
        } = GetApiFlightStatus<any>(DELETE_ADDRESS);

  const {
          0: EXCHANGE_FOR_STRIPE_TOKEN_LOADING,
          1: EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS,
          2: EXCHANGE_FOR_STRIPE_TOKEN_ERROR,
          3: EXCHANGE_FOR_STRIPE_TOKEN_EVENT_CODE,
          4: EXCHANGE_FOR_STRIPE_TOKEN_MESSAGE,
          5: EXCHANGE_FOR_STRIPE_TOKEN_PAYLOAD,
        } = GetApiFlightStatus<any>(EXCHANGE_FOR_STRIPE_TOKEN);

  const {
          0: RESEND_VERIFICATION_EMAIL_LOADING,
          1: RESEND_VERIFICATION_EMAIL_SUCCESS,
          2: RESEND_VERIFICATION_EMAIL_ERROR,
          3: RESEND_VERIFICATION_EMAIL_EVENT_CODE,
          4: RESEND_VERIFICATION_EMAIL_MESSAGE,
          5: RESEND_VERIFICATION_EMAIL_PAYLOAD,
        } = GetApiFlightStatus(RESEND_VERIFICATION_EMAIL);

  const {
          0: GET_USER_PURCHASE_COUNT_HISTORY_LOADING,
          1: GET_USER_PURCHASE_COUNT_HISTORY_SUCCESS,
          2: GET_USER_PURCHASE_COUNT_HISTORY_ERROR,
          3: GET_USER_PURCHASE_COUNT_HISTORY_EVENT_CODE,
          4: GET_USER_PURCHASE_COUNT_HISTORY_MESSAGE,
          5: GET_USER_PURCHASE_COUNT_HISTORY_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_USER_PURCHASE_COUNT_HISTORY);

  const {
          0: GET_STRIPE_CLIENT_SECRET_LOADING,
          1: GET_STRIPE_CLIENT_SECRET_SUCCESS,
          2: GET_STRIPE_CLIENT_SECRET_ERROR,
          3: GET_STRIPE_CLIENT_SECRET_EVENT_CODE,
          4: GET_STRIPE_CLIENT_SECRET_MESSAGE,
          5: GET_STRIPE_CLIENT_SECRET_PAYLOAD
        } = GetApiFlightStatus<GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD>(GET_STRIPE_CLIENT_SECRET);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          setActiveMenuItem,
          exchangeForStripeTokenAction,
          resetState,

          CLEAR_AUTHENTICATION_STATE_ACTION,
          USER_V2_RESEND_VERIFICATION_EMAIL_ACTION,
          USER_V2_AUTHENTICATE_ACCOUNT_ACTION,
          USER_V2_GET_USER_QUICK_PROFILE_ACTION,
          USER_V2_CREATE_USER_ACCOUNT_ACTION,
          USER_V2_GET_CARDS_ACTION,
          USER_V2_GET_ADDRESSES_ACTION,
          USER_V2_ADD_CARD_ACTION,
          USER_V2_ADD_ADDRESS_ACTION,
          USER_V2_DELETE_CARD_ACTION,
          USER_V2_DELETE_ADDRESS_ACTION,
          USER_V2_GET_USER_PURCHASE_COUNT_HISTORY_ACTION,
          USER_V2_CAPTURE_CLIENT_INTENT_ACTION,
          USER_V2_GET_STRIPE_CLIENT_SECRET_ACTION
        } = useActions()

  const navigate = useNavigate();
  let location = useLocation();
  const profile = useTypedSelector<ProfileReducer>(state => state.profile)

  const {
          register,
          handleSubmit,
          setValue,
          getValues,
          formState: {errors}
        } = useForm<UserFormValues>()

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {

    const query = new URLSearchParams(location.search);
    const v = query.get("v")
    const setup_intent = query.get("setup_intent")

    // setActiveMenuItem("102");

    // updateWindowDimensions();
    // window.addEventListener(
    //     "resize",
    //     updateWindowDimensions.bind(
    //     );

    if (profile.jwt === null) {
      setView(UserViewEnum.SIGN_IN)
    }

    if (profile.jwt !== null) {
      setView(UserViewEnum.PROFILE)
    }

    if (v === "create-account") {
      setView(UserViewEnum.CREATE_ACCOUNT)
    }

    if (v === "pi") {
      if (openModal === false) {
        USER_V2_CAPTURE_CLIENT_INTENT_ACTION({
          setup_intent: setup_intent
        })

        setOpenModal(true)
      }
    }


    if (profile.jwt !== null) {
      USER_V2_GET_CARDS_ACTION({})
      // USER_V2_GET_ADDRESSES_ACTION({})
      USER_V2_GET_USER_QUICK_PROFILE_ACTION({})
      USER_V2_GET_USER_PURCHASE_COUNT_HISTORY_ACTION({past_month_count: 12})
    }
  }, [])

  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_ERROR) {
      toast.error(AUTHENTICATE_ACCOUNT_MESSAGE, {
        duration: 10000,
        position: 'top-center',
        iconTheme: {primary: '#ab2939', secondary: '#fff',},
      })

      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [AUTHENTICATE_ACCOUNT_ERROR])


  useEffect(() => {
    if (CREATE_USER_ACCOUNT_ERROR) {
      toast.error(CREATE_USER_ACCOUNT_MESSAGE, {
        duration: 10000,
        position: 'top-center',
        iconTheme: {primary: '#ab2939', secondary: '#fff',},
      })
    }
  }, [CREATE_USER_ACCOUNT_ERROR])


  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_SUCCESS) {
      USER_V2_GET_CARDS_ACTION({})
      USER_V2_GET_ADDRESSES_ACTION({})

      setView(UserViewEnum.PROFILE)
      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [AUTHENTICATE_ACCOUNT_SUCCESS])


  useEffect(() => {
    if (RESEND_VERIFICATION_EMAIL_SUCCESS) {
      toast.success(`Sent! Please check your email inbox.`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      setView(UserViewEnum.PROFILE)
    }
  }, [RESEND_VERIFICATION_EMAIL_SUCCESS])


  useEffect(() => {
    if (CREATE_USER_ACCOUNT_SUCCESS) {
      toast.success(`Account successfully created!`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      setView(UserViewEnum.PROFILE)
    }
  }, [CREATE_USER_ACCOUNT_SUCCESS])


  useEffect(() => {
    if (GET_ADDRESSES_SUCCESS) {
      let addresses = GET_ADDRESSES_PAYLOAD === null ? [] : JSON.parse(JSON.stringify(GET_ADDRESSES_PAYLOAD))
      addresses.push({
        _id: "ADD"
      })
      setSavedAddress(addresses)
    }
  }, [GET_ADDRESSES_SUCCESS])


  useEffect(() => {
    if (GET_CARDS_SUCCESS) {
      let cards = GET_CARDS_PAYLOAD === null ? [] : JSON.parse(JSON.stringify(GET_CARDS_PAYLOAD))
      cards.push({
        _id: "ADD"
      })
      setSaveCards(cards)
    }
  }, [GET_CARDS_SUCCESS])


  useEffect(() => {
    if (DELETE_CARD_SUCCESS) {
      setEditCard(false)
      setCardToDelete("")
      USER_V2_GET_CARDS_ACTION({})
      handleCloseDeleteCardModal()
    }
  }, [DELETE_CARD_SUCCESS])


  useEffect(() => {
    if (DELETE_ADDRESS_SUCCESS) {
      setEditAddress(false)
      setAddressToDelete("")
      USER_V2_GET_ADDRESSES_ACTION({})
      handleCloseDeleteAddressModal()
    }
  }, [DELETE_ADDRESS_SUCCESS])


  useEffect(() => {
    if (ADD_ADDRESS_SUCCESS) {
      // toast.error(ADD_ADDRESS_SUCCESS)
      // console.log(ADD_ADDRESS_SUCCESS)
      let addresses = JSON.parse(JSON.stringify(savedAddress));

      for (let i = 0; i < addresses.length; i++) {
        addresses[i].selected = false
      }

      const {
              address_id,
              name,
              address_street,
              address_unit_no,
              address_postal_code,
              address_country,
              email,
              contact_number,
            } = getValues()
      const addressBody = {
        _id: address_id,
        name,
        contact_number,
        address_street,
        address_unit_no,
        address_postal_code,
        address_country,
        selected: false
      }

      addresses.splice(addresses.length - 1, 0, addressBody)

      setSavedAddress(addresses)
      setAddAddress(false)
      setOpenModal(false)

    }
  }, [ADD_ADDRESS_SUCCESS])


  useEffect(() => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS) {
      const {name_on_card, card_id, cc_no, cc_exp, cc_cvv} = getValues()

      USER_V2_ADD_CARD_ACTION({
        _id: card_id,
        name_on_card,
        stripe_token: EXCHANGE_FOR_STRIPE_TOKEN_PAYLOAD.id
      })
    }
  }, [EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS])


  useEffect(() => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_ERROR) {
      toast.error(EXCHANGE_FOR_STRIPE_TOKEN_MESSAGE)
    }
  }, [EXCHANGE_FOR_STRIPE_TOKEN_ERROR])

  useEffect(() => {
    if (ADD_CARD_SUCCESS) {
      setAddCard(false)
      setOpenModal(false)
      USER_V2_GET_CARDS_ACTION({})

    }
  }, [ADD_CARD_SUCCESS])


// updateWindowDimensions() {
//   setState({width: window.innerWidth, height: window.innerHeight});
// }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleGetClientSecret = () => {
    USER_V2_GET_STRIPE_CLIENT_SECRET_ACTION({})
  }

  const handleResendVerificationEmail = () => {
    USER_V2_RESEND_VERIFICATION_EMAIL_ACTION({
      name: profile.name,
      email: profile.email
    })
  }

  const handleUpdateAddressFormikValue = (field: string, value: any) => {
    // @ts-ignore
    setValue(field, value)
  }

  const handleUpdateCCFormikValue = (field: string, value: any) => {
    // @ts-ignore
    setValue(field, value)
  }

  const handleCloseAddCardSuccessModal = () => {
    navigate("/user")
    USER_V2_GET_CARDS_ACTION({})
    setOpenModal(false)
  }

  const handleDeleteCard = () => {
    if (DELETE_CARD_LOADING) {
      return
    }

    USER_V2_DELETE_CARD_ACTION({_id: cardToDelete})
  }

  const handleDeleteAddress = () => {
    if (DELETE_ADDRESS_LOADING) {
      return
    }
    USER_V2_DELETE_ADDRESS_ACTION({_id: addressToDelete})
  }

  const handleToggleEditCards = () => {
    setEditCard(!editCard)
  }

  const handleToggleEditAddresses = () => {
    setEditAddress(!editAddress)
  }

  const handleCloseDeleteAddressModal = () => {
    setOpenModal(false)
    setAddressToDelete("")
    setAddAddress(false)
    // setSelectedAddress("")

  }

  const handleCloseDeleteCardModal = () => {
    setOpenModal(false)
    setCardToDelete("")
    setAddCard(false)
    // setSelectedCard("")
  }

  const handleDeleteAddressPrompt = (address_id: string) => {
    setAddressToDelete(address_id)
    setOpenModal(true)
  }

  const handleDeleteCardPrompt = (card_id: string) => {
    setCardToDelete(card_id)
    setOpenModal(true)
  }

  const handleAddAddress = () => {
    let addresses = JSON.parse(JSON.stringify(savedAddress));

    handleUpdateAddressFormikValue("address_id", new ObjectId().toString(),)

    for (let i = 0; i < addresses.length; i++) {
      addresses[i].selected = false
    }

    setSavedAddress(addresses)
    setAddAddress(true)
    setOpenModal(true)
    // setSelectedAddress()

  }

  const handleSelectAddCard = () => {

    handleGetClientSecret()
    let cards = JSON.parse(JSON.stringify(savedCards));

    setValue("card_id", new ObjectId().toString())

    for (let i = 0; i < cards.length; i++) {
      cards[i].selected = false
    }

    setSaveCards(cards)
    setAddCard(true)
    // setSelectedCard("")
    setOpenModal(true)
  }

  const handleAddressFormValidation = (body: any) => {
    const {name, address_street, address_postal_code, address_country, email, contact_number} = body
    if (isAuthenticated()) {
      if (!name || !address_street || !address_postal_code || !address_country || !contact_number) {
        toast.error(`Oooops! Missing required fields`)
        return false
      }
      return true
    }
  }

  const handleSaveAddress = () => {

    const {
            name,
            address_id,
            address_street,
            address_postal_code,
            address_country,
            address_unit_no,
            contact_number
          } = getValues()

    const body = {
      _id: address_id,
      name,
      contact_number,
      address_street,
      address_unit_no,
      address_postal_code,
      address_country,
    }

    if (handleAddressFormValidation(body) === false) {
      return
    }

    if (ADD_ADDRESS_LOADING) {
      return
    }

    USER_V2_ADD_ADDRESS_ACTION(body)
  }


  const handleSaveCard = () => {
    const {card_id, name_on_card, cc_no, cc_cvv, cc_exp} = getValues()

    exchangeForStripeTokenAction({
      card_id,
      name_on_card,
      cc_no,
      cc_cvv,
      cc_month: parseInt(cc_exp.split("/")[0]),
      cc_year: parseInt(`20${cc_exp.split("/")[1]}`)
    })
  }

  const handleClearAuthenticationState = () => {
    CLEAR_AUTHENTICATION_STATE_ACTION()
  }

  const handleUpdateViewToResetPassword = () => {

  }

  const handleAuthenticateAccount = () => {
    const body = {
      email: getValues().email,
      password: getValues().password
    }

    if (AUTHENTICATE_ACCOUNT_LOADING) {
      return
    }

    USER_V2_AUTHENTICATE_ACCOUNT_ACTION(body)
  }

  const handleCreateAccount = () => {
    const body = {
      name: getValues().name,
      email: getValues().email,
      password: getValues().password
    }

    if (CREATE_USER_ACCOUNT_LOADING) {
      return
    }

    USER_V2_CREATE_USER_ACCOUNT_ACTION(body)
  }

  const handleUpdateViewToCreateAccount = () => {
    setView(UserViewEnum.CREATE_ACCOUNT)
  }

  const handleUpdateViewToSignIn = () => {
    setView(UserViewEnum.SIGN_IN)
  }

  const handleEditCardText = () => {

    if (savedCards.length === 0) {
      return ""
    }

    if (editCard === true) {
      return "done!"
    } else {
      return "edit cards"
    }
  }

  const handleEditAddressText = () => {
    if (savedAddress.length === 0) {
      return ""
    }

    if (editAddress === true) {
      return "done!"
    } else {
      return "edit addresses"
    }
  }

  const renderAccountSection = () => {

    return (
      <div className={styles.detailsContainer}>
        <div className={styles.headingBlock}>
          <div className={styles.headingText}>
            ID
          </div>

          {/*<a>*/}
          {/*  manage addresses*/}
          {/*</a>*/}
        </div>

        <div className={styles.contentDetailsBlock}>
          <Account
            email={profile.email}
            email_verified={profile.email_verified}
            handleResendVerificationEmail={handleResendVerificationEmail}
            RESEND_VERIFICATION_EMAIL_LOADING={RESEND_VERIFICATION_EMAIL_LOADING}
          />
        </div>
      </div>
    )
  }

  const renderAddressesSection = () => {
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.headingBlock}>
          <div className={styles.headingText}>
            Addresses
          </div>
          <a onClick={handleToggleEditAddresses}>
            {handleEditAddressText()}
          </a>
        </div>

        <div className={styles.contentDetailsBlock}>
          <Addresses
            GET_ADDRESSES_LOADING={GET_ADDRESSES_LOADING}
            saved_addresses={savedAddress}
            edit_addresses={editAddress}
            handleDeleteAddressPrompt={handleDeleteAddressPrompt}
            handleAddAddress={handleAddAddress}
          />
        </div>
      </div>
    )
  }

  const renderCardSection = () => {
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.headingBlock}>
          <div className={styles.headingText}>
            Payment
          </div>
          <a onClick={handleToggleEditCards}>
            {handleEditCardText()}
          </a>
        </div>

        <div className={styles.contentDetailsBlock}>
          <Cards
            GET_CARDS_LOADING={GET_CARDS_LOADING}
            saved_cards={savedCards}
            width={window.innerWidth}
            edit_cards={editCard}
            handleDeleteCardPrompt={handleDeleteCardPrompt}
            handleSelectAddCard={handleSelectAddCard}
            handleSelectCard={() => {
            }}
          />
        </div>
      </div>
    )
  }

  const renderGreeting = () => {
    const {name, email} = profile
    return (
      <div className={styles.greetingText}>
        Hi, {name ? name : email}.
      </div>
    )
  }

  const renderOrdersSection = () => {
    return (
      <div className={styles.ordersContainer}>
        <div className={styles.ordersBlock}>
          <InfoCard
            GET_USER_PURCHASE_COUNT_HISTORY_PAYLOAD={GET_USER_PURCHASE_COUNT_HISTORY_PAYLOAD}
          />
        </div>
      </div>
    )
  }


  const renderCardsAndAddresses = () => {
    return (
      <div>
        <div className={styles.sectionHeaderText}>
          Account Settings
        </div>
        {renderAccountSection()}
        <div className={styles.lineBetweenPaymentAndFulfilmentBlock}/>
        {renderCardSection()}
        {/*<div className={styles.lineBetweenPaymentAndFulfilmentBlock}/>*/}
        {/*{renderAddressesSection()}*/}
      </div>
    )
  }

  const renderCTAContent = () => {
    if (DELETE_ADDRESS_LOADING || DELETE_CARD_LOADING) {
      return (
        <Dots/>
      )
    }
    return "Delete"
  }

  const renderDeleteCardConfirmation = () => {
    return (
      <div className={styles.modalCard}>
        <div className={styles.modalHeadingText}>
          Are you sure you want to delete the card?
        </div>

        <div className={styles.buttonBlock}>
          <div className={styles.secondaryButton} onClick={() => handleCloseDeleteCardModal()}>
            No
          </div>

          <div className={styles.primaryButton} onClick={() => handleDeleteCard()}>
            {renderCTAContent()}
          </div>
        </div>
      </div>
    )
  }

  const renderDeleteAddressConfirmation = () => {
    return (
      <div className={styles.modalCard}>
        <div className={styles.modalHeadingText}>
          Are you sure you want to delete the address?
        </div>

        <div className={styles.buttonBlock}>
          <div className={styles.secondaryButton} onClick={() => handleCloseDeleteAddressModal()}>
            No
          </div>

          <div className={styles.primaryButton} onClick={() => handleDeleteAddress()}>
            Delete
          </div>
        </div>
      </div>
    )
  }


  const renderUserContactForm = () => {
    return <UserContactInfoForm
      handleUpdateFormikValue={handleUpdateAddressFormikValue}
    />
  }

  const renderAddressForm = () => {
    return <UserAddressForm
      handleUpdateFormikValue={handleUpdateAddressFormikValue}
    />
  }

  const renderCardForm = () => {
    return (
      <StripeCreditCardForm
        handleCloseModal={handleCloseDeleteAddressModal}
        GET_STRIPE_CLIENT_SECRET_LOADING={GET_STRIPE_CLIENT_SECRET_LOADING}
        GET_STRIPE_CLIENT_SECRET_PAYLOAD={GET_STRIPE_CLIENT_SECRET_PAYLOAD?.client_secret}
        return_path={"/user?v=pi"}
      />
    )


    // return (
    //     <CreditCardForm
    //         handleUpdateFormikValue={handleUpdateCCFormikValue}
    //     />
    // )
  }

  const renderPaymentIntentStatus = () => {
    return (
      // <div className={modalStyles.modalContainer}>
      //   <div className={modalStyles.modalCard} style={{height: 250}}>
      //     <div className={modalStyles.modalContent}>
      <ModalContent handleCloseModal={handleCloseAddCardSuccessModal}>
        <PaymentIntentSection
          handleCloseAddCardSuccessModal={handleCloseAddCardSuccessModal}
        />
      </ModalContent>
      //     </div>
      //   </div>
      // </div>
    )
  }


  const renderAddUserAddress = () => {
    //   if (add_address === true) {
    return (
      <div className={styles.formContainerAddress}>
        <div className={styles.formBlockAddress}>

          {renderAddressForm()}
          <div className={styles.spacer}/>
          {renderUserContactForm()}
          <div className={styles.spacer}/>

          <div className={styles.buttonBlock}>
            <div className={styles.secondaryButton} onClick={handleCloseDeleteAddressModal}>
              Cancel
            </div>

            <div className={styles.primaryButton} onClick={handleSaveAddress}>
              {ADD_ADDRESS_LOADING ? <Dots/> : "Save Address"}
            </div>
          </div>

          <div className={styles.spacer}/>
          <div className={styles.spacer}/>
        </div>
      </div>
    )
    // }
  }

  const renderAddUserCard = () => {

    return (
      <ModalContent handleCloseModal={handleCloseDeleteAddressModal}>
        {renderCardForm()}
        <div className={styles.spacer}/>
      </ModalContent>
    )


    // return (
    //     <div className={styles.formContainerCard}>
    //         <div className={styles.formBlockCard}>
    //
    //             {renderCardForm()}
    //             <div className={styles.spacer}/>
    //
    //             <div className={styles.buttonBlock}>
    //                 <div className={styles.secondaryButton} onClick={handleCloseDeleteAddressModal}>
    //                     Cancel
    //                 </div>
    //
    //                 <div className={styles.primaryButton} onClick={handleSaveCard}>
    //                     {EXCHANGE_FOR_STRIPE_TOKEN_LOADING || ADD_CARD_LOADING ? <Dots/> : "Save Card"}
    //                 </div>
    //             </div>
    //
    //             <div className={styles.spacer}/>
    //             <div className={styles.spacer}/>
    //         </div>
    //     </div>
    // )
  }


  const renderModalContent = () => {

    const query = new URLSearchParams(location.search);
    const v = query.get("v")

    if (v === "pi") {
      return renderPaymentIntentStatus()
    }

    if (addressToDelete !== "") {
      return (renderDeleteAddressConfirmation())
    }

    if (cardToDelete !== "") {
      return (renderDeleteCardConfirmation())
    }

    if (addAddress === true) {
      return (renderAddUserAddress())
    }

    if (addCard === true) {
      return renderAddUserCard()
    }

  }


  const renderModal = () => {
    if (openModal === true) {
      return (
        <div className={styles.modalContainer}>
          {renderModalContent()}
        </div>
      )
    }
  }

  const renderContent = () => {
    if (view === UserViewEnum.SIGN_IN) {
      return (
        <SignIn
          headingTextSize={""}
          headingText={"You are not signed in."}
          subHeadingText={"Sign in to see if you have any saved items. Or to continue shopping."}
          // formik={formik}
          AUTHENTICATE_ACCOUNT_LOADING={AUTHENTICATE_ACCOUNT_LOADING}
          handleUpdateViewToCreateAccount={handleUpdateViewToCreateAccount}
          handleAuthenticateAccount={handleAuthenticateAccount}
          handleUpdateViewToResetPassword={handleUpdateViewToResetPassword}
          register={register as any}
        />
      )
    }

    if (view === UserViewEnum.CREATE_ACCOUNT) {
      return (
        <CreateAccount
          // formik={formik}
          CREATE_USER_ACCOUNT_LOADING={CREATE_USER_ACCOUNT_LOADING}
          handleUpdateViewToSignIn={handleUpdateViewToSignIn}
          handleCreateAccount={handleCreateAccount}
        />
      )
    }


    if (view === UserViewEnum.PROFILE) {
      return (
        <div className={styles.profileContainer}>
          {/*<button onClick={handleClearAuthenticationState}> clear authentication state</button>*/}
          {renderGreeting()}
          {renderOrdersSection()}
          {renderCardsAndAddresses()}
        </div>
      )
    }
  }


  return (
    <Impression pageload page={"user"}>
      <div className={styles.pageContainer}>
        {renderModal()}
        {renderContent()}
        <div className={styles.bottomSpacer}/>
      </div>
    </Impression>
  )
}


export default User;

