import React from "react";
import styles from "./InfoCard.module.scss"
import {Link} from "react-router-dom";

const InfoCard = props => {
  
  const renderCount = () => {
    let count = 0
    
    if (props.GET_USER_PURCHASE_COUNT_HISTORY_PAYLOAD) {
      count = props.GET_USER_PURCHASE_COUNT_HISTORY_PAYLOAD.count
    }
    return count
  }
  
  return (
    <div className={styles.card}>
      <div className={styles.cardDetails}>
        
        <div className={styles.headingText}>
          Your Purchases
        </div>
        
        <div className={styles.subHeadingText}>
          You have <b>{renderCount()}</b> purchases the past 12 months.
        </div>
        
        <Link to="/purchases">
          See your purchase history {">"}
        </Link>
      </div>
    </div>
  )
}

export default InfoCard