import React, {Component, useEffect} from "react";

import styles from "./ArticlePage.module.scss"
import sanitizeHtml from 'sanitize-html';
import moment from "moment"
import "./ArticlePage.css"
import {bindActionCreators} from "redux";
import {getEntry} from "../../../02_redux/actions";
import {connect} from "react-redux";
import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {AUTHENTICATE_ACCOUNT, GET_ENTRY} from "../../../02_redux/redux_config/types";
import ReactPlayer from 'react-player/lazy'
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import useWindowDimensions from "../../../04_utilities/hooks/getWindowDimensions";
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";
import Impression from "../../components_higherOrder/Ping/Impression";

const ArticlePage = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const {
          0: GET_ENTRY_LOADING,
          1: GET_ENTRY_SUCCESS,
          2: GET_ENTRY_ERROR,
          3: GET_ENTRY_EVENT_CODE,
          4: GET_ENTRY_MESSAGE,
          5: GET_ENTRY_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_ENTRY);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          getEntry
        } = useActions()

  const params = useParams()
  const {height, width} = useWindowDimensions();


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {

    // @ts-ignore
    const {slug} = params;

    getEntry({
      slug: slug
    })
  }, [])

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleTogglePreviewPost = () => {
    handleTogglePreviewPost()
  }

  // renderPreviewBanner = () => {
  //   return (
  //     <div className={styles.previewBanner} onClick={handleTogglePreviewPost}>
  //       &nbsp; &nbsp; &nbsp; This article is in preview
  //
  //       <div className={styles.preivewBannerExitBtn}>
  //         Exit Preview
  //       </div>
  //
  //
  //     </div>
  //   )
  // }

  const renderLoading = () => {
    return (
      <LoadingDots/>
    )
  }

  const renderTitle = () => {
    return (
      <div className={styles.title}>
        {GET_ENTRY_PAYLOAD[0].title}
      </div>
    )
  }

  const renderAuthorAndDate = () => {
    return (
      <div className={styles.authorAndDateContainer}>

        <div>
          <img src={GET_ENTRY_PAYLOAD[0].author.profile_picture_url}
               className={styles.authorProfilePicture}/>
        </div>
        <div className={styles.authorName}>
          {GET_ENTRY_PAYLOAD[0].author.name}
        </div>

        <div className={styles.date}>
          {renderDate()}
        </div>
      </div>
    )
  }

  const renderDate = () => {

    if (GET_ENTRY_PAYLOAD[0].publication_date) {

      const currentDate = moment()

      // console.log(currentDate)
      // console.log(payload.publication_date)

      const diff = currentDate.diff(GET_ENTRY_PAYLOAD[0].publication_date, "days")

      if (diff === 0) {
        return "Today"
      }

      if (diff > 0 && diff < 7) {
        return `${diff} days ago`
      }

      return moment(GET_ENTRY_PAYLOAD[0].publication_date).format("DD MMMM YYYY")
    }
  }


  const renderList = () => {
    return GET_ENTRY_PAYLOAD[0].content.map((block: any, i: number) => {
      // console.log(block)

      const {content_type,} = block;

      switch (content_type) {
        case "hero":
          return renderHero(block)

        case "single_image":
          return renderSingleImage(block)

        case "youtube_video":
          return renderYoutubeVideo(block)

        case "h1":
          return renderH1(block)

        case "h2":
          return renderH2(block)

        case "h3":
          return renderH3(block)

        case "s1":
          return renderS1(block)

        case "s2":
          return renderS2(block)

        case "s3":
          return renderS3(block)

        case "spacer":
          return renderSpacerHeight(block)

        case "horizontal_bar":
          return renderHorizontalLength(block)

        case "paragraph":
          return renderParagraph(block)
      }
    })
  }

  const renderHero = (block: any) => {
    return (
      <div key={block.key}>
        <img src={block.content_payload.image_url} className={styles.hero}/>
      </div>
    )
  }

  const renderImageCaption = (caption: string) => {
    if (caption) {
      return (
        <div className={styles.imageCaption}>
          {caption}
        </div>
      )
    }
  }

  const renderSingleImage = (block: any) => {
    return (
      <div key={block.key} className={styles.alignCenter}>
        <img src={block.content_payload.image_url} className={styles.singleImage}/>
        {renderImageCaption(block.content_payload.caption)}
      </div>
    )
  }

  const renderYoutubeVideo = (block: any) => {

    let tempWidth = 600
    if (width && width > 600) {
      tempWidth = 600
    } else if (width && width < 600) {
      tempWidth = width
    }


    return (
      <div key={block.key} className={styles.alignCenter}>
        <ReactPlayer
          url={block.content_payload}
          pip={true}
          width={tempWidth}
        />
      </div>
    )
  }

  const renderH1 = (block: any) => {
    return (
      <div key={block.key} className={styles.h1}>
        {block.content_payload}
      </div>
    )
  }

  const renderH2 = (block: any) => {
    return (
      <div key={block.key} className={styles.h2}>
        {block.content_payload}
      </div>
    )
  }

  const renderH3 = (block: any) => {
    return (
      <div key={block.key} className={styles.h3}>
        {block.content_payload}
      </div>
    )
  }


  const renderS1 = (block: any) => {
    return (
      <div key={block.key} className={styles.s1}>
        {block.content_payload}
      </div>
    )
  }


  const renderS2 = (block: any) => {
    return (
      <div key={block.key} className={styles.s2}>
        {block.content_payload}
      </div>
    )
  }


  const renderS3 = (block: any) => {
    return (
      <div key={block.key} className={styles.s3}>
        {block.content_payload}
      </div>
    )
  }

  const renderSpacerHeight = (block: any) => {
    return (
      <div key={block.key} style={{height: parseInt(block.content_payload), width: 5}}/>
    )
  }

  const renderHorizontalLength = (block: any) => {
    return (
      <div key={block.key} style={{
        width: `${block.content_payload}%`,
        height: 1,
        background: "#989898",
        marginTop: 30,
        marginBottom: 30
      }}/>
    )
  }

  const renderParagraph = (block: any) => {
    return (
      <div
        className={styles.paragraph}
        key={block.key}
        dangerouslySetInnerHTML={{__html: sanitizeHtml(block.content_payload)}}
      />
    )
  }

  const renderContent = () => {
    if (GET_ENTRY_LOADING) {
      return renderLoading()
    }

    if (GET_ENTRY_SUCCESS) {
      return (
        <div className={styles.contentContainer}>
          {renderTitle()}
          {renderAuthorAndDate()}
          {renderList()}
        </div>
      )
    }

    return (
      <div>
        Error Loading article
      </div>
    )
  }


  // console.log(GET_ENTRY_SUCCESS)

  return (
    <Impression pageload page={"article"}>
      <div className={styles.articlePageContainer}>
        {/*{renderPreviewBanner()}*/}

        <div className={styles.contentContainer}>
          {renderContent()}
        </div>
      </div>
    </Impression>
  )
}

export default ArticlePage;



