import React from "react";
import styles from "./HeroSection.module.scss";
import {Bar, BarChart, Tooltip, XAxis, YAxis} from "recharts";


const HeroSection = props => {
  return (
    <div className={styles.HeroSection}>
      <div className={styles.statsContainer}>
        <div className={styles.stallDetails}>
          <div className={styles.stallName}>
            Meals served over time from the {props.stall.payload.stall.stallname}
          </div>
        </div>

        <div className={styles.graph}>
          <BarChart
            width={
              props.width >= 1000
                ? 800
                : props.width * 0.8
            }
            height={250}
            data={props.data}
            // margin={{top: 5, right: 20, left: 10, bottom: 5}}
          >
            {/*<CartesianGrid strokeDasharray="3 3" />*/}
            <XAxis dataKey="month"/>
            <YAxis dataKey="count" type="number"/>
            <Tooltip/>
            {/*<Legend />*/}
            <Bar dataKey="count" fill="#02BAA3"/>
          </BarChart>
        </div>
      </div>
    </div>
  );


};

export default HeroSection;
