import apiFlight from "../redux_utilities/apiFlightInterceptor";
import { DELETE_ADDRESS} from "../redux_config/types";
import { USER_V2_DELETE_ADDRESS} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";


export const USER_V2_DELETE_ADDRESS_ACTION = (payload) => {
  
  return async dispatch => {
  
    if (isLoading(DELETE_ADDRESS) === true) {
      return dispatch({type:"PREVIOUS_ACTION_STILL_LOADING"})
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(DELETE_ADDRESS, dispatch).post(
        USER_V2_DELETE_ADDRESS,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
