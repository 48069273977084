import {
    CLEAR_PURCHASE_PROPS,
    EXCHANGING_FOR_STRIPE_TOKEN,
    FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN,
    FAILED_TO_PURCHASE_MEALS,
    FAILED_TO_CHARGE,
    PURCHASE_MEALS_SUCCESS
} from "../redux_config/types";



export interface PurchaseMealsReducer {
    case: string
    payload: Array<any>
}


const INITIAL_STATE = {
    case:null,
    payload: []
}

/*
     action here grabs all actions dispatched by actions,
     we are merely switching through them in compartments for readability and maintainability
*/
export function purchase_meals_redux (state = INITIAL_STATE, action: any) {

    switch (action.type) {

        case FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN:
            return {
                case: FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN,
                payload:[]
            }

        case FAILED_TO_PURCHASE_MEALS:
            return {
                case: FAILED_TO_PURCHASE_MEALS,
                payload:[]
            }

        case PURCHASE_MEALS_SUCCESS:
            return {
                case: PURCHASE_MEALS_SUCCESS,
                payload: action.payload
            }

        case EXCHANGING_FOR_STRIPE_TOKEN:
            return {
                case: EXCHANGING_FOR_STRIPE_TOKEN,
                payload: {}
            }

      case FAILED_TO_CHARGE:
        return {
          case: FAILED_TO_CHARGE,
          payload: {}
        }

        case CLEAR_PURCHASE_PROPS:
            return INITIAL_STATE

        default:
            return state;
    }

}
