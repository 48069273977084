import React from "react";
import styles from "./ModalConfirmation.module.scss";
import {Paragraph} from "../Typography/Typography";
import ButtonFilled from "../Button/ButtonFilled";
import {ButtonOutline} from "../ButtonOutline/ButtonOutline";

type ModalConfirmationProps = {
    headerText: string;
    subHeaderText: string;
    primaryBtnText: string;
    secondaryBtnText: string;
    primaryBtnPress: () => void;
    secondaryBtnPress: () => void;
    primaryLoading: boolean;
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = (props: ModalConfirmationProps) => {
    const {
        headerText,
        subHeaderText,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnPress,
        secondaryBtnPress,
        primaryLoading,
        // height
    } = props


    const RenderSubHeaderText = () => {
        const newText = subHeaderText.split('\n').map((str, i) => <Paragraph key={i}>{str}</Paragraph>);
        return (
            <>
                {newText}
            </>
        )
    }


    return (
        <div className={styles.modalContainer}>
            {/*<div className={styles.modalCard} style={{height: height}}>*/}
            <div className={styles.modalCard}>

                <div className={styles.modalContent}>
                    <h3>
                        {headerText}
                    </h3>

                    <RenderSubHeaderText/>

                    <br/>

                    <div className={styles.buttonBlock}>

                        <ButtonOutline
                            buttonText={secondaryBtnText}
                            onClick={() => secondaryBtnPress()}
                        />

                        {/*<br/>*/}
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;


                        <ButtonFilled
                            buttonText={primaryBtnText}
                            isLoading={primaryLoading}
                            onClick={() => primaryBtnPress()}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalConfirmation
