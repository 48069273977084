import React from 'react';
import WhyMealsCoverImage from "../../../01_application/assets/ui/why-meals-cover.png"
import HordingDistributionImage from "../../../01_application/assets/ui/hording-distributions.jpg"
import "./WhyMeals.modules.scss"
import Impression from "../../components_higherOrder/Ping/Impression";

const WhyMeals = () => {


  return (
    <Impression pageload page={"why_meals"}>
      <div>

        <div className={"whyMealsCoverImageBlock"}>
          <div className={"whyMealsCoverImageTextBlock"}>
            {/*<div className={"whyMealsCoverImageTextText"}>*/}
            {/*  WHY MEALS?*/}
            {/*</div>*/}
          </div>
          <div className={"coverImageOverLay"}/>
          <img src={WhyMealsCoverImage} alt="why-meals" className="coverImage"/>
        </div>


        <div className={"titleBlock"}>
          <div className={"titleBlock_text"}>
            Why meals?
          </div>
        </div>

        <div className={"paragraphBlock"}>
          <div className={"paragraphBlock_text"}>
            In the past our strong kampung spirit created communities characterised by sharing and togetherness.
            Communities would take care of those less fortunate around them - and donate food to
            their needy neighbours. Today, this kampung spirit is still very much needed.
          </div>
        </div>

        <div className={"paragraphBlock"}>
          <div className={"paragraphBlock_text"}>
            There is abundance of the problem of scarcity in Singapore. An estimated 105,000 Singaporean households face
            hunger and food insecurity, and get by on less than $1500 a month. Many poor families and elderlies
            can only afford low-cost and low-nutrition food. Sometimes they would skip meals.
          </div>
        </div>


        <div className={"paragraphBlock"}>
          <div className={"paragraphBlock_text"}>
            More than just the struggle to put food on the table, food poverty is also associated with the
            inability to obtain healthy and nutritious food. Poor diets affect our daily productivity and
            routines. But prolonged hunger and malnutrition often lead to health issues such as
            heart disease, obesity, diabetes and cancer.
          </div>
        </div>

        <div className={"paragraphBlock"}>
          <div className={"paragraphBlock_text"}>
            In fact, hunger and malnutrition are the number one risk to health worldwide --greater than AIDS,
            malaria and tuberculosis combined. The good news is that hunger is entirely solvable. And it cost
            just a few dollars to feed a person in need today.
          </div>
        </div>


        <div className="hordingDistribution_Container">
          <div className={"hordingDistribution_Block"}>
            <img src={HordingDistributionImage} alt="hording-distributions" className="bodyImage"/>

            <div className={"hordingDistribution_TextBlock"}>

              <div className={"hordingDistribution_title"}>
                What we think they need may not necessarily be what they need.
              </div>

              <div className={"hordingDistribution_bodyText"}>
                Very often we would like to help people and do good. We may think that the beneficiaries live a life
                similar to us and require similar needs as us. More often than not, it is a vast generalisation unless
                we
                sit down and talk to the beneficiaries at a more intimate level to understand their needs.
              </div>

              <div className={"hordingDistribution_bodyText"}>
                Sometimes we may be reluctant to offer forms of help in monetary support because we may fear if the
                money may not have been put to good use.
              </div>

              <div className={"hordingDistribution_bodyText"}>
                Purchase a meal with Secondmeal to know that your contribution is fully accountable and meets the needs
                of those that requires.
              </div>

            </div>
          </div>
        </div>


        <div className={"whynot-block"}>

        </div>


        <div className={"footer"}/>

      </div>
    </Impression>
  );


}

export default WhyMeals;
