import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {EXTENDED_GET_USER_PURCHASES, GET_USER_PURCHASES} from "../redux_config/types";
import {USER_V2_GET_USER_PURCHASES} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";
import {Dispatch} from "redux";


export interface USER_V2_GET_USER_PURCHASES_REQUEST_PAYLOAD {
    limit: number
}


export const USER_V2_GET_USER_PURCHASES_ACTION = (payload: USER_V2_GET_USER_PURCHASES_REQUEST_PAYLOAD) => {

    return async (dispatch: Dispatch<any>) => {

        if (isLoading(GET_USER_PURCHASES) === true) {
            return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
        }

        const config = {
            headers: {
                Authorization: `Bearer ${isAuthenticated()}`
            }
        }
        // const params = {}

        try {
            await apiFlight(GET_USER_PURCHASES, dispatch).post(
                USER_V2_GET_USER_PURCHASES,
                payload,
                config
            )
        } catch (err) {
            console.log(err)
        }
    }
}



interface InitiativeNameData {
    _id: string
    name: string
}

export interface StallData {
    stallname: string
}

export interface UserPurchasePayload {
    _id: string
    amount: number
    auto_repeat_purchase: boolean     |null
    card_brand: string
    card_last4: string
    created_at: number
    display_name: string
    initiative_id:InitiativeNameData
    month: number
    purchased: number
    stall_id:StallData
    subscription: number
    suspended_count: number
    year: number
    refunded: boolean
    processing_payment: number
    status: number
    subscription_number: number
}

export interface PeriodInterface {
    month_index: number
    order: number
    payload: Array<UserPurchasePayload> | null
    year: number
}

export interface USER_V2_GET_USER_PURCHASES_SUCCESS_PAYLOAD {
    has_more: boolean
    periods: Array<PeriodInterface>
}


interface USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED_REQUEST_PAYLOAD {
    limit: number
    cursor: string
    last_date: number
}

export const USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED = (payload: USER_V2_GET_USER_PURCHASES_ACTION_EXTENDED_REQUEST_PAYLOAD) => {

    return async (dispatch: Dispatch<any>) => {

        if (isLoading(EXTENDED_GET_USER_PURCHASES) === true) {
            return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
        }

        const config = {
            headers: {
                Authorization: `Bearer ${isAuthenticated()}`
            }
        }
        // const params = {}

        try {
            await apiFlight(EXTENDED_GET_USER_PURCHASES, dispatch).post(
                USER_V2_GET_USER_PURCHASES,
                payload,
                config
            )
        } catch (err) {
            console.log(err)
        }
    }

}
