import {KLANGA_API_KEY, KLANGA_ROOT_URL} from "../../../00_config/keys/keys";
import {useEffect} from "react";
import axios from "axios";
import {store} from "../../../01_application/store";


export enum PingTypeEnum {
  NULL       = 0,
  PAGE_LOAD  = 1,
  IMPRESSION = 2,
  CLICK      = 3
}


interface PingRequestPayload {
  action?: PingTypeEnum
  page?: string
  section?: string
  component?: string
  element_type?: string
  element_name?: string
  element_index?: string
}

export async function Ping(payload: PingRequestPayload) {

  let s_id = store.getState().analytics.s_id
  let c_id = store.getState().analytics.c_id

  const body = {
    s_id: s_id,
    c_id: c_id,
    type: payload.action,
    page: payload.page,
    section: payload.section,
    component: payload.component,
    element_type: payload.element_type,
    element_name: payload.element_name,
    element_index: payload.element_index
  }


  const config = {
    headers: {
      APIKey: KLANGA_API_KEY,
    }
  }

  // console.log(payload)
  // console.log(body)
  // console.log(config)

  try {
    axios.post(`${KLANGA_ROOT_URL}/listener/v1/ping`, body, config).then()
  } catch (e) {
    console.log(e)
  }

}


