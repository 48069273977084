
import {CLEAR_AUTHENTICATION_STATE,} from "../redux_config/types";

export const CLEAR_AUTHENTICATION_STATE_ACTION = () => {
  return async dispatch => {
    dispatch({
      type   : CLEAR_AUTHENTICATION_STATE,
      payload: null
    })
  }
  
}
