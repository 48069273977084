import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_INITIATIVES, GET_USER_PURCHASES} from "../redux_config/types";
import {USER_V2_GET_INITIATIVES} from "../redux_config/endpoints";
import {Dispatch} from "redux";
import {isLoading} from "../redux_utilities/loadingStatus";


interface USER_V2_GET_INITIATIVES_REQUEST_PAYLOAD {

}

export const USER_V2_GET_INITIATIVES_ACTION = (payload: USER_V2_GET_INITIATIVES_REQUEST_PAYLOAD) => {
    return async (dispatch: Dispatch<any>) => {

        if (isLoading(GET_USER_PURCHASES) === true) {
            return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
        }

        const config = {}
        const params = {}

        try {
            await apiFlight(GET_INITIATIVES, dispatch).post(
                USER_V2_GET_INITIATIVES,
                params,
                config
            )
        } catch (err) {
            console.log(err)
        }
    }
}


export interface InitiativeInterface {
    brief_description: string
    description: string
    general_meal_fund: string
    meal_subscription_count: number
    meals_available: number
    monthly_expected_claim_count: number
    name: string
    price_per_meal: number
    _id: string
}

export interface USER_V2_GET_INITIATIVES_SUCCESS_PAYLOAD extends Array<InitiativeInterface>{

}