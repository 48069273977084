import {useSelector, TypedUseSelectorHook} from "react-redux";
import {RootState} from "../reducers"

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// or

// import {useSelector as _useSelector, TypedUseSelectorHook} from "react-redux";
// import {RootState} from "../state"
//
// export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;
