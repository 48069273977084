import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_CAMPAIGN_PURCHASES_ACTIVE} from "../redux_config/types";
import { USER_V2_GET_CAMPAIGN_PURCHASES_ACTIVE} from "../redux_config/endpoints";


export const getCampaignPurchasesActive = ({partner_id}) => {

  return async dispatch => {

    const config = {}
    const params = {
      partner_id
    }

    try {
      await apiFlight(GET_CAMPAIGN_PURCHASES_ACTIVE, dispatch).post(
        USER_V2_GET_CAMPAIGN_PURCHASES_ACTIVE,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
