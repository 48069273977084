import React from "react";
import { Link } from "react-router-dom";
import infoIcon from "../../../../01_application/assets/icons/info.svg";
import ProgressBar from "../../../components_common/ProgressBar/ProgressBar";
import "./stallStatusCard.modules.scss";

const StallStatusCard = props => {
  // console.log(props);

  // const handleCalculatePercentage = () => {
  //   const percentage = (props.item.count / props.item.stall_id.monthly_expected_serve_count) * 100;
  //
  //   console.log(percentage)
  //
  //   if (percentage >= 100) {
  //     return 100;
  //   }
  //
  //   return percentage;
  // };

  const renderCount = () => {
    // if (props.item.status === 1) {
    //   return "-";
    // }


    if(props.item.status=== 6) {
      return props.item.count
    }

    if (props.item.meals_served_this_month) {
      return props.item.meals_served_this_month
    }

    else return 0
  };


  const renderStatus = () => {
    if (props.item.status === 1) {
      return (
        <div className={"info-section"}>
          <img src={infoIcon} alt="info" className="info-icon" />

          <div className={"info-text"}>
            This stall is currently not raising meals on this platform. The
            initiative is raising funds on their own and paying the stall
            directly.
          </div>
        </div>
      );
    } else if (props.item.status === 2) {
      return (
        <div className={"info-section"}>
          <img src={infoIcon} alt="info" className="info-icon" />

          <div className={"info-text"}>
            We are currently raising funds for this stall, beneficiaries are not
            claiming from this pool yet.
          </div>
        </div>
      );
    } else if (props.item.status === 3) {
      // return (
      //     <div className={"info-section"}>
      //         <img src={infoIcon} alt="info" className="info-icon" />
      //
      //         <div className={"info-text"}>
      //             .
      //         </div>
      //     </div>
      // );
    }
  };

  const renderCTA = () => {
    if (props.item.status === 1) {
      return <div className={"card__bottom-button__buy-text"} />;
    }

    if (props.item.status === 6) {
      return (
        <Link
          to={`/buy-meals/${props.item.stall_id._id}&${
            props.item.initiative_id._id
          }`}
          className={
            "card__status6-bottom-button" +
            (props.item.status === 1 ? "disable-button" : " ")
          }
        >
          <div className={"card__bottom-button__buy-text"}>Buy Meals</div>
        </Link>
      );

    }

    else {
      return (
        <Link
          to={`/buy-meals/${props.item.stall_id._id}&${
            props.item.initiative_id._id
          }`}
          className={
            "card__bottom-button " +
            (props.item.status === 1 ? "disable-button" : " ")
          }
        >
          <div className={"card__bottom-button__buy-text"}>Buy Meals</div>
        </Link>
      );
    }
  };


  // const handleRenderHealthStatus = (percentage) => {
  //   if(percentage <= 20) {
  //     return (
  //       <div className={"low"}>
  //         Severely Low
  //       </div>
  //     )
  //   }
  //
  //   if(percentage > 20 && percentage <= 50) {
  //     return (
  //       <div className={"low"}>
  //         Low
  //       </div>
  //     )
  //   }
  //
  //   if(percentage > 50 && percentage <= 80) {
  //     return (
  //       <div className={"average"}>
  //         Average
  //       </div>
  //     )
  //   }
  //
  //   if(percentage > 80) {
  //     return (
  //       <div className={"healthy"}>
  //         Healthy
  //       </div>
  //     )
  //   }
  // }


  // const renderProgressBar = () => {
  //
  //     // console.log(props.item)
  //
  //     const percentage = handleCalculatePercentage()
  //
  //     return (
  //       <div className={"progress-bar-block"}>
  //         <div className={"stats"}>
  //           <div>Status:</div>
  //           <div>{ProgressBar(percentage)}</div>
  //         </div>
  //
  //         <div className={"progress-bar"}>
  //           <div
  //             style={{
  //               background: "#74B8AE",
  //               height: 5,
  //               width: `${percentage}%`
  //             }}
  //           />
  //         </div>
  //
  //         <div className={"stats"}>
  //           <div className={"fontSize"}>meals available for claim:</div>
  //           <div className={"fontSize"}>{props.item.count}</div>
  //         </div>
  //         <div className={"stats"}>
  //           <div className={"fontSize"}>expected meals claimed a month:</div>
  //           <div className={"fontSize"}>{props.item.stall_id.monthly_expected_serve_count}</div>
  //         </div>
  //
  //       </div>
  //     );
  //
  // };

  //
  const renderStatusCards_legacy = () => {
    return (
      <div className={"card"}>
        <div className={"card__info-section"}>
          <div className={"stall-name"}>{props.item.stall_id.stallname}</div>

          <div className={"stall-address"}>{props.item.stall_id.address}</div>

          {/*<Link to={`/stall-stats/${props.item.stall_id._id}`}>*/}
          <div className={"suspended-count"}>{renderCount()}</div>
          {/*</Link>*/}

          {/*<Link to={`/stall-stats/${props.item.stall_id._id}`}>*/}
          <div className={"stall-count-desc"}>meals served to the people
            at <br/><b>{props.item.initiative_id.name} </b> <br/>this month
          </div>
          {/*</Link>*/}
          {/*{renderProgressBar()}*/}
          <ProgressBar
            count={props.item.count}
            expected={props.item.stall_id.monthly_expected_serve_count}
          />

          <Link to={`/stall-stats/${props.item.stall_id._id}&${props.item.initiative_id._id}`}>
            <div className={"view-details"}>
              {/*<a>*/}
              View details
              {/*</a>*/}
            </div>
          </Link>

          {/*<div>{renderStatus()}</div>*/}
        </div>

        {/*{renderCTA()}*/}
      </div>
    );
  }

  const renderStatusCards_supported = () => {
    return(
      <div className={"card"}>
        <div className={"card__info-section"}>
          <div className={"stall-name"}>{props.item._stallID.stallname}</div>
          <div className={"stall-address"}>{props.item._stallID.address}</div>

          {/*<Link to={`/stall-stats/${props.item._stallID._id}`}>*/}

            <div className={"suspended-count"}>{props.item.count}</div>
            <div className={"stall-count-desc"}>
              meals served to the people at <br/> <b> {props.initiative.name} </b><br/> this month
            </div>

          {/*  <div className={"status6-description"}>*/}
          {/*    As we scale across more stalls and more causes, it may not necessarily make sense to purchase meals at individual stalls for individual causes/initiatives.*/}
          {/*    Therefore we are now exploring the possibility of purchasing meals at a general pool for the cause/initiative and the system will allocate meals to stalls in need.*/}
          {/*  </div>*/}


        {/*    <div className={"view-details"}>*/}
        {/*      /!*<a>*!/*/}
        {/*      View details*/}
        {/*      /!*</a>*!/*/}
        {/*    </div>*/}
        {/*  </Link>*/}

        {/*  /!*<div>{renderStatus()}</div>*!/*/}
        </div>

        <div className={"footer"} />

        {/*{renderCTA()}*/}
      </div>
    )
  }


  const renderStatusCards = () => {
    // if (props.initiative._id === "5b1354600601250014b38587") {
    //   return renderStatusCards_legacy()
    // }

    return renderStatusCards_supported()
  }

  const renderCards = () => {
      return(
        renderStatusCards()
      )
  }

  return(
    <div>
      {renderCards()}
    </div>
  )

};

export default StallStatusCard;
