import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {
  AUTHENTICATE_ACCOUNT,
  RESET_PASSWORD_WITH_VERIFICATION_CODE,
  VERIFY_VERIFICATION_CODE
} from "../../../02_redux/redux_config/types";
import {bindActionCreators} from "redux";
import {
  USER_V2_RESET_PASSWORD_WITH_VERIFICATION_CODE_ACTION,
  USER_V2_VERIFY_VERIFICATION_CODE_ACTION,
} from "../../../02_redux/actions";
import {connect} from "react-redux";
import {useFormik} from "formik";
import React, {Component, useEffect, useState} from "react";
import styles from "./PasswordReset.module.scss";
import NewPasswordForm from "./components/NewPasswordForm";
import VerificationCodeError from "./components/VerificationCodeError";
import {handlePasswordValidation} from "./local_utilities/formik";
import toast from "react-hot-toast";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useNavigate, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UserFormValues} from "../User/User";
import {FormValueErrors} from "../PurchaseMeals/PurchasePreview";
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";
import Impression from "../../components_higherOrder/Ping/Impression";


export interface PasswordResetFormValues {
  password: string,
  repeat_password: string,
}

export interface PasswordResetFormValuesErrors {
  password?: FormValueErrors | undefined;
  repeat_password?: FormValueErrors | undefined;

}


const PasswordResetViews = {
  NULL: "NULL",
  SIGN_IN: "SIGN_IN",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  PROFILE: "PROFILE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
}


const PasswordReset = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const [view, setView] = useState(PasswordResetViews.FORGOT_PASSWORD)
  const [formErrors, setFormErrors] = useState<PasswordResetFormValuesErrors>({})


  const {
          0: RESET_PASSWORD_WITH_VERIFICATION_CODE_LOADING,
          1: RESET_PASSWORD_WITH_VERIFICATION_CODE_SUCCESS,
          2: RESET_PASSWORD_WITH_VERIFICATION_CODE_ERROR,
          3: RESET_PASSWORD_WITH_VERIFICATION_CODE_EVENT_CODE,
          4: RESET_PASSWORD_WITH_VERIFICATION_CODE_MESSAGE,
          5: RESET_PASSWORD_WITH_VERIFICATION_CODE_PAYLOAD,
        } = GetApiFlightStatus<any>(RESET_PASSWORD_WITH_VERIFICATION_CODE);

  const {
          0: VERIFY_VERIFICATION_CODE_LOADING,
          1: VERIFY_VERIFICATION_CODE_SUCCESS,
          2: VERIFY_VERIFICATION_CODE_ERROR,
          3: VERIFY_VERIFICATION_CODE_EVENT_CODE,
          4: VERIFY_VERIFICATION_CODE_MESSAGE,
          5: VERIFY_VERIFICATION_CODE_PAYLOAD,
        } = GetApiFlightStatus<any>(VERIFY_VERIFICATION_CODE);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const {
          USER_V2_RESET_PASSWORD_WITH_VERIFICATION_CODE_ACTION,
          USER_V2_VERIFY_VERIFICATION_CODE_ACTION
        } = useActions()

  const navigate = useNavigate();
  let location = useLocation();

  const {
          register,
          handleSubmit,
          setValue,
          getValues,
          formState: {errors}
        } = useForm<PasswordResetFormValues>()


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const verificationCode = query.get("verification_code")

    // console.log(verificationCode)

    USER_V2_VERIFY_VERIFICATION_CODE_ACTION({
      verification_code: verificationCode
    })
  }, [])


  useEffect(() => {

    if (RESET_PASSWORD_WITH_VERIFICATION_CODE_SUCCESS) {
      toast.success(`You have successfully reset your password!`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })
      navigate("/sign-in")
    }

  }, [RESET_PASSWORD_WITH_VERIFICATION_CODE_SUCCESS])


  const handleResetPassword = () => {


    let errors: PasswordResetFormValuesErrors = {}


    if (!getValues().password) {
      errors.password = {...errors.password, type: "", message: 'Password name required'}
    }

    if (!getValues().repeat_password) {
      errors.repeat_password = {...errors.repeat_password, type: "", message: 'Repeat password name required'}
    }

    if (getValues().password !== getValues().repeat_password) {
      errors.repeat_password = {...errors.repeat_password, type: "", message: 'Repeat password does not match password'}
    }

    // if error, set error in state and do not procced to next step
    if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
      setFormErrors(errors)
      return
    }


    const query = new URLSearchParams(location.search);
    const verificationCode = query.get("verification_code")

    // console.log(verificationCode)
    // console.log(formik.values.password)

    const body = {
      verification_code: verificationCode,
      new_password: getValues().password
    }

    USER_V2_RESET_PASSWORD_WITH_VERIFICATION_CODE_ACTION(body)
  }

  const renderNewPasswordForm = () => {
    return (
      <NewPasswordForm
        headingText={"Reset Password"}
        subHeadingText={""}
        register={register}
        formErrors={formErrors}
        // handleUpdateViewToCreateAccount={handleUpdateViewToCreateAccount}
        // handleUpdateViewToResetPassword={handleUpdateViewToResetPassword}
        handleResetPassword={handleResetPassword}
        RESET_PASSWORD_WITH_VERIFICATION_CODE_LOADING={RESET_PASSWORD_WITH_VERIFICATION_CODE_LOADING}
      />
    )
  }

  const renderError = () => {
    return (
      <VerificationCodeError
        message={VERIFY_VERIFICATION_CODE_MESSAGE}
      />
    )
  }

  const renderContent = () => {
    if (VERIFY_VERIFICATION_CODE_ERROR) {
      return renderError()
    } else if (VERIFY_VERIFICATION_CODE_SUCCESS) {
      return renderNewPasswordForm()
    }
    return (
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <LoadingDots/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }


  return (
    <Impression pageload page={"password_reset"}>
      <div className={styles.pageContainer}>
        {renderContent()}
        <div className={styles.bottomSpacer}/>
      </div>
    </Impression>
  )

}

export default PasswordReset;
