import React from "react";
import styles from "./SignIn.module.scss";
import SM2 from "../../../01_application/assets/ui/Frame 1.png";
import classNames from "classnames/bind";
import {Dots} from "react-activity";
import {UseFormReturn} from "react-hook-form";
import {InputText, InputType} from "../../components_core/InputText/InputText";
import Interaction from "../../components_higherOrder/Ping/Interaction";

var cx = classNames.bind(styles);


interface SignInPromptProps {
    headingTextSize: string
    headingText: string
    subHeadingText: string
    AUTHENTICATE_ACCOUNT_LOADING: boolean
    handleAuthenticateAccount: () => void
    handleUpdateViewToCreateAccount: () => void
    handleUpdateViewToResetPassword: () => void
    register: UseFormReturn['register'];
}

const NotSignedIn = (props: SignInPromptProps) => {

    const {
        headingTextSize,
        headingText,
        subHeadingText,
        AUTHENTICATE_ACCOUNT_LOADING,
        handleAuthenticateAccount,
        handleUpdateViewToCreateAccount,
        handleUpdateViewToResetPassword,
        register
    } = props

    const handleKeyDown = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            handleAuthenticateAccount()
        }
    }

    const handleAuthenticateAccountForwarder = () => {
        handleAuthenticateAccount()
    }

    const handleUpdateViewToCreateAccountForwarder = () => {
        handleUpdateViewToCreateAccount()
    }

    const handleUpdateViewToResetPasswordForwarder = () => {
        handleUpdateViewToResetPassword()
    }

    const renderSignInButtonContent = () => {
        if (AUTHENTICATE_ACCOUNT_LOADING) {
            return <Dots/>
        }

        return "Sign In"
    }

    //if path is in pathForTealHeaderArr, set background to teal
    const size = cx({
        headingText: true,
        heading3Text: headingTextSize === "Heading3" ? true : false,
    });

    return (
        <div className={styles.container}>

            <div>
                {/*<img src={Logo} alt="socialgood" className={styles.logoImage}/>*/}
                <img src={SM2} alt="socialgood" className={styles.logoImage}/>
                {/*<img src={SM} alt="secondmeal" className={styles.logoImage}/>*/}
            </div>

            <div className={size}>
                {headingText}
            </div>

            <div className={styles.subheadingText}>
                {subHeadingText}
            </div>


            <div className={styles.formBlock}>


                <InputText
                    inputType={InputType.EMAIL}
                    fieldName={"email"}
                    fieldLabel={""}
                    fieldPlaceHolder={"email"}
                    fieldError={false}
                    register={register}
                />

                <InputText
                    inputType={InputType.PASSWORD}
                    fieldName={"password"}
                    fieldLabel={""}
                    fieldPlaceHolder={"password"}
                    fieldError={false}
                    register={register}
                />

                {/*<input*/}
                {/*  className={styles.formField}*/}
                {/*  name="email"*/}
                {/*  type="email"*/}
                {/*  placeholder={"email"}*/}
                {/*  onChange={props.formik.handleChange}*/}
                {/*  value={props.formik.values.email}*/}
                {/*/>*/}
                {/*<input*/}
                {/*  className={styles.formField}*/}
                {/*  name="password"*/}
                {/*  type="password"*/}
                {/*  placeholder={"password"}*/}
                {/*  onChange={props.formik.handleChange}*/}
                {/*  value={props.formik.values.password}*/}
                {/*  onKeyDown={handleKeyDown}*/}
                {/*/>*/}

                {/*<div className={styles.subheadingText}>*/}
                {/*  Your Social ID is the account you use to access services under our umbrella, namely <a href={"https://www.secondmeal.io/"}>Secondmeal</a> and  <a href={"https://www.secondmeal.io/"}>SocialGood</a>*/}
                {/*</div>*/}

            </div>


            <div className={styles.signInBlock}>
                <Interaction
                  elementProps={{
                      element_name: 'authenticate_account',
                  }}
                  interactionProps={{onClick: {action: 3}}}
                >
                <div className={styles.priBtn} onClick={handleAuthenticateAccount}>
                    {renderSignInButtonContent()}
                </div>
                </Interaction>
            </div>

            <br/>

            <div className={styles.auxLinksBlock}>
                <Interaction
                  elementProps={{
                      element_name: 'create_account',
                  }}
                  interactionProps={{onClick: {action: 3}}}
                >
                <a onClick={handleUpdateViewToCreateAccount}>
                    <div className={styles.createAccount}>
                        create an account
                    </div>
                </a>
                </Interaction>


                <Interaction
                  elementProps={{
                      element_name: 'forgot_password',
                  }}
                  interactionProps={{onClick: {action: 3}}}
                >
                <a onClick={handleUpdateViewToResetPassword}>
                    <div className={styles.createAccount}>
                        forgot password
                    </div>
                </a>
                </Interaction>
            </div>


        </div>
    );
};

export default NotSignedIn;
