import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_CAMPAIGN_PURCHASES_HISTORY} from "../redux_config/types";
import { USER_V2_GET_CAMPAIGN_PURCHASES_HISTORY} from "../redux_config/endpoints";


export const getCampaignPurchasesHistory = ({partner_id}) => {

  return async dispatch => {

    const config = {}
    const params = {
      partner_id
    }

    try {
      await apiFlight(GET_CAMPAIGN_PURCHASES_HISTORY, dispatch).post(
        USER_V2_GET_CAMPAIGN_PURCHASES_HISTORY,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
