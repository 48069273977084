import axios from "axios";
import { claimsURL} from "../redux_config/endpoints"
import {
 GETTING_CLAIMS_FROM_PURCHASE,
 GET_CLAIMS_FROM_PURCHASE_SUCCESS,
 GET_CLAIMS_FROM_PURCHASE_ERROR
} from "../redux_config/types";

/*
      Actions on this page will be handled in claimsFromPurchase_reducer.js
 */

export const getClaimsFromPurchase = (purchase_id) => {
    return async dispatch => {

        dispatch ({ type: GETTING_CLAIMS_FROM_PURCHASE})

        const config = {
            // headers: {
            //     Authorization: "Bearer " + JWT
            // }
        }

        const result = await axios.get(`${claimsURL}/${purchase_id}`, config);

        // console.log(result)

        if(result.data.status_code === 200 ) {
            return dispatch({
                type: GET_CLAIMS_FROM_PURCHASE_SUCCESS,
                payload: result.data.payload
            })
        } else {
            return dispatch({
                type: GET_CLAIMS_FROM_PURCHASE_ERROR,
                payload: result.data.payload
            })
        }

        // if(result.data.status_code === SUCCESS && result.data.payload.length === 0) {
        //   return dispatch({
        //     type: "EMPTY",
        //   })
        // }

        // if ( result.status !== 200) {
        //     return dispatch({
        //         type: "ERROR_GETTING_PACKS",
        //     })
        // }

    }
}
