import axios from "axios";
import qs from "qs";
import {purchasemealsURL, USER_V2_GUEST_PURCHASE_MEALS} from "../redux_config/endpoints";
import {
  CLEAR_PURCHASE_PROPS, EXCHANGE_FOR_STRIPE_TOKEN,
  EXCHANGING_FOR_STRIPE_TOKEN,
  FAILED_TO_CHARGE,
  FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN,
  GUEST_PURCHASE_MEALS,
  PURCHASE_MEALS_SUCCESS
} from "../redux_config/types";
import apiFlight from "../redux_utilities/apiFlightInterceptor";
import keys from "../../00_config/keys/keys";
import {isLoading} from "../redux_utilities/loadingStatus";


/*
      Actions on this page will be handled in purchasemeals_reducer.js
 */


export const clearPurchaseProps = () => {
  return ({
    type: CLEAR_PURCHASE_PROPS
  })
}


export const exchangeForStripeToken = (
  {
    number,
    exp_month,
    exp_year,
    cvc,
    display_name,
    email,
    meal_count,
    stall_id,
    initiative_id,
    auto_repeat_purchase,
    subscription,
    post_message
  }
) => {
  return async dispatch => {
    // console.log(number);
    // console.log(exp_month);
    // console.log(exp_year);
    // console.log(cvc);

    if (isLoading(EXCHANGE_FOR_STRIPE_TOKEN) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }

    dispatch({type: `${EXCHANGE_FOR_STRIPE_TOKEN}_LOADING`});

    // dispatch({type: EXCHANGING_FOR_STRIPE_TOKEN});

    const config = {
      headers: {
        Authorization : "Bearer " + keys.STRIPE_PUBLISHABLE_KEY,
        "Content-type": "application/x-www-form-urlencoded"
      }
    };

    try {
      const stripeRes = await axios.post(
        "https://api.stripe.com/v1/tokens",
        qs.stringify({
          ["card[number]"]   : number,
          ["card[exp_month]"]: exp_month,
          ["card[exp_year]"] : exp_year,
          ["card[cvc]"]      : cvc
        }),
        config
      );

      if (stripeRes.data.id) {
        // console.log("got stripe token, going to dispatch actions")
        // await purchaseMeals(
        //   dispatch,
        //   stripeRes.data.id,
        //   display_name,
        //   email,
        //   meal_count,
        //   stall_id,
        //   initiative_id,
        //   auto_repeat_purchase,
        //   subscription,
        //   post_message
        // )


        // await USER_V2_GUEST_PURCHASE_MEALS_action({
        //   dispatch            : dispatch,
        //   stripe_token        : stripeRes.data.id,
        //   display_name        : display_name,
        //   email               : email,
        //   meal_count          : meal_count,
        //   stall_id            : stall_id,
        //   initiative_id       : initiative_id,
        //   auto_repeat_purchase: auto_repeat_purchase,
        //   subscription        : subscription,
        //   post_message        : post_message
        // })


      } else {
        // console.log("going to call failed dispatch")
        failed(dispatch);
      }
    } catch (e) {
      // console.log("going to call failed dispatch")
      failed(dispatch);
    }

  };
};


export const failed = dispatch => {
  // console.log("ERRORRRRRRRRR")
  dispatch({
    type   : FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN,
    payload: {}
  });
};


export const purchaseMeals = async (
  dispatch,
  stripe_token,
  display_name,
  email,
  meal_count,
  stall_id,
  initiative_id,
  auto_repeat_purchase,
  subscription,
  post_message
) => {

  // console.log("purchasing meals")

  const params = {
    display_name,
    email,
    initiative_id,
    meal_count,
    stall_id,
    stripe_token,
    send_email: true,
    auto_repeat_purchase,
    subscription,
    post_message
  }

  const config = {
    // headers: {
    //     Authorization: "Bearer " + JWT
    // }
  }

  // console.log(params)

  const purchaseResponse = await axios.post(
    purchasemealsURL,
    params,
    config
  )

  // console.log(purchaseResponse)

  if (purchaseResponse.data.status_code === 200) {
    dispatch({
      type   : PURCHASE_MEALS_SUCCESS,
      payload: purchaseResponse.data.payload
    });
  }

  if (purchaseResponse.data.status_code === 490) {
    dispatch({
      type   : FAILED_TO_CHARGE,
      payload: purchaseResponse.data.payload
    });
  }
};


export const USER_V2_GUEST_PURCHASE_MEALS_ACTION = (payload) => {

  const config = {}
  const params = {}

  return async dispatch => {
    if (isLoading(GUEST_PURCHASE_MEALS) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    dispatch({type: `${GUEST_PURCHASE_MEALS}_LOADING`});
    
    try {
      await apiFlight(GUEST_PURCHASE_MEALS, dispatch).post(
        USER_V2_GUEST_PURCHASE_MEALS,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }

}


/*
NODE SERVR   - doesnt work on client side
 */

// module.exports = {
//     async generateStripeTokenFromCC() {
//         const stripeRes = await stripe.tokens.create({
//             card: {
//                 number: '4242424242424242',
//                 exp_month: 12,
//                 exp_year: 2020,
//                 cvc: '123'
//             }
//         })
//         return stripeRes
//     }
// }
//

/*
STRIPE-CLIENT   ---- somehow not returning token?
 */

//
// const stripeRes = await stripe.createToken({
//     card: {
//         number,
//         exp_month,
//         exp_year,
//         cvc,
//     }
// })

/*
URL MTD   -- working!
 */

//
// const params = {
//     card: {
//         number,
//         exp_month,
//         exp_year,
//         cvc
//     }
// };
//
// const config = {
//     headers: {
//         Authorization: "Bearer " + keys.STRIPE_PUBLISHABLE_KEY,
//         "Content-type": "application/x-www-form-urlencoded"
//     }
// };
//
// const stripeRes = await axios.post(
//     "https://api.stripe.com/v1/" + "tokens",
//     params,
//     config
// );
//
// console.log(stripeRes);
