import styles from "./PurchaseTypeTags.module.scss";
import React from "react";

const PurchaseTypeTags = props => {
  
  
  const renderPurchaseTypeTags = () => {
    switch (props.purchaseType) {
      case "SUBSCRIPTION": {
        return (
          <div className={styles.monthlySubscription}>
            <div className={styles.tagText}>
            Monthly subscription
            </div>
          </div>
        )
      }
      
      case "AUTO_REPEAT_PURCHASE": {
        return (
          <div className={styles.autoRepeatPurchase}>
            <div className={styles.tagText}>
            Auto repeat purchase
            </div>
          </div>
        )
      }
      

      
      default:
        return null
    }
  }
  
  return (
    renderPurchaseTypeTags()
  )
  
  
}

export default PurchaseTypeTags;