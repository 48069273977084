import axios from "axios";
import {purchasePreviewURL} from "../redux_config/endpoints"
import {
    GETTING_PURCHASE_PREVIEW,
    GET_PURCHASE_PREVIEW_SUCCESS,
    RESOURCE_NOT_FOUND
} from "../redux_config/types";


/*
      Actions on this page will be handled in purchasePreview_reducer.js
 */

export const getPurchasePreview = (stall_id, initiative_id, count) => {

    return async dispatch => {

        dispatch({type: GETTING_PURCHASE_PREVIEW})

        const config = {
            // headers: {
            //     Authorization: "Bearer " + JWT
            // }
        }

        const params = {
            stall_id,
            initiative_id,
            count:10
        };

        // console.log(params)

        const result = await axios.post(`${purchasePreviewURL}`, params, config);

        // console.log(result)

        if(result.data.status_code === 200 ) {
            return dispatch({
                type: GET_PURCHASE_PREVIEW_SUCCESS,
                payload: result.data.payload
            })
        } else {
            return dispatch({
                type: RESOURCE_NOT_FOUND,
                payload: result.data.payload
            })
        }

    }
}
