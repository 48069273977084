import React from "react";
import {Dots} from "react-activity";
// import 'react-activity/dist/react-activity.css';
import styles from "./FeaturedEntries.module.scss"
import {NavLink} from "react-router-dom";
import Interaction from "../../../components_higherOrder/Ping/Interaction";


const FeaturedEntries = props => {
  
  const renderLoading = () => {
    return (
      <div className={`${styles.itemWrapper} ${styles.loadingBlock}`}>
        <Dots/>
      </div>
    )
  }
  
  const renderCard = (item, i) => {
    
    const {title, og_image, og_description, slug} = item
    return (
      <Interaction
        elementProps={{
          element_name  : 'article_card',
          element_index: {i}
        }}
        interactionProps={{onClick: {action: 3}}}
      >
        <NavLink
          to={`/a/${slug}`}
          key={item._id}
        >
          <div className={styles.cardBlock}>
            
            <div>
              <img src={og_image} className={styles.cardImage}/>
            </div>
            
            <div className={styles.cardTitle}>
              <div className={styles.titleText}>
                {title}
              </div>
            </div>
            
            
            <div className={styles.cardDescription}>
              <div className={styles.descriptionText}>
                {og_description}
              </div>
            </div>
          
          </div>
        </NavLink>
      </Interaction>
    )
  }
  
  
  const renderContent = () => {
    
    if (props.GET_ENTRIES_FEATURED_LOADING === true) {
      return (
        <div>
          {renderLoading()}
        </div>
      )
    }
    
    if (!props.GET_ENTRIES_FEATURED_SUCCESS) {
      return null
    }
    
    if (props.GET_ENTRIES_FEATURED_SUCCESS.length > 0) {
      return props.GET_ENTRIES_FEATURED_SUCCESS.map((item, i) => {
        return (
          <div className={`${styles.itemWrapper} ${styles.item}`} key={item._id}>
            {renderCard(item, i)}
          </div>
        )
      })
    }
  }
  
  // console.log(props.GET_ENTRIES_FEATURED_SUCCESS)
  return (
    <div className={styles.container}>
      <div className={styles.horizontalScrollWrapper}>
        {renderContent()}
      </div>
    </div>
  )
}

export default FeaturedEntries
