import React from "react";
import styles from "./ButtonFilled.module.scss"
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/library.css";
import Interaction from "../../components_higherOrder/Ping/Interaction";

export enum ButtonType {
    SUBMIT = "submit",
    RESET = "reset",
    BUTTON = "button"
}

export interface ButtonProps {
    element_name?: string
    buttonType?: ButtonType
    buttonText: string
    isLoading?: boolean
    onClick?: () => void
}


const ButtonFilled = (props: ButtonProps) => {
// const ButtonFilled = ({buttonText, isLoading}) => {

    if (props.isLoading) {
        return (
            <div className={styles.buttonFilled}>
                <Dots/>
            </div>
        )
    }

    const clickButton = () => {
      if (!props.onClick) {
        return
      } else {
        return props.onClick()
      }
    }

    return (
        // @ts-ignore
      <Interaction
        elementProps={{
            element_name: props.element_name ? props.element_name : "",
        }}
        interactionProps={{onClick: {action: 3}}}
      >
        <button className={styles.buttonFilled} type={props.buttonType} onClick={() => clickButton()}>
            {props.buttonText}
        </button>
      </Interaction>
    )

}

export default ButtonFilled
