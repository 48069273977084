import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import  * as actionCreators from "../actions/index"

export const useActions = () => {
    const dispatch = useDispatch()

    //will be returning something like
    //{searchRepositories: dispatch(searchRepositories)}}
    return bindActionCreators(actionCreators, dispatch)

}
