import React, {PureComponent} from "react";
import ReactDOM from 'react-dom';

import styles from "./ProfileMenu.module.scss";
// import {ReactComponent as Bag} from "../../../../01_application/assets/icons/bag.svg";
import {ReactComponent as User} from "../../../../01_application/assets/icons/user.svg";
import {ReactComponent as Cog} from "../../../../01_application/assets/icons/setting.svg";
import {ReactComponent as Rice} from "../../../../01_application/assets/icons/rice.svg";
import {isAuthenticated} from "../../../../02_redux/redux_utilities/authenticationStatus";

/**
 * main navigation
 */
class ProfileMenu extends PureComponent {
  
  state = {
    activeMainItem: null
  }
  
  
  /**
   * Touch outside of component implementation start
   */
  constructor(props) {
    super(props);
    
    // this.setProfileMenuWrapperRef = this.setProfileMenuWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  
  
  /**
   * Touch outside of component implementation Start
   */
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }
  
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.props.handleCloseProfileMenu()
    }
  }
  /**
   * Touch outside of component implementation end
   */
  
  
  
  handleSignOut = () => {
    this.props.handleToggleProfileMenu()
    this.props.handleClearAuthenticationState()
    this.props.navigate("/")
  }
  
  
  handleNavigateTo = (navTo) => {
    this.props.navigate(navTo)
    this.props.handleToggleProfileMenu()
  }
  
  
  renderAuthMenuItem = () => {
    if (isAuthenticated()) {
      const name = this.props.profile.name ? this.props.profile.name : ""
      
      return (
        <a className={styles.tableItem} onClick={this.handleSignOut}>
          <User fill={"#6fa69e"} stroke={"#6fa69e"} className={styles.bagIcon}/>
          {`Sign out ${name}`}
        </a>
      )
    }
    
    return (
      <a className={styles.tableItem} onClick={() => this.handleNavigateTo("/sign-in")}>
        <User fill={"#6fa69e"} stroke={"#6fa69e"} className={styles.bagIcon}/>
        Sign in
      </a>
    )
  }
  
  renderNameAndEmail = () => {
    if (isAuthenticated()) {
      return (
        <div>
          <div className={styles.name}>
            {this.props.profile.name}
          </div>
          
          <div className={styles.email}>
            {this.props.profile.email}
          </div>
        </div>
      )
    }
  }
  
  render() {
    
    const initials = this.props.profile.name ? this.props.profile.name.charAt(0) : this.props.profile.email.charAt(0)
    
    return (
      // <nav className={`${!this.props.DISPLAY_MOBILE_MENU ? "menu-items-block" : "menu-item-drawer-active"}`}>
      // <div className={styles.menuContainer}>
      <div className={styles.menuBlock}
        // ref={this.setProfileMenuWrapperRef}
      >
        
        <div className={styles.itemsContainer}>
          <div className={styles.userIconBlock}>
            <div className={styles.userIcon}>
              <div className={styles.userIconText}>
                {initials}
              </div>
            </div>
          </div>
          {this.renderNameAndEmail()}
          
          {/*<div className={styles.table}>*/}
          
          {/*<a onClick={() => this.handleNavigateTo("/bag")} className={styles.tableItem}>*/}
          {/*  <Bag fill={"#6fa69e"} stroke={"#6fa69e"} className={styles.bagIcon}/>*/}
          {/*  Bag*/}
          {/*</a>*/}
          {/*<div className={styles.lineSeparator}/>*/}
          
          <a onClick={() => this.handleNavigateTo("/purchases")} className={styles.tableItem}>
            <Rice fill={"#6fa69e"} stroke={"#6fa69e"} className={styles.bagIcon}/>
            Purchases
          </a>
          <div className={styles.lineSeparator}/>
          
          <a onClick={() => this.handleNavigateTo("/user")} className={styles.tableItem}>
            <Cog fill={"#6fa69e"} stroke={"#6fa69e"} className={styles.bagIcon}/>
            Account
          </a>
          <div className={styles.lineSeparator}/>
          
          {this.renderAuthMenuItem()}
        
        </div>
        
        
        {/*</div>*/}
      
      </div>
      // </div>
      // </nav>
    );
  }
}


export default ProfileMenu
