import {store} from "../../01_application/store"
// import atob from "atob"
// base64js = require('base64-js')


export function getJWT () {
    const jwt = store.getState().profile.jwt
    
    if (!jwt) {
        return '';
    }
    return jwt
}

export function isAuthenticated () {
    const jwt = store.getState().profile.jwt

    if (!jwt) {
        return '';
    }

    // const base64Url = jwt.toString().split('.')[1];
    // const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));

    // // console.log(jsonPayload)

    // if(new Date().getTime() > jsonPayload.exp * 1000){
    //     //Expired
    //     return false
    // }

    // return JSON.parse(jsonPayload);

    return jwt
}
