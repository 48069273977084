import React from "react";
import styles from "./Account.module.scss"
import {Dots} from "react-activity";

const Account = props => {
  const {email, email_verified = false} = props
  
  const sendVerificationEmailLink = () => {
    
    if (props.RESEND_VERIFICATION_EMAIL_LOADING) {
      return <Dots />
    }
    
    
    if (email_verified === false) {
      return (
        <a onClick={() => props.handleResendVerificationEmail()}>
          resend verification email
        </a>
      )
    }
  }
  
  const renderVerificationStatus = () => {
    if (email_verified === true) {
      return (
        <div className={styles.lineBlock}>
          <div className={styles.verifiedTag}>
            verified
          </div>
        </div>
      )
    }
  
    return (
      <div className={styles.lineBlock}>
        <div className={styles.unverifiedTag}>
          unverified
        </div>
      </div>
    )
  }
  
  return (
    <div className={styles.container}>
      
      <div className={styles.lineBlock}>
        {email}
        &nbsp; &nbsp;
        {renderVerificationStatus()}
      </div>
      <br/>
      <div>
        {sendVerificationEmailLink()}
      </div>
      
      <br/>
      {/*<div>*/}
      {/*  Your Social ID is the account you use to access services under our umbrella, namely <a href={"https://www.secondmeal.io/"}>Secondmeal</a> and  <a href={"https://www.secondmeal.io/"}>SocialGood</a>*/}
      {/*</div>*/}
    </div>
  )
}

export default Account