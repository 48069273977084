import React, {Component} from "react";

import styles from "./BeneficiaryOnboardSuccess.module.scss"
import complete from "../../../01_application/assets/icons/mission-accomplished.svg";
import {NavLink} from "react-router-dom";
import {useParams} from "react-router";
import Impression from "../../components_higherOrder/Ping/Impression";


const BeneficiaryOnboardSuccess = () => {


  const params = useParams()


  //Doing this weird split because react router v6 no longer supports multiple params and
  // I want to keep the link the same
  let {id} = params
  if (!id) {
    id = ""
  }
  const split = id.split("&")
  const initiative_id = split[0].split("=")[1]
  const beneficiary_id = split[1].split("=")[1]


  return (
    <Impression pageload page={"beneficiary_onboard_success"}>
      <div className={styles.pageContainer}>

        <div className={styles.registrationSuccessBlock}>

          <img src={complete} alt="secondmeal" className={styles.completeLogo}/>

          Beneficiary has been onboarded! <br/>
          please send the above link to the beneficiary
          <br/><br/>

          <br/>
          <div className={styles.beneficiaryURL}>{`www.secondmeal.sg/profile/b_id=${beneficiary_id}`}</div>
          {/*<div className={styles.beneficiaryURL}>{` ${process.env.PUBLIC_URL}/profile/b_id=${beneficiary_id}`}</div>*/}

          <NavLink
            to={`/onboard/i_id=${initiative_id}`}
          >
            <button className={styles.homeBtn}>
              Create Another Beneficiary
            </button>
          </NavLink>

        </div>

      </div>
    </Impression>

  )
}


export default BeneficiaryOnboardSuccess;
