import React from "react";
import "./BeneficiaryPage.modules.scss";
import QRCode from "qrcode.react";
import useWindowDimensions from '../../../../04_utilities/hooks/getWindowDimensions';
import moment from "moment";
import momentTZ from "moment-timezone";


const header = (payload) => {

  const {profile_picture_url, daily_max_meals} = payload

  return (
    <div className={"header-container"}>

      <div className={"date-block"}>
        <div className={"date"}>
          {moment().tz("Asia/Singapore").format("DD MMMM YYYY")}
        </div>

        <div className={"date"}>
        {moment().tz("Asia/Singapore").format("LT")}
        </div>
      </div>


      <img
        src={profile_picture_url}
        alt="new"
        className="profile-picture"
      />

      <div className={"profile-name"}>
        {payload.name}
      </div>

      <div className={"supplementary-info-block"}>
        <div className={"supplementary-info"}>
          <div className={"key"}>
            Meal entitlement:
          </div>

          <div className={"value"}>
            {daily_max_meals}
          </div>
        </div>

        <div className={"supplementary-info"}>
          <div className={"key"}>
            Expiry:
          </div>

          <div className={"value"}>
            -
          </div>
        </div>
      </div>
    </div>
  )
}

const renderInstructions = () => {
  return (
    <div className={"instructions-block"}>
      Your QR code refreshes regularly to prevent fraud and abuse. Do not screenshot your QR code as your code will exipre. <br/> <br/>Use this page to scan your QR code
    </div>
  )
}



const qrCode = (code, scrollToBottom) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {_, width} = useWindowDimensions();
  //
  return (
    <div className={"qr-code-block"}>

      {/*{renderInstructions()}*/}

      <QRCode value={code} className="qr-code" bgColor={"#F4F4F4"} includeMargin={true} style={{ marginTop: 20,width: width * 0.5, maxHeight:300, maxWidth: 300, height: width * 0.5}}/>

    </div>
  )
}


const BeneficiaryPage = props => {
  const {payload} = props
  
  const {scrollToBottom} = props
  
  return (
    <div>
      {header(payload)}
      {qrCode(payload.code, scrollToBottom)}
    </div>
  );
};

export default BeneficiaryPage;
