import React from "react";
import PropTypes from "prop-types";

import "./HeaderDrawerIcon.scss";

const propTypes = {
    /** @type {boolean}		shows cross when active */
    active: PropTypes.bool,
    /** @type {function}	onClick callback */
    onClick: PropTypes.func.isRequired
};
const defaultProps = {
    active: false
};

/**
 * Hamburger icon in the header
 * Only visible in smaller screens
 */
export default function HeaderDrawerIcon({ active, onClick }) {
    let naviconClassName = "headerNavIcon ";
    if (active) naviconClassName += "headerNavIcon--active";
    return (
        <button type="button" className={naviconClassName} onClick={onClick}>
            <div className="headerNavIcon__top animated" />
            <div className="headerNavIcon__mid animated" />
            <div className="headerNavIcon__btm animated" />
        </button>
    );
}
HeaderDrawerIcon.propTypes = propTypes;
HeaderDrawerIcon.defaultProps = defaultProps;
