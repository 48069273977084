import styles from "./StripeCreditCardForm.module.scss";
import React, {useEffect, useState} from "react";
import {Elements, PaymentElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import keys from "../../../00_config/keys/keys";
import {Dots} from "react-activity";
import buttonStyles from "../../../01_application/styles/buttonStyles.module.scss";
import loadingStyles from "../../../01_application/styles/loading.module.scss";
import {isAuthenticated} from "../../../02_redux/redux_utilities/authenticationStatus";
import toast from "react-hot-toast";
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";


interface SetupFormProps {
  return_path: string
  handleCloseModal: () => void;
}

const SetupForm = (props: SetupFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const origin = window.location.origin

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1200);
  }, []);


  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    setIsSubmitLoading(true)

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    //If user is logged in, call set up intent flow
    //When called, stripe.confirmSetup will attempt to complete any required actions,
    // such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
    // Your user will be redirected to the return_url you pass once the authorization is complete.
    if (isAuthenticated()) {
      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // return_url: `${keys.WEB_ROOT_URL}/user?v=pi`,
          return_url: `${origin}${props.return_path}`,
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        // @ts-ignore
        setErrorMessage(error.message);
        setIsSubmitLoading(false)
        // @ts-ignore
        toast.error(error.message, {
          duration : 10000,
          position : 'top-center',
          iconTheme: {primary: '#ab2939', secondary: '#fff',},
        })
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      // if user is not logged in, call create payment intent flow
    } else {
      // await stripe.
      // const { error } = await stripe.confirmPayment({
      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${keys.WEB_ROOT_URL}${props.return_path}`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        // @ts-ignore
        setErrorMessage(error.message);
        // @ts-ignore
        toast.error(error.message, {
          duration : 10000,
          position : 'top-center',
          iconTheme: {primary: '#ab2939', secondary: '#fff',},
        })
        setIsSubmitLoading(false)
        // showMessage(error.message);
      } else {
        // showMessage("An unexpected error occured.");
      }

      // setLoading(false);
    }


  };

  const renderLoading = () => {
    if (isLoading) {
      return (
        <div className={loadingStyles.loading}>
          <Dots/>
        </div>
      )
    }
  }

  const renderForm = () => {
    return (
      <div className={`${isLoading ? styles.hidden : ""}`}>
        <PaymentElement/>
      </div>
    )
  }

  const renderSubmitButtonContent = () => {
    if (isSubmitLoading) {
      return <Dots/>
    } else {
      return "Submit"
    }
  }


  return (
    <form onSubmit={handleSubmit}>

      {renderLoading()}
      {renderForm()}
      {/*<button disabled={!stripe}>Submit</button>*/}


      {/* Show error message to your customers */}
      {/*{errorMessage && <div>{errorMessage}</div>}*/}

      <div className={buttonStyles.buttonBlock}>
        <div className={buttonStyles.secondaryButton} onClick={props.handleCloseModal}>
          Cancel
        </div>

        <button className={buttonStyles.primaryButton} disabled={!stripe}>
          {/*{this.props.EXCHANGE_FOR_STRIPE_TOKEN_LOADING || this.props.ADD_CARD_LOADING ? <Dots/> : "Save Card"}*/}
          {renderSubmitButtonContent()}
        </button>
      </div>

    </form>
  )
};


interface StripeCreditCardFromProps {
  GET_STRIPE_CLIENT_SECRET_LOADING: boolean
  GET_STRIPE_CLIENT_SECRET_PAYLOAD: any
  handleCloseModal: () => void
  return_path: string
}

const StripeCreditCardForm = (props: StripeCreditCardFromProps) => {

  // render loading if still getting client secret
  if (props.GET_STRIPE_CLIENT_SECRET_LOADING || !props.GET_STRIPE_CLIENT_SECRET_PAYLOAD) {
    return (
      <div className={styles.container}>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <LoadingDots/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }

  // console.log(props.GET_STRIPE_CLIENT_SECRET_PAYLOAD)

  const stripePromise = loadStripe(keys.STRIPE_PUBLISHABLE_KEY);
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: props.GET_STRIPE_CLIENT_SECRET_PAYLOAD,
    // Fully customizable with appearance API.
    appearance: {
      theme: "flat",

      // variables: {
      //   colorPrimary: '#0570de',
      //   colorBackground: '#ffffff',
      //   colorText: '#30313d',
      //   colorDanger: '#df1b41',
      //   fontFamily: 'Ideal Sans, system-ui, sans-serif',
      //   spacingUnit: '2px',
      //   borderRadius: '4px',
      //   // See all possible variables below
      // }
    },

  };

  return (
    <div className={styles.container}>
      <div className={styles.heading2Text}>
        Enter your card information:
      </div>

      <br/>
      <br/>


      {/*@ts-ignore*/}
      <Elements stripe={stripePromise} options={options}>
        <SetupForm
          handleCloseModal={props.handleCloseModal}
          return_path={props.return_path}
        />
      </Elements>

    </div>
  )
}

export default StripeCreditCardForm
