import React, {useEffect, useRef} from "react";
import PinInput from "react-pin-input"
import "./BeneficiaryCode.modules.scss"
import {Dots} from "react-activity";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BeneficiaryPage from "./components/BeneficiaryPage"
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {GET_BENEFICIARY_PROFILE} from "../../../02_redux/redux_config/types";
import {useParams} from "react-router";
import {USER_V2_GET_BENEFICIARY_PROFILE_SUCCESS_PAYLOAD} from "../../../02_redux/actions";
import Impression from "../../components_higherOrder/Ping/Impression";

// let pin;

const BeneficiaryCode = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let pin: any;
  const {
          0: GET_BENEFICIARY_PROFILE_LOADING,
          1: GET_BENEFICIARY_PROFILE_SUCCESS,
          2: GET_BENEFICIARY_PROFILE_ERROR,
          3: GET_BENEFICIARY_PROFILE_EVENT_CODE,
          4: GET_BENEFICIARY_PROFILE_MESSAGE,
          5: GET_BENEFICIARY_PROFILE_PAYLOAD
        } = GetApiFlightStatus<USER_V2_GET_BENEFICIARY_PROFILE_SUCCESS_PAYLOAD>(GET_BENEFICIARY_PROFILE);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const el = useRef(null);
  const {id} = useParams();

  let b_id = ""
  if (!id) {
    b_id = ""
  } else {
    b_id = id.split("=")[1]
  }

  const {
          resetState,
          USER_V2_GET_BENEFICIARY_PROFILE_ACTION
        } = useActions();


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    scrollToBottom();
  }, [])


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const scrollToBottom = () => {
    // if (el) {
    //   el.current?.scrollIntoView({behavior: "smooth"});
    // }
  }

  const handleSubmitPin = (value: string) => {

    if (!b_id) {
      return
    }

    USER_V2_GET_BENEFICIARY_PROFILE_ACTION({id: b_id, last4: value})

    pin.clear();
  }

  const renderLoading = () => {
    if (GET_BENEFICIARY_PROFILE_LOADING) {
      return (
        <div className={"indicator-block"}>
          <Dots/>
        </div>
      )
    }
  }

  const renderError = () => {
    if (GET_BENEFICIARY_PROFILE_ERROR) {
      toast.error("Profile not found or incorrect last 4 digits", {
        onClose: () => resetState(GET_BENEFICIARY_PROFILE)
      });

      return (
        <div>
          <ToastContainer/>
        </div>
      )
    }
  }

  const renderSuccess = () => {

    return (
      <div>
        <BeneficiaryPage
          payload={GET_BENEFICIARY_PROFILE_PAYLOAD}
          scrollToBottom={scrollToBottom}
        />

        <div style={{float: "left", clear: "both"}}
             ref={el}>
        </div>
      </div>
    )
  }


  const renderPinInput = () => {

    return (
      <div className={"pin-input-container"}>

        Please enter the last 4 digits of your handphone number
        <PinInput
          length={4}
          initialValue=""
          // secret
          // onChange={this.handleOnChangePin}
          // onChange={(value, index) => { this.handleOnChangePin}}
          ref={p => {
            return (pin = p)
          }}
          type="numeric"
          style={{padding: '10px'}}
          inputStyle={{borderColor: 'black', borderRadius: 10, fontSize: 16}}
          inputFocusStyle={{borderColor: 'black', borderWidth: 3}}
          onComplete={handleSubmitPin}
        />
        {renderLoading()}
        {/*<div style={{float: "left", clear: "both"}}*/}
        {/*     ref={(el) => {this.el = el}}>*/}
        {/*  dsdsd*/}
        {/*</div>*/}
      </div>
    )
  }

  const renderContent = () => {
    if (GET_BENEFICIARY_PROFILE_SUCCESS) {
      return renderSuccess()

    } else {
      return renderPinInput()
    }
  }


  return (
    <Impression pageload page={"beneficiary_profile"}>
      <div className="beneficiary-profile-page">
        {renderError()}
        {renderContent()}

      </div>
    </Impression>
  );
}

export default BeneficiaryCode;

