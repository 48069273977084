import React from "react";
import styles from "./PaymentInformation.module.scss";
import ProgressBar from "./ProgressBar";
import {Dots} from "react-activity";
import {UseFormReturn} from "react-hook-form";
import ManualCreditCardForm from "../../../components_common/ManualCreditCardForm/ManualCreditCardForm";
import Impression from "../../../components_higherOrder/Ping/Impression";

const scrollTo = (ref: any) => {
  if (ref /* + other conditions */) {
    ref.scrollIntoView({behavior: 'smooth', block: 'start'})
  }
}

interface PaymentInformationProps {
  loading: boolean
  view: string
  views: any
  handleNavigateBackToPurchaseInfo: () => void
  handleValidation: () => void
  handleProceedToPurchaseInfo: () => void
  // purchaseMeals: PurchaseMealsReducer
  register: UseFormReturn['register'];
  formErrors:any
}

const PaymentInformation = (props: PaymentInformationProps) => {

  const {
    loading,
    view,
    views,
    // purchaseMeals,
    handleNavigateBackToPurchaseInfo,
    handleValidation,
    formErrors,
    register
  } = props

  const renderProgressBar = () => {
    return (
            <ProgressBar
              view={view}
              views={views}
            />
    )
  }

  const renderPurchaseMealBtn = () => {
    if (loading === true) {
      return (
              <div>
                <Dots/>
              </div>
      );
    } else {
      return (
              <div className={styles.purchaseMealsCTAText} onClick={handleValidation}>
                Buy Meals For The Beneficiaries
              </div>
      );
    }
  };

  return (

          <div ref={scrollTo}>

            {renderProgressBar()}

            <Impression impression section={"payment_information"} component={"manual_credit_card_form"}>
            <ManualCreditCardForm
              register={register as any}
              formErrors={formErrors}
            />
            </Impression>

            <div className={styles.buttonSection}>

              <div className={styles.backBtnSection}>
                <a>
                  <div className={styles.backBtn} onClick={handleNavigateBackToPurchaseInfo}>
                    back
                  </div>
                </a>
              </div>


              <div
                className={styles.purchaseMealsCTA}
                onClick={handleValidation}
              >
                {renderPurchaseMealBtn()}
              </div>
            </div>
          </div>
  )
}

export default PaymentInformation;
