import {combineReducers} from "redux";
import {ui} from "./ui_reducer";
import {stalls_redux} from "./stall_reducer";
import {purchase_preview_redux} from "./purchasePreview_reducer";
import {purchase_meals_redux} from "./purchaseMeals_reducer";
import {suspendedMeals_redux} from "./suspendedMeals_reducer";
import {claimsFromPurchase_redux} from "./claimsFromPurchase_reducer"
import {apiReducer} from "./api_reducer"
import {profileReducer} from "./profile_reducer";
import {purchaseInformationReducer} from "./purchaseInformation_reducer";
import {analyticsReducer} from "./analytics_reducer";

const reducers = combineReducers({
    ui,
    stalls: stalls_redux,
    purchasePreview: purchase_preview_redux,
    purchaseMeals: purchase_meals_redux,
    suspendedMeals: suspendedMeals_redux,
    claimsFromPurchase: claimsFromPurchase_redux,
    api: apiReducer,
    profile: profileReducer,
    purchaseInformation: purchaseInformationReducer,
    analytics: analyticsReducer
});

export default reducers
export type RootState = ReturnType<typeof reducers>
