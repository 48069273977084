import React from "react";
import "./StallList.modules.scss";

const StallList = props => {
  // console.log(props.stalls)
  const renderMealType = (stall) => {
    
    switch (stall.meal_type) {
      case 1:
        return null;
        
      case 2:
        return (
          <div className={"halal-label"}>
            Halal
          </div>
        )

      case 3:
        return (
          <div className={"vegetarian-label"}>
            Vegetarian
          </div>
        )

      default:
        return null
    }
    
  }

  const renderOpeningHours = (stall) => {
    if (stall.opening_hours) {
      return(
        <div>
          {stall.opening_hours}
        </div>
      )
    }

    return null
  }


  const renderRows = () => {
    return props.stalls.map((stall) => {
      return (
        <div className={"table-row"} key={stall._id}>

          <div className={"stall-name-block"}>

          <div className={"stall-name"}>
          {stall.stallname}
          </div>

          {renderMealType(stall)}
          </div>

          <div className={"stall-address"} >
          {stall.address}
          </div>

          <div className={"stall-address"} >
          {renderOpeningHours(stall)}
          </div>
        </div>
      );
    });
  };

  return(
    <div className={"stall-list-container"}>
      {renderRows()}
    </div>
  )

};

export default StallList;

