import axios from 'axios';
import {FORBIDDEN, UNAUTHORISED} from '../redux_config/types'
import {store} from '../../01_application/store';
import short from 'short-uuid';
import {
  initiatedAction,
  initialiseNewSessionAction,
  initialiseNewClientAction
} from "../actions/analytics_actions";


export default function (type, dispatch) {

  //CHECKING FOR AUTHENTICATION TOKEN
  let s_id = store.getState().analytics.s_id
  let c_id = store.getState().analytics.c_id

  // console.log(session_id)
  // console.log(client_id)


  dispatch(initiatedAction({}));

  const config = {
    timeout: 5000,  //ms
    headers: {
      Authorization: null,
      s_id: s_id,
      c_id :c_id
    }
  }

  // const jwt = store.getState().profile.jwt

  // if (jwt) config.headers.Authorization = `Bearer ${jwt}`

  const flight = axios.create(config);

  flight.interceptors.request.use(
    (request) => {
      // console.log("flight interceptor request", request)
      store.dispatch({
        type: `${type}_LOADING`,
        payload: {}
      });

      return request
    }
  )

  flight.interceptors.response.use(
    response => {

      // console.log("flight interceptor response", response)

      if (response.data.status === 400) {

        store.dispatch({
          type: `${type}_ERROR`,
          payload: response.data,
        });

        return null;
      } else if (response.data.status === 200) {

        store.dispatch({
          type: `${type}_SUCCESS`,
          payload: response.data,
        });

        return null;
      }

      return null;
    }, error => {

      if (error.response?.status === 401) {
        store.dispatch({
          type: UNAUTHORISED,
          // payload: err.data,
        });

        return null;
      } else if (error.response?.status === 403) {
        store.dispatch({
          type: FORBIDDEN,
          // payload: response.data,
        });

        return null;
      }

      console.warn(error)

      store.dispatch({
        type: `${type}_ERROR`,
        payload: error,
      });



    }
  )

  return flight;

}
