import {getEventCode} from "../redux_utilities/apiEventCode"
import {produce} from "immer";

// export function apiReducer(store= {}, action) {
//   // console.log("in API reducer", action)
//   const { type, payload } = action;
//   const matches = /(.*)_(LOADING|SUCCESS|ERROR|RESET)/.exec(type);
//   const extendedMatch = /(EXTENDED)_(.*)/.exec(type);
//
//
//   // if it does not match, we ignore them
//   if (!matches) return store;
//
//   const [, baseType, baseTypeState] = matches;
//
//   return produce(store, draft => {
//     if (baseTypeState === 'RESET') {
//       // draft.status = type;   //unsure what this is for????
//       draft[baseType] = [false, false, false, null];
//     } else {
//       // draft.status = type; //unsure what this is for????
//       draft[baseType] = [
//         baseTypeState === 'LOADING',
//         baseTypeState === 'SUCCESS' ? payload : false,
//         baseTypeState === 'ERROR' ? payload : false,
//         getEventCode(payload),
//       ];
//     }
//   });
// }




export function apiReducer(state = {}, action) {
  // console.log("in API reducer", action)
  const {type, payload} = action;
  const matches = /(.*)_(LOADING|SUCCESS|ERROR|RESET)/.exec(type);

  // if it does not match, we ignore them
  if (!matches) return state;

  const [, baseType, baseTypeState] = matches;

  return produce(state, draft => {

    if (baseTypeState === 'RESET') {
      // draft.status = type;   //unsure what this is for????
      draft[baseType] = [false, false, false, "", "", {}];
    } else {
      // draft.status = type; //unsure what this is for????
      draft[baseType] = [
        baseTypeState === 'LOADING',   // LOADING - bool
        baseTypeState === 'SUCCESS',   // SUCCESS - bool
        baseTypeState === 'ERROR',     // ERROR - bool
        getEventCode(payload) ? getEventCode(payload) : "",        //  EVENT_CODE - string
        payload?.message ? payload.message : "",              // MESSAGE - string
        payload?.payload ? payload.payload : null             // PAYLOAD - object
      ];
    }
  });
}
