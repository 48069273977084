

const MealHealthStatus = (percentage) => {
  if (percentage <= 45) {
    return "Severely Low"
  }

  if (percentage > 45 && percentage <= 65) {
    return "Low"
  }

  if (percentage > 65 && percentage <= 80) {
    return "Average"

  }

  if (percentage > 80) {
    return "Healthy"
  }
}


module.exports = {
  MealHealthStatus
}
