import React, {Component, useEffect} from "react";
import styles from "./VerifyEmail.module.scss"
import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {bindActionCreators} from "redux";
import {USER_V2_VERIFY_EMAIL_ACTION} from "../../../02_redux/actions";
import {connect} from "react-redux";
import EmailVerified from "./components/EmailVerified";
import EmailVerificationError from "./components/EmailVerificationError";
import {VERIFY_EMAIL} from "../../../02_redux/redux_config/types";
import {Dots} from "react-activity";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useLocation} from "react-router-dom";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import Impression from "../../components_higherOrder/Ping/Impression";


const VerifyEmail = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        0: VERIFY_EMAIL_LOADING,
        1: VERIFY_EMAIL_SUCCESS,
        2: VERIFY_EMAIL_ERROR,
        3: VERIFY_EMAIL_EVENT_CODE,
        4: VERIFY_EMAIL_MESSAGE,
        5: VERIFY_EMAIL_PAYLOAD
    } = GetApiFlightStatus<any>(VERIFY_EMAIL);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {
        USER_V2_VERIFY_EMAIL_ACTION
    } = useActions()

    const location = useLocation();

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const email = query.get("email")
        const verificationCode = query.get("verification_code")

        // console.log(email)
        // console.log(verificationCode)

        USER_V2_VERIFY_EMAIL_ACTION({
            email: email,
            verification_code: verificationCode
        })
    }, [])


    const renderEmailVerified = () => {
        return (
            <EmailVerified/>
        )
    }

    const renderError = () => {
        return <EmailVerificationError
            message={VERIFY_EMAIL_MESSAGE}
        />
    }

    const renderContent = () => {

        if (VERIFY_EMAIL_LOADING) {
            return (
                <div className={styles.loading}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Dots/>
                </div>
            )
        } else if (VERIFY_EMAIL_SUCCESS) {
            return renderEmailVerified()
        } else {
            return renderError()
        }
    }


    return (
      <Impression pageload page={"verify_email"}>
        <div className={styles.pageContainer}>
            {renderContent()}
        </div>
      </Impression>
    )

}

export default VerifyEmail
