import {store} from '../../01_application/store';

/**
 * ApiSelector which can pick success, error and loading state
 * for a specific action type
 * one api call should follow [BASE_TYPE, BASE_TYPE_LOADING, BASE_TYPE_ERROR, BASE_TYPE_SUCCESS]
 *
 * @param {String}   type       type of redux action
 * @return {Array}  [{Bool}, {Bool}, {String}, {Number}]   [loading state, success state, error state, error code]
 */
export function apiFlightStatus(type) {
  
  //reading the key api from the API reducer
  return store.getState().api[type] || [false, false, false, 0, "", null];
}
