import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_SUSPENDED_MEALS} from "../redux_config/types";
import { USER_V2_GET_SUSPENDED_MEALS} from "../redux_config/endpoints";


export const GET_SUSPENDED_MEALS_action = (payload) => {

  return async dispatch => {

    const config = {}
    // const params = {
    //   partner_id
    // }

    try {
      await apiFlight(GET_SUSPENDED_MEALS, dispatch).post(
        USER_V2_GET_SUSPENDED_MEALS,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
