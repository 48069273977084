import {
    AUTHENTICATE_ACCOUNT,
    CLEAR_AUTHENTICATION_STATE,
    CREATE_USER_ACCOUNT,
    INITIATED_ACTION,
    GET_USER_QUICK_PROFILE,
    INITIALISE_NEW_SESSION, INITIALISE_NEW_CLIENT
} from "../redux_config/types";
import short from 'short-uuid';

export interface AnalyticsReducerInterface {
    user_id: string
    email: string
    s_id: string
    c_id: string
    last_action_at: Date | null

}

const INITIAL_STATE = {
    user_id: "",
    email: "",
    s_id: "",
    c_id: "",
    last_action_at: null
}


export function analyticsReducer(state = INITIAL_STATE, action: any) {
    // console.log(action.type)
    switch (action.type) {
        case CLEAR_AUTHENTICATION_STATE: {
            return {
                ...state,
                email: "",
            };
        }

        case `${AUTHENTICATE_ACCOUNT}_SUCCESS`:
        case `${CREATE_USER_ACCOUNT}_SUCCESS`: {
            return {
                ...state,
                email: action.payload.payload.email,
                c_id: state.c_id === "" ? short.generate() : state.c_id
            };
        }

        case `${GET_USER_QUICK_PROFILE}_SUCCESS`: {
            return {
                ...state,
                email: action.payload.payload.email,
                c_id: state.c_id === "" ? short.generate() : state.c_id
            };
        }

        case INITIATED_ACTION: {
            return {
                ...state,
                last_action_at: new Date()
            }
        }

        case INITIALISE_NEW_SESSION: {
            return {
                ...state,
                c_id: state.c_id === "" ? short.generate() : state.c_id,
                s_id: action.payload.s_id
            }
        }

        case INITIALISE_NEW_CLIENT: {
            return {
                ...state,
                c_id: action.payload.c_id
            }
        }

        default:
            return state;
    }
}
