import {SAVE_PURCHASE_COUNT_AND_TYPE, SAVE_PURCHASE_USER_INFORMATION} from "../redux_config/types";


export interface PurchaseCountAndTypePayload {
    meal_count: number
    auto_repeat_purchase: boolean
    subscription: number
}

export const savePurchaseCountAndTypeAction = (payload: PurchaseCountAndTypePayload) => ({
    type: SAVE_PURCHASE_COUNT_AND_TYPE,
    payload: payload
});


export interface PurchaseUserInformation {
    display_name: string
    post_message: string
    email: string
}


export const savePurchaseUserInformation = (payload: PurchaseUserInformation) => ({
    type: SAVE_PURCHASE_USER_INFORMATION,
    payload: payload
});