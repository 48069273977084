import React, {Component, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SuspendedMealsTable from "./components/SuspendedMealsTable";
import SuspendedMealsTableActive from "./components/SuspendedMealsTableActive";
import SuspendedMealsTableHistory from "./components/SuspendedMealsTableHistory";
import {
  AUTHENTICATE_ACCOUNT, CREATE_USER_ACCOUNT,
  GET_INITIATIVES_STALL_STATS,
  GET_SUSPENDED_MEALS,
  GET_SUSPENDEDMEALS_ACTIVE_SUCCESS,
  GET_SUSPENDEDMEALS_HISTORY_SUCCESS
} from "../../../02_redux/redux_config/types";
import styles from "./InitiativeDonationStats.module.scss";
import {
  GET_SUSPENDED_MEALS_action,
  getInitiativeStallStats,
  getStallStats,
  getSuspendedMealsActive,
  getSuspendedMealsHistory
} from "../../../02_redux/actions/";
import HeroSection from "./components/HeroSection";
import {apiFlightStatus} from "../../../02_redux/redux_utilities/apiFlightStatus";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {ProfileReducer} from "../../../02_redux/reducers/profile_reducer";
import {SuspendedMealsReducer} from "../../../02_redux/reducers/suspendedMeals_reducer";
import Impression from "../../components_higherOrder/Ping/Impression";

const period = {
  ACTIVE: "ACTIVE",
  HISTORY: "HISTORY"
}

const views = {
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  AUTO_REPEAT_PURCHASE: "AUTO_REPEAT_PURCHASE",
  POST: "POST",
  ALL: "ALL"
}

const InitiativeDonationStats = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState(period.ACTIVE)
  const [view, setView] = useState(views.SUBSCRIPTIONS)
  const [current, setCurrent] = useState("")
  const [activePurchases, setActivePurchases] = useState([])

  const {
          0: GET_INITIATIVES_STALL_STATS_LOADING,
          1: GET_INITIATIVES_STALL_STATS_SUCCESS,
          2: GET_INITIATIVES_STALL_STATS_ERROR,
          3: GET_INITIATIVES_STALL_STATS_EVENT_CODE,
          4: GET_INITIATIVES_STALL_STATS_MESSAGE,
          5: GET_INITIATIVES_STALL_STATS_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_INITIATIVES_STALL_STATS);

  const {
          0: GET_SUSPENDED_MEALS_LOADING,
          1: GET_SUSPENDED_MEALS_SUCCESS,
          2: GET_SUSPENDED_MEALS_ERROR,
          3: GET_SUSPENDED_MEALS_EVENT_CODE,
          4: GET_SUSPENDED_MEALS_MESSAGE,
          5: GET_SUSPENDED_MEALS_PAYLOAD,
        } = GetApiFlightStatus<any>(GET_SUSPENDED_MEALS);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
          getStallStats,
          getInitiativeStallStats,
          getSuspendedMealsActive,
          getSuspendedMealsHistory,
          GET_SUSPENDED_MEALS_action
        } = useActions()


  const suspendedMeals = useTypedSelector<SuspendedMealsReducer>(state => state.suspendedMeals)
  const navigate = useNavigate();
  const params = useParams()
  //Doing this weird split because react router v6 no longer supports multiple params and
  // I want to keep the link the same
  let {id} = params
  if (!id) {
    id = ""
  }
  const split = id.split("&")
  const stall_id = split[0].split("=")[1]
  const initiative_id = split[1].split("=")[1]

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getInitiativeStallStats(initiative_id);
    handleGetMeals()
  }, [])

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const handleGetMeals = () => {
    const payload = {
      initiative_id: initiative_id,
      active_purchases: selected === period.ACTIVE ? true : false,
      subscription: view === views.SUBSCRIPTIONS ? 1 : 0,
      auto_recurring_purchase: view === views.AUTO_REPEAT_PURCHASE ? 1 : 0,
      post: view === views.POST ? 1 : 0,
      all: view === views.ALL ? 1 : 0,
    }

    GET_SUSPENDED_MEALS_action(payload)
  }

  const handleSelectView = (e: any) => {
    setView(e.target.value)
    // handleGetMeals()

    const payload = {
      initiative_id: initiative_id,
      active_purchases: selected === period.ACTIVE ? true : false,
      subscription: e.target.value === views.SUBSCRIPTIONS ? 1 : 0,
      auto_recurring_purchase: e.target.value === views.AUTO_REPEAT_PURCHASE ? 1 : 0,
      post: e.target.value === views.POST ? 1 : 0,
      all: e.target.value === views.ALL ? 1 : 0,
    }

    GET_SUSPENDED_MEALS_action(payload)

  }


  const handleViewTrimmedMessageActive = (i: number) => {
    // console.log(suspendedMeals)
    // suspendedMeals.active.payload[i].viewTrimmedMessage = true
    // console.log(data[i])
    // // data[i].viewMore = true
    // console.log(data[i])

    // let duplicateState = activePurchases.map(obj => ({
    //   ...obj,
    // }));
    // duplicateState[i].viewTrimmedMessage = true
    //
    //
    // setState({
    //   activePurchases: duplicateState
    // })
  }


  const handleClick = (e: { key: any; }) => {
    setCurrent(e.key)
  };

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleSelectHistory = () => {
    if (selected === period.ACTIVE) {
      setSelected(period.HISTORY)
      handleGetMeals()
    }
  };

  const handleSelectActive = () => {
    // console.log("active selected")
    if (selected === period.HISTORY) {
      setSelected(period.ACTIVE)
      handleGetMeals()
    }
  };

  const renderSuspendedMeals = () => {
    if (GET_SUSPENDED_MEALS_SUCCESS) {
      return (
        <SuspendedMealsTable
          // data={activePurchases}
          data={GET_SUSPENDED_MEALS_PAYLOAD}
          handleViewTrimmedMessageActive={handleViewTrimmedMessageActive}
        />
      );
    }

    if (selected === period.ACTIVE) {
      return (
        renderSuspendedMealsActive()
      )
    }

    if (selected === period.HISTORY) {
      return (
        renderSuspendedMealsHistory()
      )
    }
  }

  const renderSuspendedMealsHistory = () => {
    if (suspendedMeals.history.case === GET_SUSPENDEDMEALS_HISTORY_SUCCESS) {
      return (
        <SuspendedMealsTableHistory
          data={suspendedMeals.history.payload}
        />
      );
    }
  }


  const renderSuspendedMealsActive = () => {
    if (suspendedMeals.active.case === GET_SUSPENDEDMEALS_ACTIVE_SUCCESS) {
      return (
        <SuspendedMealsTableActive
          data={activePurchases}
          handleViewTrimmedMessageActive={handleViewTrimmedMessageActive}
        />
      );
    }
  };


  const renderOptions = () => {
    return (
      <div className={styles.optionsContainer}>
        <a>
          <div
            className={selected === period.ACTIVE ? styles.selected : styles.unselected}
            onClick={() => handleSelectActive()}
          >
            ACTIVE
          </div>
        </a>
        |
        <a>
          <div
            className={selected === period.HISTORY ? styles.selected : styles.unselected}
            onClick={() => handleSelectHistory()}
          >
            HISTORY
          </div>
        </a>
      </div>
    );
  };

  const renderViewSelection = () => {
    return (
      <div className={styles.selectionBlock}>
        <select onChange={handleSelectView} className={styles.selectionField}>
          <option value={views.SUBSCRIPTIONS} onChange={handleSelectView}>Subscription</option>
          <option value={views.AUTO_REPEAT_PURCHASE} onChange={handleSelectView}>Auto Repeat Purchase</option>
          <option value={views.POST} onChange={handleSelectView}>With Post</option>
          <option value={views.ALL} onChange={handleSelectView}>All</option>
        </select>
      </div>
    )
  }

  const renderHeroSection = () => {
    if (GET_INITIATIVES_STALL_STATS_SUCCESS) {
      return (
        <HeroSection
          handleGoBack={handleGoBack}
          item={GET_INITIATIVES_STALL_STATS_PAYLOAD}
        />
      )
    }
  }


  return (
    <Impression pageload page={"initiative_donation_stats"}>
      <div className={styles.pageContainer}>

        {/*<div className={styles.backBtnSection}>*/}
        {/*  <a>*/}
        {/*    <div className={styles.backBtn} onClick={() => history.goBack()}>*/}
        {/*      back*/}
        {/*    </div>*/}
        {/*  </a>*/}
        {/*</div>*/}

        {renderHeroSection()}

        {/*{renderStats()}*/}
        <br/>

        {renderOptions()}
        {renderViewSelection()}
        <div className={styles.purchaseContainer}>
          {renderSuspendedMeals()}
        </div>
      </div>
    </Impression>
  );

}


export default InitiativeDonationStats;
