import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {UPLOAD_DOCUMENT} from "../redux_config/types";
import { USER_V2_UPLOAD_DOCUMENT} from "../redux_config/endpoints";
import {Dispatch} from "react";



export interface SHOP_V1_UPLOAD_DOCUMENT_ACTION {
    upload_id: string;
    file_category: string;
    file: any;
}


export const USER_V2_UPLOAD_DOCUMENT_ACTION = (payload: SHOP_V1_UPLOAD_DOCUMENT_ACTION) => {

    const {upload_id, file_category, file} = payload

    return async (dispatch: Dispatch<any>) => {

        let data = new FormData();
        data.set("upload_id", upload_id)
        data.set("file_category", file_category)
        data.append('file', file);

    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
      }
    }

    try {

      await apiFlight(UPLOAD_DOCUMENT, dispatch).post(
        USER_V2_UPLOAD_DOCUMENT,
        data,
        config
      )
    } catch (err) {
      console.log(err)
    }

  }
}

export interface UPLOAD_DOCUMENT_SUCCESS_PAYLOAD {
    _id: string;
    upload_id: string;
    file_category: string;
    url: string;
}


