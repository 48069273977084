import React, { useEffect} from "react";
import ReactGA from "react-ga";
import keys from "../../00_config/keys/keys";
import {useLocation} from "react-router-dom";

const AnalyticsController = () => {
  
  let location = useLocation();
  
  
  useEffect(() => { //This will run whenever params change
    const {params = {}} = location;
    //your logic here
    
  //   ReactGA.initialize(keys.GOOGLE_ANALYTICS_TRACKING_ID, {
  //     'cookieDomain': 'auto',
  //     'debug'       : false
  //   });
  //   ReactGA.plugin.require('ec');
  //   ReactGA.plugin.require('ecommerce');
  //   ReactGA.set({page: params.pathname})
  //   ReactGA.pageview(params.pathname)
  //
  }, []);
  

  
  return null;
  
}

export default AnalyticsController;
