import { createStore, compose, applyMiddleware } from 'redux';
// import AsyncStorage from '@react-native-community/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import reducers from '../../02_redux/reducers';
import storage from 'redux-persist/lib/storage';
import * as Sentry from "@sentry/react";

const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'profile',
    'purchaseInformation',
    'analytics'
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  {},
  compose(applyMiddleware(thunk), sentryReduxEnhancer)
);

const persistor = persistStore(store);

export {
  store,
  persistor,
};
