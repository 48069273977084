import axios from "axios";
import { suspendedHistoryURL} from "../redux_config/endpoints"
import {
    GETTING_SUSPENDEDMEALS_HISTORY,
    GET_SUSPENDEDMEALS_HISTORY_SUCCESS,
    GET_SUSPENDEDMEALS_HISTORY_ERROR
} from "../redux_config/types";


/*
      Actions on this page will be handled in suspendedMeals_reducer.js
 */

export const getSuspendedMealsHistory = (stall_id) => {
    return async dispatch => {
        dispatch ({ type: GETTING_SUSPENDEDMEALS_HISTORY})

        const config = {
            // headers: {
            //     Authorization: "Bearer " + JWT
            // }
        }

        const result = await axios.get(`${suspendedHistoryURL}/${stall_id}`, config);

        // console.log("SUSPENDED_MEALS_HISTORY", result)

        if(result.data.status_code === 200 ) {
            return dispatch({
                type: GET_SUSPENDEDMEALS_HISTORY_SUCCESS,
                payload: result.data.payload
            })
        } else {
            return dispatch({
                type: GET_SUSPENDEDMEALS_HISTORY_ERROR
            })
        }
    }
}
