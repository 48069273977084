import styles from "./InputText.module.scss";
import React, {ChangeEvent} from "react";
import {Paragraph, ParagraphSemiBold, SmallText, SmallTextLabel} from "../Typography/Typography";
import {UseFieldArrayUpdate, UseFormReturn} from "react-hook-form";
import {update} from "lodash";
// import {useFormContext} from "react-hook-form";

// Various input type available
// select,
// textarea,
// input[type="text"],
// input[type="password"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="date"],
// input[type="month"],
// input[type="time"],
// input[type="week"],
// input[type="number"],
// input[type="email"],
// input[type="tel"],
// input[type="url"]

export enum InputType {
  CHECKBOX = "checkbox",
  SELECT   = "select",
  TEXT     = "text",
  EMAIL    = "email",
  PASSWORD = "password",
  TEL      = "tel",
  URL      = "url",
  TEXTAREA = "textarea",
  NUMBER   = "number"
}

export interface InputTextProps {
  inputType: InputType,
  register?: UseFormReturn['register'];
  // update?: UseFieldArrayUpdate<any, any>
  // index?: number
  fieldName: string;
  fieldLabel: string;
  fieldSubLabel?: string;
  fieldFootNote?: string;
  fieldPlaceHolder?: string;
  fieldError: boolean;
  fieldErrorMessage?: string | null;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string | null
  handleSetValueOnBlur?: (fieldName: string, value: any) => void;
  locked?: boolean;
}


export const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const {
          // update,
          // index,
          fieldName,
          register,
          inputType,
          fieldLabel,
          fieldSubLabel,
          fieldFootNote,
          fieldPlaceHolder,
          fieldError,
          fieldErrorMessage,
          defaultValue,
          onChange,
          handleSetValueOnBlur,
          locked,
        } = props

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (handleSetValueOnBlur) {
      handleSetValueOnBlur(fieldName, e.target.value)
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }

  // const {register} = useFormContext()
  const renderSubLabel = () => {
    if (fieldSubLabel) {
      return (
        <div className={styles.formSubLabel}>
          <SmallTextLabel>
            {fieldSubLabel}
          </SmallTextLabel>
        </div>
      )
    }
  }

  const renderFootNote = () => {
    if (fieldFootNote) {
      return (
        <div className={styles.footnote}>
          <SmallTextLabel>
            {fieldFootNote}
          </SmallTextLabel>
        </div>
      )
    }
  }

  const renderFieldErrorMessage = () => {
    if (fieldErrorMessage) {
      return (
        <div className={styles.errorBlock}>
          {fieldErrorMessage}
        </div>
      )
    }
  }
  // {...registerField(fieldName)}

  const renderTextField = () => {
    if (onChange) {
      return (
        <input
          readOnly={locked === true}
          {...register ? register(fieldName) : ""}
          defaultValue={defaultValue ? defaultValue : ""}
          type={inputType}
          placeholder={fieldPlaceHolder}
          className={`${styles.formField} ${fieldError !== false ? styles.error : ""}`}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
      )
    } else {
      return (
        <input
          readOnly={locked === true}
          {...register ? register(fieldName) : ""}
          defaultValue={defaultValue ? defaultValue : ""}
          type={inputType}
          placeholder={fieldPlaceHolder}
          className={`${styles.formField} ${fieldError !== false ? styles.error : ""}`}
          onBlur={handleOnBlur}
        />
      )
    }
  }

  return (
    <div className={styles.vertical}>
      <div className={styles.fieldLabel}>
        <ParagraphSemiBold>
          {fieldLabel}
        </ParagraphSemiBold>
      </div>

      {renderSubLabel()}

      {renderTextField()}


      {renderFootNote()}
      {renderFieldErrorMessage()}

    </div>
  )
}

