import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {UNSUBSCRIBE} from "../redux_config/types";
import { USER_V2_UNSUBSCRIBE} from "../redux_config/endpoints";


export const unsubscribe = (params) => {

  return async dispatch => {

    const config = {}
    // const params = {
    //   purchase_id
    // }

    // console.log(params)

    try {
      await apiFlight(UNSUBSCRIBE, dispatch).post(
        USER_V2_UNSUBSCRIBE,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}



