import {
    GETTING_SUSPENDEDMEALS_ACTIVE,
    GET_SUSPENDEDMEALS_ACTIVE_SUCCESS,
    GET_SUSPENDEDMEALS_ACTIVE_ERROR,

    GETTING_SUSPENDEDMEALS_HISTORY,
    GET_SUSPENDEDMEALS_HISTORY_SUCCESS,
    GET_SUSPENDEDMEALS_HISTORY_ERROR
} from "../redux_config/types";


export interface SuspendedMealsReducer {
    active: {
        case: null | string
        payload: Array<any>
    }
    history: {
        case: null | string
        payload: Array<any>
    }
}


const INITIAL_STATE = {
    active: {
        case: null,
        payload: []
    },
    history: {
        case: null,
        payload: []
    }
};

/*
     action here grabs all actions dispatched by actions,
     we are merely switching through them in compartments for readability and maintainability
*/

export function suspendedMeals_redux(state = INITIAL_STATE, action: any) {

    switch (action.type) {
        case GETTING_SUSPENDEDMEALS_ACTIVE: {

            return {
                active: {
                    case: GETTING_SUSPENDEDMEALS_ACTIVE,
                    payload: []
                },
                history: {
                    case: null,
                    payload: []
                }
            }

        }

        case GET_SUSPENDEDMEALS_ACTIVE_ERROR: {
            return {
                case: GET_SUSPENDEDMEALS_ACTIVE_ERROR,
                payload: null
            };
        }

        case GET_SUSPENDEDMEALS_ACTIVE_SUCCESS:
            return {

                active: {
                    case: GET_SUSPENDEDMEALS_ACTIVE_SUCCESS,
                    payload: action.payload
                },
                history: {
                    case: state.history.case,
                    payload: state.history.payload
                }

            };

        case GETTING_SUSPENDEDMEALS_HISTORY: {

            return {

                active: {
                    case: state.active.case,
                    payload: state.active.payload
                },
                history: {
                    case: GETTING_SUSPENDEDMEALS_HISTORY,
                    payload: action.payload
                }
            }

        }

        case GET_SUSPENDEDMEALS_HISTORY_SUCCESS:
            return {

                active: {
                    case: state.active.case,
                    payload: state.active.payload
                },
                history: {
                    case: GET_SUSPENDEDMEALS_HISTORY_SUCCESS,
                    payload: action.payload
                }

            };

        case GET_SUSPENDEDMEALS_HISTORY_ERROR: {
            return {
                case: GET_SUSPENDEDMEALS_HISTORY_ERROR,
                payload: null
            };
        }

        default:
            return state;
    }
}
