import {
    CLEAR_PURCHASE_INFORMATION,
    SAVE_PURCHASE_COUNT_AND_TYPE,
    SAVE_PURCHASE_USER_INFORMATION
} from "../redux_config/types";

export interface PurchaseInformationReducer {
    //Purchaseinfo
    display_name: string
    post_message: string
    email: string

    meal_count: number
    auto_repeat_purchase: boolean
    subscription: number
}

const INITIAL_STATE = {
    display_name: "",
    post_message: "",
    email: "",


    meal_count: 0,
    auto_repeat_purchase: false,
    subscription: 0,
}


export function purchaseInformationReducer(state = INITIAL_STATE, action: any) {

    switch (action.type) {

        case SAVE_PURCHASE_COUNT_AND_TYPE:
            return {
                ...state,
                meal_count: action.payload.meal_count,
                auto_repeat_purchase: action.payload.auto_repeat_purchase,
                subscription: action.payload.subscription,
            }

        case SAVE_PURCHASE_USER_INFORMATION:
            return {
                ...state,
                display_name: action.payload.display_name,
                post_message: action.payload.post_message,
                email: action.payload.email,

            }
        case CLEAR_PURCHASE_INFORMATION:
            return {
                INITIAL_STATE,
            }


        default:
            return state;
    }
}