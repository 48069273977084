import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";
// import ErrorBoundary from "../../03_views/components_higherOrder/ErrorBoundary/ErrorBoundary";
import ScrollToTop from "../../04_utilities/core/scrollToTop/scrollToTop";


// Route-based code splitting
// const Home = lazy(() => import('./routes/Home'));
// const About = lazy(() => import('./routes/About'));

import Landing from "../../03_views/pages/Landing/Initiatives"
import InitiativeDistributionStats from "../../03_views/pages/InitiativeDistributionStats/InitiativeDistributionStats"
import PurchasePreview from "../../03_views/pages/PurchaseMeals/PurchasePreview"
import InitiativeDonationStats from "../../03_views/pages/InitiativeDonationStats/InitiativeDonationStats"
import WhyMeals from "../../03_views/pages/WhyMeals/WhyMeals"
import Claims from "../../03_views/pages/ClaimsFromPurchase/ClaimsFromPurchase"
import AboutUs from "../../03_views/pages/AboutUs/AboutUs"
import HowItWorks from "../../03_views/pages/HowItWorks/HowItWorks"
import BeneficiaryCode from "../../03_views/pages/BeneficiaryCode/BeneficiaryCode"
import ParticipatingStalls from "../../03_views/pages/ParticipatingStalls/ParticipatingStalls"
import PrivacyPolicy from "../../03_views/pages/PrivacyPolicy/PrivacyPolicy"
import BeneficiaryRecommend from "../../03_views/pages/_deprecated/BeneficiaryRecommend/BeneficiaryRecommend"
import BeneficiaryOnboard from "../../03_views/pages/BeneficiaryOnboard/BeneficiaryOnboard"
import BeneficiaryOnboardSuccess from "../../03_views/pages/BeneficiaryOnboardSuccess/BeneficiaryOnboardSuccess"
import Facebook from "../../03_views/pages/Facebook/Facebook"
import PurchaseSuccess from "../../03_views/pages/PurchaseSuccess/PurchaseSuccess"
import Unsubscribe from "../../03_views/pages/ManageRecurring/ManageRecurring"
import Article from "../../03_views/pages/ArticlePage/ArticlePage"
import SignIn from "../../03_views/pages/SignIn/SignIn"
import User from "../../03_views/pages/User/User"
import VerifyEmail from "../../03_views/pages/VerifyEmail/VerifyEmail"
import PasswordReset from "../../03_views/pages/PasswordReset/PasswordReset"
import Purchases from "../../03_views/pages/UserPurchases/UserPurchases"
import * as Sentry from "@sentry/react";

const RoutingDirectory = () => {


  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  // console.log("loclocloclocloc", location.pathname)
  // let pathsArray = location.pathname.split("/").filter(p => p !== "");
  // let path = location.pathname;
  //
  // //filter through paths to disable transition animation when necessary
  // switch (pathsArray[0]) {
  //     case "our-team":
  //         path = "our-team";
  //         break;
  // }

  return (
          <TransitionGroup>
            <CSSTransition
                    timeout={300}
                    classNames="transition__crossfade"
            >
              {/*<ErrorBoundary>*/}
              <div className="transition__holder">
                <ScrollToTop/>
                <SentryRoutes>
                  <Route path="/" element={<Landing/>} key="landing"/>
                  <Route path="/home" element={<Navigate to={"/"}/>} key="home"/>
                  <Route path="/buy-meals/:stall_id" element={<PurchasePreview/>}
                         key="buyMeals"/> {/*  path="/buy-meals/:stall_id&:initiative_id"*/}
                  <Route path="/initiative/donation-stats/:id" element={<InitiativeDonationStats/>}
                         key="initiativeDonationStats"/> {/* path="/initiative/donation-stats/s_id=:stall_id&:i_id=:initiative_id"*/}
                  <Route path="/initiative/distribution-stats/:id" element={<InitiativeDistributionStats/>}
                         key="initiativeDistributionStats"/> {/* path="/initiative/distribution-stats/s_id=:stall_id&:i_id=:initiative_id"*/}
                  <Route path="/why-meals" element={<WhyMeals/>} key="whyMeals"/>
                  <Route path="/about-us" element={<AboutUs/>} key="aboutUs"/>
                  <Route path="/how-it-works" element={<HowItWorks/>} key="howItWorks"/>
                  <Route path="/claims/:id"
                         element={<Claims/>}/> {/* "/claims/purchase_id=:purchase_id" */}
                  <Route path="/profile/:id" element={<BeneficiaryCode/>}/> {/*path="/profile/b_id=:b_id"*/}
                  <Route path="/participating-stalls" element={<ParticipatingStalls/>}/>
                  <Route path="/onboard/:i_id" element={<BeneficiaryOnboard/>}/> {/*path="/onboard/i_id=:i_id" */}
                  <Route path="/onboard-success/:id" element={<BeneficiaryOnboardSuccess/>}/>  {/*  /onboard-success/i_id=:i_id&b_id=:b_id */}
                  <Route path="/recommend-beneficiary" element={<BeneficiaryRecommend/>}/>
                  <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                  <Route path="/facebook" element={<Facebook/>}/>
                  <Route path="/success/:id" element={<PurchaseSuccess/>} key={"PurchaseSuccess"}/>
                  <Route path="/manage-recurring-purchase/:parent_repeat_purchase" element={<Unsubscribe/>}
                         key={"Unsubscribe"}/>
                  <Route path="/a/:slug" element={<Article/>} key={"article"}/>
                  <Route path="/sign-in" element={<SignIn/>} key={"fresh"}/>
                  <Route path="/user" element={<User/>} key="user"/>
                  <Route path="/verify-email" element={<VerifyEmail/>} key="verify-email"/>
                  <Route path="/reset-password" element={<PasswordReset/>} key="reset-password"/>
                  <Route path="/purchases" element={<Purchases/>} key="purchases"/>


                  {/*<Route exact path="/buy-meals/:stall_id&:initiative_id/partners" component={PartnersPurchase} key="buyMeals"/>*/}
                  {/*<Route exact path="/initiative/i_id=:i_id" component={initiativeStallStats} key="initiativeStallStats"/>*/}
                  {/*<Route exact path="/registration/i_id=:i_id" component={BeneficiaryRegistration}/>*/}
                  {/*<Route exact path="/e7ae162a8f754973bb89656f5a009f5cdc5a1cea34c74719bd4aee7d78e6c6765f6cb0a8b0ce4ada828f475596cda2b8e3687b5003374166b47cbbb00fe76d16" component={CabbieRegistration}/>*/}
                  {/*<Route exact path="/campaign/:id" component={Campaign}/>*/}
                  {/*<Route exact path="/fresh" component={FreshSocial} key={"fresh"}/>*/}
                  {/*<Route exact path="/campaigns" component={Campaigns} key={"campaigns"}/>*/}

                </SentryRoutes>

              </div>
              {/*</ErrorBoundary>*/}
            </CSSTransition>
          </TransitionGroup>
  );
};

export default RoutingDirectory;




