import {ReactComponent as EmailError} from "../../../../01_application/assets/icons/email-error.svg";
import React from "react";
import styles from "./VerificationCodeError.module.scss"

const VerificationCodeError = props => {
  
  return (
    <div className={styles.container}>
      
      <div className={styles.iconBlock}>
        <EmailError className={styles.icon}/>
      </div>
      
      <div className={styles.headerText}>
        Error verifying reset password link
      </div>
      
      <div className={styles.subHeadingText}>
        {props.message}
      </div>
    
    
    </div>
  )
}

export default VerificationCodeError