import React, {useEffect, useState} from "react";
import {Dots} from "react-activity";
import toast from 'react-hot-toast';
import {useLocation, useNavigate} from 'react-router-dom'
import {
  ADD_CARD,
  AUTHENTICATE_ACCOUNT,
  CAPTURE_CLIENT_INTENT,
  CREATE_USER_ACCOUNT,
  DELETE_CARD,
  EXCHANGE_FOR_STRIPE_TOKEN,
  EXCHANGING_FOR_STRIPE_TOKEN,
  GET_CARDS,
  GET_PURCHASE_PREVIEW,
  GET_STRIPE_CLIENT_SECRET,
  GUEST_PURCHASE_MEALS,
  MARK_MEALS_AS_PAID,
  REQUEST_PASSWORD_RESET,
  USER_PURCHASE_MEALS,
} from "../../../02_redux/redux_config/types";
import MealCountSelection from "./components/MealCountSelection";
import PurchaseInformation from "./components/PurchaseInformation";
import PaymentInformation from "./components/PaymentInformation";
import {
  clearPurchaseProps,
  GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD,
  getPurchasePreview,
  savePurchaseCountAndTypeAction,
  savePurchaseUserInformation,
  USER_V2_GUEST_PURCHASE_MEALS_ACTION,
} from "../../../02_redux/actions/";
import styles from "./PurchasePreview.module.scss"
import CardSelection from "./components/CardSelection";
import SignInPrompt from "../../components_common/SignInPrompt/SignInPrompt";
import {useForm} from "react-hook-form";
import {useTypedSelector} from "../../../02_redux/redux_utilities/useTypedSelector";
import {ProfileReducer} from "../../../02_redux/reducers/profile_reducer";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import {useParams} from "react-router";
import {GetApiFlightStatus} from "../../../02_redux/redux_utilities/getApiFlightStatus";
import {PurchaseMealsReducer} from "../../../02_redux/reducers/purchaseMeals_reducer";
import ModalConfirmation from "../../components_core/ModalConfirmation/ModalConfirmation";
import ModalContent from "../../components_core/ModalContent/ModalContent";
import PaymentIntentSection from "../../components_common/PaymentIntentStatus/PaymentIntentStatus";
import keys from "../../../00_config/keys/keys";
import {loadStripe} from '@stripe/stripe-js';
import {usePersistForm} from "../../../04_utilities/hooks/useFormPersist/useFormPersist";
import ForgotPasswordForm from "../../components_common/ForgotPasswordForm/ForgotPasswordForm";
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";
import CreateAccount from "../../components_common/CreateAccount/CreateAccount";
import StripeCreditCardForm from "../../components_common/StripeCreditCardForm/StripeCreditCardForm";
import {PurchaseInformationReducer} from "../../../02_redux/reducers/purchaseInformation_reducer";
import Impression from "../../components_higherOrder/Ping/Impression";

export enum PurchasePreviewViews {
  MEAL_COUNT_SELECTION = "MEAL_COUNT_SELECTION",
  PURCHASE_INFORMATION = "PURCHASE_INFORMATION",
  PAYMENT_INFORMATION  = "PAYMENT_INFORMATION",
  FORGOT_PASSWORD      = "FORGOT_PASSWORD",
  CREATE_ACCOUNT       = "CREATE_ACCOUNT"
}

export enum PurchasePreviewModalViews {
  NULL                           = "NULL",
  ADD_CARD_FORM                  = "ADD_CARD_FORM",
  DELETE_CARD                    = "DELETE_CARD",
  PENDING_PAYMENT_AUTHENTICATION = "PENDING_PAYMENT_AUTHENTICATION",
  PURCHASING_MEALS               = "PURCHASING_MEALS",
  LOADING                        = "LOADING"
}

const views = {
  MEAL_COUNT_SELECTION: "MEAL_COUNT_SELECTION",
  PURCHASE_INFORMATION: "PURCHASE_INFORMATION",
  PAYMENT_INFORMATION: "PAYMENT_INFORMATION"
}

export interface FormValueErrors {
  type: string;
  message: string;
}

export interface PurchasePreviewFormValuesErrors {
  name?: FormValueErrors | undefined;
  address_id?: FormValueErrors | undefined;
  address_street?: FormValueErrors | undefined;
  address_unit_no?: FormValueErrors | undefined;
  address_postal_code?: FormValueErrors | undefined;
  address_country?: FormValueErrors | undefined;
  card_id?: FormValueErrors | undefined;
  name_on_card?: FormValueErrors | undefined;
  cc_no?: FormValueErrors | undefined;
  cc_exp?: FormValueErrors | undefined;
  cc_cvv?: FormValueErrors | undefined;

  email?: FormValueErrors | undefined;
  contact_number?: FormValueErrors | undefined;
  password?: FormValueErrors | undefined;

  display_name?: FormValueErrors | undefined;
  post_message?: FormValueErrors | undefined;
  meal_count?: FormValueErrors | undefined;
}

interface PurchasePreviewFormValues {
  name: string
  address_id: string
  address_street: string
  address_unit_no: string
  address_postal_code: string
  address_country: string
  card_id: string


  name_on_card: string
  cc_no: string
  cc_exp: string
  cc_cvv: string


  //Purchaseinfo
  display_name: string
  post_message: string
  meal_count: number
  auto_repeat_purchase: boolean
  subscription: number

  //auth information
  contact_number: string
  email: string
  password: string
}


export enum PaymentMethodsEnum {
  NULL = "NULL",
  CARD = "CARD"
}


export function removeUrlParameter(paramKey: string) {
  const url = window.location.href
  // console.log("url", url)
  var r = new URL(url)
  r.searchParams.delete(paramKey)
  const newUrl = r.href
  // console.log("r.href", newUrl)
  window.history.pushState({path: newUrl}, '', newUrl)
}


// const PurchasePreview = props => {
//     // const toasts = useToasts()
//
//     const checkOutFormik = useFormik({
//         initialValues: formikValues,
//         // validate     : values => handleShopDetailsValidation(values),
//         // onSubmit     : values => {
//         //   // alert(JSON.stringify(values, null, 2));
//         // },
//     });
//
//     const {
//         register,
//         handleSubmit,
//         setValue,
//         getValues,
//         formState: {errors}
//     } = useForm()
//
//
//     return <PurchasePreviewClass
//         checkOutFormik={checkOutFormik}
//         register={register}
//         getValues={getValues}
//         {...props}
//         {...props}
//     />
// }


export const PurchasePreviewSectionIndex = {
  meal_count_selection: "meal_count_selection",
  not_logged_in: "not_logged_in",
  payment_information: "payment_information"
}

export const PurchasePreviewComponentIndex = {
  meal_count_selection: {},
  not_logged_in: {
    create_account: "create_account",
    forgot_password: "forgot_password",
  },
  payment_information: {
    card_selection: "card_selection"
  }
}


const PurchasePreview = () => {

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [forceRefresh, setForceRefresh] = useState(1)
  const [formErrors, setFormErrors] = useState<PurchasePreviewFormValuesErrors>({})
  const [view, setView] = useState(PurchasePreviewViews.MEAL_COUNT_SELECTION)
  const [openModal, setOpenModal] = useState(false)
  const [modalView, setModalView] = useState<PurchasePreviewModalViews>(PurchasePreviewModalViews.NULL)
  const [page, setPage] = useState(1)
  const [displayName, setDisplayName] = useState("")
  const [email, setEmail] = useState("")
  const [notify, setNotify] = useState(false)
  const [mealCount, setMealCount] = useState(20)
  const [invalidMealCount, setInvalidMealCount] = useState(false)
  const [invalidDisplayName, setInvalidDisplayName] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [autoRepeatPurchase, setAutoRepeatPurchase] = useState(false)
  const [subscription, setSubscription] = useState(-1)
  const [postMessage, setPostMessage] = useState("")
  const [proceedAsGuest, setProceedAsGuest] = useState(false)

  //UserCardState
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodsEnum>(PaymentMethodsEnum.CARD)
  const [savedCards, setSavedCards] = useState([])
  const [selectedCard, setSelectedCard] = useState("")
  const [addCard, setAddCard] = useState(false)
  const [editCard, setEditCard] = useState(false)
  const [deleteCard, setDeleteCard] = useState(false)
  const [cardToDelete, setCardToDelete] = useState("")


  const {
          0: GUEST_PURCHASE_MEALS_LOADING,
          1: GUEST_PURCHASE_MEALS_SUCCESS,
          2: GUEST_PURCHASE_MEALS_ERROR,
          3: GUEST_PURCHASE_MEALS_EVENT_CODE,
          4: GUEST_PURCHASE_MEALS_MESSAGE,
          5: GUEST_PURCHASE_MEALS_PAYLOAD
        } = GetApiFlightStatus<any>(GUEST_PURCHASE_MEALS);

  const {
          0: GET_PURCHASE_PREVIEW_LOADING,
          1: GET_PURCHASE_PREVIEW_SUCCESS,
          2: GET_PURCHASE_PREVIEW_ERROR,
          3: GET_PURCHASE_PREVIEW_EVENT_CODE,
          4: GET_PURCHASE_PREVIEW_MESSAGE,
          5: GET_PURCHASE_PREVIEW_PAYLOAD
        } = GetApiFlightStatus<any>(GET_PURCHASE_PREVIEW);

  const {
          0: GET_STRIPE_CLIENT_SECRET_LOADING,
          1: GET_STRIPE_CLIENT_SECRET_SUCCESS,
          2: GET_STRIPE_CLIENT_SECRET_ERROR,
          3: GET_STRIPE_CLIENT_SECRET_EVENT_CODE,
          4: GET_STRIPE_CLIENT_SECRET_MESSAGE,
          5: GET_STRIPE_CLIENT_SECRET_PAYLOAD
        } = GetApiFlightStatus<GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD>(GET_STRIPE_CLIENT_SECRET);


  const {
          0: EXCHANGE_FOR_STRIPE_TOKEN_LOADING,
          1: EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS,
          2: EXCHANGE_FOR_STRIPE_TOKEN_ERROR,
          3: EXCHANGE_FOR_STRIPE_TOKEN_EVENT_CODE,
          4: EXCHANGE_FOR_STRIPE_TOKEN_MESSAGE,
          5: EXCHANGE_FOR_STRIPE_TOKEN_PAYLOAD
        } = GetApiFlightStatus<any>(EXCHANGE_FOR_STRIPE_TOKEN);

  const {
          0: GET_CARDS_LOADING,
          1: GET_CARDS_SUCCESS,
          2: GET_CARDS_ERROR,
          3: GET_CARDS_EVENT_CODE,
          4: GET_CARDS_MESSAGE,
          5: GET_CARDS_PAYLOAD
        } = GetApiFlightStatus<any>(GET_CARDS);


  const {
          0: ADD_CARD_LOADING,
          1: ADD_CARD_SUCCESS,
          2: ADD_CARD_ERROR,
          3: ADD_CARD_EVENT_CODE,
          4: ADD_CARD_MESSAGE,
          5: ADD_CARD_PAYLOAD
        } = GetApiFlightStatus<any>(ADD_CARD);

  const {
          0: DELETE_CARD_LOADING,
          1: DELETE_CARD_SUCCESS,
          2: DELETE_CARD_ERROR,
          3: DELETE_CARD_EVENT_CODE,
          4: DELETE_CARD_MESSAGE,
          5: DELETE_CARD_PAYLOAD
        } = GetApiFlightStatus<any>(DELETE_CARD);

  const {
          0: USER_PURCHASE_MEALS_LOADING,
          1: USER_PURCHASE_MEALS_SUCCESS,
          2: USER_PURCHASE_MEALS_ERROR,
          3: USER_PURCHASE_MEALS_EVENT_CODE,
          4: USER_PURCHASE_MEALS_MESSAGE,
          5: USER_PURCHASE_MEALS_PAYLOAD
        } = GetApiFlightStatus<any>(USER_PURCHASE_MEALS);

  const {
          0: AUTHENTICATE_ACCOUNT_LOADING,
          1: AUTHENTICATE_ACCOUNT_SUCCESS,
          2: AUTHENTICATE_ACCOUNT_ERROR,
          3: AUTHENTICATE_ACCOUNT_EVENT_CODE,
          4: AUTHENTICATE_ACCOUNT_MESSAGE,
          5: AUTHENTICATE_ACCOUNT_PAYLOAD
        } = GetApiFlightStatus<any>(AUTHENTICATE_ACCOUNT);


  const {
          0: CAPTURE_CLIENT_INTENT_LOADING,
          1: CAPTURE_CLIENT_INTENT_SUCCESS,
          2: CAPTURE_CLIENT_INTENT_ERROR,
          3: CAPTURE_CLIENT_INTENT_EVENT_CODE,
          4: CAPTURE_CLIENT_INTENT_MESSAGE,
          5: CAPTURE_CLIENT_INTENT_PAYLOAD
        } = GetApiFlightStatus<any>(CAPTURE_CLIENT_INTENT);

  const {
          0: MARK_MEALS_AS_PAID_LOADING,
          1: MARK_MEALS_AS_PAID_SUCCESS,
          2: MARK_MEALS_AS_PAID_ERROR,
          3: MARK_MEALS_AS_PAID_EVENT_CODE,
          4: MARK_MEALS_AS_PAID_MESSAGE,
          5: MARK_MEALS_AS_PAID_PAYLOAD
        } = GetApiFlightStatus<any>(MARK_MEALS_AS_PAID);

  const {
          0: REQUEST_PASSWORD_RESET_LOADING,
          1: REQUEST_PASSWORD_RESET_SUCCESS,
          2: REQUEST_PASSWORD_RESET_ERROR,
          3: REQUEST_PASSWORD_RESET_EVENT_CODE,
          4: REQUEST_PASSWORD_RESET_MESSAGE,
          5: REQUEST_PASSWORD_RESET_PAYLOAD,
        } = GetApiFlightStatus<any>(REQUEST_PASSWORD_RESET);

  const {
          0: CREATE_USER_ACCOUNT_LOADING,
          1: CREATE_USER_ACCOUNT_SUCCESS,
          2: CREATE_USER_ACCOUNT_ERROR,
          3: CREATE_USER_ACCOUNT_EVENT_CODE,
          4: CREATE_USER_ACCOUNT_MESSAGE,
          5: CREATE_USER_ACCOUNT_PAYLOAD,
        } = GetApiFlightStatus<any>(CREATE_USER_ACCOUNT);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const navigate = useNavigate();
  const location = useLocation();
  let {stall_id} = useParams()

  if (!stall_id) {
    stall_id = ""
  }

  const split = stall_id.split("&")

  stall_id = split[0]
  const initiative_id = split[1]

  const profile = useTypedSelector<ProfileReducer>(state => state.profile)
  const purchaseMeals = useTypedSelector<PurchaseMealsReducer>(state => state.purchaseMeals)
  const purchaseInformation = useTypedSelector<PurchaseInformationReducer>(state => state.purchaseInformation)

  const {
          register,
          handleSubmit,
          setValue,
          getValues,
          formState: {errors}
        } = useForm<PurchasePreviewFormValues>()


  const {
          resetState,
          clearPurchaseProps,
          getPurchasePreview,
          savePurchaseCountAndTypeAction,
          savePurchaseUserInformation,
          USER_V2_ADD_CARD_ACTION,
          USER_V2_GET_PURCHASE_PREVIEW_ACTION,
          USER_V2_GET_CARDS_ACTION,
          USER_V2_DELETE_CARD_ACTION,
          USER_V2_USER_PURCHASE_MEALS_ACTION,
          exchangeForStripeTokenAction,
          USER_V2_AUTHENTICATE_ACCOUNT_ACTION,
          USER_V2_GET_STRIPE_CLIENT_SECRET_ACTION,
          USER_V2_CAPTURE_CLIENT_INTENT_ACTION,
          USER_V2_MARK_MEALS_AS_PAID_ACTION,
          USER_V2_REQUEST_PASSWORD_RESET_ACTION,
          USER_V2_CREATE_USER_ACCOUNT_ACTION,
          USER_V2_GUEST_PURCHASE_MEALS_ACTION
        } = useActions()


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {

    // let data: any = localStorage.getItem("PURCHASE_PREVIEW_PERSIST_KEY");
    // if (data) {
    //   // Parse it to a javaScript object
    //   try {
    //     const dataParsed = JSON.parse(data);
    //
    //     setValue("display_name", dataParsed.display_name)
    //     setValue("post_message", dataParsed.post_message)
    //     setValue("email", dataParsed.email)
    //     setValue("meal_count", dataParsed.meal_count)
    //     setValue("subscription", dataParsed.subscription)
    //     setValue("auto_repeat_purchase", dataParsed.auto_repeat_purchase)
    //
    //
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }


    const query = new URLSearchParams(location.search);
    const v = query.get("v")
    const setup_intent = query.get("setup_intent")

    clearPurchaseProps();
    USER_V2_GET_PURCHASE_PREVIEW_ACTION({stall_id, initiative_id, count: mealCount})
    // getPurchasePreview(stall_id, initiative_id, 10);
    //Setup intent is not equals to null, add card payment status page

    if (v === "pi" && setup_intent !== null) {


      //Let the server know that the card is successful
      USER_V2_CAPTURE_CLIENT_INTENT_ACTION({
        setup_intent: setup_intent
      })

      setAddCard(false)
      // setOpenModal(true)
      // setModalView(PurchasePreviewModalViews.PENDING_PAYMENT_AUTHENTICATION)
      setView(PurchasePreviewViews.PAYMENT_INFORMATION)


      // view is card and setup intent is null, just load the card page,
      // do not need to load the add card payment status page
    } else if (v === "c" && setup_intent === null) {
      setAddCard(false)
      setOpenModal(false)
      setView(PurchasePreviewViews.PAYMENT_INFORMATION)
    }


    if (profile.jwt !== null) {
      setValue("email", profile.email)
      // setEmail(profile.email)
      USER_V2_GET_CARDS_ACTION({})
    }

    if (purchaseInformation.meal_count === 0) {
      setView(PurchasePreviewViews.MEAL_COUNT_SELECTION)
    }
  }, [])


  useEffect(() => {
    if (DELETE_CARD_SUCCESS) {
      toast.success(`Deleted`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      setEditCard(false)
      setCardToDelete("")
      USER_V2_GET_CARDS_ACTION({})
      handleCloseDeleteCardModal()
    }

  }, [DELETE_CARD_SUCCESS])


  useEffect(() => {
    if (GET_CARDS_SUCCESS) {
      let cards = GET_CARDS_PAYLOAD === null ? [] : JSON.parse(JSON.stringify(GET_CARDS_PAYLOAD))
      cards.push({
        _id: "ADD"
      })

      setSavedCards(cards)
    }

  }, [GET_CARDS_SUCCESS])

  useEffect(() => {
    // const query = new URLSearchParams(location.search);
    // const redirect_status = query.get("redirect_status")
    if (CAPTURE_CLIENT_INTENT_SUCCESS === true) {
      // removeUrlParameter("setup_intent_client_secret")
      // removeUrlParameter("setup_intent")
      setOpenModal(false)
      USER_V2_GET_CARDS_ACTION()
      // // @ts-ignore
      // const {stall_id, initiative_id} = params;
      navigate(`/buy-meals/${stall_id}&${initiative_id}?v=pi`)

    }
  }, [CAPTURE_CLIENT_INTENT_SUCCESS])


  useEffect(() => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_ERROR) {
      toast.error(EXCHANGE_FOR_STRIPE_TOKEN_MESSAGE, {
        duration: 10000,
        position: 'top-center',
        iconTheme: {primary: '#ab2939', secondary: '#fff'}
      })
    }
  }, [EXCHANGE_FOR_STRIPE_TOKEN_ERROR])

  useEffect(() => {
    if (EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS) {
      const {name_on_card, card_id, cc_no, cc_exp, cc_cvv} = getValues()

      const params = {
        display_name: getValues().display_name,
        email: getValues().email,
        initiative_id: initiative_id,
        meal_count: getValues().meal_count,
        stall_id: stall_id,
        stripe_token: EXCHANGE_FOR_STRIPE_TOKEN_PAYLOAD.data.id,
        send_email: true,
        auto_repeat_purchase: autoRepeatPurchase,
        subscription: subscription,
        post_message: getValues().post_message,
      }

      USER_V2_GUEST_PURCHASE_MEALS_ACTION(params)
      resetState(EXCHANGE_FOR_STRIPE_TOKEN)


      // USER_V2_ADD_CARD_ACTION({
      //   _id: card_id,
      //   name_on_card,
      //   stripe_token: EXCHANGE_FOR_STRIPE_TOKEN_PAYLOAD.id
      // })

    }
  }, [EXCHANGE_FOR_STRIPE_TOKEN_SUCCESS])

  useEffect(() => {
    if (ADD_CARD_SUCCESS) {

      window.scrollTo(0, 0);
      const {name_on_card, card_id, cc_no, cc_exp, cc_cvv} = getValues()

      USER_V2_GET_CARDS_ACTION({})
      setAddCard(false)
      setSelectedCard("")


    }
  }, [ADD_CARD_SUCCESS])


  useEffect(() => {
    if (GUEST_PURCHASE_MEALS_ERROR) {
      toast.error(GUEST_PURCHASE_MEALS_MESSAGE, {
        duration: 10000,
        position: 'top-center',
        iconTheme: {primary: '#ab2939', secondary: '#fff'}
      })
      clearPurchaseProps();
      resetState(GUEST_PURCHASE_MEALS)
    }
  }, [GUEST_PURCHASE_MEALS_ERROR])


  useEffect(() => {
    if (USER_PURCHASE_MEALS_ERROR) {

      if (USER_PURCHASE_MEALS_EVENT_CODE === "U89M390") {
        handleStripeAuth().then(() => {
        })

        return
      }

      toast.error(USER_PURCHASE_MEALS_MESSAGE, {
        duration: 10000,
        position: 'top-center',
        iconTheme: {primary: '#ab2939', secondary: '#fff'}
      })
      // clearPurchaseProps();
      // resetState(USER_PURCHASE_MEALS)
    }
  }, [USER_PURCHASE_MEALS_ERROR])

  useEffect(() => {
    if (GUEST_PURCHASE_MEALS_SUCCESS === true) {
      const purchase_id = GUEST_PURCHASE_MEALS_PAYLOAD.purchase_id
      //clear props here, if not when user returns, it will bring user to the success page
      localStorage.removeItem("PURCHASE_PREVIEW_PERSIST_KEY");
      clearPurchaseProps();
      resetState(GUEST_PURCHASE_MEALS)
      navigate(`/success/${purchase_id}&${getValues().email}`)
    }

  }, [GUEST_PURCHASE_MEALS_SUCCESS])

  useEffect(() => {
    if (USER_PURCHASE_MEALS_SUCCESS === true) {
      const purchase_id = USER_PURCHASE_MEALS_PAYLOAD.purchase_id
      //clear props here, if not when user returns, it will bring user to the success page
      localStorage.removeItem("PURCHASE_PREVIEW_PERSIST_KEY");
      clearPurchaseProps();
      resetState(USER_PURCHASE_MEALS)
      navigate(`/success/${purchase_id}&${getValues().email}`)
    }
  }, [USER_PURCHASE_MEALS_SUCCESS])

  useEffect(() => {
    if (MARK_MEALS_AS_PAID_SUCCESS === true) {
      const purchase_id = USER_PURCHASE_MEALS_PAYLOAD.purchase_id
      //clear props here, if not when user returns, it will bring user to the success page
      localStorage.removeItem("PURCHASE_PREVIEW_PERSIST_KEY");
      clearPurchaseProps();
      resetState(MARK_MEALS_AS_PAID)
      setModalView(PurchasePreviewModalViews.NULL)
      navigate(`/success/${purchase_id}&${getValues().email}`)
    }

  }, [MARK_MEALS_AS_PAID_SUCCESS])

  useEffect(() => {
    if (REQUEST_PASSWORD_RESET_SUCCESS) {
      toast.success(`Reset link sent! `, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })
      setView(PurchasePreviewViews.PURCHASE_INFORMATION)
    }
  }, [REQUEST_PASSWORD_RESET_SUCCESS])

  useEffect(() => {
    if (CREATE_USER_ACCOUNT_SUCCESS) {

      toast.success(`Account successfully created!`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      USER_V2_GET_CARDS_ACTION({})
      setView(PurchasePreviewViews.PURCHASE_INFORMATION)
      resetState(CREATE_USER_ACCOUNT)
    }
  }, [CREATE_USER_ACCOUNT_SUCCESS])


  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_SUCCESS) {

      toast.success(`Logged in!`, {
        duration: 4000,
        position: 'top-center',
        iconTheme: {primary: '#74B8AE', secondary: '#fff',}
      })

      USER_V2_GET_CARDS_ACTION({})
      setView(PurchasePreviewViews.PURCHASE_INFORMATION)
      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [AUTHENTICATE_ACCOUNT_SUCCESS])

  useEffect(() => {
    if (AUTHENTICATE_ACCOUNT_ERROR) {
      toast.error(AUTHENTICATE_ACCOUNT_MESSAGE)
      resetState(AUTHENTICATE_ACCOUNT)
    }
  }, [AUTHENTICATE_ACCOUNT_ERROR])


  // componentDidMount in this controller reads form data from the storage and loads into react-hook-form
  // usePersistForm persist data into the storage
  // We have to put usePersistForm after componentDidMount so that it does not persist empty data


  usePersistForm({values: getValues(), localStorageKey: "PURCHASE_PREVIEW_PERSIST_KEY"});

  //
  // if (prevProps.purchaseMeals.case !== purchaseMeals.case) {
  //     if (purchaseMeals.case === FAILED_TO_CHARGE) {
  //         addToast("Failed to charge your credit card. No charges were made", {
  //             appearance: "error",
  //             autoDismiss: true,
  //             transitionDuration: 500
  //         })
  //         clearPurchaseProps()
  //     }
  // }
  //
  // if (prevProps.purchaseMeals.case !== purchaseMeals.case) {
  //     if (purchaseMeals.case === FAILED_TO_EXCHANGE_FOR_STRIPE_TOKEN) {
  //         addToast("An error occurred. Please check the details of your credit card. No charges are made", {
  //             appearance: "error",
  //             autoDismiss: true,
  //             transitionDuration: 500
  //         })
  //         clearPurchaseProps()
  //     }
  // }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// CONTROLLERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const handleScrollToTop = () => {
    window.scrollTo(0, 0)
  }


  //duplicated in cart.tsx
  const handleStripeAuth = async () => {

    setOpenModal(true)
    // setModalView(PurchasePreviewModalViews.PENDING_PAYMENT_AUTHENTICATION)
    setModalView(PurchasePreviewModalViews.PURCHASING_MEALS)
    const stripe = await loadStripe(keys.STRIPE_PUBLISHABLE_KEY);

    // @ts-ignore
    stripe
      //@ts-ignore
      .confirmCardPayment(USER_PURCHASE_MEALS_PAYLOAD.payment_intent_client_secret)
      .then((result) => {

        if (result.error?.message) {
          toast.error(result.error.message, {
            duration: 10000,
            position: 'top-center',
            iconTheme: {primary: '#ab2939', secondary: '#fff',},
          })

          setOpenModal(false)
          setModalView(PurchasePreviewModalViews.NULL)
          return
        }

        // @ts-ignore
        if (result.paymentIntent.status === "succeeded") {
          setModalView(PurchasePreviewModalViews.PURCHASING_MEALS)

          USER_V2_MARK_MEALS_AS_PAID_ACTION({
            purchase_id: USER_PURCHASE_MEALS_PAYLOAD.purchase_id
          })

        }
      });
  }


  const handleNavigateBackToMealCountSelection = () => {
    setView(PurchasePreviewViews.MEAL_COUNT_SELECTION)
  }

  const handleNavigateBackToPurchaseInfo = () => {
    setView(PurchasePreviewViews.PURCHASE_INFORMATION)
  }

  const handleProceedAsGuest = () => {
    setProceedAsGuest(true)
  }

  const handleViewForgotPassword = () => {
    setView(PurchasePreviewViews.FORGOT_PASSWORD)
  }

  const handleViewCreateAccount = () => {
    setView(PurchasePreviewViews.CREATE_ACCOUNT)
  }

  const handleUpdateViewToSignIn = () => {
    setView(PurchasePreviewViews.PURCHASE_INFORMATION)
  }

  const handleProceedToPurchaseInfo = () => {


    if (!mealCount || mealCount <= 0) {
      setInvalidMealCount(true)
    } else {
      setValue("meal_count", mealCount)

      savePurchaseCountAndTypeAction({
        auto_repeat_purchase: autoRepeatPurchase,
        meal_count: mealCount,
        subscription: subscription
      })

      setView(PurchasePreviewViews.PURCHASE_INFORMATION)
    }
    handleScrollToTop()
  };

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleValidateAndProceedToPaymentInfo = () => {

    let errors: PurchasePreviewFormValuesErrors = {}
    if (!getValues().display_name) {
      errors.display_name = {...errors.display_name, type: "", message: 'Display name required'}
    }

    if (!getValues().email) {
      errors.email = {...errors.email, type: "", message: 'Email required'}
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(getValues().email)
    ) {
      errors.email = {...errors.email, type: "", message: 'Invalid email address'}
    }

    if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
      setFormErrors(errors)
      return
    }

    savePurchaseUserInformation({
      display_name: getValues().display_name,
      email: getValues().email,
      post_message: getValues().post_message
    })

    // if (!values.password) {
    //   errors.password = {...errors.password, type: "", message: 'Password required'}
    // } else if (values.password.toString().length <= 6) {
    //   errors.password = {...errors.password, type: "", message: 'Invalid password'}
    // }


    // let invalidDisplayName = false
    // let invalidEmail = false
    //
    // if (displayName.trim() === "") {
    //     invalidDisplayName = true
    //
    //     setInvalidDisplayName(true)
    //     handlePurchaseMeals()
    // }
    //
    // if (email.trim() === "" || !email.includes("@")) {
    //     invalidEmail = true
    //     setInvalidEmail(true)
    //     handleScrollToTop()
    // }
    //
    // if (invalidDisplayName || invalidEmail) {
    //     return
    // }


    setView(PurchasePreviewViews.PAYMENT_INFORMATION)

  }

  const handleCloseDeleteCardModal = () => {
    setModalView(PurchasePreviewModalViews.NULL)
    setCardToDelete("")
  }

  const handleAuthenticateAccount = () => {
    const {email, password} = getValues()

    USER_V2_AUTHENTICATE_ACCOUNT_ACTION({
      email: email,
      password: password
    })
  }

  const handleRequestPasswordReset = () => {

    // console.log("reset password for : ",  formik.values.email)

    USER_V2_REQUEST_PASSWORD_RESET_ACTION({
      email: getValues().email,
    })
  }

  const handleUpdateFormikValue = (field: string, value: any) => {
    // @ts-ignore
    setValue(field, value)
  }

  const handleSelectCard = (card_id: string) => {
    let cards = JSON.parse(JSON.stringify(savedCards));

    for (let i = 0; i < cards.length; i++) {
      cards[i].selected = false
    }

    for (let i = 0; i < cards.length; i++) {
      if (cards[i]._id === card_id) {
        cards[i].selected = true
      }
    }

    setSavedCards(cards)
    setAddCard(false)
    setSelectedCard(card_id)

  }

  const handleSelectPaymentMethod = (paymentMethod: PaymentMethodsEnum) => {

    setPaymentMethod(paymentMethod)

    if (paymentMethod === PaymentMethodsEnum.CARD) {
      USER_V2_GET_CARDS_ACTION({})
    }
  }

  const handleToggleEditCards = () => {
    setEditCard(!editCard)
  }

  const handleDeleteCard = () => {
    USER_V2_DELETE_CARD_ACTION({_id: cardToDelete})
  }

  const handleDeleteCardPrompt = (card_id: string) => {
    // setOpenModal(true)
    setModalView(PurchasePreviewModalViews.DELETE_CARD)
    setCardToDelete(card_id)
  }


  const handleSelectAddCard = () => {
    USER_V2_GET_STRIPE_CLIENT_SECRET_ACTION({})
    setModalView(PurchasePreviewModalViews.ADD_CARD_FORM)

    // let cards = JSON.parse(JSON.stringify(savedCards));
    //
    // setValue("card_id", new ObjectID().toString())
    //
    // for (let i = 0; i < cards.length; i++) {
    //     cards[i].selected = false
    // }
    //
    // setSavedCards(cards)
    // setAddCard(true)
    setSelectedCard("")
  }

  const handleValidation = () => {

    let errors: PurchasePreviewFormValuesErrors = {}

    if (!getValues().display_name) {
      errors.name_on_card = {...errors.display_name, type: "", message: 'Name on card required'}
    }

    if (!getValues().cc_no) {
      errors.name_on_card = {...errors.display_name, type: "", message: 'Credit card number required'}
    }

    if (!getValues().cc_exp) {
      errors.name_on_card = {...errors.display_name, type: "", message: 'Expiry required'}
    }

    if (!getValues().cc_cvv) {
      errors.name_on_card = {...errors.display_name, type: "", message: 'CVV required'}
    }


    if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
      setFormErrors(errors)
      return
    }

    handlePurchaseMeals()
  };

  const handleCancelAddCard = () => {
    setModalView(PurchasePreviewModalViews.NULL)
    setAddCard(false)
  }


  const handleUpdateRepeat = (status: boolean) => {
    setValue("auto_repeat_purchase", status)
    setAutoRepeatPurchase(status)
  }

  const handleSubscription = (status: number) => {
    setValue("subscription", status)
    setSubscription(status)
  }


  // const handleDisplayNameInput = (e: any) => {
  //     if (e.target.value.length < 41) {
  //         setDisplayName(e.target.value)
  //     }
  //     setInvalidDisplayName(false)
  // };
  //
  // const handlePostMessageInput = (e: any) => {
  //     setPostMessage(e.target.value)
  // };
  //
  //
  // const handleEmailInput = (e: any) => {
  //     setEmail(e.target.value)
  //     setInvalidEmail(false)
  // };

  // const handleExpMMInput = e => {
  //     if (e.target.value < 13) {
  //         setState({exp_mm: e.target.value});
  //     }
  //
  //     setState({invalidExpMM: false})
  //
  // };
  //
  // const handleExpYYYYInput = e => {
  //
  //     if (e.target.value < 2050) {
  //         setState({exp_yyyy: e.target.value});
  //     }
  //
  //
  //     setState({invalidExpYYYY: false})
  //
  // };
  //
  // const handleCCinput = e => {
  //     if (e.target.value.length < 24) {
  //         setState({creditCard: e.target.value});
  //     }
  // };
  //
  // const handleOnCCFieldKeyDown = e => {
  //
  //     if (e.keyCode === 8) {
  //
  //         setState({invalidCC: false})
  //
  //     } else if (
  //         e.target.value.length === 4 ||
  //         e.target.value.length === 9 ||
  //         e.target.value.length === 14 ||
  //         e.target.value.length === 19 ||
  //         e.target.value.length === 24
  //     ) {
  //         setState((state, props) => ({
  //             creditCard: state.creditCard + " ",
  //             invalidCC: false
  //         }));
  //     }
  // };
  //
  // const handleCCVinput = e => {
  //
  //     const re = /^[0-9\b]/;
  //
  //     // if value is not blank, then test the regex
  //     if (e.target.value < 1000) {
  //         if (e.target.value === "" || re.test(e.target.value)) {
  //             setState({ccv: e.target.value});
  //         }
  //     }
  //
  //     setState({invalidCCV: false})
  //
  // };

  const handleMealCountInput = (e: any) => {
    const re = /^[0-9\b]/;

    if (e.target.value < 1000) {
      if (e.target.value === "" || re.test(e.target.value)) {
        setMealCount(parseInt(e.target.value))
        setInvalidMealCount(false)
      }
    }
    if (e.target.value >= 2000) {
      if (e.target.value === "" || re.test(e.target.value)) {
        setMealCount(2000)
        setInvalidMealCount(false)
      }
    }
  };


  const handleCountInc = () => {
    if (mealCount < 10) {
      setMealCount(mealCount + 1)
      return
    }

    setMealCount(mealCount + 5)
  };

  const handleCountDec = () => {
    if (mealCount - 10 <= 0) {
      if (mealCount - 1 > 0) {
        setMealCount(mealCount - 1)
      }
      return;
    }

    setMealCount(mealCount - 5)
  };

  const handleCalculateTotal = () => {
    if (!((mealCount * GET_PURCHASE_PREVIEW_PAYLOAD.matrix.price_per_meal))) {
      return 0;
    } else {
      return (
        ((mealCount * GET_PURCHASE_PREVIEW_PAYLOAD.matrix.price_per_meal) / 100).toFixed(2)
      );
    }
  };

  const handleCreateAccount = () => {
    const body = {
      name: getValues().name,
      email: getValues().email,
      password: getValues().password
    }

    USER_V2_CREATE_USER_ACCOUNT_ACTION(body)
  }

  const handleUserPurchaseMeals = () => {
    const {
            display_name,
            post_message,
            auto_repeat_purchase,
            email,
            meal_count
          } = purchaseInformation

    const payload = {
      card_id: selectedCard,
      display_name: display_name.trim(),
      email: email.trim(),
      meal_count: meal_count,
      stall_id: stall_id,
      initiative_id: initiative_id,
      auto_repeat_purchase: auto_repeat_purchase,
      subscription: subscription === 1 ? subscription : 0,
      post_message: post_message
    }

    USER_V2_USER_PURCHASE_MEALS_ACTION(payload)
  }

  const renderPaymentIntentStatus = () => {
    return (
      <Impression impression section={"payment_information"} component={"stripe_payment_intent"}>
        <ModalContent handleCloseModal={handleCloseModal}>
          <PaymentIntentSection
            handleCloseAddCardSuccessModal={handleCloseModal}
          />
        </ModalContent>
      </Impression>


    )
  }

  const handlePurchaseMeals = () => {
    if (
      GUEST_PURCHASE_MEALS_LOADING === true ||
      EXCHANGE_FOR_STRIPE_TOKEN_LOADING === true ||
      GET_STRIPE_CLIENT_SECRET_LOADING === true ||
      USER_PURCHASE_MEALS_LOADING === true ||
      CAPTURE_CLIENT_INTENT_LOADING === true ||
      MARK_MEALS_AS_PAID_LOADING === true ||
      purchaseMeals.case === EXCHANGING_FOR_STRIPE_TOKEN
    ) {

      // console.log("returning")
      return
    }

    exchangeForStripeTokenAction(
      {
        cc_no: getValues().cc_no,
        cc_month: getValues().cc_exp.split("/")[0],
        cc_year: getValues().cc_exp.split("/")[1],
        cc_cvv: getValues().cc_cvv,
        card_id: "",
        name_on_card: "",
      }
    );

  }

  const renderCountInfo = () => {
    return (
      <Impression impression section={"meal_count_selection"} component={"meal_count_selection"}>
        <MealCountSelection
          view={view}
          views={views}
          mealCount={mealCount}
          invalidMealCount={invalidMealCount}
          subscription={subscription}
          auto_repeat_purchase={autoRepeatPurchase}
          handleMealCountInput={handleMealCountInput}
          handleCountDec={handleCountDec}
          handleCountInc={handleCountInc}
          handleCalculateTotal={handleCalculateTotal}
          handleProceedToPurchaseInfo={handleProceedToPurchaseInfo}
          purchasePreview={GET_PURCHASE_PREVIEW_PAYLOAD}
          handleSubscription={handleSubscription}
          handleUpdateRepeat={handleUpdateRepeat}
        />
      </Impression>
    );
  };


  const renderPurchaseInformation = () => {

    if (profile.jwt === null && proceedAsGuest === false) {
      return (
        <Impression impression section={"not_logged_in"} component={"sign_in_prompt"}>
          <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <SignInPrompt
              register={register as any}
              handleProceedAsGuest={handleProceedAsGuest}
              handleAuthenticateAccount={handleAuthenticateAccount}
              handleViewForgotPassword={handleViewForgotPassword}
              handleViewCreateAccount={handleViewCreateAccount}
              AUTHENTICATE_ACCOUNT_LOADING={AUTHENTICATE_ACCOUNT_LOADING}
            />
          </div>
        </Impression>
      )
    }


    return (
      <Impression impression section={"purchase_information"}>
        <PurchaseInformation
          view={view}
          views={views}
          register={register as any}
          formErrors={formErrors}
          handleValidateAndProceedToPaymentInfo={handleValidateAndProceedToPaymentInfo}
          handleNavigateBackToMealCountSelection={handleNavigateBackToMealCountSelection}
          // display_name={displayName}
          // email={email}
          // post_message={postMessage}
          // invalidEmail={invalidEmail}
          // invalidDisplayName={invalidDisplayName}
          // profile={profile}
          // handleDisplayNameInput={handleDisplayNameInput}
          // handlePostMessageInput={handlePostMessageInput}
          // handleEmailInput={handleEmailInput}
        />
      </Impression>
    )
  }


  const renderPaymentInformation = () => {

    if (!initiative_id) {
      return
    }

    if (!stall_id) {
      return
    }

    if (profile.jwt !== null) {
      return (
        <Impression impression section={"payment_information"} component={"card_selection"}>
          <CardSelection
            stall_id={stall_id}
            initiative_id={initiative_id}
            view={view}
            views={views}
            add_card={addCard}
            edit_cards={editCard}
            selected_card={selectedCard}
            handleCancelAddCard={handleCancelAddCard}
            handleUpdateFormikValue={handleUpdateFormikValue}
            handleSelectCard={handleSelectCard}
            handleToggleEditCards={handleToggleEditCards}
            handleSelectAddCard={handleSelectAddCard}
            // handleAddCard={handleAddCard}
            handleSelectPaymentMethod={handleSelectPaymentMethod}
            handleDeleteCardPrompt={handleDeleteCardPrompt}
            GET_STRIPE_CLIENT_SECRET_LOADING={GET_STRIPE_CLIENT_SECRET_LOADING}
            GET_STRIPE_CLIENT_SECRET_PAYLOAD={GET_STRIPE_CLIENT_SECRET_PAYLOAD}
            EXCHANGE_FOR_STRIPE_TOKEN_LOADING={EXCHANGE_FOR_STRIPE_TOKEN_LOADING}
            GET_CARDS_LOADING={GET_CARDS_LOADING}
            ADD_CARD_LOADING={ADD_CARD_LOADING}
            USER_PURCHASE_MEALS_LOADING={USER_PURCHASE_MEALS_LOADING}
            handleUserPurchaseMeals={handleUserPurchaseMeals}
            paymentMethod={paymentMethod}
            saved_cards={savedCards}
            // paymentMethod={state.paymentMethod}
            // checkOutFormik={checkOutFormik}
            // add_card={state.add_card}
            // edit_cards={state.edit_cards}
            // selected_card={state.selected_card}
            // EXCHANGE_FOR_STRIPE_TOKEN_LOADING={EXCHANGE_FOR_STRIPE_TOKEN_LOADING}
          />
        </Impression>
      )
    }


    let loading = false
    if (EXCHANGE_FOR_STRIPE_TOKEN_LOADING === true || GUEST_PURCHASE_MEALS_LOADING === true) {
      loading = true
    }

    return (
      //Impression logged inside component
      <PaymentInformation
        view={view}
        views={views}
        formErrors
        loading={loading}
        handleValidation={handleValidation}
        handleNavigateBackToPurchaseInfo={handleNavigateBackToPurchaseInfo}
        handleProceedToPurchaseInfo={handleProceedToPurchaseInfo}
        register={register as any}
        // creditCard={state.creditCard}
        // exp_mm={state.exp_mm}
        // exp_yyyy={state.exp_yyyy}
        // ccv={state.ccv}
        // invalidExpYYYY={state.invalidExpYYYY}
        // invalidExpMM={state.invalidExpMM}
        // invalidCCV={state.invalidCCV}
        // handleCCinput={handleCCinput}
        // handleOnCCFieldKeyDown={handleOnCCFieldKeyDown}
        // handleCCVinput={handleCCVinput}
        // handleExpMMInput={handleExpMMInput}
        // handleExpYYYYInput={handleExpYYYYInput}
      />
    )
  }

  const renderForgotPasswordForm = () => {
    return (
      <Impression impression section={"not_logged_in"} component={"forgot_password"}>
        <ForgotPasswordForm
          headingTextSize={""}
          register={register as any}
          handleUpdateViewToSignIn={handleProceedToPurchaseInfo}
          // handleCreateAccount={handleCreateAccount}
          handleRequestPasswordReset={handleRequestPasswordReset}
          REQUEST_PASSWORD_RESET_LOADING={REQUEST_PASSWORD_RESET_LOADING}
        />
      </Impression>
    )
  }

  const renderCreateAccountForm = () => {
    return (
      <Impression impression section={"not_logged_in"} component={"create_account"}>
        <CreateAccount
          register={register as any}
          handleUpdateViewToSignIn={handleUpdateViewToSignIn}
          handleCreateAccount={handleCreateAccount}
          CREATE_USER_ACCOUNT_LOADING={CREATE_USER_ACCOUNT_LOADING}
        />
      </Impression>
    )
  }


  const renderPurchaseForm = () => {

    // // purchaseMeals.case === PURCHASE_MEALS_SUCCESS
    // if (GUEST_PURCHASE_MEALS_SUCCESS || USER_PURCHASE_MEALS_SUCCESS) {
    //     return (
    //         <div className={styles.buyMealsContainer}>
    //             {renderSuccess()}
    //         </div>
    //     );
    // }

    if (GET_PURCHASE_PREVIEW_SUCCESS) {
      if (view === PurchasePreviewViews.MEAL_COUNT_SELECTION) {
        return (
          <div>
            {renderCountInfo()}
          </div>
        );
      } else if (view === PurchasePreviewViews.PURCHASE_INFORMATION) {
        return (
          <div>
            {renderPurchaseInformation()}
          </div>
        )
      } else if (view === PurchasePreviewViews.PAYMENT_INFORMATION) {
        return (
          <div>
            {renderPaymentInformation()}
          </div>
        )
      } else if (view === PurchasePreviewViews.FORGOT_PASSWORD) {
        return (
          <div>
            {renderForgotPasswordForm()}
          </div>
        )
      } else if (view === PurchasePreviewViews.CREATE_ACCOUNT) {
        return (
          <div>
            {renderCreateAccountForm()}
          </div>
        )
      } else {
        return (
          <Dots/>
        );
      }
    }
    ;
  }

  const renderModalContent = () => {
    // if (state.address_to_delete !== null) {
    //   return (renderDeleteAddressConfirmation())
    // }

    const query = new URLSearchParams(location.search);
    const v = query.get("v")
    const setup_intent = query.get("setup_intent")

    if (v === "pi" && setup_intent !== null) {
      setOpenModal(true)
      setModalView(PurchasePreviewModalViews.LOADING)
      return (
        <div>
          {renderPaymentIntentStatus()}
        </div>
      )
    }

    if (modalView === PurchasePreviewModalViews.PURCHASING_MEALS) {
      return (
        <ModalContent>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <LoadingDots/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </ModalContent>
      )
    }

    if (modalView === PurchasePreviewModalViews.LOADING) {
      return (
        <ModalContent>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <LoadingDots/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </ModalContent>
      )
    }

    if (modalView === PurchasePreviewModalViews.ADD_CARD_FORM) {
      return (
        <Impression impression section={"payment_information"} component={"stripe_credit_card_form"}>
          <ModalContent>
            <StripeCreditCardForm
              GET_STRIPE_CLIENT_SECRET_LOADING={GET_STRIPE_CLIENT_SECRET_LOADING}
              GET_STRIPE_CLIENT_SECRET_PAYLOAD={GET_STRIPE_CLIENT_SECRET_PAYLOAD?.client_secret}
              return_path={`/buy-meals/${stall_id}&${initiative_id}?v=pi`}
              handleCloseModal={() => {
                handleCancelAddCard()
              }}
            />

          </ModalContent>
        </Impression>

      )
    }

    if (cardToDelete !== "") {
      return renderDeleteCardConfirmation()
    }
  }


  const renderModal = () => {
    // if (openModal === true) {
    if (modalView !== PurchasePreviewModalViews.NULL) {
      return (
        <div className={styles.modalContainer}>
          {renderModalContent()}
        </div>
      )
    }
  }

  const renderDeleteCardConfirmation = () => {
    return (
      <Impression impression section={"delete_card_modal"}>
        <ModalConfirmation
          headerText={"Are you sure you want to delete the card?"}
          subHeaderText={""}
          primaryBtnText={"Delete"}
          secondaryBtnText={"Cancel"}

          primaryBtnPress={() => handleDeleteCard()}
          secondaryBtnPress={() => handleCloseDeleteCardModal()}
          primaryLoading={DELETE_CARD_LOADING}
        />
      </Impression>

      // <div className={styles.modalCard}>
      //
      //
      //
      //     <div className={styles.modalHeadingText}>
      //         Are you sure you want to delete the card?
      //     </div>
      //
      //     <div className={styles.buttonBlock}>
      //         <div className={styles.secondaryButton} onClick={() => handleCloseDeleteCardModal()}>
      //             No
      //         </div>
      //
      //         <div className={styles.primaryButton} onClick={() => handleDeleteCard()}>
      //             {renderCTAContent()}
      //         </div>
      //     </div>
      // </div>
    )
  }


  return (
    <Impression pageload page={"buy_meals"}>
      <div className={styles.purchasePreviewPage}>
        {renderModal()}

        <div className={styles.buyMealsContainer}>
          {renderPurchaseForm()}
        </div>
      </div>
    </Impression>
  );

}

export default PurchasePreview
