import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {PURCHASE_MEALS_DEFERRED_PAYMENT} from "../redux_config/types";
import { USER_V2_PURCHASE_MEALS_DEFERRED_PAYMENT} from "../redux_config/endpoints";


export const purchaseMealsDeferredPayment = (payload) => {
  return async dispatch => {

    const config = {}
    const params = {
      display_name: payload.display_name,
      meal_count: payload.meal_count,
      stall_id: payload.stall_id,
      initiative_id: payload.initiative_id,
      email: payload.email,
      partner_id: payload.partner_id,
      send_email:true
    }

    try {
      await apiFlight(PURCHASE_MEALS_DEFERRED_PAYMENT, dispatch).post(
        USER_V2_PURCHASE_MEALS_DEFERRED_PAYMENT,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
