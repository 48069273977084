import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_RECURRING_PURCHASES} from "../redux_config/types";
import { USER_V2_GET_RECURRING_PURCHASES} from "../redux_config/endpoints";


export const getUnsubscribePreview = ({purchase_id}) => {

  return async dispatch => {

    const config = {}
    const params = {
      purchase_id
    }

    try {
      await apiFlight(GET_RECURRING_PURCHASES, dispatch).post(
        USER_V2_GET_RECURRING_PURCHASES,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}



