import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_NEW_SUBSCRIPTION_COUNT} from "../redux_config/types";
import { USER_V2_GET_NEW_SUBSCRIPTION_COUNT } from "../redux_config/endpoints";


export const GET_NEW_SUBSCRIPTION_COUNT_action = (payload) => {
  
  return async dispatch => {
    
    const config = {}
    // const params = {
    //   partner_id
    // }
    
    try {
      await apiFlight(GET_NEW_SUBSCRIPTION_COUNT, dispatch).post(
        USER_V2_GET_NEW_SUBSCRIPTION_COUNT,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
