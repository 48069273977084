import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {GET_PARTICIPATING_STALLS} from "../redux_config/types";
import { USER_V2_GET_PARTICIPATING_STALLS} from "../redux_config/endpoints";


export const getParticipatingStalls = () => {
  return async dispatch => {

    const config = {}
    const params = {}

    try {
      await apiFlight(GET_PARTICIPATING_STALLS, dispatch).post(
        USER_V2_GET_PARTICIPATING_STALLS,
        params,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
}
