import {GET_STRIPE_CLIENT_SECRET} from "../redux_config/types";
import {isLoading} from "../redux_utilities/loadingStatus";
import {getJWT} from "../redux_utilities/authenticationStatus";
import apiFlight from "../redux_utilities/apiFlightInterceptor";
import { USER_V2_GET_STRIPE_CLIENT_SECRET} from "../redux_config/endpoints";
import {Dispatch} from "react";

interface USER_V2_GET_STRIPE_CLIENT_SECRET_PAYLOAD {

}

export const USER_V2_GET_STRIPE_CLIENT_SECRET_ACTION = (payload: USER_V2_GET_STRIPE_CLIENT_SECRET_PAYLOAD) => {


    return async (dispatch: Dispatch<any>) => {

        if (isLoading(GET_STRIPE_CLIENT_SECRET) === true) {
            return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
        }

        const config = {
            headers: {
                Authorization: `Bearer ${getJWT()}`
            }
        }

        try {
            await apiFlight(GET_STRIPE_CLIENT_SECRET, dispatch).post(
                USER_V2_GET_STRIPE_CLIENT_SECRET,
                payload,
                config
            )
        } catch (err) {
            console.log(err)
        }
    }
}
export interface GET_STRIPE_CLIENT_SECRET_SUCCESS_PAYLOAD {
    client_secret: string
}