import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {USER_PURCHASE_MEALS} from "../redux_config/types";
import {USER_V2_USER_PURCHASE_MEALS} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";


export const USER_V2_USER_PURCHASE_MEALS_ACTION = (payload) => {
  
  return async dispatch => {
    
    if (isLoading(USER_PURCHASE_MEALS) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(USER_PURCHASE_MEALS, dispatch).post(
        USER_V2_USER_PURCHASE_MEALS,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
