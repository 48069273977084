// PaymentStatus.jsx

import React, {useEffect, useState} from 'react';
import {Elements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import keys from "../../../00_config/keys/keys";
import styles from "./PaymentIntentStatus.module.scss";
import buttonStyles from "../../../01_application/styles/buttonStyles.module.scss"
import {Dots} from "react-activity";
import LoadingDots from "../../components_core/LoadingDots/LoadingDots";


interface PaymentIntentStatusProps {
  handleCloseAddCardSuccessModal: () => void
}

const PaymentIntentStatus = (props: PaymentIntentStatusProps) => {

  const stripe = useStripe();
  const [message, setMessage] = useState<string>("");
  const [setupIntent, setSetupIntent] = useState<any>(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (!clientSecret) {
      return
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({setupIntent}) => {
        setSetupIntent(setupIntent)

        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        // @ts-ignore
        switch (setupIntent.status) {
          case "succeeded":
            setMessage('Success! Your payment method has been saved.');
            break;

          case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            break;
        }
      });
  }, [stripe]);

  const renderButtons = () => {
    if (setupIntent.status === "succeeded") {
      return (
        <div className={buttonStyles.primaryButton} onClick={() => {
          props.handleCloseAddCardSuccessModal()
        }}>
          Great!
        </div>
      )
    }

    if (setupIntent.status === "requires_payment_method") {
      return (
        <div className={buttonStyles.buttonBlock}>
          <div className={buttonStyles.secondaryButton} onClick={() => {
          }}>
            Dismiss
          </div>

          <div className={buttonStyles.primaryButton} onClick={() => {
          }}>
            Try again
          </div>

        </div>
      )
    }

    if (setupIntent.status === "processing") {
      return (
        <div className={buttonStyles.primaryButton} onClick={() => {
        }}>
          Okay!
        </div>
      )
    }


  }


  const renderContent = () => {
    if (!message) {
      return (
        <div>
          <br/>
          <br/>
          <br/>
          <LoadingDots/>
          <br/>
          <br/>
          <br/>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          {message}

          <br/>
          <br/>
          <br/>

          {renderButtons()}

        </div>
      )
    }
  }

  return renderContent()
};

interface PaymentIntentSectionProps {
  handleCloseAddCardSuccessModal: () => void;
}

const PaymentIntentSection = (props: PaymentIntentSectionProps) => {

  const stripePromise = loadStripe(keys.STRIPE_PUBLISHABLE_KEY);

  return (
    <div className={styles.container}>
      <div className={styles.heading2Text}>
        Saving your card with Stripe:
      </div>


      {/*<Elements stripe={stripePromise} options={options}>*/}
      <Elements stripe={stripePromise}>
        <PaymentIntentStatus
          handleCloseAddCardSuccessModal={props.handleCloseAddCardSuccessModal}
        />
      </Elements>

      <br/>
      <br/>


      {/*<div className={buttonStyles.buttonBlock}>*/}
      {/*  /!*<div className={buttonStyles.secondaryButton} onClick={() => {}}>*!/*/}
      {/*  /!*  Cancel*!/*/}
      {/*  /!*</div>*!/*/}

      {/*  <div className={buttonStyles.primaryButton} onClick={() => {}}>*/}
      {/*    Great!*/}
      {/*  </div>*/}
      {/*</div>*/}


    </div>
  )
}

export default PaymentIntentSection;