import {CREATE_USER_ACCOUNT, EXCHANGE_FOR_STRIPE_TOKEN} from "../redux_config/types";
import keys from "../../00_config/keys/keys";
import qs from "qs";
import axios from "axios";
import {isLoading} from "../redux_utilities/loadingStatus";

export const exchangeForStripeTokenAction = ({card_id, name_on_card, cc_no, cc_month, cc_year, cc_cvv}) => {
  
  return async dispatch => {
    
    if (isLoading(EXCHANGE_FOR_STRIPE_TOKEN) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    dispatch({type: `${EXCHANGE_FOR_STRIPE_TOKEN}_LOADING`});

    const config = {
      headers: {
        Authorization : "Bearer " + keys.STRIPE_PUBLISHABLE_KEY,
        "Content-type": "application/x-www-form-urlencoded"
      }
    };
    
    const obj = qs.stringify({
      "card[number]"   : cc_no,
      "card[exp_month]": cc_month,
      "card[exp_year]" : cc_year,
      "card[cvc]"      : cc_cvv
    })
    
    try {
      const stripeRes = await axios.post(
        "https://api.stripe.com/v1/tokens",
        obj,
        config
      );
      
      const payload = {
        payload: stripeRes
      }
      
      if (stripeRes.data.id) {
        dispatch({
          type   : `${EXCHANGE_FOR_STRIPE_TOKEN}_SUCCESS`,
          payload: payload
        });
        
      } else {
        dispatch({
          type   : `${EXCHANGE_FOR_STRIPE_TOKEN}_ERROR`,
          payload: "failed to exchange for stripe token"
        });
        
      }
    } catch (e) {
      console.log(e)
      dispatch({
        type   : `${EXCHANGE_FOR_STRIPE_TOKEN}_ERROR`,
        payload: e.response.data.error.message
      });
    }
    
  };
};
