export const handleCalculatePercentage = (count, expected) => {
  const percentage = (count / expected) * 100;
  // console.log(percentage)

  if (percentage >= 100) {
    return 100;
  }

  return percentage;
};

