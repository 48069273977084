import {GET_PURCHASE_PREVIEW_SUCCESS, GETTING_PURCHASE_PREVIEW, RESOURCE_NOT_FOUND} from "../redux_config/types";

const INITIAL_STATE = {
    case:null,
    payload: []
}

/*
     action here grabs all actions dispatched by actions,
     we are merely switching through them in compartments for readability and maintainability
*/
export function purchase_preview_redux (state = INITIAL_STATE, action) {

    //console.log("wewewewewe", action.type)
    switch (action.type) {
        case GET_PURCHASE_PREVIEW_SUCCESS:
            return {
                case: GET_PURCHASE_PREVIEW_SUCCESS,
                payload: action.payload
            }

        case GETTING_PURCHASE_PREVIEW:
            return {
                case: GETTING_PURCHASE_PREVIEW,
                payload: {}
            }

        case RESOURCE_NOT_FOUND:
            return {
                case: RESOURCE_NOT_FOUND,
                payload: {}
            }

        default:
            return state;
    }

}
