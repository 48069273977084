import React, {PureComponent} from "react";

import styles from "./ReviewProfile.module.scss"
import {Dots} from "react-activity";
// import 'react-activity/lib/Dots/Dots.css';


class ReviewProfile extends PureComponent {

  handleEditInformation = () => {
    this.props.handleEditInformation()
  }

  handleCreateBeneficiary = () => {
    this.props.handleSubmitBeneficiaryOnboard()
  }

  renderButtonContent = () => {
    if (this.props.B_ONBOARD_LOADING === true) {
      return <Dots/>
    }

    return "Create"
  }


  renderButtons = () => {
    return (
      <div className={styles.buttonBlock}>
        <a>
          <div className={styles.editBtn} onClick={this.handleEditInformation}>
            edit
          </div>
        </a>

        <a>
          <div className={styles.createBtn} onClick={this.handleCreateBeneficiary}>
            {this.renderButtonContent()}
          </div>
        </a>
      </div>
    )
  }

  render() {

    const {
      name = "-",
      gender = 0,
      dd = "0",
      mm = "0",
      yyyy = "0000",
      contactNumber = "-",
      password = "-",
      scanQRCode = "-",
      mealEntitlement = "-",
      weeklyMealEntitlement = "-",
      validityPeriod = "-",
      notes = null

    } = this.props.profileInformation

    return (
      <div className={styles.container}>

        <div className={styles.informationBlock}>
          <div>
            Name:
          </div>

          <div>
            {name}

          </div>
        </div>

        <div className={styles.informationBlock}>
          <div>
            Gender:
          </div>

          <div>
            {gender === 1 ? "M" : "F"}
          </div>
        </div>


        <div className={styles.informationBlock}>
          <div>
            DOB:
          </div>

          <div>
            {dd}/{mm}/{yyyy}
          </div>
        </div>

        <div className={styles.informationBlock}>
          <div>
            Contact Number:
          </div>

          <div>
            {contactNumber}
          </div>
        </div>


        <div className={styles.informationBlock}>
          <div>
            Daily Meal Entitlement:
          </div>

          <div>
            {mealEntitlement} meals a day
          </div>
        </div>


        <div className={styles.informationBlock}>
          <div>
            Weekly Meal Entitlement:
          </div>

          <div>
            {weeklyMealEntitlement} meals a week
          </div>
        </div>

        <div className={styles.informationBlock}>
          <div>
            Validity:
          </div>

          <div>
            {validityPeriod}
          </div>
        </div>

        <div>
          <div className={styles.notesBlock}>
            Notes:
          </div>

          <br/>
          {/*<div className={styles.noteInformationBlock}>*/}
          {/*{notes}*/}

          <textarea
            // component="textarea"
            rows={8}
            readOnly
            value={notes}
            className={styles.noteInformationBlock}
          />
          {/*</div>*/}
        </div>

        <br/>
        <br/>
        <div className={styles.informationBlock}>
          <div>
            Initiative Password:
          </div>

          <div>
            <b>
              {password}
            </b>
          </div>
        </div>

        {this.renderButtons()}

      </div>
    )
  }
}


export default ReviewProfile
