import {MARK_MEALS_AS_PAID} from "../redux_config/types";
import {isLoading} from "../redux_utilities/loadingStatus";
import {getJWT} from "../redux_utilities/authenticationStatus";
import apiFlight from "../redux_utilities/apiFlightInterceptor";
import { USER_V2_MARK_MEALS_AS_PAID} from "../redux_config/endpoints";
import {Dispatch} from "react";

interface USER_V2_MARK_MEALS_AS_PAID_REQUEST_PAYLOAD {
    purchase_id: string
}

export const USER_V2_MARK_MEALS_AS_PAID_ACTION = (payload: USER_V2_MARK_MEALS_AS_PAID_REQUEST_PAYLOAD) => {


    return async (dispatch: Dispatch<any>) => {

        if (isLoading(MARK_MEALS_AS_PAID) === true) {
            return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
        }

        const config = {
            headers: {
                Authorization: `Bearer ${getJWT()}`
            }
        }

        try {
            await apiFlight(MARK_MEALS_AS_PAID, dispatch).post(
                USER_V2_MARK_MEALS_AS_PAID,
                payload,
                config
            )
        } catch (err) {
            console.log(err)
        }
    }
}
export interface USER_V2_MARK_MEALS_AS_PAID_SUCCESS_PAYLOAD {
}