import React from "react";
import styles from "./ButtonOutline.module.scss"
import {ButtonProps} from "../Button/ButtonFilled";


export const ButtonOutline: React.FC<ButtonProps> = ({buttonText, buttonType, onClick}) => {
    return (
        <button className={styles.buttonOutline} type={buttonType} onClick={() => onClick ? onClick() :""}>
            {buttonText}
        </button>
    )
}

