import React, {PureComponent, useState} from "react";
import {connect, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, useNavigate, withRouter} from "react-router-dom";
import {disableMobileScroll, setActiveSection, setCondensedHeader, DISPLAY_MOBILE_MENU, CLEAR_AUTHENTICATION_STATE_ACTION} from "../../../02_redux/actions";
import HeaderNavItems from "./HeaderNavItems/HeaderNavItems.jsx";
import HeaderDrawerIcon from "./HeaderDrawerIcon/HeaderDrawerIcon.jsx";
import "./Header.scss";
import ProfileMenu from "./components/ProfileMenu";
import {ReactComponent as User} from "../../../01_application/assets/icons/user3.svg";
import {useActions} from "../../../02_redux/redux_utilities/useActions";
import * as Sentry from "@sentry/react";

/**
 * Main header of the site
 */
const HeaderController = () => {


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// VARIABLES / CONST / STATE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  
  // state = {
  //   display_profile_menu: false
  // };
  
  // constructor(props) {
  //     super(props);
  //     /*
  //     Unsure why pathsArray is needed: to investigate YM
  //      */
  //     // let pathsArray = this.props.location.pathname.split("/").filter(p => p);
  //     // if (pathsArray[0]) this.props.setActiveSection(pathsArray[0]);
  // }



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// SET UP / HOOKS / ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  const {
          setCondensedHeader,
          disableMobileScroll,
          DISPLAY_MOBILE_MENU,
          setActiveSection,
          CLEAR_AUTHENTICATION_STATE_ACTION
        } = useActions()
  const profile = useSelector(store => store.profile)
  // const profile = useTypedSelector<ProfileReducer>(state => state.profile)
  const UI = useSelector(store => store.ui)
  const navigate = useNavigate()

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// EFFECTS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  if (profile.jwt !== null) {
      Sentry.setUser({
          email: profile.email
      })
  }
  
  
  // componentDidMount() {
  //   this.onScroll = e => this.handleScroll(e);
  //
  //   window.addEventListener("scroll", this.onScroll);
  //   window.dispatchEvent(new Event("scroll", {bubbles: true}));
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.onScroll);
  // }
  //
  // /**
  //  * runs on scroll to condense header
  //  * dont seem to be working?
  //  */
  // handleScroll(e) {
  //   // let latestUIProps = end(this.props.ui);
  //   // //console.log(latestUIProps.condenseHeader);
  //   //
  //   // let scrollPos = document.body.scrollTop;
  //   //
  //   // //console.log(scrollPos)
  //   // //console.log(window.innerWidth)
  //   //
  //   // if (
  //   //     (window.innerWidth <= 1600 || scrollPos > 0) &&
  //   //     !latestUIProps.condenseHeader
  //   // )
  //   //     this.props.setCondensedHeader(true);
  //   // else if (
  //   //     window.innerWidth > 1600 &&
  //   //     scrollPos <= 0 &&
  //   //     latestUIProps.condenseHeader
  //   // )
  //   //     this.props.setCondensedHeader(false);
  // }
  
  /**
   * toggle showing mobile navigation / enable mobile scrollbar
   * @param  {boolean}  show    flag to control navigation
   */
 const  handleMobileNavigation = (show) => {
    // let latestUIProps = end(this.props.ui);
    
    disableMobileScroll(show);
    DISPLAY_MOBILE_MENU(show);
    setActiveSection("");
  }
  
  const  handleToggleProfileMenu = () => {
    
    setOpenProfileMenu(!openProfileMenu)
   
   
    // this.setState(prevState => ({
    //   display_profile_menu: !prevState.display_profile_menu
    // }), () => {
    //   // console.log(this.state)
    // })
  }
  
  const  handleClearAuthenticationState = () => {
    CLEAR_AUTHENTICATION_STATE_ACTION()
  }
  
  const  handleCloseProfileMenu = () => {
    setOpenProfileMenu(false)
  }
  
  
  const  renderProfileMenu = () => {
    if (openProfileMenu === true) {
      // console.log(this.props.profile)
      return (
        <ProfileMenu
          profile={profile}
          navigate={navigate}
          handleCloseProfileMenu={handleCloseProfileMenu}
          handleClearAuthenticationState={handleClearAuthenticationState}
          handleToggleProfileMenu={handleToggleProfileMenu}
        />
      )
    }
  }
  
  const   renderLogInBlock = () => {
    // console.log(this.props.profile)
    const jwt = profile.jwt
    
    if (jwt) {
      return(
        <div className={"loginBlock"} onClick={handleToggleProfileMenu}>
            <div className={"loginSubBLock"}>
          {/*<div className={`${styles.bagBlock} ${MIStyles.rowItem} ${ACTIVE_MENU_ITEM === "102" ? activeItem : " "}`}*/}
          {/*     onClick={() => this.handleMenuItemClick("102")}>*/}
      
              <div>
              
            {/*<div className={`${ACTIVE_MENU_ITEM === "102" ? MIStyles.selectedRowText : MIStyles.unselectedRowText}`}>*/}
              <User className={"profileIcon"}/>
            {/*</div>*/}
              </div>
            
          {/*</div>*/}
            </div>
        </div>
      )
    }
    return (
      <div className={"loginBlock"}>
        <div className={"loginSubBLock"}>
          <Link to={`/sign-in`}>
          Sign in
          </Link>
        </div>
      </div>
    )
  }
  

    
    if (UI.hideHeader) return null;
    
    return (
      <header
        className={
          "header " +
          (UI.condenseHeader && " header--condensed ")
        }
      >
        {renderProfileMenu()}
        
        <HeaderDrawerIcon
          active={UI.DISPLAY_MOBILE_MENU}
          onClick={() =>
            handleMobileNavigation(!UI.DISPLAY_MOBILE_MENU)
          }
        />
        
        <Link
          to="/"
          onClick={() => handleMobileNavigation(false)}
        >
          <div className={"secondmeal-text"}>SECONDMEAL</div>
        </Link>
        
        <div
          className={`navigation_items_container ${
            UI.DISPLAY_MOBILE_MENU
              ? "navigation_items_container--drawer-active"
              : ""
          }`}
        >
          <HeaderNavItems/>
        </div>
        
        
        {renderLogInBlock()}
      
      </header>
    );
  
}



export default HeaderController
