import React from "react";
import styles from "./ForgotPasswordForm.module.scss";
import SM2 from "../../../01_application/assets/ui/Frame 1.png";
import classNames from "classnames/bind";
import {Dots} from "react-activity";
import {InputText, InputType} from "../../components_core/InputText/InputText";
import {UseFormReturn} from "react-hook-form";

var cx = classNames.bind(styles);

interface ResetPasswordFormInterface {
  register: UseFormReturn['register'];
  headingTextSize: string
  handleRequestPasswordReset: () => void
  handleUpdateViewToSignIn: () => void
  REQUEST_PASSWORD_RESET_LOADING: boolean
}

const ForgotPasswordForm = (props: ResetPasswordFormInterface) => {

  const {register,
    headingTextSize,
    handleRequestPasswordReset,
    handleUpdateViewToSignIn,
    REQUEST_PASSWORD_RESET_LOADING
          , } = props

  const handleKeyDown = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      handleRequestPasswordReset()
    }
  }

  const handleRequestPasswordResetForwarder = () => {
    handleRequestPasswordReset()
  }

  const handleUpdateViewToSignInForwarder = () => {
    handleUpdateViewToSignIn()
  }

  const renderSignInButtonContent = () => {
    if (REQUEST_PASSWORD_RESET_LOADING) {
      return <Dots/>
    }

    return "Send reset link"
  }

  //if path is in pathForTealHeaderArr, set background to teal
  const size = cx({
    headingText: true,
    heading3Text: headingTextSize === "Heading3" ? true : false,
  });

  return (
          <div className={styles.container}>

            <div>
              {/*<img src={Logo} alt="socialgood" className={styles.logoImage}/>*/}
              <img src={SM2} alt="socialgood" className={styles.logoImage}/>
              {/*<img src={SM} alt="secondmeal" className={styles.logoImage}/>*/}
            </div>

            <div className={size}>
              Forgot your password?
            </div>

            <div className={styles.subheadingText}>
              Enter your email address below and we'll send you a link to reset your password.
            </div>


            <div className={styles.formBlock}>


              <InputText
                      inputType={InputType.EMAIL}
                      fieldName={"email"}
                      fieldLabel={""}
                      fieldPlaceHolder={"email"}
                      fieldError={false}
                      register={register}
              />

              {/*<div className={styles.subheadingText}>*/}
              {/*  Your Social ID is the account you use to access services under our umbrella, namely <a href={"https://www.secondmeal.io/"}>Secondmeal</a> and  <a href={"https://www.secondmeal.io/"}>SocialGood</a>*/}
              {/*</div>*/}

            </div>


            <div className={styles.signInBlock}>
              <div className={styles.priBtn} onClick={handleRequestPasswordResetForwarder}>
                {renderSignInButtonContent()}
              </div>
            </div>

            <br/>

            <div className={styles.auxLinksBlock}>
              <a onClick={handleUpdateViewToSignInForwarder}>
                <div className={styles.createAccount}>
                  back to log in
                </div>
              </a>
            </div>


          </div>
  );
};

export default ForgotPasswordForm;
