import React, {Component} from 'react';
// import { sendLog } from './utils';
import find from "lodash/find";
import {Ping, PingTypeEnum} from "./Ping";

const Context = React.createContext('');
export const LogContext = Context;

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

class Impression extends Component {

  constructor(props) {
    super(props);

    this.logDOMElementRef = React.createRef();
    this.state = {isInViewport: false};
    this.hasImpressionAlreadyBeenLogged = false;
    this.observerCallback = this.observerCallback.bind(this);
  }


  async componentDidMount() {
    if (this.props.impression) {
      this.setupObserver();
    }

    if (this.props.pageload === true) {


      const body = {
        action: PingTypeEnum.PAGE_LOAD,
        page  : this.props.page
      }

      //delay to ensure that client_id and session_id is ready by analytics controller
      await sleep(2000);
      Ping(body).then()
    }

    // some code that calls sendLog
    if (
      this.props.impression &&
      this.state.isInViewport &&
      !this.hasImpressionAlreadyBeenLogged
    ) {

      // console.log("Pinging Impression ", this.combinedProps)

      // sendLog(this.combinedProps);
      this.hasImpressionAlreadyBeenLogged = true;
    }
  }

  componentDidUpdate(_prevProps, _prevState, _snapshot) {
    if (
      this.props.impression &&
      this.state.isInViewport &&
      !this.hasImpressionAlreadyBeenLogged
    ) {

      // console.log("Pinging Impression", this.combinedProps)
      // sendLog(this.combinedProps);
      this.hasImpressionAlreadyBeenLogged = true;
    }
  }

  observerCallback(entries) {
    const entry = entries[0];

    if (entry !== undefined && this.state.isInViewport !== entry.isIntersecting) {
      this.setState(() => ({
        isInViewport: entry.isIntersecting,
      }));
    }
  }


  setupObserver() {
    this.observer = new IntersectionObserver(this.observerCallback, {
      root      : null,
      rootMargin: '0px',
      threshold : 0,
    });

    const wrappedDOMElements = this.logDOMElementRef?.current?.childNodes;
    const firstVisibleElement = find(wrappedDOMElements, (el) => el.offsetParent !== null);

    if (firstVisibleElement) {
      this.observer.observe(firstVisibleElement);
    }
  }

  render() {
    const {children, impression, ...directProps} = this.props;
    // console.log(this.props)

    return (
      <LogContext.Consumer>
        {(consumedProps) => {
          this.combinedProps = {...consumedProps, ...directProps};

          return (
            <Context.Provider value={this.combinedProps}>
              <div style={{display: 'contents'}} ref={this.logDOMElementRef}>
                {children}
              </div>
            </Context.Provider>
          );
        }}
      </LogContext.Consumer>
    );
  }
}

export default Impression;
