import {
    GETTING_CLAIMS_FROM_PURCHASE,
    GET_CLAIMS_FROM_PURCHASE_SUCCESS,
    GET_CLAIMS_FROM_PURCHASE_ERROR
} from "../redux_config/types";

const INITIAL_STATE = {
    case: null,
    payload: null
};

export interface ClaimsFromPurchaseReducer {
    case: string
    payload: {
        _id: string
        amount: number
        claims: Array<any>
        created_at: number
        day: number
        display_name: string
        month: number
        purchased: number
        suspended_count: number
        time: string
        user_id: string
        year: number
        parent_repeat_purchase: string
        subscription: number
        auto_repeat_purchase: boolean
        processing_payment: number
        status: number
        refunded: boolean
    }
}


export function claimsFromPurchase_redux(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case GETTING_CLAIMS_FROM_PURCHASE: {
            return {
                case: GETTING_CLAIMS_FROM_PURCHASE,
                payload: []
            };
        }

        case GET_CLAIMS_FROM_PURCHASE_SUCCESS: {
            return {
                case: GET_CLAIMS_FROM_PURCHASE_SUCCESS,
                payload: action.payload
            };
        }

        case GET_CLAIMS_FROM_PURCHASE_ERROR: {
            return {
                case: GET_CLAIMS_FROM_PURCHASE_ERROR,
                payload: {}
            };
        }

        default:
            return state;
    }
}