import React from "react";
import styles from "./Cards.module.scss";
import cardStyles from "../Card/Card.module.scss"
import {Dots} from "react-activity";
import Card from "../Card/Card";
import Carousel from "react-multi-carousel";
import {ReactComponent as Plus} from "../../../01_application/assets/icons/plus.svg";
import {ReactComponent as Cancel} from "../../../01_application/assets/icons/red-cancel.svg";


const PaymentInfo = props => {
  
  const responsive = {
    // the naming can be any, depends on you.
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items     : 5,
      // partialVisibilityGutter: 40
      
    },
    desktop          : {
      breakpoint: {max: 3000, min: 1024},
      items     : 3,
      // partialVisibilityGutter: 40
    },
    tablet           : {
      breakpoint: {max: 1024, min: 612},
      items     : 2,
      // partialVisibilityGutter: props.width/30
    },
    mobile           : {
      breakpoint: {max: 612, min: 0},
      items     : 1,
      // partialVisibilityGutter:  props.width < 480 ?  props.width/4 : props.width /2.8
    }
  };
  
  const handleDeleteCardPrompt = (card_id) => {
    props.handleDeleteCardPrompt(card_id)
  }
  
  const handleSelectAddCard = (card_id) => {
    props.handleSelectAddCard(card_id)
  }
  
  const handleSelectCard = (card_id) => {
    props.handleSelectCard(card_id)
  }
  
  
  const renderCard = (card) => {
    const {_id, selected, name_on_card, last_4, exp_month, exp_year, brand} = card
    // console.log(_id)
    if (_id === "ADD") {
      return renderAddCard()
    } else {
      return (
        <div>
          <Card
            _id={_id}
            selected={selected}
            name_on_card={name_on_card}
            last_4={last_4}
            exp_month={exp_month}
            exp_year={exp_year}
            brand={brand}
            handleSelectCard={handleSelectCard}
          />
        </div>
      )
    }
  }
  const renderAddCard = () => {
    return (
      <div className={`${cardStyles.card} ${props.add_card ? cardStyles.selected : ""}`}
           onClick={() => handleSelectAddCard()}>
        <div className={styles.addCardBlock}>
          <Plus fill={"#565656"} className={styles.plusIcon}/>
          <div className={styles.addCardText}>
            Add Card
          </div>
        </div>
      </div>
    )
  }
  
  const renderCancelIcon = (card_id) => {
    if (props.edit_cards === true) {
      
      if (card_id === "ADD") {
        return null
      }
      
      return (
        <div className={`${styles.cancelBlock}`}>
          <Cancel className={`${styles.cancelIcon}`} onClick={() => handleDeleteCardPrompt(card_id)}/>
        </div>
      )
    }
  }
  
  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Dots/>
      </div>
    )
  }
  
  const mapCards = () => {
    return (
      props.saved_cards.map((card, index) => {
        return (
          <div key={card._id}>
            <div>
              {renderCancelIcon(card._id)}
            </div>
            {renderCard(card)}
          </div>
        )
      })
    )
  }
  
  
  // const renderCards = () => {
  //   if (props.width > 820) {
  //     return(
  //       <div className={styles.cardList}>
  //         {mapCards()}
  //       </div>
  //     )
  //   } else {
  //     return(
  //       <div className={styles.cardList}>
  //         <Carousel
  //           swipeable={true}
  //           draggable={true}
  //           showDots={true}
  //           responsive={responsive}
  //           ssr={false} // means to render carousel on server-side.
  //           // containerClass={styles.carouselContainer}
  //           containerClass={styles.cardList}
  //           itemClass={styles.carouselItem}
  //           infinite={props.saved_cards?.length > 1 ? true : false}
  //           keyBoardControl={true}
  //           partialVisible={props.saved_cards?.length > 1 ? true : false}
  //           removeArrowOnDeviceType={["tablet", "mobile"]}
  //           // customTransition="transform 180000ms linear"
  //           // beforeChange={() => {
  //           //   console.log("before change")
  //           //   body.classList.add("react-multi-carousel-scroll-lock");
  //           // }}
  //           // afterChange={() => {
  //           //   console.log("after change")
  //           //   body.classList.remove("react-multi-carousel-scroll-lock");
  //           // }}
  //           // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  //           // autoPlaySpeed={1000}
  //           // customTransition="all .5"
  //           // transitionDuration={500}
  //           // centerMode={true}
  //           // deviceType={this.props.deviceType}
  //           // dotListClass="custom-dot-list-style"
  //         >
  //           {mapCards()}
  //         </Carousel>
  //       </div>
  //     )
  //   }
  // }
  
  const renderSavedCardContainer = () => {
    // const body = document.body;
    
    if (props.GET_CARDS_LOADING === true) {
      return renderLoading()
    }
    
    if (props.saved_cards?.length > 0) {
      return (
        <div>
          
          <div className={styles.carouselContainer}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              // containerClass={styles.carouselContainer}
              containerClass={styles.cardList}
              itemClass={styles.carouselItem}
              infinite={props.saved_cards?.length > 1 ? true : false}
              keyBoardControl={true}
              partialVisible={props.saved_cards?.length > 1 ? true : false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // customTransition="transform 180000ms linear"
              // beforeChange={() => {
              //   console.log("before change")
              //   body.classList.add("react-multi-carousel-scroll-lock");
              // }}
              // afterChange={() => {
              //   console.log("after change")
              //   body.classList.remove("react-multi-carousel-scroll-lock");
              // }}
              // autoPlay={this.props.deviceType !== "mobile" ? true : false}
              // autoPlaySpeed={1000}
              // customTransition="all .5"
              // transitionDuration={500}
              // centerMode={true}
              // deviceType={this.props.deviceType}
              // dotListClass="custom-dot-list-style"
            >
              {mapCards()}
            </Carousel>
          </div>
          
          <div className={styles.cardListContainer}>
            {mapCards()}
          </div>
        
        
        
        </div>
      )
    }
  }
  
  
  const renderCardList = () => {
    return (
      <div className={styles.cardBlock}>
        {renderSavedCardContainer()}
      </div>
    )
  }
  
  return (
    <div className={styles.container}>
      {renderCardList()}
    </div>
  );
};

export default PaymentInfo;
