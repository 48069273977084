import React from "react";
import styles from "./UserPurchaseList.module.scss"
import InfiniteScroll from 'react-infinite-scroll-component';
import {Dots} from "react-activity";
import {getFullMonthName} from "../../../../04_utilities/core/dateTime/dateTime";
import {ReactComponent as Plate} from "../../../../01_application/assets/icons/lunch.svg";
import {ReactComponent as Visa} from "../../../../01_application/assets/ui/card_brands/visa.svg";
import {ReactComponent as MC} from "../../../../01_application/assets/ui/card_brands/mc_symbol.svg";
import {ReactComponent as Amex} from "../../../../01_application/assets/ui/card_brands/Amex_logo_color.svg";
import {ReactComponent as RightChevron} from "../../../../01_application/assets/icons/right-chevron.svg";
import {NavLink} from "react-router-dom";
import PurchaseTypeTags from "../../../components_common/PurchaseTypeTags/PurchaseTypeTags";
import {PeriodInterface, UserPurchasePayload} from "../../../../02_redux/actions";
import PurchaseStatusTag from "../../../components_common/PurchaseStatusTag/PurchaseStatusTag";


export interface UserPurchaseListProps {
  handleGetMorePurchases: () => void
  GET_USER_PURCHASES_LOADING: boolean
  EXTENDED_GET_USER_PURCHASES_LOADING: boolean
  periods: Array<PeriodInterface>
  GET_USER_PURCHASES_ERROR: boolean
  has_more: boolean
}

const UserPurchaseList = (props: UserPurchaseListProps) => {

  const handleGetMorePurchases = () => {
    props.handleGetMorePurchases()
  }

  const renderCardIcon = (brand: string) => {
    switch (brand) {
      case "Visa": {
        return (
                <Visa className={styles.visaIcon}/>
        )
      }

      case "MasterCard": {
        return (
                <MC className={styles.masterCardIcon}/>
        )
      }

      case "American Express": {
        return (
                <Amex className={styles.amexIcon}/>
        )
      }

      default:
        return null
    }
  }


  const renderLoading = () => {
    return (
            <div className={styles.loading}>
              <Dots/>
            </div>
    )
  }

  const renderEndOfList = () => {
    return (
            <div className={styles.loading}>
              That's all folks!
            </div>
    )
  }


  const renderRow = (item: UserPurchasePayload) => {
    const {
      _id,
      stall_id,
      initiative_id,
      display_name,
      card_brand,
      card_last4,
      purchased,
      suspended_count,
      created_at,
      auto_repeat_purchase,
      subscription,
      refunded,
      processing_payment,
      subscription_number,
      status,
    } = item
    const {stallname} = stall_id
    const {name} = initiative_id

    let purchaseType = ""
    if (subscription === 1) {
      purchaseType = "SUBSCRIPTION"
    } else if (auto_repeat_purchase === true) {
      purchaseType = "AUTO_REPEAT_PURCHASE"
    }

    return (
            <NavLink className={styles.rowBlock} to={`/claims/purchase_id=${_id}`}>
              <div className={styles.leftBlock}>

                <div className={styles.nameBlock}>
                  <div className={styles.nameText}>
                    {display_name}
                  </div>
                  &nbsp;   &nbsp;   &nbsp;   &nbsp;
                  <PurchaseTypeTags
                          purchaseType={purchaseType}
                  />

                  &nbsp;
                  &nbsp;


                  <PurchaseStatusTag
                          refunded={refunded}
                          processing_payment={processing_payment}
                          status={status}
                  />


                </div>

                {/*<div>*/}
                {/*  {stallname}*/}
                {/*</div>*/}

                <div className={styles.subInfoBlock}>
                  <div className={styles.keyBlock}>
                    purchased on:
                  </div>

                  <div className={styles.dataBlock}>
                    {new Date(created_at).toLocaleDateString()}
                  </div>
                </div>

                <div className={styles.subInfoBlock}>
                  <div className={styles.keyBlock}>
                    meals purchased:
                  </div>
                  <div className={styles.dataBlock}>
                    {purchased}
                  </div>
                </div>

                <div className={styles.subInfoBlock}>
                  <div className={styles.keyBlock}>
                    meals remaining:
                  </div>
                  <div className={styles.dataBlock}>
                    {suspended_count}
                  </div>
                </div>

                <div className={styles.subInfoBlock}>
                  <div className={styles.keyBlock}>
                    payment:
                  </div>
                  <div className={`${styles.dataBlock} ${styles.paymentBlock} ${styles.subText}`}>
                    {renderCardIcon(card_brand)} {card_brand} ending with {card_last4}
                  </div>
                </div>

                {/*<div className={`${styles.paymentBlock} ${styles.subText}`}>*/}
                {/*  {renderCardIcon(card_brand)}  &nbsp;  &nbsp; ****  &nbsp;  &nbsp; **** &nbsp; &nbsp; ****  &nbsp; &nbsp;{card_last4}*/}
                {/*</div>*/}

                {/*<div className={`${styles.paymentBlock} ${styles.subText}`}>*/}
                {/*  {renderCardIcon(card_brand)} &nbsp; {card_brand} ending with {card_last4}*/}
                {/*</div>*/}

              </div>

              <div className={styles.rightBlock}>
                <RightChevron fill={"#74B8AE"} className={styles.chevronIcon}/>
              </div>

              {/*<div>*/}
              {/*  {created_at}*/}
              {/*</div>*/}
              <br/>


            </NavLink>
    )

  }


  const renderEmptyPurchases = () => {
    return (
            <div className={styles.emptyMealsContainer}>
              <div className={styles.iconBlock}>
                <Plate fill={"#656565"} className={styles.icon}/>
              </div>

              <div className={styles.noDonationText}>
                no donations this month
              </div>
            </div>
    )

  }

  const renderLineSeparator = (count: number, index: number) => {
    if (count !== index + 1) {
      return (
              <div className={styles.lineSeparator}/>
      )
    } else {
      return (
              <div className={styles.height}/>
      )
    }
  }

  const renderPurchaseList = (periodPurchase: Array<UserPurchasePayload> | null) => {

    if (!periodPurchase) {
      return renderEmptyPurchases()
    }

    return periodPurchase.map((purchases: UserPurchasePayload, index) => {
      // console.log(index)
      return (
              <div key={purchases._id} className={styles.rowContainer}>
                {renderRow(purchases)}
                {renderLineSeparator(periodPurchase.length, index)}
              </div>
      )
    })
  }


  const renderPeriod = (period: PeriodInterface) => {

    const {year, month_index} = period
    return (
            <div className={styles.periodContainer}>
              <div className={styles.periodBlock}>
                <div className={styles.periodHeaderBlock}>
                  <div className={styles.periodHeaderText}>
                    {getFullMonthName(month_index)} {year}
                  </div>
                </div>

                {renderPurchaseList(period.payload)}
              </div>
            </div>
    )
  }

  const emptyContent = () => {
    return (
            <div className={styles.periodContainer}>
              <div className={styles.periodBlock}>
                <br/>
                <br/>
                <div className={styles.iconBlock}>
                  <Plate fill={"#656565"} className={styles.icon}/>
                </div>

                <div>
                  You have not bought any meals yet
                </div>
                <br/>
                <br/>
                <br/>
              </div>
            </div>
    )
  }

  const renderList = () => {

    if (props.periods.length === 0) {
      return emptyContent()
    }

    return (
            <InfiniteScroll
                    dataLength={props.periods.length}
                    next={handleGetMorePurchases}
                    hasMore={props.has_more}
                    loader={renderLoading()}
                    endMessage={renderEndOfList()}
                    className={styles.infiniteScroll}
            >
              {props.periods.map((item, i) => {
                return (
                        <div key={item.order}>
                          {renderPeriod(item)}
                        </div>
                )
              })}
            </InfiniteScroll>

    )
  }

  const renderContent = () => {
    if (props.GET_USER_PURCHASES_LOADING) {
      return renderLoading()
    }


    return renderList()

  }

  return (
          <div className={styles.container}>
            <div className={styles.subContainer}>
              <div className={styles.headerBlock}>
                <div className={styles.headerText}>
                  Meal Purchases
                </div>
              </div>
              {renderContent()}
            </div>
          </div>
  )
}

export default UserPurchaseList