import apiFlight from "../redux_utilities/apiFlightInterceptor";
import {VERIFY_EMAIL} from "../redux_config/types";
import {USER_V2_VERIFY_EMAIL} from "../redux_config/endpoints";
import {isAuthenticated} from "../redux_utilities/authenticationStatus";
import {isLoading} from "../redux_utilities/loadingStatus";


export const USER_V2_VERIFY_EMAIL_ACTION = (payload) => {
  
  return async dispatch => {
    
    if (isLoading(VERIFY_EMAIL) === true) {
      return dispatch({type: "PREVIOUS_ACTION_STILL_LOADING"})
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`
      }
    }
    // const params = {}
    
    try {
      await apiFlight(VERIFY_EMAIL, dispatch).post(
        USER_V2_VERIFY_EMAIL,
        payload,
        config
      )
    } catch (err) {
      console.log(err)
    }
  }
  
}
