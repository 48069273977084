import styles from "./Address.module.scss";
import {ReactComponent as Stamp} from "../../../01_application/assets/icons/stamp.svg";
import React from "react";

const Address = props => {
  const {_id, name, address_street, address_unit_no, address_postal_code, address_country, email, contact_number, selected} = props
  const {handleSelectAddress} = props
  
  return (
    <div className={`${styles.addressCard} ${selected ? styles.selected : ""}`}
         onClick={() => handleSelectAddress(_id)}>
      <div className={styles.addressDetailsBlock}>
        
        <div className={styles.stampBlock}>
          <div>
            {/*<Pencil fill={"#74B8AE"} className={styles.editIcon}/>*/}
            {/*<Trash fill={"#74B8AE"} className={styles.trashIcon}/>*/}
          </div>
          <Stamp fill={"#818181"} className={styles.stampIcon}/>
        </div>
        
        <div className={styles.detailsBlock}>
        

        <div>
          {name}
        </div>
        <div>
          {contact_number}
        </div>
        
        <div className={styles.spacerBelowContactDetails}/>
        
        <div>
          {address_street}
        </div>
        <div>
          {address_unit_no}
        </div>
        <div>
          {address_postal_code}
        </div>
        <div>
          {address_country}
        </div>
        </div>
      </div>
    </div>
  )
}

export default Address