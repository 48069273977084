import React, {Component} from "react";
import moment from "moment";

import "./SuspendedMealsTableActive.scss";
import styles from "./SuspendedMealsTableActive.module.scss"
import sanitizeHtml from "sanitize-html-react";


class SuspendedMealsTable extends Component {
  
  handleViewTrimmedMessageActive=(i)=>{
    this.props.handleViewTrimmedMessageActive(i)
  }
  
  renderPaymentProcessing = (purchase) => {
    if (purchase?.processing_payment === 1) {
      return (
        <i className={"processing-payment"}>Processing Payment</i>
      )
    }
  }
  
  
  renderTag = (purchase) => {
    if (purchase?.auto_repeat_purchase === true) {
      return (
        <i className={styles.autoRepeatPurchase}>Automated Repeat Purchase</i>
      )
    }
    
    if (purchase?.subscription === 1) {
      return (
        <i className={styles.monthlySubscription}>Monthly Subscription</i>
      )
    }
  }
  
  renderFullPostMessage = (message) => {
    return (
      <div
        className={styles.postMessageTextArea}
        dangerouslySetInnerHTML={{__html: sanitizeHtml(message)}}
      />
    )
  }
  
  renderTrimmedPostMessage = (i, message, msgLimit) => {
    
    //trim the string to the maximum length
    let trimmedString = message.substr(0, msgLimit);
    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    trimmedString = trimmedString.concat(" ...")
    return (
      <div>
        
        <div
          className={styles.postMessageTextArea}
          dangerouslySetInnerHTML={{__html: sanitizeHtml(trimmedString)}}
        />
        
        <div className={styles.viewMoreText} onClick={() => this.handleViewTrimmedMessageActive(i)}>
          View More
        </div>
      </div>
    )
  }
  
  renderPostMessage = (i, post_message, viewTrimmedMessage) => {
    
    if (post_message !== undefined) {
      if (post_message.trim() !== "") {
        // console.log(post_message)
        
        const replaceMsg = post_message.replace(new RegExp('\r?\n', 'g'), '<br />')
        const msgLength = replaceMsg.length
        const msgLimit = 300
        
        
        if (msgLength > msgLimit && viewTrimmedMessage !== true) {
          return (
            this.renderTrimmedPostMessage(i, post_message, msgLimit)
          )
        }
        
        return this.renderFullPostMessage(replaceMsg)
        
        
      }
    }
  }
  
  
  renderRows = () => {
    if (this.props.data.length > 0) {
      return this.props.data.map((purchase, i) => {
        // console.log("purpurpurpurpurpurpurpurpur", purchase);
        return (
          <div className={styles.tableRow} key={purchase._id}>
            
            <div className={styles.mainInfoContainer}>
              <div className={styles.leftInnerRowContainer}>
                <div className={styles.rowDate}>
                  {moment(purchase.created_at).format("Do MMMM YYYY")}
                </div>
                
                <div className={styles.displayName}>
                  {purchase.display_name}
                </div>
                
                {this.renderTag(purchase)}
                {this.renderPaymentProcessing(purchase)}
              
              </div>
              
              
              <div className={styles.rightInnerRowContainer}>
                <div className={styles.countContainer}>
                  {purchase.suspended_count}/{purchase.purchased}
                </div>
              </div>
            </div>
            
            <div className={styles.userPurchaseStats}>
              <div className={""}>
                Meals available for claim: &nbsp;
              </div>
              
              <div className={""}>
                {purchase.suspended_count}
              </div>
            </div>
            
            <div className={styles.userPurchaseStats}>
              <div className={""}>
                Total meals purchased:
              </div>
              
              <div className={""}>
                {purchase.purchased}
              </div>
            </div>
            
            {this.renderPostMessage(i, purchase.post_message, purchase.viewTrimmedMessage)}
          
          </div>
        );
      });
    }
  };
  
  render = () => {
    //console.log("yoyoyoyoyoyoy", this.props.data);
    return <div className={styles.tableContainer}>{this.renderRows()}</div>;
  };
}


export default SuspendedMealsTable;
