import styles from "./SignInPrompt.module.scss"
import ButtonFilled from "../../components_core/Button/ButtonFilled";
import {InputText, InputType} from "../../components_core/InputText/InputText";
import {UseFormReturn} from "react-hook-form";
import SM2 from "../../../01_application/assets/ui/Frame 1.png";
import React from "react";
import Interaction from "../../components_higherOrder/Ping/Interaction";

interface SignInPromptProps {
  handleProceedAsGuest: () => void
  handleAuthenticateAccount: () => void
  handleViewForgotPassword: () => void
  handleViewCreateAccount: () => void
  register: UseFormReturn['register'];
  AUTHENTICATE_ACCOUNT_LOADING: boolean
}

const SignInPrompt = (props: SignInPromptProps) => {

  const {
          handleProceedAsGuest,
          handleAuthenticateAccount,
          handleViewForgotPassword,
          handleViewCreateAccount,
          register,
          AUTHENTICATE_ACCOUNT_LOADING
        } = props

  return (
    <div className={styles.container}>

      <div>
        {/*<img src={Logo} alt="socialgood" className={styles.logoImage}/>*/}
        <img src={SM2} alt="socialgood" className={styles.logoImage}/>
        {/*<img src={SM} alt="secondmeal" className={styles.logoImage}/>*/}
      </div>

      <div className={styles.header}>
        Sign in for faster checkout.
      </div>

      <br/>
      <br/>
      <br/>

      <div className={styles.subContainer}>
        <div className={styles.content}>

          <div className={styles.leftBlock}>
            <div className={styles.subHeader}>
              Sign in with your Secondmeal Account
            </div>


            <InputText
              inputType={InputType.EMAIL}
              fieldName={"email"}
              fieldLabel={"email"}
              fieldError={false}
              register={register}
            />

            <InputText
              inputType={InputType.PASSWORD}
              fieldName={"password"}
              fieldLabel={"password"}
              fieldError={false}
              register={register}
            />

            <br/>
            <br/>

            <ButtonFilled
              buttonText={"Sign In"}
              onClick={handleAuthenticateAccount}
              isLoading={AUTHENTICATE_ACCOUNT_LOADING}
            />

            <br/>

            <a onClick={handleViewCreateAccount}>
              create account
            </a>

            <br/>

            <a onClick={handleViewForgotPassword}>
              forgot password
            </a>


          </div>


          <div className={styles.verticalLine}/>

          <div className={styles.rightBlock}>
            <div className={styles.subHeader}>
              Guest Checkout
            </div>

            <br/>

            <div>
              Proceed and create an account later.
            </div>

            <br/>

              <ButtonFilled
                buttonText={"continue as guest"}
                element_name={"continue_as_guest"}
                onClick={handleProceedAsGuest}
              />


          </div>
        </div>
      </div>
    </div>
  )
}

export default SignInPrompt
