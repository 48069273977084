import React, {PureComponent} from "react";

import {ErrorMessage, Field, Form, Formik} from 'formik';
import "./FormSection.modules.scss"
import Dropzone from "./Dropzone";
// import FormikPersist from "../../../common_components/FormikPersist.jsx";
import {Dots} from "react-activity";
// import 'react-activity/lib/Dots/Dots.css';


let initialValues = {
  name         : '',
  gender       : 2,
  dd           : "",
  mm           : "",
  yyyy         : "",
  contactNumber: '',
  notes        : '',
  initiativeProfile: ""
}

class FormSection extends PureComponent {
  
  componentDidMount() {
  
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
  
  }
  
  handleSubmitBeneficiaryRecommend = (values) => {
    this.props.handleSubmitBeneficiaryRecommend(values)
  }
  
  handleTab = (event, limit, setFieldValue) => {
    if (event.currentTarget.value.length >= limit) {
      event.target.nextElementSibling !== null ? event.target.nextElementSibling.focus() : console.log()
    }
    
    setFieldValue(event.currentTarget.name, event.currentTarget.value, true)
  }
  
  handleUpdateField = (setFieldValue, field, value) => {
    setFieldValue(field, value)
  }
  
  
  handleValidation = (values) => {
    
    // console.log(values)
    
    const errors = {};
    
    // if (!values.email) {
    //   errors.email = 'Required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    // ) {
    //   errors.email = 'Invalid email address';
    // }
    
    
    if (!values.name) {
      errors.name = 'Required';
    }
    
    if (!values.dd) {
      errors.yyyy = 'Required';
    }
    
    if (!values.mm) {
      errors.yyyy = 'Required';
    }
    
    if (!values.yyyy) {
      errors.yyyy = 'Required';
    }
    
    if (parseInt(values.dd) > 31) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.mm) > 12) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.yyyy) > 2022) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    if (parseInt(values.yyyy) < 1900) {
      errors.yyyy = 'Invalid Date of Birth';
    }
    
    return errors;
  }
  
  renderUploadError = () => {
    if (this.props.profilePictureMissing) {
      return <div className={"error-block"}>missing profile picture</div>
    }
  }
  
  renderButtonContent = () => {
    if (this.props.B_ONBOARD_LOADING) {
      return <Dots/>
    }
    
    return (
      <div className={"buttonContentSubBlock"}>
        <div className={"buttonContentSubBlock"}>
          Submit
        </div>
      </div>
    )
  }
  
  render() {
    return (
      <div className={"form-block"}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={values => this.handleValidation(values)}
          onSubmit={(values) => {
            this.handleSubmitBeneficiaryRecommend(values);
          }}
        >
          {({setFieldValue, values}) => (
            <Form>
              {/*<FormikPersist name="FormName"/>*/}
              
              <div className={"form-label"}>
                Name
              </div>
              <div className={"form-subLabel"}>
                Tell us your name so that we can address you!
              </div>
              <Field type="name" name="name" className={"form-field"}/>
              <ErrorMessage name="name">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              <div className={"form-label"}>
                Gender
              </div>
              
              <div className={"radioHorizontalOptionsBlock"}>
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.gender === 1}
                    onChange={() => {
                      this.handleUpdateField(setFieldValue, "gender", 1)
                    }}
                  />
                  Male
                </div>
                
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.gender === 2}
                    onChange={() => {
                      this.handleUpdateField(setFieldValue, "gender", 2)
                    }}
                  />
                  Female
                </div>
              </div>
              
              <div className={"form-label"}>
                Date Of Birth
              </div>
              
              <div className={"DOBBlock"} aria-label="Date of Birth">
                <Field type="number" name="dd" placeholder="DD" className={"form-field DD"}
                       onChange={e => this.handleTab(e, 2, setFieldValue)}/>
                <Field type="number" name="mm" placeholder="MM" className={"form-field MM"}
                       onChange={e => this.handleTab(e, 2, setFieldValue)}/>
                <Field type="number" name="yyyy" placeholder="YYYY" className={"form-field YYYY"}
                       onChange={e => this.handleTab(e, 4, setFieldValue)}/>
              </div>
              
              <ErrorMessage name="yyyy">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              {/*<div className={"form-label"}>*/}
              {/*  Email*/}
              {/*</div>*/}
              {/*<div className={"form-subLabel"}>*/}
              {/*  We will email you your peronalised link where you can access your QR code*/}
              {/*</div>*/}
              {/*<Field type="email" name="email" className={"form-field"} />*/}
              {/*<ErrorMessage name="email" >{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>*/}
              
              <div className={"form-label"}>
                Contact Number
              </div>
              <div className={"form-subLabel"}>
                So that we can contact you, if not we may pop by unannounced
              </div>
              <Field name="contactNumber" className={"form-field"}/>
              <ErrorMessage name="contactNumber">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              
              <div className={"form-label"}>
                Tell us more, which profile do you think you fit in?
              </div>
              
              <div className={"radioVerticalOptionsBlock"}>
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.initiativeProfile === 1}
                    onChange={() => {
                      this.handleUpdateField(setFieldValue, "initiativeProfile", 1)
                    }}
                  />
                  Person with disabilities or special needs
                </div>
                
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.initiativeProfile === 2}
                    onChange={() => {
                      this.handleUpdateField(setFieldValue, "initiativeProfile", 2)
                    }}
                  />
                  Elder in need with no dependents
                </div>
                
                <div className={"radioHorizontalOptionBlock"}>
                  <input
                    type="radio"
                    //value={result.SITE_NAME}
                    checked={values.initiativeProfile === 3}
                    onChange={() => {
                      this.handleUpdateField(setFieldValue, "initiativeProfile", 3)
                    }}
                  />
                  None of the above
                </div>
              
              </div>
              
              
              <div className={"form-label"}>
                Other Notes and Address
              </div>
              <div className={"form-subLabel"}>
                Please include an address so that we can do a house visit.
              </div>
              <div className={"form-subLabel"}>
                A short write up about yourself will be helpful, if someone recommended you, please let us know too!
              </div>
              
              <Field type="notes" name="notes" className={"form-field"} component="textarea" rows={8}/>
              <ErrorMessage name="notes">{msg => <div className={"error-block"}>{msg}</div>}</ErrorMessage>
              
              
              <Dropzone
                handleUploadDocument={this.props.handleUploadDocument}
                UPLOAD_DOCUMENT_LOADING={this.props.UPLOAD_DOCUMENT_LOADING}
                initiative_id={this.props.initiative_id}
              />
              
              {this.renderUploadError()}
              
              <div className={"buttons"}>
                <button
                  type="submit"
                  className={"submit-button"}
                >
                  {this.renderButtonContent()}
                </button>
                
                <br/>
              
              </div>
            
            
            </Form>
          )}
        </Formik>
      </div>
    )
  }
  
}

export default FormSection
