import React from "react";
//import infoIcon from "../../../../assets/icons/info.svg";
// import styles from "./HeroSection.module.scss";
// import styles from "./HeroSection_Fresh.module.scss";
import styles from "./HeroSection_quatedge.module.scss"

import logo from "../../../../01_application/assets/ui/logo-secondmeal-yellow-bowl.svg";

//import "./initiativeStatusCard.module.scss";
//import logo from "../../../../assets/ui/logo-secondmeal-yellow-bowl.svg";

//ORGINAL
const HeroSection = props => {
  return (
    <div className={styles.hero}>
      <img src={logo} alt="secondmeal" className={styles.logo} />
      
      <div className={styles.heroText}>Have you had your lunch?</div>
      
      <div className={styles.heroParagraphText}>
        A humble hawker meal costs you around $5. For most of us, we
        don't feel the pinch and we can easily afford to treat a friend
        to a meal.
      </div>
      
      <div className={styles.heroParagraphText}>
        <div className={styles.heroParagraphTextAccent}>
          So why not buy a meal for someone in need today?
        </div>
      </div>
    </div>
  );
};

export default HeroSection;


// const HeroSection = props => {
//   return (
//         <div className={styles.hero}>
//             <img src={freshLogo} alt="secondmeal" className={styles.logo} />
//
//             {/*<h2 className={styles.heroText}>Have you had your lunch?</h2>*/}
//
//             <h3 className={styles.heroParagraphText}>
//               This year more than ever, we understand that together
//               isn't about physical distance as much as connection.
//             </h3>
//
//           <h3 className={styles.heroParagraphText}>
//             <h3 className={styles.heroParagraphText}>
//               This December, we come together with Fresh in the <b>#TogetherIsBeautifulSG</b> campaign
//               to celebrate the spirit of giving and togetherness.
//             </h3>
//           </h3>
//
//            <NavLink className={styles.callToActionBlock}   to={"/fresh"}>
//              <div className={styles.callToActionText}>
//              Join us in sharing the love
//              </div>
//            </NavLink>
//         </div>
//     );
// }


// const HeroSection = props => {
//
//   let percentage = 0
//
//   if (props.GET_NEW_SUBSCRIPTION_COUNT_SUCCESS) {
//     percentage = (props.GET_NEW_SUBSCRIPTION_COUNT_SUCCESS?.payload.campaign_subscription_count / 3000) * 100
//   }
//
//
//   return (
//     <div className={styles.hero}>
//
//       <div className={styles.logoBlock}>
//         {/*<img src={qfs} alt="secondmeal" className={styles.quantedgeLogo}/>*/}
//         <img src={logo} alt="secondmeal" className={styles.logo}/>
//
//       </div>
//
//       <div className={styles.heroText}>
//         We are in a quest to reach 3,000 meal subscriptions.
//       </div>
//
//
//       <div className={styles.progressBarBlock}>
//
//         <div className={styles.progressBarIndicatorBlock}>
//           <div>0</div>
//           <div>3,000</div>
//         </div>
//
//         <div className={styles.progressBar}>
//           <div
//             style={{
//               // background: "#74B8AE",
//               borderBottomLeftRadius : 8,
//               borderBottomRightRadius: 4,
//               borderTopLeftRadius    : 8,
//               borderTopRightRadius   : 4,
//               height                 : 20,
//               background             : "#f1c40f",
//               width                  : `${percentage}%`
//             }}
//           />
//
//
//
//
//           <div className={styles.progressBarMealCount}>
//             {props.GET_NEW_SUBSCRIPTION_COUNT_SUCCESS?.payload?.campaign_subscription_count} meals subscribed
//           </div>
//         </div>
//       </div>
//
//       <div className={styles.heroParagraphText}>
//         Food is a necessity for all. Yet even in a country as developed as Singapore, some are not always able to afford
//         sufficient or nutritious food.
//       </div>
//
//
//       <div className={styles.heroParagraphText}>
//         It has always been Secondmeal's goal to combat food insecurity while also supporting
//         the livelihoods of local hawkers. We do so by facilitating meal donations to those in need which these meals are
//         claimable by beneficiaries at participating hawker stalls.
//       </div>
//
//
//       <div className={styles.heroParagraphText}>
//         #3000mealsubs
//       </div>
//
//
//       <a className={styles.callToActionBlock}
//          href="https://www.secondmeal.sg/a/in-conjunction-with-world-hunger-day,-have-your-meals-matched-on-secondmeal-fhqkql">
//         <div className={styles.callToActionText}>
//           Find Out More!
//         </div>
//       </a>
//
//       <div className={styles.heroParagraphText}>
//         {/*<div className={styles.heroParagraphTextAccent}>*/}
//         {/*  So why not buy a meal for someone in need today?*/}
//         {/*</div>*/}
//       </div>
//     </div>
//   );
// };
//
// export default HeroSection;


// <NavLink
//   to={"/dashboard"}
//   className={`${ACTIVE_MENU_ITEM === "1" ? rowBlockWithNoHover : rowBlockWithHover}`}
//   // activeClassName="HeaderNavItem__link--active"
// >


