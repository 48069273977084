import React from "react";
import styles from "./NewPasswordForm.module.scss";
import SM2 from "../../../../01_application/assets/ui/Frame 1.png";
import classNames from "classnames/bind";
import {Dots} from "react-activity";
import {InputText, InputType} from "../../../components_core/InputText/InputText";

var cx = classNames.bind(styles);

const NewPasswordForm = props => {
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleResetPassword()
    }
  }
  
  const handleResetPassword = () => {
    props.handleResetPassword()
  }
  
  const renderSignInButtonContent = () => {
    if (props.RESET_PASSWORD_WITH_VERIFICATION_CODE_LOADING) {
      return <Dots/>
    }
    
    return "Reset password"
  }
  
  //if path is in pathForTealHeaderArr, set background to teal
  const size = cx({
    headingText : true,
    heading3Text: props.headingTextSize === "Heading3" ? true : false,
  });

  return (
    <div className={styles.container}>
      
      <div>
        {/*<img src={Logo} alt="socialgood" className={styles.logoImage}/>*/}
        <img src={SM2} alt="socialgood" className={styles.logoImage}/>
        {/*<img src={SM} alt="secondmeal" className={styles.logoImage}/>*/}
      </div>
      
      <div className={size}>
        {props.headingText}
      </div>
      
      <div className={styles.subheadingText}>
        Enter your email address below and we'll send you a link to reset your password.
      </div>
      
      
      <div className={styles.formBlock}>
        
        
        <InputText
          fieldName={"password"}
          // fieldLabel={"Credit/Debit Card Number"}
          fieldLabel={""}
          fieldPlaceHolder={"Password"}
          fieldErrorMessage={props.formErrors.password?.message ? props.formErrors.password?.message : null}
          fieldError={false}
          inputType={InputType.TEXT}
          register={props.register}
        />
        
        
        <InputText
          fieldName={"repeat_password"}
          // fieldLabel={"Credit/Debit Card Number"}
          fieldLabel={""}
          fieldPlaceHolder={"repeat password"}
          fieldErrorMessage={props.formErrors.repeat_password?.message ? props.formErrors.repeat_password?.message : null}
          fieldError={false}
          inputType={InputType.TEXT}
          register={props.register}
        />
        
        {/*<div className={styles.subheadingText}>*/}
        {/*  Your Social ID is the account you use to access services under our umbrella, namely <a href={"https://www.secondmeal.io/"}>Secondmeal</a> and  <a href={"https://www.secondmeal.io/"}>SocialGood</a>*/}
        {/*</div>*/}
      </div>
      
      
      <div className={styles.signInBlock}>
        <div className={styles.priBtn} onClick={handleResetPassword}>
          {renderSignInButtonContent()}
        </div>
      </div>
      
      <br/>
    
    </div>
  );
};

export default NewPasswordForm;
