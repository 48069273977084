import React from "react";
import {Dots} from "react-activity";
import styles from "./Loading.module.scss"
import "react-activity/dist/Dots.css";


const LoadingDots = () => {
  return (
    <div className={styles.block}>
      <Dots/>
    </div>
  )
}

export default LoadingDots
