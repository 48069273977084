import {INITIATED_ACTION, INITIALISE_NEW_SESSION, INITIALISE_NEW_CLIENT,} from "../redux_config/types";


export interface InitiatedActionInterface {

}

export const initiatedAction = (props: InitiatedActionInterface) => ({
    type: INITIATED_ACTION,
    payload: null
});


export interface InitiatedNewSessionActionInterface {
    s_id: string
}


export const initialiseNewSessionAction = (props: InitiatedNewSessionActionInterface) => ({
    type: INITIALISE_NEW_SESSION,
    payload: props
});


export interface InitiatedNewClientActionInterface {
    c_id: string
}

export const initialiseNewClientAction = (props: InitiatedNewClientActionInterface) => ({
    type: INITIALISE_NEW_CLIENT,
    payload: props
});

